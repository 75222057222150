import React, { useContext, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { lodge as api, worksite } from '../../../apis';
import _, { isNull, map } from 'lodash';
import { showAlert } from '../../../utils/common';
import { MessageContext, MessageImpl } from '../../../routes';

import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { useStores } from '../../../store/mobx';
import { Building } from '../../templetes/building';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';


//발송대상 팝업
const ComboFilter = forwardRef((props: any, ref: any) => {
  const [filterText, setFilterText] = useState('');
  const [items, setItems] = useState<string[]>([]);

  // const init = () => {
  //   console.log("init");
  // };

  // useEffect(() => {
  //   props.api.addEventListener("RowDataUpdatedEvent", init);
  //   return () => {
  //     props.api.removeEventListener("RowDataUpdatedEvent", init);
  //   };
  // }, []);

  useEffect(() => {
    props.parentFilterInstance((instance: any, event: any) => {
      return instance.onFloatingFilterChanged("contains", filterText);
    });
  }, [filterText]);

  useImperativeHandle(ref, () => ({
    onParentModelChanged(parentModel: any) {
      if (parentModel === null) {
        setItems([]);
      }
      setFilterText(!parentModel ? '' : parentModel.filter);
    }
  }));

  const valueChanged = (event: any) => {
    setFilterText(event.target.value);
  };

  const valueFocus = (event: any) => {
    // console.log(event.target.value, props.api.getSelectedRows());
    if (items.length === 0) {
      const _items: any = {};
      props.api.forEachNodeAfterFilterAndSort((node: any) => {
        const keyValue: string = props.api.getValue(props.column.colId, node);
        _items[keyValue || ''] = keyValue || '' ;
      });
      setItems(Object.keys(_items));
    }
  };

  return (
    <select
      style={{ width: '100%' }}
      value={filterText}
      onChange={valueChanged}
      onFocus={valueFocus}
    >
      <option value="">전체</option>
      {items.map((item, index) => <option key={index} value={item}>{item}</option>)}
    </select>
  );
});

const ChoiceGuests = (props: any) => {
  const {headerStore: headerStore} = useStores();
  const [sourceGrid, setSourceGrid] = useState<any>(null);
  const [targetGrid, setTargetGrid] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [sources, setSources] = useState<any[]>([]);
  const [targets, setTargets] = useState<any[]>([]);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    (async () => {
      if (props.isOpen) {
        await fetchItems();
      }
    })();
  }, []);

  const fetchItems = async () => {
    // console.log("입숙>>>headerStore.workSite", headerStore.workSite);
    headerStore.getAuthRoomRole();
    headerStore.getAuthWorkSiteRole();
    headerStore.getAuthBuilding();
    
    const payload : any = {
      worksite : headerStore.workSite,
      roomtype : headerStore.paramAuthRoomTypeRole,
      status : 3,
    }
    
    if(Number(headerStore.workSite) === 2) {
      if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
        payload.authBuilding = headerStore.paramAuthBuildingRole;
      }
    }


    try {
      headerStore.isLoading = true;
      const { data } = await api.lodgeGuests(payload);
      setSources(_.differenceBy(data, props.guests, 'id'));
      setTargets(_.intersectionBy(data, props.guests, 'id'));
      headerStore.isLoading = false;
    }catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다.");
      return;
    }
  };

  const onDownHandler = (e: any) => {
    const selectedRows = [ ...sourceGrid.getSelectedRows() ];
    const changeSources = _.differenceBy(sources, selectedRows, 'id');
    setSources([ ...changeSources ]);
    setTargets([ ...targets, ...selectedRows ]);
  };

  const onUpHandler = (e: any) => {
    const selectedRows = [ ...targetGrid.getSelectedRows() ];
    const changeTargets = _.differenceBy(targets, selectedRows, 'id');
    setTargets([ ...changeTargets ]);
    setSources([ ...sources, ...selectedRows ]);
  };

  const close = async () => {
    setTargets([]);
    setSources([]);
    props.setIsOpen(false);
  };

  const onApply = () => {
    if (targets.length === 0) {
      info('발송 대상자를 선택하세요.');
      return;
    } 
    
    props.onApply(targets.map(target => ({
      id: target.id,
      guest_cellphone : target.guest_cellphone,
      guest_name : target.guest_name,
      email : target.email
    }))); 

    close();
  };

  const onSourceGridReady = (params: any) => {
    setSourceGrid(params.api);
  }

  const onTargewtGridReady = (params: any) => {
    setTargetGrid(params.api);
  }

  const filterRowSelectable = (node: any) => {
    // console.log(node);
    return node ? true : false;
  };

  return (
    <div
      className="pop_dim_wrap pop_modal pop_lg"
      style={{ display: props.isOpen ? 'block' : 'none'}}
    >
      <div className="pop_wrap"style={{width: '1050px'}}>
        <button
          className="pop_btn_close"
          onClick={close}>
          닫기
        </button>
        <div className="title_wrap">발송 대상 선택</div>

        <div className="contents_wrap">
          <div className="title_contents bd0">
            이용자
          </div>
          <div className="ag-theme-balham" style={{height: '350px'}}>
            <AgGridReact
              //components={{datePicker: () => {}}}
              modules={AllCommunityModules}
              defaultColDef={{
                width: 110,
                wrapText: true,
                resizable: true,
                floatingFilter: true,
                // autoHeight: true,
                cellStyle: { textAlign: 'center' },
                headerClass: 'text-center',
              }}
              suppressRowTransform={true}
              suppressRowClickSelection={true}
              frameworkComponents={{ comboFilter: ComboFilter }}
              onGridReady={onSourceGridReady}
              multiSortKey={'ctrl'}
              rowSelection={'multiple'}
              rowData={sources}
            >
              <AgGridColumn
                headerName=""
                editable={false}
                headerCheckboxSelection={true}
                checkboxSelection={true}
                headerCheckboxSelectionFilteredOnly={true}
                sortable={false}
                width={41}
                resizable={false}
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn
                headerName="사업장유형"
                field="room_work_site"
                width={150}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="빌딩"
                field="room_building_name"
                width={160}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="동"
                field="room_building_dong"
                width={110}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="호수"
                field="room_name"
                width={105}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="사번"
                field="guest_employee_number"
                width={120}
                filter="agTextColumnFilter"
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="사원명"
                field="guest_name"
                width={120}
                filter="agTextColumnFilter"
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="휴대폰 번호"
                field="guest_cellphone"
                width={145}
                filter="agTextColumnFilter"
                unSortIcon={true} sortable={true}
              />
            </AgGridReact>
          </div>
          <div className="dash" style={{ textAlign: 'center' }}>
            <button className="btn_sm btn_outline" style={{ margin: '5px' }} onClick={onDownHandler}>내리기<span className="ico_down"></span></button>
            <button className="btn_sm btn_outline" style={{ margin: '5px' }} onClick={onUpHandler}>올리기<span className="ico_up"></span></button>
          </div>
          <div className="title_contents bd0">
            선택된 발송 대상자
          </div>
          <div className="ag-theme-balham" style={{height: '350px'}}>

            <AgGridReact
              //components={{datePicker: () => {}}}
              modules={AllCommunityModules}
              defaultColDef={{
                width: 100,
                wrapText: true,
                resizable: true,
                floatingFilter: true,
                // autoHeight: true,
                cellStyle: { textAlign: 'center' },
                headerClass: 'text-center',
              }}
              suppressRowTransform={true}
              suppressRowClickSelection={true}
              frameworkComponents={{ comboFilter: ComboFilter }}
              onGridReady={onTargewtGridReady}
              multiSortKey={'ctrl'}
              rowSelection={'multiple'}
              rowData={targets}
            >
              <AgGridColumn
                headerName=""
                editable={false}
                headerCheckboxSelection={true}
                headerCheckboxSelectionFilteredOnly={true}
                checkboxSelection={true}
                sortable={false}
                width={41}
                resizable={false}
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn
                headerName="사업장유형"
                field="room_work_site"
                width={150}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="빌딩"
                field="room_building_name"
                width={160}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="동"
                field="room_building_dong"
                width={110}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="호수"
                field="room_name"
                width={105}
                filter="agTextColumnFilter"
                floatingFilterComponent="comboFilter"
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="사번"
                field="guest_employee_number"
                width={120}
                filter="agTextColumnFilter"
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                headerName="사원명"
                field="guest_name"
                width={120}
                filter="agTextColumnFilter"
                unSortIcon={true} sortable={true}
              />
              <AgGridColumn
                  headerName="휴대폰번호"
                  field="guest_cellphone"
                  width={145}
                  filter="agTextColumnFilter"
                  unSortIcon={true} sortable={true}
                />
            </AgGridReact>
          </div>
        </div>

        <div className="button_wrap">
          <button type="button" className="btn_md btn_darkblue" onClick={onApply}>적용</button>
        </div>
      </div>
    </div>
  )
}

export default ChoiceGuests;
