import React from 'react';

import DefaultTemplate from '../../components/templetes/default';
import Pagination from '../../components/organismas/pagination';

import useAdminManager, { AdminInterface } from '../../hooks/useAdminManager';

const AdminAuthManageList = (props: any) => {
  const {
    admins = [],
    totalPage, offset, limit, setOffset,
    
    isActive, isRegisterd, workSite, gender,
    setIsActive, setIsRegistered, setWorkSite, setGender,
  } = useAdminManager();

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      {...props}
    >
      <div className="tab_contents on">
        <h4>권한관리 리스트
                    {/* <div className="right_area">
            <select name="" id="" className="form_control">
              <option value="">근무지</option>
            </select>
            <select name="" id="" className="form_control">
              <option value="">숙소권한</option>
            </select>
          </div> */}
        </h4>
        <div className="table_normal_list">
          <table>
            <caption className="sr_only">계정 및 권한관리 리스트</caption>
            <colgroup>
              <col width="12%" />
              <col width="12%" />
              <col width="12%" />
              <col width="12%" />
         
            </colgroup>
            <thead>
              <tr>
                <th>관리번호</th>
                <th>성명</th>
                <th>근무지</th>
                <th>소속</th>
                
              </tr>
            </thead>
            <tbody>
              {/*데이타 없을 시*/}
              {!admins.length && (
                <tr>
                  <td className="blank_area type_white" colSpan={6}>
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
              )}

              {admins.map((admin: AdminInterface, adminIdx: number) => (
                <tr onClick={() => props.history.replace(`/admin/manage_auth/${admin.id}/detail`, admin)}>
                  <td>{admin.employee_number}</td>
                  <td>{admin.name}</td>
                  <td>{admin.worksite}</td>
                  <td>{admin.duty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>  {/*table*/}

        <div className="board_paging">
          <ul>
          <Pagination offset={offset} limit={limit} totalPage={totalPage} setOffset={setOffset} />
          </ul>
        </div>   {/*board_paging*/}

      </div> {/* tab_content */}
    </DefaultTemplate>
  );
};

export default AdminAuthManageList;
