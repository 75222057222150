import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../apis';
import { WORK_SITE } from '../../../constants';
import { onShowMsg } from '../../../store/modules/modal';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import ConfirmBtn from '../../atoms/button/confirm';
import './index.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';

const MobileSignUp = observer((props: any) => {
    const {headerStore: headerStore} = useStores();
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [employeeNumber, setEmployeeNumber] = useState<string>();
    const [adminData, setAdminData] = useState<any>([]);
    const [gender, setGender] = useState<number>(1);
    const [birthdate, setBirthdate] = useState<string>('');
    const [assignedTask, setAssignedTask] = useState<string>('');
    const [pw1, setPw1] = useState<string>('');
    const [pw2, setPw2] = useState<string>('');
    const [email, setEmail] = useState<string>("");
    const [verifyCode, setVerifyCode] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const [isVerify, setIsVerify] = useState<boolean>(false);
    const dispatch = useDispatch();

    const popInit = () => {
        setEmployeeNumber('');
        setAdminData([]);
        setGender(1);
        setBirthdate('');
        setAssignedTask('');
        setPw1('');
        setPw2('');
        setEmail('');
        setVerifyCode('');
        setToken('');
        setIsVerify(false);
    }

    // 사번으로 조회 
    const onSetSignupUser = async (employeeNumber: string) => {
        if(!employeeNumber) {
            dispatch(onShowMsg({ msg: '관리번호를 입력해주세요' }));
            return;
        }
        try {
            headerStore.isLoading = true;
            let { data } = await auth.getTempAdminInfo(employeeNumber);
            if (data === "Not Find") {
                dispatch(onShowMsg({ msg: '정확한 관리번호를 확인해 주세요' }));
            } else {
                setAdminData(data);
                setGender(data.gender);
                setBirthdate(data.birthdate);
                setAssignedTask(data.assigned_task);
                dispatch(onShowMsg({ msg: '조회를 완료했습니다' }));
            }
            headerStore.isLoading = false;
        } catch (err) {
            headerStore.isLoading = false;
            // console.log(err);
            dispatch(
                onShowMsg({ msg: '조회 중 문제가 발생했습니다.' })
            )
            return;
        }
    }

    // 휴대폰 번호로 인증번호 요청
    const onAuth = async (cell: string) => {
        try {
            headerStore.isLoading = true;
            //let {data} = await auth.signupAuth({ cellphone })
            let { data } = await auth.signupAuth({ cellphone: cell })
            setToken(data.token);

            dispatch(onShowMsg({ msg: '입력하신 정보로 인증번호를 발송하였습니다' }));
            headerStore.isLoading = false;
        } catch (err) {
            headerStore.isLoading = false;
            // console.log(err)
            dispatch(
                onShowMsg({ msg: '인증 요청 중 문제가 발생했습니다.' })
            )
            return;
        }
    }
    // 인증번호 검증
    const onVerify = async (cell: string) => {
        if(!verifyCode) {
            dispatch(onShowMsg({ msg: '인증번호를 확인하여 주세요' }));
            return;
        }

        try {
            headerStore.isLoading = true;
            let { data } = await auth.signupVerify({
                number: verifyCode,
                cellphone: cell,
                token,
            })

            dispatch(onShowMsg({ msg: '인증을 완료했습니다.' }));
            setIsVerify(true);
            headerStore.isLoading = false;
        } catch (err) {
            headerStore.isLoading = false;
            // console.log(err);
            dispatch(
                onShowMsg({ msg: '인증 중 문제가 발생했습니다.' })
            )
            return;
        }
    }

    // 회원가입
    const signupSubmit = async () => {
        const [isValidate, msg] = validate()
        if (isValidate) {
            dispatch(onShowMsg({ msg: msg }));
            return
        }

        let payload: any = {
            new_password: pw1,
            new_password2: pw2,
            gender: gender,
            assigned_task: assignedTask,
            cellphone: adminData.cellphone,
            birthdate: birthdate,
            email
            /* employee_number: (signupUser?.employee_number).toString(),
            gender: signupUser?.gender,
            duty: signupUser?.duty,
            birthdate: signupUser?.birthdate,
             */
        }

        try {
            headerStore.isLoading = true;
            let data = await auth.signup(adminData.id, payload);
            //dispatch(onShowMsg({ msg: '회원가입 완료' }));
            setIsComplete(true);
            headerStore.isLoading = false;
        } catch (err) {
            headerStore.isLoading = false;
            // console.log(err);
            dispatch(onShowMsg({ msg: '회원가입 중 문제가 발생했습니다.' }));
            return;
        }
    }

    const validate = (): [boolean, string] => {
        let isValidate: boolean = false;
        let msg: string = '';

        if (!adminData?.employee_number) {
            isValidate = true;
            msg = '사번(관리번호) 조회를 하지 않아 회원가입을 진행할 수 없습니다.'
            
        }
        //else if (!signupUser?.duty || !signupUser?.birthdate) {
        //  isValidate = true;
        //  msg= '담당업무 OR 생년월일을 입력하지 않았습니다.' 
        //} 
        else if ((pw1.length < 8)) {
            isValidate = true;
            msg = '비밀번호는 최소 8자리 입니다.'
        }
        /*else if (/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/g.test(pw1)) {
          isValidate = true;
          msg= '비밀번호는 8~20자 영문, 특수문자, 숫자 중 세가지를 조합해야햡니다.' 
        }*/
        else if (!pw1.length || !pw2.length || pw1 !== pw2) {
            isValidate = true;
            msg = '비밀번호를 입력하지 않았거나 비밀번호가 일치하지 않습니다.'
        } else if (!isVerify) {
            isValidate = true;
            msg = '인증을 진행하지 않았습니다.'
        }
        /* else if (signupUser?.user.birthdate.length !== 6) {
          isValidate = true;
          msg= '생년월인은 6자리입니다'
        } else if (!signupUser?.user.gender) {
          isValidate = true;
          msg= '성별을 선택해야 합니다.' 
        } */

        return [isValidate, msg]
    }

    return (
        <>
        {!isComplete ? (
            <div id="wrap" className="wrap_mobile" style={{ display: props.isShowSingUp ? 'block' : 'none' }}>
                <div id="pop_join" className="pop_modal">
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <h3>가입</h3>
                            <button className="pop_btn_close pop_close" onClick={()=>{props.popClose(); popInit();}}>닫기</button>
                        </div>
                            
                                <div className="contents_wrap">
                                    <div className="group_title">
                                        <h4 className="title_contents">기본정보 입력</h4>
                                    </div>
                                    <hr />
                                    <ul className="lst_warning">
                                        <li>모든 입력사항 필수 입력 입니다.</li>
                                    </ul>

                                    <div className="form_write">
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_managenum" className="title_form">관리번호</label>
                                                <div className="form_phone">
                                                    <input type="text" 
                                                        id="input_managenum" 
                                                        className="form_control form_sm" 
                                                        placeholder="관리번호를 입력해주세요" 
                                                        value={employeeNumber} 
                                                        maxLength={7}
                                                        onChange={(e: any) => {
                                                            e.target.value = getEscapeString(e.target.value, INPUT_TYPE.employee);
                                                            setEmployeeNumber(e.target.value);
                                                        }}
                                                    />
                                                    <button className="btn btn_sm btn_gray" onClick={ () => {onSetSignupUser(employeeNumber || '');}}>적용</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <ul className="lst_userinfo">
                                        <li className="user_area">
                                            <span className="img_user"></span>
                                            <span className="username">{adminData.name}</span>
                                        </li>
                                        <li>
                                            <strong>관리번호(아이디)</strong>
                                            <span>{adminData.employee_number}</span>
                                        </li>
                                        <li>
                                            <strong>휴대폰번호</strong>
                                            <span>{adminData.cellphone}</span>
                                        </li>
                                        <li>
                                            <strong>근무지</strong>
                                            <span>{WORK_SITE[adminData.work_site_id]}</span>
                                        </li>
                                        <li>
                                            <strong>소속회사</strong>
                                            <span>{adminData.company}</span>
                                        </li>
                                    </ul>

                                    <div className="form_write">
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_birthday" className="title_form">생년월일</label>
                                                <input 
                                                    type="text" 
                                                    id="input_birthday" 
                                                    className="form_control form_sm" 
                                                    placeholder="주민등록번호 앞자리 입력"
                                                    maxLength={6}
                                                    value={birthdate}
                                                    onChange={(e:any) => setBirthdate(getEscapeString(e.target.value, INPUT_TYPE.phoneNum))} 
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="select_sex" className="title_form">성별</label>
                                                <select className="form_control"
                                                    value={gender}
                                                    onChange={(e:any) => {setGender(e.target.value);}}
                                                >
                                                    <option value="1">남</option>
                                                    <option value="2">여</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_managepart" className="title_form">담당업무</label>
                                                <input type="text" 
                                                    id="input_managepart" 
                                                    className="form_control form_sm" 
                                                    placeholder="업무 입력" 
                                                    maxLength={20}
                                                    value={assignedTask}
                                                    onChange={(e:any) => setAssignedTask(e.target.value)} 
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="group_title">
                                        <h4 className="title_contents">추가정보 입력</h4>
                                    </div>
                                    <hr />

                                    <div className="form_write">
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_pw" className="title_form">비밀번호</label>
                                                <input type="password" 
                                                    id="input_pw" 
                                                    className="form_control form_sm" 
                                                    placeholder="새 비밀번호를 입력해주세요" 
                                                    maxLength={20}
                                                    value={pw1} 
                                                    onChange={(e: any) => setPw1(e.target.value)} 
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_pw2" className="title_form">비밀번호 확인</label>
                                                <input 
                                                    type="password" 
                                                    id="input_pw2" 
                                                    className="form_control form_sm" 
                                                    placeholder="비밀번호를 다시 한번 입력해주세요" 
                                                    maxLength={20}
                                                    value={pw2} 
                                                    onChange={(e: any) => setPw2(e.target.value)} 
                                                />
                                            </div>
                                        </div>
                                        <ul className="lst_warning">
                                            <li>비밀번호는 8~20자로 영문 , 특수문자, 숫자 중 세 가지를 조합하여 입력해주세요. 영문 대문자와 소문자를 구별해주세요</li>
                                        </ul>
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_phonenum" className="title_form">휴대폰번호</label>
                                                <input type="text" id="input_phonenum" className="form_control form_sm" placeholder="" value={adminData.cellphone} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_email" className="title_form">이메일 주소</label>
                                                <input type="text" 
                                                    id="input_email" 
                                                    className="form_control form_sm" 
                                                    placeholder="이메일주소를 입력해주세요" 
                                                    value={email} 
                                                    onChange={(e: any) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col w100">
                                                <label htmlFor="input_confirm" className="title_form">휴대폰번호 인증</label>
                                                <div className="form_confirm">
                                                    <input type="text"
                                                        id="input_confirm" 
                                                        className="form_control form_sm"
                                                        placeholder="인증번호를 입력해주세요" 
                                                        value={verifyCode} 
                                                        onChange={(e: any) => setVerifyCode(e.target.value)}
                                                    />
                                                    <button className="btn btn_sm btn_outline" onClick={() => onAuth(adminData.cellphone)}>인증번호 보내기</button>                                                </div>
                                                    <button className="btn btn_sm btn_gray btn_confirm mt10" onClick={() => onVerify(adminData.cellphone)}>확인</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="button_wrap">
                                    <ConfirmBtn
                                        className="btn btn_outline w50"
                                        action={() => {props.popClose(); popInit();}}
                                        label="취소"
                                        title=""
                                        msg={"취소하시겠습니까?"}
                                    />
                                    <button type="button" className="btn btn_darkblue w50"
                                         onClick={() => signupSubmit()}
                                    >
                                        완료
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div id="wrap" className="wrap_mobile" style={{ display: props.isShowSingUp ? 'block' : 'none' }}>
                    <div id="pop_join_complete" className="pop_modal">
                        <div className="pop_wrap">
                            <div className="title_wrap">
                                <h3>가입</h3>
                                <button className="pop_btn_close pop_close" onClick={()=>{props.popClose(); popInit();}}>닫기</button>
                            </div>
                            <div className="contents_wrap">
                                <dl className="lst_complete">
                                    <dt>가입이 완료되었습니다</dt>
                                    <dd>
                                        하기와 같은 정보로 가입이 완료되었습니다<br />
                                    완료 버튼을 누르시면 로그인 화면으로 이동하고<br />
                                    가입한 정보로 관리자 기능을 이용하실 수 있습니다
                                </dd>
                                </dl>

                                <dl className="lst_basicinfo">
                                    <dt>가입 요약 정보</dt>
                                    <dd>
                                        <ul>
                                            <li>
                                                <strong>성명</strong>
                                                <span>{adminData.name}</span>
                                            </li>
                                            <li>
                                                <strong>관리번호 (아이디)</strong>
                                                <span>{adminData.employee_number}</span>
                                            </li>
                                            <li>
                                                <strong>휴대폰 번호</strong>
                                                <span>{adminData.cellphone}</span>
                                            </li>
                                            <li>
                                                <strong>이메일 주소</strong>
                                                <span>{email}</span>
                                            </li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>
                            <div className="button_wrap">
                                <button type="button" className="btn btn_darkblue" onClick={()=>{props.popClose(); popInit();}}>확인</button>
                            </div>
                        </div>
                    </div>
                </div>  
            )}            
        </>
    );
});

export default MobileSignUp;
