import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import DefaultTemplate from '../../components/templetes/default';
import ApplicantForm from '../../components/organismas/form/applicant';
import GuestForm from '../../components/organismas/form/guest';
import ConfirmBtn from '../../components/atoms/button/confirm';
import { getTodayDate } from '../../utils/date';
import { STATUS_CODE } from '../../constants';
import { lodge, build } from '../../apis';
import './index.scss';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../routes';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';

const LodgedApplicantDetailPage = observer((props: any) => {
  const today = getTodayDate();
  const { tab } = useSelector((state: any) => ({
    tab: state.base.tab,
  }));
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);

  const [guests, setGuests] = useState<any>([]);
  const [applicant, setApplicant] = useState({
    employeeNumber: '',
    cellphone: '',
    department: '',
    name: '',
  });
  const [files, setFiles] = useState([]);
  const [status, setState] = useState(0);
  const [createdAt, setCreatedAt] = useState(today[0]);
  const [roomType, setRoomType] = useState('');
  const [workSite, setWorkSite] = useState('');
  const {headerStore: headerStore} = useStores();
  const [isShowCheckinModal, setIsShowCheckinModal] = useState<boolean>(false);
  const [checkInAt, setCheckInAt] = useState(today[0]);
  const [checkInKeyoutAt, setCheckInKeyoutAt] = useState(today[0]);
  const guestIds = guests.map((guest: any) => guest.id);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  useEffect(() => {
    const bookingId: number = parseInt(props.match.params.bookingId);
    (async () => {
      headerStore.isLoading = true;
      // console.log(bookingId);
      try{
        const { data } = await build.getBooking(bookingId, {
          today: false,
        });
        setGuests(data.guests);
        setApplicant({
          employeeNumber: data.applicant_employee_number,
          cellphone: data.applicant_cellphone,
          department: data.applicant_department,
          name: data.applicant_name,
        });
        setFiles(data.files || []);
        setState(data.guests[0].status);
        setCreatedAt(data.created_at);
        setRoomType(data.room_type);
        setWorkSite(data.room_work_site);
      }catch({status, data}){
        headerStore.isLoading = false;
        if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
    }

      
      headerStore.isLoading = false;
    })();
  }, []);

  const onCancelhandler = async () => {
    if (
      guests[0].status !== STATUS_CODE.booking_requested &&
      guests[0].status !== STATUS_CODE.booking_completed &&
      guests[0].status !== STATUS_CODE.checkin_pending
    ) {
      alert('입숙취소 할 수 없는 상태를 가진 항목이 선택되었습니다.');
      return;
    }

    try {
      headerStore.isLoading = true;
      const { data } = await lodge.lodgeCancle(guestIds.join(','));
      info('신청취소 완료');
      props.history.goBack();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info('신청취소중 문제발생');
    }
  };

  const onCheckinHandler = async (e: any) => {
    if (guests[0].status !== STATUS_CODE.checkin_pending) {
      info('입숙완료 할 수 없는 상태입니다.');
      return;
    }

    const payload = {
      checkin_at: checkInAt,
      checkin_keyout_at: checkInKeyoutAt,
    };

    try {
      headerStore.isLoading = true;
      const { data } = await lodge.checkin(guestIds.join(','), payload);
      // console.log(data);
      info('입숙승인 완료');
      setIsShowCheckinModal(false);
      props.history.goBack();
      headerStore.isLoading = false;
    } catch (e) {
      headerStore.isLoading = false;
      // console.log(e);
      const { status, data } = e;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (data.invalid_status && data.invalid_status.length) {
        info(data.invalid_status[0]);
        return;
      }
      info('입숙승인 실패');
    }
  };

  const onShowCompletePopup = () => {
    if (guests[0].status === STATUS_CODE.checkin_pending) {
      setIsShowCheckinModal(true);
    } else {
      info('입숙완료 할 수 없는 상태입니다.');
    }
  };

  return (
    <>
      <DefaultTemplate {...props} id="wrap" className="" 
      isLoading={headerStore.isLoading}
      >
        <h4>입숙신청현황 상세</h4>
        <div className="table_normal_list">
          <ApplicantForm
            applicant={applicant}
            roomType={roomType}
            workSite={workSite}
            createdAt={createdAt}
            status={status}
          />
          {/* </div> */}
          {/*<FileForm value={files} downFlag={""}/>*/}

          <GuestForm guests={guests} isUpdateMode={isUpdateMode} setGuests={setGuests} />
        </div>

        <div className="submit">
          <div className="fl">
            <button type="button" className="btn btn_lg btn_gray" onClick={() => props.history.goBack()}>
              목록
            </button>
          </div>
          <div className="fr">
            <ConfirmBtn
              className="btn btn_lg btn_outline"
              action={onCancelhandler}
              label="신청취소"
              title=""
              msg="선택된 항목을 취소하시겠습니까?"
            />
            {/* <button type="button" className="btn_md btn_darkblue btn_outline" onClick={ onCancelhandler}>신청취소</button> */}
            {/* <button type="button" className="btn_md btn_darkblue btn_outline" onClick={ () => setIsUpdateMode(!isUpdateMode)}>수정</button> */}
            <button type="button" className="btn btn_lg btn_darkblue" onClick={onShowCompletePopup}>
              입숙처리
            </button>
          </div>
        </div>
      </DefaultTemplate>

      {/* <CheckPopup
        isShowModal={isShowCheckinModal}
        onCloseModalHandler={() => setIsShowCheckinModal(false)}
        onCompleteHander={onCheckinHandler}
        checkKeyAt={checkInKeyoutAt}
        checkAt={checkInAt}
        bookingData={guests}
        setCheckAt={setCheckInAt}
        setCheckKeyAt={setCheckInKeyoutAt}
        type="checkin"
      /> */}
    </>
  );
});

export default LodgedApplicantDetailPage;
