import {action, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { NOTICE_TYPE, SEARCH_TYPE } from '../../constants';
import { BoardStore } from './boardStore';
import {getTwoLatersMonthDate, getIntervalMonthDate} from '../../utils/date';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';
import { parserEscape } from '../../utils/common';

export class NoticeStore extends BoardStore {
  categories: any[] = [];
  searchTypes: any[] = [];
  created_after: string = getIntervalMonthDate(2)[1];
  created_before: string = getTwoLatersMonthDate(2)[1];
  searchType = -1;
  search = '';
  worksite : any;
  
  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
      categories: observable,
      searchTypes: observable,
      created_after: observable,
      created_before: observable,
      searchType: observable,
      search: observable,
      worksite : observable,
      
      getItems: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      isChange: action,
      saveItem: action,
      deleteItem: action,
      deleteItems: action,
    });

    this.initDatas();
  }

  private initDatas () {
    const _categories = [{ value: '-1', label: '선택', optionIdx: 0 }];
    Object.keys(NOTICE_TYPE).forEach((key: string, index: number) => {
      _categories.push({
        value: key,
        label: NOTICE_TYPE[Number(key)],
        optionIdx: index + 1,
      });
    });
    this.categories = _categories;

    // const _searchTypes = [{ value: '-1', label: '선택', optionIdx: 0 }];
    const _searchTypes : any = [];
    Object.keys(SEARCH_TYPE).forEach((key: string, index: number) => {
      _searchTypes.push({
        value: key,
        label: SEARCH_TYPE[Number(key)],
        optionIdx: index + 1,
      });
    });
    this.searchTypes = _searchTypes;
  }

  init () {
    this.category = -1;
    this.searchType = -1;
    this.search = '';
    this.created_after = getIntervalMonthDate(2)[1];
    this.created_before = getTwoLatersMonthDate(2)[1];
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.checkeds = {};
    this.item = {};
  }

  async getItem(id: string) {
    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.notice.getNotice({ id });
      if (data) {
        this.selectedItem(data);
      }
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  @debounce(500)
  async getItems() {
    // console.log('getItems: ', this.category, this.limit, this.offset, this.worksite);
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      worksite: this.worksite,
    };

    if (this.category > 0) {
      filter['category'] = this.category;
    }

    switch (this.searchType) {
      case 1: filter['title'] = this.search; break;
      case 2: filter['contents'] = this.search; break;
      case 3: filter['author'] = this.search; break;
      default:
        filter['search'] = this.search;
        break;
    }

    if (this.created_after) {
      filter['created_after'] = this.created_after;
    }

    if (this.created_before) {
      filter['created_before'] = this.created_before;
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.notice.getNotices(filter);
      this.items = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  cancelItem() {
    this.item = { ...this.orgItem };
    this.orgItem = {};
  }

  newItem() {
    console.log('newItem');
    const user = JSON.parse(window.sessionStorage.getItem('user') || '');
    this.item = {
      id: -1,
      category: 1,
      author_name: user.username,
      author_employee_number: user.employeeNumber,
      title: '',
      contents: '',
      display_after: '',
      display_before: '',
      images: [],
      files: [],
      guests: [],
      is_sms_send: false,
      sms_message: '',
      is_mail_send: false,
      mail_title: '',
      mail_message: '',
      is_instant: false,
      send_at: '',
      created_at: new Date(),
    };
    this.orgItem = { ...this.item };
  }

  clone() {
    console.log(this.orgItem);
    const user = this.user;
    const tmpItem = { ...this.orgItem };
    tmpItem.id = -1;
    tmpItem.author_name = user.username;
    tmpItem.author_employee_number = user.employeeNumber;
    tmpItem.title = `[복제] ${this.orgItem.title}`;
    tmpItem.created_at = new Date();
    tmpItem.guests = this.item.guests;
    this.selectedItem(tmpItem);
  }

  selectedItem(item: any) {
    this.item = item;
    this.orgItem = { ...item };
  }

  isChange() {
    return JSON.stringify(this.orgItem) !== JSON.stringify(this.item)
  }

  async saveItem() {
    console.log('FaqStore.saveItem:', this.item);
    console.log('items : ' + this.items);

    const data: any = {
      category: this.item.category,
      author_name: this.item.author_name,
      title: this.item.title,
      contents: parserEscape(this.item.contents),
      display_after: this.item.display_after,
      display_before: this.item.display_before,
      is_sms_send: this.item.is_sms_send,
      sms_message: this.item.sms_message,
      is_mail_send: this.item.is_mail_send,
      mail_title: this.item.mail_title,
      mail_message: parserEscape(this.item.mail_message),
      is_instant: this.item.is_instant,
      send_at: new Date(),
      work_site: this.worksite
     };
    data.images = this.item.images.map((image: any) => image.id);
    data.files = this.item.files.map((file: any) => file.id);
    data.guests = this.item.guests.map((guest: any) => guest.id);
    console.log(data.guests);
    try {
      this.headerStore.isLoading = true;
      if (this.item.id < 0) {
        console.log('create');
        await apis.notice.createNotice(data);
      } else {
        console.log('update');
        await apis.notice.updateNotice(this.item.id, data);
      }
      this.newItem();
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async deleteItem() {
    console.log('NoticeStore.deleteItem:', this.item);
    if (this.item) {
      try {
        this.headerStore.isLoading = true;
        await apis.notice.deleteNotice(this.item.id);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async deleteItems() {
    const keys = Object.keys(this.checkeds);
    // console.log(keys);
    if (keys.length > 0) {
      try {
        this.headerStore.isLoading = true;
        const actions = keys.map((checkKey: string) => apis.notice.deleteNotice(checkKey));
        await Promise.all(actions);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }
}
