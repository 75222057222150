import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from '../../apis';
import { LOGIN_REQUEST_SSO } from '../../store/modules/auth';
import './index.scss';

const SigninSsoPage = (props: any) => {
    let dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            // console.log(props);
            const param = props.location.search;
        
            const ssoPayload: any = {
                param: param
            }
        
            //await auth.ssoSignin(ssoPayload);
            dispatch({ type: LOGIN_REQUEST_SSO, ssoPayload, history: props.history });
        })();
    }, []);

    return (
        <>
            <div id="wrap" className="loading">
                <div className="loading_message">
                    <h1>통합숙소관리시스팀(AMS)</h1>
                    <p className="ico_wait">로그인 페이지로 이동 중입니다. 잠시만 기다려주세요.</p>
                </div>
            </div>
        </>
    );
}

export default SigninSsoPage;
