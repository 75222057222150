import { takeEvery, put, call } from 'redux-saga/effects';

import { USERINFO_REQUEST, USERINFO_COMPLETE } from '../modules/user';

import { auth } from '../../apis';
import { SHOW_ALERT_MODAL } from '../modules/modal';

//유저 정보 가져오기
function* getUserInfo({ payload, history }: any) {
  try {
    const { data } = yield call(auth.getProfile, payload);
    yield put({ type: USERINFO_COMPLETE, payload: data });
  } catch (err) {
    // console.log('[SAGA] API error');
    // console.log(err);
    const { status, data } = err;
    
    if (status === 401) {
      if (!window.localStorage.getItem("isRetryLogin")) {
        window.localStorage.setItem("isRetryLogin", "retry");
      }
      window.location.href = '/';
      return ;
    } else if (status === 403) {
      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '권한오류',
          msg: '접근권한이 없습니다.',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }else if ( status === 500 ) {
      window.location.href = '/500';
      return;
    }

  }
}

function* userSaga() {
  yield takeEvery(USERINFO_REQUEST, getUserInfo);
}

export default userSaga;
