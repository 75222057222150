import React from 'react';

import DefaultTemplate from '../../../components/templetes/default';
import AuditDetail from '../../../components/templetes/audit/detail';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';

const AuditDetailPage = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <AuditDetail isEdit={true} {...props} />
    </DefaultTemplate>
  );
});

export default AuditDetailPage;
