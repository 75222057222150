import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import TempleteDefault from '../../../components/templetes/default';
import { ROOM_TYPE, ROOM_TYPE_CODE, GUEST_TYPE, MANAGEMENT_CATEGORY, GUEST_TYPE_CODE } from '../../../constants';
import { formatByString_YYYYMMDD, format_YYYYMMDD, getDate, getDays, getMonth, getMonths, getYear, getYear5 } from '../../../utils/date';
import GuestDetailForm from './guestDetail';
import MngFileUpload from '../../../components/organismas/form/managementFile';
import { MessageContext, MessageImpl } from '../../../routes';
import { showConfirm } from '../../../utils/common';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import './index.scss';
import LodgedTotal from '..';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const LodgedCheckOutHistoryDetail = observer((props: any) => {
    const {loadgedTotalStore: store} = useStores();
    const {lodgedHistoryStore: historystore} = useStores();
    const {headerStore: headerStore} = useStores();
    const dispatch = useDispatch();
    const { info }: MessageImpl = useContext(MessageContext);
    const [isCheckOutPop, setIsCheckOutPop] = useState<boolean>(false);
    const [isImgPop, setIsImgPop] = useState<boolean>(false);
    const [imgPath, setImgPath] = useState<string>('');

    useEffect(() => {
        if(!store.checkOutDetailItem || store.checkOutDetailItem.length === 0) {
            props.history.replace(`/guest/status/checkout`);
            return;
        }
        (async () => {
            await historystore.getItem(store.checkOutDetailItem.id);
        })();
    },[]);

    const saveCheckOut = async() => {
        showConfirm('퇴숙처리 하시겠습니까?', dispatch, async () => {
            if(store.manageYear === '' || store.manageMonth === '' || store.manageDay === ''){
                info('필수 입력사항을 체크하여주세요');
                return false;
            }else if(!store.isKeyReturn && store.keySelect === '기타' && (store.keyDescription === '' || store.keyDescription === null)){
                info('열쇠 미 반납 이유를 적어주세요');
                return false;
            }else if(store.isEquipment && (store.equipmentDescription === '' || store.equipmentDescription === null)){
                info('비품 이상 여부를 적어주세요');
                return false;
            }else if((store.checkoutData.room_type === ROOM_TYPE_CODE.business_room || store.checkoutData.room_type === ROOM_TYPE_CODE.business_room_group) && 
                ((store.checkoutData.meal_ticket-store.mealTicket) < 0)) {
                info('반납수량은 식권수량보다 클 수 없습니다');
                return false;
            }else if(store.manageYear !== '' && store.manageMonth !== '' && store.manageDay !== '' ){
                if(store.checkOutDetailItem.checkin_at > format_YYYYMMDD(store.manageYear, store.manageMonth, store.manageDay)){
                    info('퇴숙일이 입숙일보다 빠를 수 없습니다.');
                    return false;
                }
            }

            //if(!store.isKeyReturn)
            try {
                headerStore.isLoading = true;
                store.checkoutData = store.checkOutDetailItem;
                let data : any = await store.checkOut();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsCheckOutPop(false);
                        store.checkOutDetailItem.checkout_at = 'true';
                        info('퇴숙처리가 완료되었습니다');
                        popInit();
                    }else {
                      return;
                    }
                }, 250);
                // await store.getCheckOutList();
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        });
    }
    
    const popInit = () => {
        store.manageYear = getYear();
        store.manageMonth = getMonth();
        store.manageDay = getDate();
        store.isKeyReturn = true;
        store.keyDescription = ''
        store.keySelect = '열쇠 분실';
        store.isEquipment  = false;
        store.equipmentDescription = '';
        store.images = [];
        store.mealTicket = 0;
    }

    if(!store.checkOutDetailItem || store.checkOutDetailItem.length === 0) return <></>;
    
    return (
        <>
        <LodgedTotal
            {...props}
            id="wrap"
            isLoading={headerStore.isLoading}
            className=""
        >
            <ul className="lst_roomtype mt30">
                <li>
                    <strong>사업장</strong>
                    <span>{store.checkOutDetailItem.room_work_site}</span>
                </li>
                <li>
                    <strong>숙소유형</strong>
                    <span>{ROOM_TYPE[store.checkOutDetailItem.room_type]}</span>
                </li>
                <li>
                    <strong>동호수</strong>
                    <span>{store.checkOutDetailItem.room_building_name} {store.checkOutDetailItem.room_building_dong} {store.checkOutDetailItem.room_name}호</span>
                </li>
                <li>
                    <strong>입숙일</strong>
                    <span>{store.checkOutDetailItem.checkin_at}</span>
                </li>
                {store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.dormitory_room || store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.company_room ? 
                    (<li>
                        <strong>만료일</strong>
                        <span>{store.checkOutDetailItem.expired_at}</span>
                    </li>) : ''
                }
                <li>
                    <strong>퇴숙일</strong>
                    <span>{store.checkOutDetailItem.checkout_at ? store.checkOutDetailItem.checkout_at : '-'}</span>
                </li>
                <li>
                    <strong>신청자ID</strong>
                    <span>{historystore.bookingData.applicant_employee_number}</span>
                </li>
                <li>
                    <strong>신청자휴대폰</strong>
                    <span>{historystore.bookingData.applicant_cellphone}</span>
                </li>
            </ul>

            <h4>
                이용자정보
                <div className="right_area">
                    {/* {bookingData.room_type === ROOM_TYPE_CODE.dormitory_room || bookingData.room_type === ROOM_TYPE_CODE.company_room? 
                        <button type="button" className="btn btn_xs btn_outline">입숙신청서류</button> : ''
                    } */}
                </div>
            </h4>
            <GuestDetailForm guestData={historystore.guestData} booking={historystore.bookingData} />

            {/* 퇴숙 완료인 경우 */}
            {store.checkOutDetailItem.checkout_at ? (
                <>
                <h4>퇴숙정보</h4>
                 <div className="table_normal_list table_write">
                    <table>
                        <caption className="sr_only">퇴숙정보</caption>
                        <colgroup>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>최종 퇴숙일</th>
                            <td>{store.checkOutDetailItem.checkout_at}</td>
                            <th>열쇠 반납 여부</th>
                            <td>
                                {historystore.guestData.is_key_return? '반납 완료' :
                                historystore.guestData.key_select !== "기타" ? '미 반납 (' + historystore.guestData.key_select +')' :
                                '미 반납 (' + historystore.guestData.key_select + ' : ' + historystore.guestData.key_description + ' )'}
                            </td>
                            <th>비품 이상 여부</th>
                            <td>{!historystore.guestData.is_equipment_ok? '이상 없음' : '이상 있음 (' + historystore.guestData.equipment_description + ')'}</td>
                        </tr>
                        {store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room || store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room_group? (
                            <tr>
                                <th>식권수량</th>
                                <td>{historystore.guestData.meal_ticket}</td>
                                <th>반납수량</th>
                                <td>{historystore.guestData.return_meal_ticket}</td>
                                <th>사용수량</th>
                                <td>{(historystore.guestData.meal_ticket - historystore.guestData.return_meal_ticket)}</td>
                            </tr>
                        ): ''}
                        <tr>
                            <th>숙소 이미지</th>
                            <td colSpan={5}>
                                <div className="images_area">
                                    {historystore.guestData.checkout_files && historystore.guestData.checkout_files.length > 0 ? (
                                        historystore.guestData.checkout_files.map((image: any, index: number) => (
                                            <div className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                                <a key={index} >
                                                <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                                                </a>
                                            </div>
                                        ))
                                    ) :''}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> 
                </>
            ) :''}

            <div className="submit">
                <div className="fl">
                    <button className="btn btn_lg btn_gray" onClick={() => props.history.replace('/guest/status/checkout/')}>목록</button>
                </div>
                <div className="fr">
                {store.checkOutDetailItem.checkout_at? (
                    <></>
                    ) : (
                    <><button className="btn btn_lg btn_darkblue fr" onClick = {()=>{setIsCheckOutPop(true)}}>퇴숙처리</button></>
                    )}
                </div>
            </div>

            {/* 이미지 팝업 */}
            <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
                <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
                <div className="pop_wrap">
                    <div className="contents_wrap">
                        <div className="slide">
                            <p className="img_area">
                                <img src={imgPath} alt=""/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="pop_change_request" className="pop_dim_wrap pop_modal pop_sm" style={{display: isCheckOutPop? 'block': 'none'}}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>퇴숙완료처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() =>{popInit(); setIsCheckOutPop(false)}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <ul className="request_date">
                            <li>
                                <strong>퇴숙요청일</strong>
                                <span>{store.checkOutDetailItem.stay_before}</span>
                            </li>
                            <li>
                                <strong>신청일</strong>
                                <span></span>
                            </li>
                        </ul>
                        <div className="process_list">
                            <dl>
                                <dt>열쇠 반납 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="return_check">
                                            <input type="radio" id="return_check" name="return" checked={store.isKeyReturn} onClick={() => store.isKeyReturn = true}/> 
                                            <span className="label_text">반납 완료</span>
                                        </label>
                                        <label className="input_radio" htmlFor="return_check2">
                                            <input type="radio" id="return_check2" name="return" checked={!store.isKeyReturn} onClick={() => store.isKeyReturn = false}/> 
                                            <span className="label_text">미 반납</span>
                                        </label>
                                    </div>
                                    
                                    {!store.isKeyReturn? (
                                        <div className="input_box">
                                            <select className="form_control" value={store.keySelect} onChange={(e:any) => store.keySelect = e.target.value}>
                                                <option value="열쇠 분실">열쇠 분실</option>
                                                <option value="기타">기타</option>
                                            </select>
                                            <input type="text" className="form_control" placeholder="미 반납 이유를 적어주세요."
                                                value={store.keyDescription} 
                                                onChange={(e:any) => store.keyDescription = e.target.value}
                                                disabled={store.keySelect !== "기타"}
                                            />
                                        </div>
                                    ) : ''}
                                </dd>

                                <dt>비품 이상 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="fixtures_check">
                                            <input type="radio" id="fixtures_check" name="fixtures" checked={!store.isEquipment} onClick={() => store.isEquipment = false}/> 
                                            <span className="label_text">이상 없음</span>
                                        </label>
                                        <label className="input_radio" htmlFor="fixtures_check2">
                                            <input type="radio" id="fixtures_check2" name="fixtures" checked={store.isEquipment} onClick={() => store.isEquipment = true}/> 
                                            <span className="label_text">이상 있음</span>
                                        </label>
                                    </div>

                                    {store.isEquipment? (
                                        <input type="text" className="form_control" placeholder="비품 이상 여부를 적어주세요."
                                            value={store.equipmentDescription} onChange={(e:any) => store.equipmentDescription = e.target.value}/>
                                    ) : ''}
                                </dd>

                                {store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room || store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room_group? (
                                    <>
                                        {/* <dt>식권 반납 수량</dt>
                                        <dd>
                                            <div className="input_box">
                                                <input type="text" className="form_control" placeholder="식권 반납 수량을 입력해주세요"
                                                    value={store.mealTicket} onChange={(e:any) => store.mealTicket = parseInt(e.target.value) || 0}/>
                                            </div>
                                        </dd> */}

                                        <ul className="request_date">
                                            <li>
                                                <strong>식권수량</strong>
                                                <span>{store.checkOutDetailItem.meal_ticket}</span>
                                            </li>
                                            <li>
                                                <strong>반납수량</strong>
                                                <span>
                                                    <input type="text" className="form_control" placeholder="식권 반납 수량을 입력해주세요" style={{'width':'50px'}}
                                                        value={store.mealTicket} onChange={(e:any) => store.mealTicket = parseInt(e.target.value) || 0}
                                                        maxLength={4}
                                                    />
                                                </span>
                                            </li>
                                            <li>
                                                <strong>사용수량</strong>
                                                <span>{(store.checkOutDetailItem.meal_ticket-store.mealTicket)}</span>
                                            </li>
                                        </ul>
                                    </>
                                ) : '' }
                            </dl>
                        </div>

                        <div className="table_normal_list mt10">
                            <table>
                                <caption className="sr_only">숙소 이미지 첨부</caption>
                                <colgroup>
                                    <col width="*"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="ac">숙소 이미지 첨부</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="al">
                                        <MngFileUpload type="image" fileId="attacheFile" items={store.images} onSetData={(data: any[]) => store.images = [...data]} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>  {/*table*/}

                        <div className="process_list mt20">
                            <dl>
                                <dt>최종 퇴숙일</dt>
                                <dd>
                                    <select className="form_control year" value={store.manageYear} onChange={(e:any) => store.manageYear = e.target.value}>
                                        {getYear5(true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                    <select className="form_control month" value={store.manageMonth} onChange={(e:any) => store.manageMonth = e.target.value}>
                                        {getMonths(store.manageYear, true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                    <select className="form_control day" value={store.manageDay} onChange={(e:any) => store.manageDay = e.target.value}>
                                        {getDays(store.manageYear, store.manageMonth, false).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </dd>
                                {/* <dt>비고입력</dt>
                                <dd>
                                    <textarea className="form_control"></textarea>
                                </dd> */}
                            </dl>
                        </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={saveCheckOut}>완료</button>
                    </div>
                </div>
            </div>
        </LodgedTotal>
    </>
    );
})

export default LodgedCheckOutHistoryDetail;