import { range } from 'lodash';
import React from 'react';

//공제년월 : 년 selectbox
const YearSelect = (props: any) => {
  return (
    <>
      <select name="" id="" className="form_control col_sm"
      value={props.year}
      onChange={props.onChange}
      >
      {range(Number(props.year) - 1, Number(props.year) + 2, 1).map((item : any , index : number) => (
          <option key={index}>{item}</option>
      ))}
      </select>
      <span className="pl10">년</span>
    </>
  );
};

export default YearSelect;
