import React from 'react';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';
import MobileServerError from '../mobile/serverError';

const ServerError = (props: any) => {
  return (
    <>
      <BrowserView>
        <div id="wrap" className="error">
            <div id="header">
                <div className="group_member">
                    <h1>
                        <a href="javascript:void(0);" className="sr_only">WESN AMS Platform</a>
                    </h1>
                </div>
            </div>

            <div className="error_message">
                <p className="ico_error type2">죄송합니다 <span>기술적인 문제로 서비스 접속이 되지 않습니다.</span></p>
                <p className="detail"> 
                    일시적인 현상으로 잠시 후 다시 이용해 보시면 정상 접속할 수 있습니다 <br />
                    해당 내용을 담당자가 확인 중에 있습니다. <br />
                    문제가 지속되는 경우에는 플랫폼 담당자(tel: 02-2134-7324 / mail: robbie@hyundai-autoever.com)로 알려주시면조속히 문제해결이 될 수 있도록 하겠습니다. <br /> <br />
                    이용에 불편을 드려 다시 한번 사과드립니다.
                </p>
                <button type="button" className="btn_lg btn_darkblue" onClick={()=> props.history.push("/home")}>Home</button>
            </div>
        </div>
      </BrowserView>
      <MobileView>
        <MobileServerError {...props} />
      </MobileView>
    </>
  );
};

export default ServerError;
