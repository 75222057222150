import React, { useEffect, useState, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { FILE_CODE, GUEST_TYPE, LODGE_STATUS_CODE, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../constants';
import FileSaver from 'file-saver';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import DeductDetail from '../../components/organismas/popup/deduct';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { core, lodge } from '../../apis';
import ConfirmBtn from '../../components/atoms/button/confirm';
import { MessageContext, MessageImpl } from '../../routes';
import { useSelector } from 'react-redux';

const GuestDetailForm = observer((props: any) => {
    const [isDeductionPop, setIsDeductionPop] = useState<boolean>(false);
    const guestData: any = props.guestData;
    const {headerStore: headerStore} = useStores();
    const {lodgedHistoryStore: store} = useStores();
    const [isImgPop, setIsImgPop] = useState<boolean>(false);
    const [imgPath, setImgPath] = useState<string>('');
    const [checkinMemo, setCheckinMemo] = useState<string>(guestData.memo1);    // 비고
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));

    const fileDown  = (url: string, name:string) => {
        FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}/`+url, name);
    }

    useEffect(() => {
        headerStore.getAuthMenuRole('2-1');
    },[]);

    const DeductionPop = async() => {
        try{
            headerStore.isLoading = true;
            setIsDeductionPop(true);
            await store.getDeductList();
            headerStore.isLoading = false;
        }catch({status, data}){
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
        }
    }

    // 비고 수정
    const updateMemo = async() => {
        try {
            headerStore.isLoading = true;
            let payload = {
                checkinmemo : store.guestData.memo1,
                checkoutmemo : store.guestData.memo1
            };
            await lodge.updateMemo( guestData.id , payload);
            headerStore.isLoading = false;
            props.reload();
        } catch ({status, data}){
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                },
                });
                return ;
            }else if ( status === 500 ) {
                //window.location.href = '/500';
                return;
            }
        }
    }

    // 파일의 확장자를 가져오는 함수
    const getFileExtension = (filename: string) => {
      const parts = filename.split('.');
      console.log(filename);
      if (parts.length > 1) {
        return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
      } else {
        return ''; // 마침표가 없는 경우 빈 문자열 반환
      }
    };

    const downloadFile = async (url: string, filename: string) => {
      const token = window.localStorage.getItem('accessToken');

      if (!url) {
        console.error('url 경로가 없습니다.');
        return;
      }

      if(!filename || filename.length == 0) {
        const name = url.split("/");
        filename = name[name.length - 1];
      }
    
      const unifiedUrl = url.replace(/\\/g, '/');
      const parts = unifiedUrl.split('/');
      const desiredParts = parts.slice(2).join('\\');

      // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
      const isImageOrPDF = (extension: string) => {
        const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
        return supportedExtensions.includes(extension);
      };
    
      const fileExtension = getFileExtension(filename);
      const isImageOrPDFFile = isImageOrPDF(fileExtension);
    
      if(isImageOrPDFFile) {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
      else {
        //fileDownload
        try {
          let payload: any = {
            filename: filename,
            url : desiredParts,
            employeeNumber : userInfo.employeeNumber,
            classification : userInfo.classification,
          };

          let { data } = await core.fileDownload(payload);
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          //info("협조전 등록이 완료되었습니다.");
        } catch ({ data, status }) {
          console.log(data, status);
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
          info("파일 다운로드 중 문제가 발생했습니다");
          return;
        }
    }
      
    };
    
    // 이용자 정보 하단
    const detailFrm = () => {
        if(props.guestData.room_type === ROOM_TYPE_CODE.dormitory_room){ 
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>근무조</th>
                        <td>{guestData.guest_party}</td>
                        <th>차량번호</th>
                        <td>{guestData.guest_car_number1}</td>
                    </tr>
                    <tr>
                        <th>공제내역</th>
                        <td>
                            <button type="button" className="btn btn_xs btn_outline" onClick={(e) => store.isDeductionPop = true}>상세내역</button> {/*DeductionPop() */}
                        </td>
                        <th>입숙신청서류</th>
                        <td colSpan={3}>
                            {props.booking.files? (
                                props.booking.files.map((obj: any, index: number) => (
                                    <>
                                    { //headerStore.downRoleFlag ? 
                                    <button 
                                    className="btn_sm btn_darkblue btn_download"
                                    onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${obj.file.path}`, '')}
                                    >
                                    {obj.document.name}
                                    </button>
                                    /*headerStore.downRoleFlag ? 
                                        <a key={index} 
                                        href={`${process.env.REACT_APP_MEDIA}/${obj.file.path}`}
                                        className="item file"
                                        //onClick={() => fileDown(obj.file.path, obj.document.name)} 
                                        target={`${obj.document.name}`.split('.').pop() 
                                                && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${obj.document.name}`.split('.').pop() || '' )
                                                && !isMobile ? '_blank' : ''}
                                    >
                                        {obj.document.name}
                                    </a>
                                    : <a className="item file">{obj.document.name}</a>*/
                                    }
                                    </>
                                ))
                            ) : ''}
                        </td>
                    </tr>
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.company_room){  
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>차량번호1</th>
                        <td>{guestData.guest_car_number1}</td>
                        <th>차량번호2</th>
                        <td>{guestData.guest_car_number2}</td>
                    </tr>
                    <tr>
                        <th>차량번호3</th>
                        <td>{guestData.guest_car_number3}</td>
                        <th>공제내역</th>
                        <td colSpan={3}>
                            <button type="button" className="btn btn_xs btn_outline" onClick={(e) => store.isDeductionPop = true}>상세내역</button>
                        </td>
                    </tr>
                    <tr>
                        <th>입숙신청서류</th>
                        <td colSpan={5}>
                            {props.booking.files? (
                                props.booking.files.map((obj: any, index: number) => (
                                    <>
                                        { 
                                        <button 
                                        className="btn item file btn_download"
                                        onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${obj.file.path}`, '')}
                                        >
                                        {obj.document.name}
                                        </button>
                                        /*headerStore.downRoleFlag ? 
                                            <a key={index} 
                                            href={`${process.env.REACT_APP_MEDIA}/${obj.file.path}`}
                                            className="item file"
                                            //onClick={() => fileDown(obj.file.path, obj.file.name)} 
                                            target={`${obj.document.name}`.split('.').pop() 
                                                    && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${obj.document.name}`.split('.').pop() || '' )
                                                    && !isMobile? '_blank' : ''}
                                            >
                                                {obj.document.name}
                                            </a>
                                : <a className="item file">{obj.document.name}</a>*/}
                                        </>
                                    )) 
                            ) : ''}
                        </td>
                    </tr>
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.vip_room){    
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>차량번호</th>
                        <td colSpan={3}>{guestData.guest_car_number1}</td>
                    </tr>
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.business_room){
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>식권 수 (장)</th>
                        <td>{guestData.meal_ticket}</td> 
                        <th>차량번호</th>
                        <td>{guestData.guest_car_number1}</td>
                    </tr>
                    {WORK_SITE_CODE[props.guestData.room_work_site] === WORK_SITE_CODE.울산공장 && store.guestData.status === LODGE_STATUS_CODE.checkout ? 
                        <tr>
                            <th>공제내역</th>
                            <td colSpan={5}>
                                <button type="button" className="btn btn_xs btn_outline" onClick={(e) => store.isDeductionPop = true}>상세내역</button>
                            </td>
                        </tr>
                        : <></>
                    }
                </>
            );
        }else if(props.guestData.room_type === ROOM_TYPE_CODE.business_room_group){
            return (
                <>
                    <tr>
                        <th>소속 부서</th>
                        <td>{guestData.guest_department}</td>
                        <th>차량번호</th>
                        <td>{guestData.guest_car_number1}</td>
                        <th>식권 수 (장)</th>
                        <td>{guestData.meal_ticket}</td> 
                    </tr>
                    <tr>
                        <th>인솔자여부</th>
                        <td>
                            <label className="input_radio" htmlFor="user_type1">
                                <input type="radio" id="user_type1" name="user" checked={guestData.is_leader? true: false}/>
                                <span className="label_text">여</span>
                            </label>
                            <label className="input_radio" htmlFor="user_type2">
                                <input type="radio" id="user_type2" name="user" checked={!guestData.is_leader? true: false}/>
                                <span className="label_text">부</span>
                            </label>
                        </td>
                        <th>첨부파일</th>
                        <td>
                            {props.booking.files? (
                                props.booking.files.map((obj: any, index: number) => (
                                    <>
                                    { headerStore.downRoleFlag ? 
                                        obj.document.visible_type === FILE_CODE.RFC? (
                                            <a key={index} className="item file"
                                                href={`${process.env.REACT_APP_MEDIA}/${obj.file.path}`} 
                                                target={`${obj.file.path}`.split('.').pop() 
                                                    && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${obj.file.path}`.split('.').pop() || '' )
                                                    && !isMobile? '_blank' : ''}
                                            >
                                                {obj.file.name}
                                            </a>
                                        ) : '' : <a className="item file">{obj.file.name}</a>
                                    } </>
                                ))
                            ) : ''}
                        </td>
                        <th></th>
                        <td></td>
                    </tr>
                </>
            );
        }
        return '';
    }

    if(props.guestData.room_type === ROOM_TYPE_CODE.family_room) {
        return (
            <div className="table_normal_list">
                <table>
                    <caption className="sr_only">이용자정보</caption>
                    <colgroup>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>성명</th>
                        <th>성별</th>
                        <th>휴대폰번호</th>
                        <th>신청자와의 관계</th>
                        <th>차량번호</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{guestData.guest_name}</td>
                        <td>{guestData.guest_gender === 1 ? '남' : '여'}</td>
                        <td>{guestData.guest_cellphone}</td>
                        <td>{guestData.guest_relation}</td>
                        <td>{guestData.guest_car_number1}</td>
                    </tr>
                    {props.related_guests ? ((
                        props.related_guests.map((list: any, historyIdx: number) => (
                            <tr key={historyIdx}>
                                <td>{list.guest_name}</td>
                                <td>{list.guest_gender === 1 ? '남' : '여'}</td>
                                <td>{list.guest_cellphone}</td>
                                <td>{list.guest_relation}</td>
                                <td>{list.guest_car_number1}</td>
                            </tr>
                        ))
                    )) : '' }
                    </tbody>
                </table>
            </div>
        );
    }else {
        return(
            <>
            <div className="table_normal_list table_write">
                <table>
                    <caption className="sr_only">이용자정보</caption>
                    <colgroup>
                        <col width="200"/>
                        <col width="*"/>
                        <col width="200"/>
                        <col width="*"/>
                        <col width="200"/>
                        <col width="*"/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>소속 회사 유형</th>
                        <td>{guestData.guest_type !== 4 ? GUEST_TYPE[guestData.guest_type] : '신입(경력)'}</td>
                        <th>회사명(단체명)</th>
                        <td>{guestData.guest_company ? guestData.guest_company : '-'}</td>
                        <th>사번(아이디)</th>
                        <td>{guestData.guest_employee_number}</td>
                    </tr>
                    <tr>
                        <th>성명</th>
                        <td>{guestData.guest_name}</td>
                        <th>휴대폰번호</th>
                        <td>{guestData.guest_cellphone}</td>
                        <th>성별</th>
                        <td>{guestData.guest_gender === 1 ? '남' : '여'}</td>
                    </tr>
                    
                    {detailFrm()}
                    
                    <tr>
                        <th>이용자 입숙서류 스캔본</th>
                        <td colSpan={5}>
                            <div className="images_area">
                            {guestData.checkin_files && guestData.checkin_files.map((image: any, index: number) => (
                                <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                <a href="javascript:void(0);">
                                    <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt="미리보기"/>
                                </a>
                                </div>
                            ))}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>침구수령여부</th>
                        <td colSpan={5}>{guestData.bedding}</td>
                    </tr>
                    <tr>
                        <th>비고</th>
                        <td colSpan={4}>
                            <textarea className="form_control" value={store.guestData.memo1} maxLength={400} 
                                onChange={(e) => {
                                    //setCheckinMemo(e.target.value);
                                    store.guestData.memo1 = e.target.value;
                                }}
                            >
                            </textarea>
                        </td>
                        <td style={{textAlign:'center'}}>
                            <ConfirmBtn
                                className="btn btn_mg btn_outline"
                                action={() => {updateMemo()}}
                                label="수정"
                                title=""
                                msg={"수정하시겠습니까?"}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div> 

            {/* 이미지 팝업 */}
            <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
                <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
                <div className="pop_wrap">
                    <div className="contents_wrap">
                        <div className="slide">
                            <p className="img_area">
                                <img src={imgPath} alt=""/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <DeductDetail/>
            {/* 공제내역 팝업 
            <div id="pop_history_deduction" className="pop_dim_wrap pop_modal pop_lg" style={{ display: isDeductionPop ? 'block' : 'none' }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>공제내역</h3>
                        <button className="pop_btn_close pop_close" onClick={(e) => setIsDeductionPop(false)}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <div className="title_contents bd0">
                            <h3>총 공제내역 : <span className="color_green">54,000원</span></h3>
                            <div className="right_area form_inline">
                            <select name="" id="" className="form_control" style={{'width': 120+'px'}}value={store.year} 
                                onChange={(e:any) => {
                                    store.setYear(e.target.value);
                                    //setClick(true);
                                    //changeYear(e.target.value); 
                                }}>
                            {getYear5(true).map((year:any, index: number) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                            </select>
                            <select name="" id="" className="form_control" style={{'width': 100+'px'}} value={store.month} 
                                onChange={(e: any) => {
                                    store.setMonth(e.target.value);
                                    //setClick(true);
                                    //changeMonth(e.target.value);
                                }}>
                                {range(0, 12, 1).map((item:any, index:number) => (
                                    <option key={index} value={`${item + 1}`.padStart(2, '0')}>{`${item + 1}`.padStart(2, '0')}</option>
                                ))}
                            </select>
                            </div>
                        </div>
                        {props.guestData.room_type == ROOM_TYPE_CODE.company_room ? //
                        <div className="table_normal_list table_write mt10">
                            <table>
                                <caption></caption>
                                <colgroup>
                                    <col width="130"/>
                                    <col width="*"/>
                                    <col width="130"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>기본료</th>
                                    <td className="ar">31,000원</td>
                                    <th>개별전기료</th>
                                    <td className="ar">10,000원</td>
                                </tr>
                                <tr>
                                    <th>공용전기료</th>
                                    <td className="ar">10,000원</td>
                                    <th>하/수도료</th>
                                    <td className="ar">4,000원</td>
                                </tr>
                                <tr>
                                    <th>기타</th>
                                    <td className="ar">3,000원</td>
                                    <th></th>
                                    <td className="ar"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        : <></>}
                        {props.guestData.room_type == ROOM_TYPE_CODE.dormitory_room ? 
                        <div className="table_normal_list table_write mt10">
                            <table>
                                <caption></caption>
                                <colgroup>
                                    <col width="130"/>
                                    <col width="*"/>
                                    <col width="130"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>기본료(유지비)</th>
                                    <td className="ar">{store.deductData ? store.deductData.additional_charges1 : 0 }원</td>
                                    <th>유틸리티비용</th>
                                    <td className="ar">10,000원</td>
                                </tr>
                                <tr>
                                    <th>침구대</th>
                                    <td className="ar">10,000원</td>
                                    <th>초과금액</th>
                                    <td className="ar">4,000원</td>
                                </tr>
                                <tr>
                                    <th>기타</th>
                                    <td className="ar">3,000원</td>
                                    <th></th>
                                    <td className="ar"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        : <></>}
                        {props.guestData.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[props.guestData.room_work_site] === WORK_SITE_CODE.울산 ? 
                        <div className="table_normal_list table_write mt10">
                            <table>
                                <caption></caption>
                                <colgroup>
                                    <col width="130"/>
                                    <col width="*"/>
                                    <col width="130"/>
                                    <col width="*"/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th>키반납</th>
                                    <td className="ar">31,000원</td>
                                    <th>숙박비</th>
                                    <td className="ar">10,000원</td>
                                </tr>
                                <tr>
                                    <th>식권금액</th>
                                    <td className="ar">10,000원</td>
                                    <th>미반납액</th>
                                    <td className="ar">4,000원</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    : <></>} 
                    </div>
                    <div className="button_wrap">
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={(e) => setIsDeductionPop(false)}>확인</button>
                    </div>
                </div>
            </div>
            */}
            </>  
        );
    }
});

export default GuestDetailForm;