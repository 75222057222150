import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState, useRef } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../components/atoms/select';
import MobileFileUpload from '../../../components/organismas/form/mobileFile';
import MobileMngFileUpload from '../../../components/organismas/form/mobileMngFile';
import DatePicker from '../../../components/organismas/input/datepicker';
import { VOC_CATEGORY, ROOM_TYPE } from '../../../constants';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import _ from 'lodash';
import '../mb_common.scss';
import MobilePlace from './place';
import CustomCalendar from '../../../components/organismas/customCalendar';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { parserEscape, showAlert } from '../../../utils/common';

const MobileVocReg = observer((props: any) => {
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const { vocStore: store } = useStores();
    const { mobileTotalStore : mobileStore } = useStores();
    const {headerStore: headerStore} = useStores();
    const item = store.item;
    const id = -1;
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const [hours, setHours] = useState<any[]>([{ value: '', label: '시간선택', optionIdx: 0 }]);
    const [isPlaceOpen, setIsPlaceOpen] = useState<boolean>(false);
    const [itemContents, setItemContents] = useState<string>(item && item.contents? item.contents : '');
    const editorRef = useRef<any>(null);
    const editorInputRef = useRef<any>(null);

    useEffect(() => {
        const temps = _.range(0, 24, 1).map(num => ({ value: (num + '').padStart(2, '0'), label: (num + '').padStart(2, '0') }));
        setHours([{ value: '', label: '시간선택' }, ...temps]);

        if(props.isUpdate){
            if (store.item.category1) {
                const c1: any = VOC_CATEGORY.find((c: any) => c.name === store.item.category1);
                store.category2 = [
                  { value: '', name: '선택', child: [] },
                  ...c1.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                ];
            }
            if (store.item.category2) {
                const c2: any = store.category2.find((c: any) => c.name === store.item.category2);
                store.category3 = [
                    { value: '', name: '선택', child: [] },
                    ...c2.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                ];
            }
            if (store.item.category3) {
                const c3: any = store.category3.find((c: any) => c.name === store.item.category3);
                store.category4 = [
                    { value: '', name: '선택', child: [] },
                    ...c3.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                ];
            }
            if (store.item.category3) {
                const c3: any = store.category3.find((c: any) => c.name === store.item.category3);
                try{
                    headerStore.isLoading = true;
                    let data:any = store.getCategories4();
                    headerStore.isLoading = false;
                    // setTimeout(function () {
                    //     if(data.save) {

                    //     }else {
                    //         info("문제가 발생했습니다.");
                    //         return;
                    //     }
                    // }, 250);
                    
                }catch ({ status, data }) {
                    headerStore.isLoading = false;
                    if (status === 401) {
                      if (!window.localStorage.getItem("isRetryLogin")) {
                        window.localStorage.setItem("isRetryLogin", "retry");
                      }
                      window.location.href = '/';
                      return ;
                    } else if (status === 403) {
                      (window as any).store.dispatch({
                        type: SHOW_ALERT_MODAL,
                        payload: {
                          title: '권한오류',
                          msg: '접근권한이 없습니다.',
                          redirectPath: false,
                          statusCode: status,
                          errorCode: '',
                          type: 'error',
                          isModalShow: true,
                        },
                      });
                      return ;
                    }else if ( status === 500 ) {
                      window.location.href = '/500';
                      return;
                    }

                    info("문제가 발생했습니다");
                    return;
                  }
              }
        }else {
            store.newItem();
        }
    }, [props.isRegPop]);

    const onSaveHandler = async () => {
        item.contents = itemContents;
        
        if (!store.isChange) {
            info('변경된 항목이 없습니다.');
            return;
        }

        if (!item.category1) {
            info('1차분류를 선택해주세요.');
            return;
        }

        if (!item.title) {
            info('제목을 입력해주세요.');
            return;
        }

        if (!item.contents) {
            info('내용을 작성해주세요');
            return;
        }

        if(!item.found_at_date){
            info('발생 일자를 입력해주세요.')
            return;
        }

        try {
            let msg = '등록 완료했습니다.';
            if (id > -1) {
                msg = '수정 완료했습니다.';
            }

            store.item.work_site = headerStore.workSite;
            headerStore.isLoading = true;
            let data : any = await store.saveItem();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info(msg);
                    props.onClose();
                    mobileStore.getVocList();
                }else {
                    info("문제가 발생했습니다.");
                    return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }else {
                info("저장 중 문제가 발생하였습니다.");
                return;
            }
        }
    };

    const onSetPlacesHandler = (_places: any[], roomType: number, etc: string, etc2: string) => {
        if(Number(roomType) === 7) {
            item.places = [];
            item.places_room_type= roomType;
            item.places_etc = etc;
            item.places_etc2 = etc2;
        }else {
            item.places = _places;
            item.places_room_type= roomType;
            item.places_etc = '';
            item.places_etc2 = '';
        }
    };

    if(!props.isRegPop) return <></>;

    return (
        <>
            {/* 등록 팝업 */}
            <div id="" className="pop_modal" style={{ display: props.isRegPop ? 'block' : 'none' }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <button className="pop_btn_prev" onClick={() => { props.onClose() }}></button>
                        <h3>유지보수 {props.isUpdate? '수정' : '등록'}</h3>
                        <button className="pop_btn_close pop_close" onClick={() => { props.onClose() }}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <div className="form_write">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="input_type1" className="title_form">1차분류</label>
                                    <select name="" id="input_type1" className="form_control"
                                        value={item.category1}
                                        onChange={(e) => {
                                            const category: any = VOC_CATEGORY.find(c => c.name === e.target.value);
                                            item.category1 = category ? category.name : '';
                                            item.category2 = '';
                                            item.category3 = '';
                                            item.category4 = '';
                                            if (item.category1 && category.child.length > 0) {
                                                store.category2 = [
                                                    { value: '', name: '선택', child: [] },
                                                    ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                                                ];
                                            } else {
                                                store.category2 = [];
                                            }
                                            store.category3 = [];
                                            store.category4 = [];
                                        }}
                                    >
                                        <option value="">선택</option>
                                        {VOC_CATEGORY.map((category: any, index: number) => (
                                            <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="input_type2" className="title_form">2차분류</label>
                                    <select name="" id="input_type2" className="form_control"
                                        disabled={store.category2.length > 0? false :  true}
                                        value={item.category2}
                                        onChange={(e) => {
                                            const category: any = store.category2.find(c => c.name === e.target.value);
                                            item.category2 = category ? category.name : '';
                                            item.category3 = '';
                                            item.category4 = '';
                                            if (item.category2 && category.child.length > 0) {
                                                store.category3 = [
                                                { value: '', name: '선택', child: [] },
                                                ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child  }))
                                                ];
                                            } else {
                                                store.category3 = [];
                                            }
                                            store.category4 = [];
                                        }}
                                    >
                                        {store.category2.map((category: any, index: number) => (
                                            <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="input_type3" className="title_form">3차분류</label>
                                    <select name="" id="input_type3" className="form_control" 
                                        disabled={store.category3.length > 0? false :  true}
                                        value={item.category3}
                                        onChange={(e) => {
                                            const category: any = store.category3.find((c: any) => c.name === e.target.value);
                                            item.category3 = category ? category.name : '';
                                            item.category4 = '';
                                            store.getCategories4()
                                            if (item.category3 && store.category4) {              
                                            } else {
                                                store.category4 = [];
                                            }
                                            }}
                                    >
                                        {store.category3.map((category: any, index: number) => (
                                            <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="input_type4" className="title_form">4차분류</label>
                                    <select name="" id="input_type4" className="form_control" 
                                        disabled={store.category4.length > 0? false :  true}
                                        value={item.category4}
                                        onChange={(e) => item.category4 = e.target.value}
                                    >
                                        {store.category4.map((category: any, index: number) => (
                                            <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <ul className="lst_userinfo">
                            <li className="user_area">
                                <span className="img_user"></span>
                                <span className="username">{userInfo.username}</span>
                                {/*<span className="state">처리중</span>*/}
                            </li>
                            <li>
                                <strong>발생장소</strong>
                                <span>
                                    {/* {item.places.map((place: any) => place.building_name === '기타'
                                        ? `${place.building_name} > ${place.room_name}-${place.floor_name}`
                                        : `${place.building_name} > ${place.building_dong ? place.building_dong + '>' : ''} ${place.room_name}`
                                        ).join(', ')} */}
                                    {item.places_etc ? (
                                        '기타 > '+ item.places_etc + ' > ' + item.places_etc2
                                    ): 
                                        item.places && item.places.length > 0? 
                                            `${ROOM_TYPE[item.places[0].room_type]} > ${item.places[0].building_name} > ${item.places[0].building_dong ? item.places[0].building_dong + '>' : ''} ${item.places[0].room_name} ${item.places.length > 1 ? ' 외 ' + (item.places.length - 1) + '곳' : ''}`
                                        : ''
                                    }
                                </span>
                            </li>
                            <li style={{borderBottom: 'none'}} className="mt10">
                                <strong></strong>
                                <span>
                                    <button type="button" className="btn btn_xs btn_darkblue" onClick={() => setIsPlaceOpen(true)}>장소선택</button>
                                </span>
                            </li>
                        </ul>

                        <div className="form_write">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="input_date" className="title_form">발생일시</label>
                                    {/* <input type="text" id="input_date" className="form_control form_sm datepicker" placeholder="" /> */}
                                    {/* <DatePicker
                                        value={item.found_at_date}
                                        width={250}
                                        onChange={(date: string) => item.found_at_date = date}
                                        isPrevMonthView={true}
                                    /> */}
                                    <CustomCalendar
                                        value={item.found_at_date}
                                        onChange={(date:string) => item.found_at_date = date}
                                    ></CustomCalendar>
                                </div>
                                <div className="col">
                                    <label htmlFor="input_time" className="title_form ir">시간</label>
                                    {/* <select name="" id="input_time" className="form_control timepicker">
                                        <option value="">00</option>
                                    </select> */}
                                    <Select
                                        className="form_control timepicker"
                                        options={hours}
                                        value={item.found_at_time}
                                        onChange={(e: any) => item.found_at_time = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_title" className="title_form">제목</label>
                                    <input 
                                        type="text" 
                                        id="input_title" 
                                        className="form_control form_sm" 
                                        value={item.title}
                                        onChange={(e: any) => item.title = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_contents" className="title_form">내용</label>
                                    {/* <textarea name="" 
                                        id="input_contents" 
                                        className="form_control" 
                                        placeholder="내용을 입력하세요"
                                        value={item.contents} 
                                        onChange={(e: any) => item.contents = e.target.value}
                                    >
                                    </textarea> */}
                                    <Editor
                                        apiKey='xlr0lh7s89xjc18l3hhxdii44v71geaj0thaocytbn5jsxu7'
                                        onInit={(evt, editor) => {
                                            editorRef.current = editor;
                                        }}
                                        initialValue={parserEscape(item.contents)}
                                        onEditorChange={(e) => {
                                            setItemContents(e);
                                            // item.contents = e;
                                        }}
                                        init={{
                                            // width: 150,
                                            // height: 500,
                                            // resize: 'both',
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount',
                                                'image code'
                                                //'image media link tinydrive code imagetools'
                                            ],
                                            toolbar:
                                            'fontselect fontsizeselect | bold',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="form_write">
                            <MobileFileUpload  
                                type="image"
                                fileId="imageFile" 
                                items={item.images} 
                                setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                                onSetData={(data: any[]) => item.images = ([...data])} 
                            />
                            
                            <hr />

                            <MobileMngFileUpload 
                                type="file"
                                fileId="attacheFile"
                                items={item.files}
                                setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                                onSetData={(data: any[]) => item.files = data}
                            />
                        </div>
                    </div>
                    <div className="button_wrap">
                        <button type="button" className="btn btn_darkblue pop_close" onClick={onSaveHandler}>완료</button>
                    </div>
                </div>
            </div>

            {isPlaceOpen ? (
                <MobilePlace
                    isOpen={isPlaceOpen}
                    isView={false}
                    setIsOpen={setIsPlaceOpen}
                    user={store.user}
                    places={item.places}
                    onApply={onSetPlacesHandler}
                    setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                />
            ) : (<></>)}
        </>
    );
});

export default MobileVocReg;
