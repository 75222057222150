import React from 'react';

import styled from 'styled-components';

// 0, 7, 14, 21, 28 일요일: 순서 - 0이 7배수 #B6001A
// 6, 13, 20, 27    토요일: 순서 - 6이 6배수 #174491

const COLOR_RED = '#C48889';
const COLOR_BLUE = '#174491';
const COLOR_BLACK = '#797979';

const COLOR_SELECTED = '#E3EBF6';
const COLOR_DISABLE = '#e7e7e7';

const getColos: any = (props: any) => {
  if (props.header) {
    return 'black';
  }
  if (!props.sequence || !((props.sequence - 0) % 7)) {
    return !props.disable ? COLOR_RED : COLOR_DISABLE;
  } else if (!((props.sequence - 6) % 7)) {
    return !props.disable ? COLOR_BLUE : COLOR_DISABLE;
  } else {
    return !props.disable ? COLOR_BLACK : COLOR_DISABLE;
  }
};

const getBackgroundColors = (props: any) => {
  // console.log(props)
  if (props.header) {
    return '#FAFAFA';
  }
  if (!props.disable && props.isSelect) return COLOR_SELECTED;
  if (props.hover && !props.disable) {
    return '';
  }
};

const _Cell = styled.div`
  width: ${100 / 7}%;
  height: ${(props: any) => (props.hover ? '5vh' : '5vh')};
  box-sizing: border-box;
  color: ${(props: any) => getColos(props)};
  font-weight: ${(props: any) => props.header? "900": "500"};
  background-color: ${(props: any) => getBackgroundColors(props)};
  text-align: center;
  padding: 8px;

  // 드래그 시 텍스트 블럭 잡히는거 비활성화(selection 비 활성화)
  unselectable: on;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  border-radius: ${(props: any) => props.header? "0px" : "25px"};

  &:hover,
  &:active {
    background-color: ${(props: any) =>
      props.hover && !props.disable ? COLOR_SELECTED : ''};
  }

  // 테블릿
  @media (min-width: 768px) {
    & {
      // border-radius: 5px;
      // padding: 15px;
      // height: 50px;
    }
  }

  // PC
  @media (min-width: 1024px) {
    & {
      // padding: 35px;
    }
  } 
`;

const Cell = (props: any) => {
  if (!props.hover) return <_Cell {...props}>{props.children}</_Cell>;

  return (
    <_Cell
      {...props}
      onMouseDown={(e: any) => {
        props.onStartDateCell(e.target);
      }}
      onMouseUp={(e: any) => {
        props.onEndDateCell(e.target);
      }}
    >
      {props.children}
    </_Cell>
  );
};

export default Cell;
