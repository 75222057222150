import React, { useContext, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import DefaultTemplate from '../../../components/templetes/default';
import DeductTab from '../deductTab';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import XLSX from 'xlsx';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import { exportFileExcel, getViewPageIdx, numberFormatter } from '../../../utils/common';
import MonthSelect from '../monthSelect';
import YearSelect from '../yearSelect';
import { formatByString } from '../../../utils/date';
import { WORK_SITE_CODE } from '../../../constants';
import { core } from '../../../apis';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';
import Button from '../../../components/molecules/fileUpload/button';


const DeductMeterPage = observer((props: any) => {
  const dispatch = useDispatch();
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const {meterStore: store} = useStores();
  const {headerStore : headerStore} = useStores();
  const baseUrl = '/guest/deductible';

  const [grid1, setGrid1] = useState<any>(null);
  const [grid2, setGrid2] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [sources, setSources] = useState<any[]>([]);
  const fileRef = useRef(null);

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>("");

  const getItems = async () => {
    switch(Number(headerStore.workSite)) {
      case WORK_SITE_CODE.울산공장:
        store.workSiteName = '울산';
        break;
      case WORK_SITE_CODE.남양연구소:
        store.workSiteName = '남양';
        break;
      case WORK_SITE_CODE.전주공장:
        store.workSiteName = '전주';
        break;
      case WORK_SITE_CODE.아산공장:
        store.workSiteName = '아산';
        break;
    }
    if(Number(headerStore.workSite) !== WORK_SITE_CODE.마북 
      && Number(headerStore.workSite) !== WORK_SITE_CODE.의왕 
      && Number(headerStore.workSite) !== WORK_SITE_CODE.기타) {
      await store.getItems();
    }else {
      store.items = [];
    }
  };

  useEffect(() => {
    headerStore.getAuthMenuRole('2-4');
    if (store.items.length === 0) {
      (async () => {
        await getItems();
      })();
    }
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, []);

  useEffect(() => {
    headerStore.getAuthMenuRole('2-4');
    (async () => {
      (fileRef.current as any).value = null;
      setSources([]);
      store.xlsDwonFlag = false;
      store.xlsParamDate = '';
      await getItems();

    })();
  }, [headerStore.workSite]);

  const onFileChange = async (e: any) => {
    let file = e.target.files[0]
    let splited = file.name.split('.')
    if (splited[splited.length - 1] !== 'xlsx' && splited[splited.length - 1] !== 'xls') {
      let msg = "업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 검침량 업로드에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.";
      info(msg);
      return;
    }
    let deductList: any = [];

    try {
      headerStore.isLoading = true;
      var excel = new FormData();
      
      excel.append("path", file);
      console.log(file);
      let { data } = await core.fileDeductList(excel);
      console.log('debug2');
      deductList = data.data || [];
      headerStore.isLoading = false;
      if(deductList.length > 0) {
        headerStore.isLoading = false;
        let errorRows : String = '';
        for(let idx=0; idx < deductList.length; idx++) {
          let excelRow = Number(idx + 1);

          //검침년월
          if(String(deductList[idx].meter_date) !== `${store.year}${store.month}`) {
            errorRows = (`검침량 년월(${store.year}${store.month})과` + excelRow + `행의 검침년월이 일치하지 않습니다.`);
            info("업로드한 파일에 오류가 있습니다.\n"+"("+errorRows+")");
            (fileRef.current as any).value = null;
            headerStore.isLoading = false;
            return;
          } else if(!deductList[idx].meter_date) {
            errorRows = (excelRow + `행의 검침년월 항목이 비어있습니다.`);
            info("업로드한 파일에 오류가 있습니다.\n"+"("+errorRows+")");
            (fileRef.current as any).value = null;
            headerStore.isLoading = false;
            return;
          }
          //건물
          if(!deductList[idx].building_name) {
            errorRows = (excelRow + '행의 건물 항목이 비어있습니다.');
            info("업로드한 파일에 오류가 있습니다.\n"+"("+errorRows+")");
            (fileRef.current as any).value = null;
            headerStore.isLoading = false;
            return;
          }
          //동
          if(!deductList[idx].dong) {
            errorRows = (excelRow + '행의 동 항목이 비어있습니다.');
            info("업로드한 파일에 오류가 있습니다.\n"+"("+errorRows+")");
            (fileRef.current as any).value = null;
            headerStore.isLoading = false;
            return;
          }
          //호
          if(!deductList[idx].ho) {
            errorRows = (excelRow + '행의 호 항목이 비어있습니다.');
            info("업로드한 파일에 오류가 있습니다.\n"+"("+errorRows+")");
            (fileRef.current as any).value = null;
            headerStore.isLoading = false;
            return;
          }
        }
        if(errorRows === '') {
          setSources(deductList);
        }
      }
    } catch ({status, data}) {
      headerStore.isLoading = false;
      (fileRef.current as any).value = null;
      if (status === 401) {
        window.location.href = '/force/signout';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        info('업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 검침량 업로드에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.');
        return;
      }
      let msg = "업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 검침량 업로드에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.";
      info(msg);
    }
  };

  const onSourceGrid1Ready = (params: any) => {
    setGrid1(params.api);
  };

  const onSourceGrid2Ready = (params: any) => {
    setGrid2(params.api);
  };

  const onCreate = async () => {
    let chk = true;

    if (sources.length === 0) {
      info('검침량 정보가 없습니다.');
      return;
    } else {
      Object.keys(sources).forEach((col: string, index:number) => {
        if(String(sources[index].meter_date) !==  `${store.year}${store.month}`) {
          error(`검침량 년월(${store.year}${store.month})과 데이터 자료(${sources[index].meter_date})가 일치하지 않습니다.`);
          chk = false;
        }
      });
    }
    if(chk) {
      try {
        headerStore.isLoading = true;
        const newData: any = {
          meter_date: `${store.year}${store.month}`,
          worksite: store.workSiteName,
          employee_number: store.user.employeeNumber,
          metervolume: [ ...sources ],
        };
        let data: any = await store.createMeter(newData);
        headerStore.isLoading = false;

        setTimeout(function () {
          if(data.save) {                
            info('검침량 정보를 생성했습니다.');
          }else {
            if(data.data.non_field_errors[0] === '이번달 검침량은 이미 등록하였습니다. 등록한 목록을 삭제후 재등록 하시기 바랍니다.') {
              info(data.data.non_field_errors[0]);
            } else {
              info('문제가 발생했습니다.');
            }
            
            return;
          }
        }, 250);

      } catch ({ status, data }) {
        headerStore.isLoading = false;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        errorHandler({ status, data }, '저장 중 오류발생');
      }
    }
  };

  const onDeletehandler = async (item: any) => {
    if (!item) {
      info('선택된 항목이 없습니다.');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data: any = await store.deleteMeter(item);
            headerStore.isLoading = false;
            
            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
              }else {
                info(data.data);
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              window.location.href = '/force/signout';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              console.log('500')
              window.location.href = '/500';
              return;
            }
          }
        },
      }
    });
  };

  const cellDeleteRenderer = ({data}: {data: any}) => {
    return <button type="button" className="btn btn_xs btn_outline" onClick={() => onDeletehandler(data)}>삭제</button>;
  };

  const cellCreatedAtRenderer = ({data}: {data: any}) => (formatByString(data.created_at, 'yyyy.MM.dd'));

  const frameworkComponents = {
    cellDeleteRenderer,
    cellCreatedAtRenderer,
  };

  const onSelectionChanged = () => {
    const selectedRows = grid2.getSelectedRows();
    if (selectedRows.length > 0) {
      (fileRef.current as any).value = null;
      store.xlsParamDate = selectedRows[0].meter_date;
      store.xlsDwonFlag = true;
      setSelectedRow(selectedRows[0]);
      setSources(selectedRows[0].metervolume);
    } else {
      (fileRef.current as any).value = null;
      setSelectedRow(null);
      setSources([]);
      store.xlsDwonFlag = false;
    }
  };

  const exportExcel = () => {
    if (!selectedRow) {
      info('선택된 관리항목이 없습니다.');
      return;
    }

    exportFileExcel(grid1, sources, selectedRow.meter_date);
  };

  const exportTemplet = (fileName: string = 'meter') => {
    const out = XLSX.utils.book_new();
    const sheet: any[] = [];
    sheet[0] = [];
    sheet[1] = [];
    sheet[1][0] = '숙소유형';
    sheet[1][1] = '건물명';
    sheet[1][2] = '동';
    sheet[1][3] = '호';
    sheet[1][4] = '검침년월';
    sheet[1][5] = '전기';
    sheet[1][6] = '수도';
    sheet[1][7] = '가스';
    //sheet[1][8] = '온수';
    //sheet[1][9] = '난방';
    const ws = XLSX.utils.aoa_to_sheet(sheet);
    XLSX.utils.book_append_sheet(out, ws, fileName);
    XLSX.writeFile(out, `${fileName}.xlsx`);
  };

  const onClear = () => {
    (fileRef.current as any).value = null;
    setSources([]);
    store.xlsDwonFlag = false;
    store.xlsParamDate = '';
  }

  // 다운로드 
  const xlsDownload = async()=>{
    try {
        if (store.xlsDwonFlag) {
          headerStore.isLoading = true;

          const payload: any = {
            employee_number : userInfo.employeeNumber,
            meter_date: store.xlsParamDate,
            reason : downloadText,
          };
  
          switch(Number(headerStore.workSite)) {
            case WORK_SITE_CODE.울산공장:
              payload.worksite = '울산';
              break;
            case WORK_SITE_CODE.남양연구소:
              payload.worksite = '남양';
              break;
            case WORK_SITE_CODE.전주공장:
              payload.worksite = '전주';
              break;
            case WORK_SITE_CODE.아산공장:
              payload.worksite = '아산';
              break;
          }
      
          let { data } = await Core.xlsDeductMeter(payload);
          if(data) {
              FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
          }
          setIsShowDownloadPop(false);
          setDownloadText('');

        } else {
          info('다운로드할 검침량 리스트를 선택해주세요.');
          setIsShowDownloadPop(false);
          setDownloadText('');
          headerStore.isLoading = false;
          return;
        }
        
        headerStore.isLoading = false;
    }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
    }
  }

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <DeductTab {...props} />

      <div className="tab_contents on">
        <h4>관리 입력</h4>
        <div className="table_modify_list">
          <dl className="autowidth">
            <dt>검침량</dt>
            <dd>
              <YearSelect year={store.year} onChange={(e: any) => store.year = e.target.value} />
              <MonthSelect month={store.month} onChange={(e: any) => store.month = e.target.value} />
            </dd>
          </dl>
        </div>
          <div className="upload_area">
            {headerStore.writeRoleFlag && userInfo.classification !== null ? 
            <>
              <label htmlFor="input_copy">파일 업로드</label>
              <div className="wrap_file">
                <input id="input_copy" className="upload_name form_control" />
                <input ref={fileRef} type="file" id="input_upload_copy" className="upload_hidden"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                  onChange={onFileChange}/>
                <label htmlFor="input_upload_copy" className="btn btn_gray">파일찾기</label>
              </div>
              </>
              :<></>
            }
            {headerStore.downRoleFlag && userInfo.classification !== null ? 
              <Button 
                  src={process.env.REACT_APP_MEDIA + "static/meter_sample.xlsx"}
                  value={"양식다운로드"}
                  onlySrc
              />
              
              : <></>
            }
            <div className="right_area">
              <div>
                {headerStore.downRoleFlag  ? // && store.item && store.item.is_finalize === 'N'
                  <>
                    <button type="button" className="btn btn_md btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button>
                  </>
                  :
                  <>
                    <></>
                  </>
                }
              </div>
            </div>
          </div>
          

          <div className="table_normal_list">
            <div className="ag-theme-alpine" style={{height: '500px'}}>
              <AgGridReact
                modules={AllCommunityModules}
                defaultColDef={{
                  width: 100,
                  wrapText: true,
                  resizable: true,
                  cellStyle: { textAlign: 'center', fontSize: '13px' },
                  headerClass: 'text-center',
                  editable: false,
                }}
                suppressRowTransform={true}
                suppressRowClickSelection={true}
                onGridReady={onSourceGrid1Ready}
                multiSortKey={'ctrl'}
                rowSelection={'multiple'}
                rowData={sources}
              >
                <AgGridColumn headerName="숙소유형" field="roomtype" width={240} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
                <AgGridColumn headerName="건물명" field="building_name" width={240} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
                <AgGridColumn headerName="동" field="dong" width={200} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
                <AgGridColumn headerName="호" field="ho" width={200} unSortIcon={true} sortable={true}/>
                <AgGridColumn headerName="검침년월" field="meter_date" width={190} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
                <AgGridColumn headerName="전기" field="electric" width={170} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.electric)}unSortIcon={true}  sortable={true}/>
                <AgGridColumn headerName="수도" field="water" width={170} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.water)}unSortIcon={true} sortable={true}/>
                <AgGridColumn headerName="가스" field="gas" width={170} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.gas)}unSortIcon={true} sortable={true}/>
                {/*AgGridColumn headerName="온수" field="hotwater" width={100} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.hotwater)} />*/}
                {/*<AgGridColumn headerName="난방" field="heat" width={100} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.heat)} />*/}
              </AgGridReact>
            </div>
          </div>

          <div className="submit ac">
            {userInfo.classification !== null ? 
              <button type="button" className="btn btn_lg btn_darkblue btn_outline" onClick={onClear}>초기화</button>
              : <></>
            }
            {headerStore.writeRoleFlag && userInfo.classification !== null ? 
              <button type="button" className="btn btn_lg btn_darkblue" onClick={onCreate}>저장</button>
              :<></>
            }
          </div>

          <h4>검침량 리스트</h4>
          <div className="table_normal_list">
            <div className="ag-theme-alpine" style={{height: '400px'}}>
              <AgGridReact
                modules={AllCommunityModules}
                defaultColDef={{
                  flex: 1,
                  width: 100,
                  wrapText: true,
                  resizable: true,
                  cellStyle: { textAlign: 'center', fontSize: '13px' },
                  headerClass: 'text-center',
                  editable: false,
                }}
                frameworkComponents={frameworkComponents}
                onGridReady={onSourceGrid2Ready}
                rowSelection={'single'}
                rowDeselection={true}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                rowData={store.items}
              >
                <AgGridColumn headerName="NO" field="index" width={60} flex={.5} unSortIcon={true} sortable={true} />
                <AgGridColumn headerName="관리제목" field="meter_date" width={300} flex={4} cellStyle={{ justifyContent: 'flex-start' }} unSortIcon={true} sortable={true} />
                <AgGridColumn headerName="작성자" field="employee_number" width={120} flex={1} unSortIcon={true} sortable={true} />
                <AgGridColumn headerName="작성일" field="created_at" flex={1} cellRenderer="cellCreatedAtRenderer" unSortIcon={true} sortable={true} />
                <AgGridColumn headerName="삭제" field="" width={100} flex={1} cellRenderer= {headerStore.deleteRoleFlag && userInfo.classification !== null ? "cellDeleteRenderer" : ' '}/>
              </AgGridReact>
            </div>
          </div>
      </div> {/* tab_content */}
      <div className="tab_contents">당월공제자관리</div>
      <div className="tab_contents">공제이력</div>

      
      {/* 다운로드 사유 팝업 */}
      <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
              <dt>사유</dt>
              <dd>
                  <textarea className="form_control"
                  value={downloadText}
                  onChange={(e: any) => setDownloadText(e.target.value)}
                  >
                  </textarea>
              </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
          </div>
        </div>
      </div>
    </DefaultTemplate>
  );
});
export default DeductMeterPage;
