import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Plege {
  static URL = `${IP}`;

  // 입숙 서약서 조회
  @apiHandler()
  static async getPledges(params: {work_site: any, room_type: any}) {
    const PATH = `/admin/worksite/lodge/pledge1/`;

    return await axios.get(`${Plege.URL}${PATH}`, {
      params,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 입숙 서약서 내역조회
  @apiHandler()
  static async getHistoryPledges(payload: any) {
    const PATH = `/admin/worksite/lodge/pledge1/`;

    return await axios.get(`${Plege.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 입숙서약서 등록
  @apiHandler()
  static async createPledge(payload: any) {
    const PATH = `/admin/worksite/lodge/pledge1/`;

    return await axios.post(`${Plege.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 수정
  @apiHandler()
  static async patchPledge(id: number, payload: any) {
    const PATH = `/admin/worksite/lodge/pledge1/${id}/`;

    return await axios.put(`${Plege.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Plege;
