import React, {useState} from 'react';

interface Props {
  onChange: Function
  onEnter?: Function
  id?: string
  className?: string
  placeholder: string
  value: string
}


//비밀번호 input
const Password = (props: Props) => {
  const [isCapsLock, setIsCapsLock] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<boolean>(false);
  const changedProps = {
    ...props,
    onChange: (e: any) => {
      if (e.target.value.length <= 20) {
        props.onChange(e);
      }
    },
    onKeyDown: (e: any) => {
      let isCapsLock = e.getModifierState('CapsLock')
      if (isCapsLock) {
        setIsCapsLock(true);
        
        if (!timeoutId) {
          let timeoutId = setTimeout(() => {
            setIsCapsLock(false)
            clearTimeout(timeoutId)
            setTimeoutId(false)
          }, 1000)
        }

        setTimeoutId(true);
      } else {
        setIsCapsLock(false)
      }
      if (e.key === 'Enter' && props.onEnter) props.onEnter()
    }
  };

  return (
    <>
      <input type="password" {...changedProps} />
      <ul className="lst_warning">
        {isCapsLock? <li className="mt10">Capslock 키가 활성화 됐습니다</li>: ""}
      </ul>
      {/*<span style={{color: "red"}}>{isCapsLock? "캡슬락키가 활성화 됬습니다": ""}</span>*/}
    </>
  );
};

export default Password;
