import React, { useEffect } from 'react';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import { includes } from 'lodash';
import YearSelect from '../yearSelect';
import MonthSelect from '../monthSelect';
import NamyangDormi from './namyangDormi';
import { WORK_SITE_CODE } from '../../../constants';
import NamyangCompany from './namyangCompany';
import JeonjuDormi from './jeonjuDormi';
import JeonjuCompany from './jeonjuCompany';
import AsanDormi from './asanDormi';
import AsanCompany from './asanCompany';

const UlsanWithout = observer((props: any) => {
    const {ulsanWithoutStore: store} = useStores();
    const {headerStore : headerStore} = useStores();
    const baseUrl = '/guest/deductible';
    useEffect(() => {
      store.init();
    }, [headerStore.workSite]);

    // 울산공장: 1, 남양연구소: 2, 전주공장: 3, 아산공장: 4,
    if (!includes([2, 3, 4], Number(headerStore.workSite))) return <></>;

    return (
      <>
        <h4>당월공제자관리 설정</h4>
        <div className="table_modify_list">
          <dl>
            <dt>숙소유형</dt>
            <dd>
              <select name="" id="" className="form_control"
                value={store.roomType}
                onChange={e => {
                  store.init();
                  store.roomType = e.target.value;
                }}
              >
                <option value="기숙사">기숙사</option>
                <option value="사택">사택</option>
              </select>
            </dd>
          </dl>
          <dl className="autowidth">
            <dt>공제년월</dt>
            <dd>
              <YearSelect year={store.year} onChange={(e: any) => store.year = e.target.value} />
            </dd>
          </dl>
          <dl className="autowidth">
            <dt></dt>
            <dd>
              <MonthSelect month={store.month} onChange={(e: any) => store.month = e.target.value} />
            </dd>
          </dl>
        </div>
        {WORK_SITE_CODE.남양연구소 === Number(headerStore.workSite) && store.roomType === '기숙사' ? <NamyangDormi /> : <></>}
        {WORK_SITE_CODE.남양연구소 === Number(headerStore.workSite) && store.roomType === '사택' ? <NamyangCompany /> : <></>}

        {WORK_SITE_CODE.전주공장 === Number(headerStore.workSite) && store.roomType === '기숙사' ? <JeonjuDormi /> : <></>}
        {WORK_SITE_CODE.전주공장 === Number(headerStore.workSite) && store.roomType === '사택' ? <JeonjuCompany /> : <></>}

        {WORK_SITE_CODE.아산공장 === Number(headerStore.workSite) && store.roomType === '기숙사' ? <AsanDormi /> : <></>}
        {WORK_SITE_CODE.아산공장 === Number(headerStore.workSite) && store.roomType === '사택' ? <AsanCompany /> : <></>}
      </>
    );
  });
  
  export default UlsanWithout;