import React, { useContext, useEffect, useState } from 'react';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import DefaultTemplate from '../../../components/templetes/default';
import { MessageImpl, MessageContext } from '../../../routes';
import DeductTab from '../deductTab';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { onlyNumberFormatter } from '../../../utils/common';
import YearSelect from '../yearSelect';
import MonthSelect from '../monthSelect';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import * as apis from '../../../apis'
import Core from '../../../apis/core';
import FileSaver from 'file-saver';

const DeductDeductibleListPage = observer((props: any) => {
  const dispatch = useDispatch();
  const {deductDeductibleListPage: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/guest/deductible';
  const [grid, setGrid] = useState<any>(null);

  const { info }: MessageImpl = useContext(MessageContext);

  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>("");

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));

  //울산 공제내역 리스트
  useEffect(() => {
    headerStore.getAuthMenuRole('2-4');
    (async () => {
      headerStore.isLoading = true;
      try {

        const filter: any = {
          start_date: `${store.startYear}${store.startMonth}`,
          end_date: `${store.endYear}${store.endMonth}`,
        };
        const { data } = await apis.deduct.getDeductList(filter);
        store.items = data.map((item: any, index: number) => ({ index: index + 1, ...item }));
        headerStore.isLoading = false;
      }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("문제가 발생했습니다");
        return;
      }
    })();
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, [headerStore.workSite, store.startYear, store.startMonth, store.endYear, store.endMonth]);

  //검색
  const onSearch = async () => {
    headerStore.isLoading = true;
        try {
          //await store.getItems();

          const filter: any = {
            start_date: `${store.startYear}${store.startMonth}`,
            end_date: `${store.endYear}${store.endMonth}`,
          };
          const { data } = await apis.deduct.getDeductList(filter);
          store.items = data.map((item: any, index: number) => ({ index: index + 1, ...item }));
          headerStore.isLoading = false;
        }catch ({ status, data }) {
          headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          info("문제가 발생했습니다");
          return;
        }
  }   

  const onSourceGrid1Ready = (params: any) => {
    setGrid(params.api);
  };

  //결혼 여부
  const cellMarriedRenderer = (data: any) => {
    return data.value ? '기혼' : '미혼';
  };

  const cellOnlyNumberFormatter = (number: any) => {
    var number_string = number.toString();
    var number_parts = number_string.split('.');
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    
    if(number)  {
        if (number_parts.length > 1) { 
          return number_parts[0].replace( regexp, ',' ).replace(/[^0-9]*$/gi, '') + '.' + number_parts[1].replace(/[^0-9]*$/gi, ''); 
        } else { 
          return number_string.replace( regexp, ',' ).replace(/[^0-9]*$/gi, ''); 
        }  
    } else {
      return 0;
    }
  }

  const frameworkComponents = {
    cellMarriedRenderer,
    cellOnlyNumberFormatter,
  };

  // 다운로드 
  const xlsDownload = async()=>{
    try {
        headerStore.isLoading = true;
        const payload: any = {
          employee_number : userInfo.employeeNumber,
          worksite: '울산',
          start_date: `${store.startYear}${store.startMonth}`,
          end_date: `${store.endYear}${store.endMonth}`,
          reason : downloadText,
        };
    
        let { data } = await Core.xlsDeductibleList(payload);
        if(data) {
            FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
        }
        setIsShowDownloadPop(false);
        setDownloadText('');
        headerStore.isLoading = false;
    }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
    }
  }

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <DeductTab {...props} />

      <div className="tab_contents">검침량 설정</div>
      <div className="tab_contents">당월공제자관리</div>
      <div className="tab_contents">공제이력 리스트</div>
      <div className="tab_contents">출장자숙소 공제 검색</div>
      <div className="tab_contents on">
        <h4>공제내역 조회</h4>
        <div className="table_modify_list">
          <dl className="autowidth">
            <dt>시작 년/월</dt>
            <dd>
              <YearSelect year={store.startYear} onChange={(e: any) => store.startYear = e.target.value} />
              <MonthSelect month={store.startMonth} onChange={(e: any) => store.startMonth = e.target.value} />
            </dd>
          </dl>
          <dl className="autowidth">
          <dt>종료 년/월</dt>
            <dd>
              <YearSelect year={store.endYear} onChange={(e: any) => store.endYear = e.target.value} />
              <MonthSelect month={store.endMonth} onChange={(e: any) => store.endMonth = e.target.value} />
            </dd>
          </dl>
          <dl className="autowidth">
            <dt></dt>
            <dd><button className="btn btn_sm btn_gray ml10" onClick={onSearch}>검색</button></dd>
          </dl>
        </div>

        <h4>공제내역 리스트
          <div className="right_area">
            <div>
              {headerStore.downRoleFlag  ? // && store.item && store.item.is_finalize === 'N'
                <>
                  <button type="button" className="btn btn_md btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button>
                </>
                :
                <>
                  <></>
                </>
              }
            </div>
          </div>
        </h4>

        <div className="table_normal_list">
          <div className="ag-theme-alpine" style={{height: '500px'}}>
            <AgGridReact
              modules={AllCommunityModules}
              defaultColDef={{
                width: 100,
                wrapText: true,
                resizable: true,
                headerClass: 'text-center',
                editable: false,
                filter: true,
                floatingFilter: true,
              }}
              
              suppressRowTransform={true}
              suppressRowClickSelection={true}
              onGridReady={onSourceGrid1Ready}
              frameworkComponents={frameworkComponents}
              multiSortKey={'ctrl'}
              rowSelection={'multiple'}
              rowData={store.items}
            >
              {/* 검침량 및 공제관리 데이터 */}
              <AgGridColumn headerName="검침(공제)년월" field="meter_date" width={150} filter="agTextColumnFilter"/>
              <AgGridColumn headerName="부서명" field="guest_department" width={180} filter="agTextColumnFilter" />
              <AgGridColumn headerName="성명" field="guest_name" width={120} filter="agTextColumnFilter" />
              <AgGridColumn headerName="사번" field="guest_employee_number" width={120} filter="agTextColumnFilter" />
              <AgGridColumn headerName="건물명" field="building_name" width={100} filter="agTextColumnFilter" />
              <AgGridColumn headerName="동" field="dong" width={100} filter="agTextColumnFilter"/>
              <AgGridColumn headerName="호" field="ho" width={100} filter="agTextColumnFilter"/>
              <AgGridColumn headerName="공제일수" field="deduct_days" width={100} filter="agTextColumnFilter"/>
              <AgGridColumn headerName="결혼유무" field="is_married" width={100} filter="agTextColumnFilter" cellRenderer="cellMarriedRenderer" />
              <AgGridColumn headerName="이용료" field="room_charge" width={110} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.room_charge)}/>
              <AgGridColumn headerName="유틸리티" field="util_charge2" width={110} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.util_charge2)}/>
              <AgGridColumn headerName="침구대" field="bedding_charge" width={110} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.bedding_charge)}/>
              <AgGridColumn headerName="기타" field="etc_charge" width={150} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.etc_charge)}/>
              <AgGridColumn headerName="공제총액" field="total_deduct" width={110} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.total_deduct)}/>
              <AgGridColumn headerName="전기" field="electric" width={110} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.electric)}/>
              <AgGridColumn headerName="수도" field="water" width={110} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.water)}/>
              <AgGridColumn headerName="가스" field="gas" width={110} filter="agTextColumnFilter" valueFormatter={params => onlyNumberFormatter(params.data.gas)}/>
            </AgGridReact>
          </div>
        </div>
      </div>
      {/* 다운로드 사유 팝업 */}
      <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
                <dl>
                <dt>사유</dt>
                <dd>
                    <textarea className="form_control"
                    value={downloadText}
                    onChange={(e: any) => setDownloadText(e.target.value)}
                    >
                    </textarea>
                </dd>
                </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
             <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
          </div>
        </div>
      </div>

      
    </DefaultTemplate>
  );
});
export default DeductDeductibleListPage;
