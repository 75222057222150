import React from 'react';
import { useDispatch } from 'react-redux';

import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

import Cell from './cell';
import Row from './row';
import Task from './task';
import Empty from './empty';

import {
  getCountDaysByMonth,
  getFirstDayByMonth,
  format_YYYYMMDD,
  leftBig,
} from '../../../utils/date';

const Body = (props: any) => {
  const dispatch = useDispatch();
  const daysCnt: number = getCountDaysByMonth(props.focusDate[0]);
  const firstDay: number = getFirstDayByMonth(props.focusDate[0]);
  const days = Array.from(Array(daysCnt + firstDay).keys());

  const onClickHandler = (idx: number, day: number) => {
    if (_isDisable(day)) {
      msg();
      return;
    }
    props.onClickSelectDay(idx - firstDay + 1);
  };

  const onStartDateCell = (idx: number, day: number) => {
    if (_isDisable(day)) {
      msg();
      return;
    }
    props.onStartDateCell(idx - firstDay + 1);
  };

  const onEndDateCell = (idx: number, day: number) => {
    if (_isDisable(day)) {
      msg();
      return;
    }
    props.onEndDateCell(idx - firstDay + 1);
  };

  const isSelect = (idx: number) => {
    // console.log(props.rangeSelect, props.focusDate, idx, firstDay, idx - firstDay + 1)
    // console.log(props.focusDate[0], idx - firstDay + 1, parseInt(props.focusDate[0].split('-')[2]) === idx - firstDay + 1)
    // console.log(props.selectDate.split('-')[2], idx - firstDay + 1, parseInt(props.focusDate[0].split('-')[2]) === idx - firstDay + 1)
    const splited = props.selectDate.split('-');
    splited[2] =
      idx - firstDay + 1 < 10 ? `0${idx - firstDay + 1}` : idx - firstDay + 1;

    const focusMonth = props.focusDate[0].split('-').slice(0, 2).join('-');
    const selectMonth = props.selectDate.split('-').slice(0, 2).join('-');
    // console.log(props.selectDate, props.focusDate)
    // console.log(props.focusDate[0], focusMonth)
    // console.log(props.selectDate, selectMonth)
    return (
      focusMonth === selectMonth &&
      parseInt(props.selectDate.split('-')[2]) === idx - firstDay + 1
    );
    // if (
    //   props.rangeSelect[0] <= idx - firstDay + 1 &&
    //   props.rangeSelect[1] >= idx - firstDay + 1
    // ) {
    //   return true;
    // }

    // return false;
  };

  const msg = () =>
    dispatch({
      type: SHOW_ALERT_MODAL,
      payload: {
        title: '날짜선택 경고',
        msg: '해당 날짜는 선택할 수 없습니다.',
        redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'warning',
        isModalShow: true,
      },
    });

  const _converDate = (_day: number) => {
    const [year, month] = props.focusDate[0].split('-').slice(0, 2);
    return format_YYYYMMDD(year, month, _day);
  };

  const _isDisable = (_day: any) => {
    let d = _converDate(_day);

    let isBefore = props.beforeDisablePoint ? leftBig(props.beforeDisablePoint, d ) : false;
    if (isBefore) return true;
    
    let isAfter = props.afterDisablePoint ? leftBig(d, props.afterDisablePoint) : false;
    if (isAfter) return true;

    let isDisableDates = props.disableDates.includes(d);
    return isDisableDates;
  };

  return (
    <Row>
      {days.map((item: any, idx: number) => (
        <>
          {firstDay <= idx ? (
            <Cell
              key={idx}
              idx={idx}
              sequence={idx}
              hover={true}
              disable={_isDisable(idx - firstDay + 1)}
              isSelect={isSelect(idx)}
              onClick={() => onClickHandler(idx, idx - firstDay + 1)}
              onStartDateCell={() => onStartDateCell(idx, idx - firstDay + 1)}
              onEndDateCell={() => onEndDateCell(idx, idx - firstDay + 1)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{idx - firstDay + 1} </div>
              <Task tasks={props.tasks[idx - firstDay + 1]} />
            </Cell>
          ) : (
            <Empty idx={idx} key={idx}></Empty>
          )}
        </>
      ))}
    </Row>
  );
};

export default Body;
