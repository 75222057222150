import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useStores } from '../../../store/mobx';
import MobileHeader from '../header';
import '../mb_common.scss';

const MobileCheckLnb = observer((props: any) => {
    const { mobileTotalStore : store } = useStores();
    const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
    const [showCheckOut, setShowCheckOut] = useState<boolean>(false);
    const [showVoc, setShowVoc] = useState<boolean>(false);
    const {headerStore: headerStore} = useStores();

    useEffect(() => {
        const authMenu = window.localStorage.getItem('auth_menu')?.replace(/\"/gi,"");
        let len: any[] = [authMenu?.split(",")];

        if(len[0]){
            len[0].map((menu: any, idx: number) => {
                if(menu.indexOf('1-2') != (-1) && menu.indexOf('F') != (-1)){
                    setShowCheckIn(true);
                }else if(menu.indexOf('2-3') != (-1) && menu.indexOf('F') != (-1)){
                    setShowCheckOut(true);
                }else if(menu.indexOf('3-2') != (-1)){
                    setShowVoc(true);
                }
            })
        }
    }, []);

    return (
        <>
            <div id="wrap" className={headerStore.isLoading ? "wrap_mobile loading" : "wrap_mobile wrap_mobile_main"}>
                <MobileHeader {...props} history={props.history} />
                <div id="container">
                    <div className="group_tab">
                        <ul className="lst_tab clearfix">
                            <li style={{display: showCheckOut? '' : 'none'}}>
                                <Link
                                    className={props.history.location.pathname.includes("/mobile/guest/checkTotal") ? "on" : ""}
                                    onClick={() => {
                                        if(!props.history.location.pathname.includes("/mobile/guest/checkTotal")) {store.init();}
                                    }}
                                    to={"/mobile/guest/checkTotal"}
                                >
                                    입숙현황
                                </Link>
                            </li>
                            <li style={{display: showCheckIn? '' : 'none'}}>
                                <Link
                                    className={props.history.location.pathname.includes("/mobile/guest/checkin") ? "on" : ""}
                                    onClick={() => {
                                        if(!props.history.location.pathname.includes("/mobile/guest/checkin")) {store.init();}
                                    }}
                                    to={"/mobile/guest/checkin"}
                                >
                                    입숙처리
                                </Link>
                            </li>
                            <li style={{display: showCheckOut? '' : 'none'}}>
                                <Link
                                    className={props.history.location.pathname.includes("/mobile/guest/checkchange") ? "on" : ""}
                                    onClick={() => {
                                        if(!props.history.location.pathname.includes("/mobile/guest/checkchange")) {store.init();}
                                    }}
                                    to={"/mobile/guest/checkchange"}
                                >
                                    이숙처리
                                </Link>
                            </li>
                            <li style={{display: showCheckOut? '' : 'none'}}>
                                <Link
                                    className={props.history.location.pathname.includes("/mobile/guest/checkout") ? "on" : ""}
                                    onClick={() => {
                                        if(!props.history.location.pathname.includes("/mobile/guest/checkout")) {store.init();}
                                    }}
                                    to={"/mobile/guest/checkout"}
                                >
                                    퇴숙처리
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {props.children}
                </div>
            </div>
        </>
    );
});

export default MobileCheckLnb;
