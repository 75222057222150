import { combineReducers } from 'redux';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import createSagaMiddleware from 'redux-saga';

import auth from './modules/auth';
import modal from './modules/modal';
import user from './modules/user';
import base from './modules/base';

import rootSaga from './saga';
import * as root from './mobx';

const rootReducer = combineReducers({
  auth,
  modal,
  user,
  base
});

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

// 루트 리듀서를 내보내주세요.
export default configureStore();

export {
  root
};