import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { lodge } from '../../../apis';
import { ROOM_TYPE, ROOM_TYPE_CODE, WORK_SITE } from '../../../constants';
import { useStores } from '../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { showAlert } from '../../../utils/common';
import { getDays, getMonth, getMonths, getYear5, getYear, getDate } from '../../../utils/date';
import MngFileUpload from '../form/managementFile';

export interface Props {
  // type: string

  // checkAt: string
  // checkKeyAt: string

  // setCheckAt: Function
  // setCheckKeyAt: Function

  isShowModal: boolean;
  onCloseModalHandler: Function;

  onCompleteHander: Function;
  // bookingData: any
  // setCheckInData: Function
}

//입숙완료처리 팝업
const CheckPopup = observer((props: Props) => {
  const dispatch = useDispatch();
  const { applicantJudgeStore: store } = useStores();
  const { headerStore: headerStore } = useStores();
  const [managedYear, setManagedYear] = useState<string>(getYear());
  const [managedMonth, setManagedMonth] = useState<string>(getMonth());
  const [managedDay, setManagedDay] = useState<string>(getDate());
  const [keyoutAtYear, setKeyoutAtYear] = useState<string>(getYear());
  const [keyoutAtMonth, setKeyoutAtMonth] = useState<string>(getMonth());
  const [keyoutAtDay, setKeyoutAtDay] = useState<string>(getDate());
  const [bedding, setBedding] = useState<number>(0);
  const [memo, setMemo] = useState<string>('');
  const [files, setFiles] = useState<any>([]);

  const saveHandler = async (e: any) => {
    let fileIds: any = files.map((obj: any) => {
      return obj.id;
    });

    let payload = {
      managed_at: managedYear + '-' + managedMonth + '-' + managedDay,
      keyout_at: keyoutAtYear + '-' + keyoutAtMonth + '-' + keyoutAtDay,
      meal_ticket: store.selectRow.meal_ticket,
      bedding: bedding,
      memo: memo,
      files: fileIds,
    };

    try {
      headerStore.isLoading = true;
      let { data } = await lodge.checkin(store.selectRow.id, payload);

      // 입숙 완료 문자 발송
      if (
        store.selectRow.room_type !== ROOM_TYPE_CODE.family_room &&
        store.selectRow.room_type !== ROOM_TYPE_CODE.business_room_group
      ) {
        let smsPayload: any = {
          cellphone: store.selectRow.guest_cellphone,
          message:
            WORK_SITE[store.selectRow.room_work_site] +
            ' ' +
            store.selectRow.room_building_name +
            ' ' +
            ROOM_TYPE[store.selectRow.room_type] +
            ' 입숙을 축하드립니다! \n입숙일 : ' +
            payload.managed_at +
            //+ '\n입숙만료예정일 : 2027.11.20'
            '\n호  실 : ' +
            store.selectRow.room_building_name +
            ' ' +
            store.selectRow.room_building_dong +
            ' ' +
            store.selectRow.room_name +
            '\n입숙서약서, 이용약관, 개인정보이용동의 등에 동의하셨으며, 각종 이용안내는 AMS 사이트 참조부탁드립니다. \n운영정보는 FAQ 및 공지사항 확인바라며, 현재 상태정보는 마이페이지 메뉴 확인 바랍니다.',
        };

        if (store.selectRow.room_type !== ROOM_TYPE_CODE.vip_room) {
          const { data: sms } = await lodge.smsSendAPI(smsPayload);
        }
      } else {
        // 단체나 가족숙소인 경우
        let guestsCellPhone = store.selectRow.guestList.map((item: any) => {
          return item.guest_cellphone;
        });

        let smsPayload: any = {
          cellphone: guestsCellPhone.join(','),
          message:
            WORK_SITE[store.selectRow.room_work_site] +
            ' ' +
            store.selectRow.room_building_name +
            ' ' +
            ROOM_TYPE[store.selectRow.room_type] +
            ' 입숙을 축하드립니다! \n입숙일 : ' +
            payload.managed_at +
            //+ '\n입숙만료예정일 : 2027.11.20'
            '\n호  실 : ' +
            store.selectRow.room_building_name +
            ' ' +
            store.selectRow.room_building_dong +
            ' ' +
            store.selectRow.room_name +
            '\n입숙서약서, 이용약관, 개인정보이용동의 등에 동의하셨으며, 각종 이용안내는 AMS 사이트 참조부탁드립니다. \n운영정보는 FAQ 및 공지사항 확인바라며, 현재 상태정보는 마이페이지 메뉴 확인 바랍니다.',
        };
        const { data: sms } = await lodge.smsSendAPI(smsPayload);
      }

      headerStore.isLoading = false;
      showAlert('입숙처리가 완료되었습니다', dispatch);
      popInit();
      props.onCloseModalHandler();
    } catch (e) {
      headerStore.isLoading = false;
      let { status, data } = e;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      } else if (data.invalid_status && data.invalid_status.length) {
        showAlert(data.invalid_status[0], dispatch);
        return;
      }

      showAlert('입숙승인 실패', dispatch);
      return;
    }

    props.onCompleteHander();
  };

  const popInit = () => {
    setManagedYear(getYear());
    setManagedMonth(getMonth());
    setManagedDay(getDate());
    setKeyoutAtYear(getYear());
    setKeyoutAtMonth(getMonth());
    setKeyoutAtDay(getDate());
    store.selectRow.meal_ticket = 0;
    setBedding(0);
    setMemo('');
    setFiles([]);
  };

  return (
    <>
      <div
        id="pop_change_request"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: props.isShowModal ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>입숙완료처리</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => {
                popInit();
                props.onCloseModalHandler();
              }}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
                <dt>입숙일</dt>
                <dd>
                  <select
                    className="form_control year"
                    value={managedYear}
                    onChange={e => setManagedYear(e.target.value)}
                  >
                    {getYear5(true).map((year: any, index: number) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form_control month"
                    value={managedMonth}
                    onChange={e => setManagedMonth(e.target.value)}
                  >
                    {getMonths(managedYear, true).map((year: any, index: number) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <select className="form_control day" value={managedDay} onChange={e => setManagedDay(e.target.value)}>
                    {getDays(managedYear, managedMonth, false).map((year: any, index: number) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </dd>

                <dt>키 불출일</dt>
                <dd>
                  <select
                    className="form_control year"
                    value={keyoutAtYear}
                    onChange={e => setKeyoutAtYear(e.target.value)}
                  >
                    {getYear5(true).map((year: any, index: number) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form_control month"
                    value={keyoutAtMonth}
                    onChange={e => setKeyoutAtMonth(e.target.value)}
                  >
                    {getMonths(keyoutAtYear, true).map((year: any, index: number) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form_control day"
                    value={keyoutAtDay}
                    onChange={e => setKeyoutAtDay(e.target.value)}
                  >
                    {getDays(keyoutAtYear, keyoutAtMonth, false).map((year: any, index: number) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </dd>
                {store.selectRow.room_type === ROOM_TYPE_CODE.business_room ||
                store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group ? (
                  <>
                    <dt>식권수량</dt>
                    <dd>
                      <input
                        className="form_control"
                        value={store.selectRow.meal_ticket}
                        onChange={(e: any) => {
                          store.selectRow.meal_ticket = parseInt(e.target.value) || 0;
                        }}
                      />
                    </dd>
                  </>
                ) : (
                  ''
                )}
                <dt>침구수령여부</dt>
                <dd>
                  <input
                    className="form_control"
                    value={bedding}
                    onChange={(e: any) => {
                      setBedding(parseInt(e.target.value) || 0);
                    }}
                  />
                </dd>
              </dl>
            </div>
            <div className="table_normal_list mt20">
              <table>
                <caption className="sr_only">이용자 입숙서류 스캔본 업로드</caption>
                <colgroup>
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="ac">이용자 입숙서류 스캔본 업로드</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="al">
                      <MngFileUpload
                        type="file"
                        fileId="attacheFile"
                        items={files}
                        onSetData={(data: any[]) => setFiles([...data])}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>{' '}
            {/*table*/}
            <div className="process_list mt20">
              <dl>
                <dt>비고입력</dt>
                <dd>
                  <textarea
                    className="form_control"
                    value={memo}
                    maxLength={400}
                    onChange={e => setMemo(e.target.value)}
                  ></textarea>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={saveHandler}>
              완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default CheckPopup;
