import React, { useState, useEffect, useContext } from 'react';
import Pagination from '../../organismas/pagination';
import { formatByString } from '../../../utils/date';
import { ROOM_TYPE, ROOM_TYPE_CODE, VOC_CATEGORY, VOC_STATUS_TYPES, WORK_SITE } from '../../../constants';
import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import _, { isNull } from 'lodash';

const VocBuilding = observer((props: any) => {
  const {vocStore} = useStores();
  const {buildingStore: store} = vocStore;
  const {headerStore: headerStore} = useStores();
  const [reload, setReload] = useState<boolean>(false);
  const baseUrl = '/lodged/maintenance';

  useEffect(() => {
    init();
    headerStore.getAuthBuilding();
    headerStore.getAuthRoomRole();
    headerStore.getAuthBuilding();

    vocStore.work_site = headerStore.workSite;
    vocStore.roomtype = headerStore.paramAuthRoomTypeRole;
    store.work_site = headerStore.workSite;
    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
      vocStore.authBuilding = headerStore.paramAuthBuildingRole;
    }
    (async () => {
      // 동별 유지운영 내역은 방을 선택해야 조회
      //getRole();
    })();
    return () => {
      if (!store.findTabs(`${baseUrl}`)) {
        store.destory();
      }
    }
  }, [reload, headerStore.workSite, store.selectedRoom]);

  const init = ()=> {
    store.item = [];
    store.items = [];
    store.buildings = [];
    store.floors = [];
    store.room_type = -1;
    store.building = -1;
    store.room = null;
    store.limit=10;
    store.offset = 0;
    store.totalPage = 0;
  }
  const getRole = async () => {
    headerStore.isLoading = true;
    await store.getItems();
    setReload(true);
    headerStore.isLoading = false;
  }
  const onSearch = store.getItems;

  const onAdd = () => {
    vocStore.newItem();
    props.history.replace(`${baseUrl}/create`);
  };

  const onGoPage = (item: any) => {
    vocStore.selectedItem(item);
    props.history.replace(`${baseUrl}/${item.id}`)
  };

  return (
    <>
    <div className={['tab_contents', vocStore.tabIndex === 1 ? 'on' : ''].join(' ')}>
      <h4>동별 유지운영내역
        <div className="right_area">
          <select name="" id="" 
            className="form_control"
            value={store.room_type}
            onChange={(e) => store.setRoomType(Number(e.target.value))}>
            {/* {store.room_types.filter(item => item.value !== 0).map((item, index) => (
              <option key={index} value={item.value}>{item.label}</option>
            ))} */}
            <option value={-1}>선택</option>
            {Number(headerStore.roomRoleBusiness_room) ===  Number(ROOM_TYPE_CODE.business_room) ? 
              <option value={ROOM_TYPE_CODE.business_room}>{ROOM_TYPE[ROOM_TYPE_CODE.business_room]}</option>
            :
            <></>
            }
            {Number(headerStore.roomRoleBusiness_room_group) === Number(ROOM_TYPE_CODE.business_room_group) ? 
              <option value={ROOM_TYPE_CODE.business_room_group}>{ROOM_TYPE[ROOM_TYPE_CODE.business_room_group]}</option>
              :
              <></>
            }
            {Number(headerStore.roomRoleVip_room) === Number(ROOM_TYPE_CODE.vip_room) ? 
              <option value={ROOM_TYPE_CODE.vip_room}>{ROOM_TYPE[ROOM_TYPE_CODE.vip_room]}</option>
              :
              <></>
            }
            {Number(headerStore.roomRoleDormitory_room) === Number(ROOM_TYPE_CODE.dormitory_room) ? 
              <option value={ROOM_TYPE_CODE.dormitory_room}>{ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}</option>
              :
              <></>
            }
            {Number(headerStore.roomRoleCompany_room) === Number(ROOM_TYPE_CODE.company_room) ? 
              <option value={ROOM_TYPE_CODE.company_room}>{ROOM_TYPE[ROOM_TYPE_CODE.company_room]}</option>
              :
              <></>
            }
            {Number(headerStore.roomRoleFamily_room) === Number(ROOM_TYPE_CODE.family_room) ? 
              <option value={ROOM_TYPE_CODE.family_room}>{ROOM_TYPE[ROOM_TYPE_CODE.family_room]}</option>
              :
              <></>
            }
          </select>
          {!_.includes([-1, 7], store.room_type) ? (
            <select name="" id=""
              className="form_control"
              value={store.building}
              onChange={(e) => store.setBuinding(Number(e.target.value))}
            >
              {store.buildings.map((item: any, index: number) => (
                <option key={item.id} value={item.id}>{item.name} : {item.dong}</option>
              ))}
            </select>
          ) : <></>}
        </div>
      </h4>

      <dl className="group_history_dong mt20">
        {store.room_type !== 7 ? (
          <>
            <dt>{store.buildingName}</dt>
            {!store.floors || store.floors.length <= 0 ? (
              <dd className="blank_area type_white">
                <p className="txt_blank">숙소정보가 없습니다.</p>
              </dd>
            ) : (
              <dd>
                {store.floors.map((floor: any, index: number) => (
                  <ul key={index} className="lst_dong">
                    {floor.rooms.map((room: any) => (
                    <li
                      key={room.id}>
                      <a
                        href="javascript:void(0);"
                        className={[room.voc ? 'on' : '', (store.room && room.id === store.room.id) ? 'active' : ''].join(' ')}
                        onClick={() => store.selectedRoom(room)}
                      >
                        {room.name}
                      </a>
                    </li>
                    ))}
                  </ul>
                ))}
              </dd>
            )}
          </>
        ) : <></>}
      </dl>

      <h4>{store.buildingAndRoomName} 유지운영 내역</h4>

      <div className="table_normal_list event_on box_scroll_x">
        <table>
            <caption className="sr_only">숙소유형별 이용자범위 설정 등록현황</caption>
            <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="20%"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
            </colgroup>
          <thead>
            <tr>
                <th>사업장</th>
                <th>진행상태</th>
                <th>구분</th>
                <th>1차분류</th>
                <th>2차분류</th>
                <th>3차분류</th>
                <th>4차분류</th>
                <th>발생장소</th>
                <th>내용</th>
                <th>완료일</th>
                <th>작성자</th>
                <th>작성일</th>
            </tr>
          </thead>
          <tbody>
            {!store.items.length ? (
              <tr>
                <td colSpan={12} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.items.map((item: any, index: number) => {
                let placeName = '';
                if (item.places.length > 0) {
                  const place = item.places[0];
                  if (item.places.length === 1) {
                    placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}`;
                  } else {
                    placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}외 ${item.places.length - 1}곳`;
                  }
                }
                return (
                <tr key={index} onClick={() => onGoPage(item)} style={{ cursor: 'pointer' }}>
                  <td>{WORK_SITE[item.work_site]}</td>
                  <td>
                    <span className={['state_type', item.is_complete ? 'type3' : ''].join(' ')}>
                      {item.is_complete ? '완료' : '처리중'}
                    </span>
                  </td>
                  <td>{item.is_maintenance ? '유지보수' : 'Voc'}</td>
                  <td>{item.category1}</td>
                  <td>{item.category2}</td>
                  <td>{item.category3}</td>
                  <td>{item.category4}</td>
                  <td>{placeName}</td>
                  <td
                    onClick={() => onGoPage(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.title}
                  </td>
                  <td>{formatByString(item.completed_at, 'yyyy.MM.dd HH:mm')}</td>
                  <td>
                    {item.author_name}
                  </td>
                  <td>{formatByString(item.created_at, 'yyyy.MM.dd')}</td>
                </tr>
              )})
            )}
          </tbody>
        </table>
      </div>  {/*table*/}

      <div className="board_paging">
        <ul>
          <Pagination
            offset={store.offset}
            limit={store.limit}
            totalPage={store.totalPage}
            setOffset={(offset: number) => store.setOffset(offset)} />
        </ul>
      </div>

      <div className="submit al">
          {headerStore.writeRoleFlag ?
          <button className="btn btn_lg btn_darkblue" onClick={onAdd}>유지보수 등록</button>
        : <></>}
      </div>
    </div>
    </>
  );
});

export default VocBuilding;
