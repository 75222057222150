import React, { useState, useEffect, useRef, useContext } from 'react';
import { core } from '../../../apis';
import { observer } from 'mobx-react';
import { MessageContext, MessageImpl } from '../../../routes';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { useStores } from '../../../store/mobx';

const UploadFileList = observer((props: any) => {
  const store = props.store;
  const [documentFiles, setDocumentFiles] = useState<any[]>([]);
  const refs = useRef([]);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const {headerStore: headerStore} = useStores();

  const addToRefs = (el: any) => {
    if (el && !(refs as any).current.includes(el)) {
      (refs as any).current.push(el);
    }
  };

  useEffect(() => {
    const data = store.documents.map((document: any) => {
      return store.item.files.reduce((acc: any, curVal: any, idx: number) => {
        if (curVal.document?.id === document.id) {
          acc.document = document.id;
          acc.title = document.name;
          acc.name = curVal.file.name;
          acc.id = curVal.file.id;
          acc.is_required = document.is_required;
          acc.visible_type = document.visible_type;
        }
        return acc;
      }, {
        document: document.id,
        title: document.name,
        id: -1,
        is_required: document.is_required,
        name: '',
        visible_type: document.visible_type
      });
    }).filter(
      (file: { visible_type: number }) => file.visible_type === 1
    );
    setDocumentFiles(data);
    const item = {...store.item};
    item.files = data;
    store.item = item;
    // console.log(data, documentFiles);
  }, [store.documents]);

  const onUploadHandler = async (e: any, fileIdx: number) => {
    let tempFiles = [...documentFiles];
    let frm = new FormData();

    frm.append("path", e.target.files[0]);

    let extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];

    let fileData: any = e.target.files[0];
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (e.target.files[0].size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }

    try {
      headerStore.isLoading = true;
      let { data } = await core.fileUpload<any>(frm);
      // console.log(data);
      tempFiles[fileIdx].id = data.id;
      tempFiles[fileIdx].name = data.name;
      const item = {...store.item};
      const _files = tempFiles.filter((file: any) => file?.document);
      setDocumentFiles(_files);
      item.files = _files;
      store.item = item;
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("문제가 발생했습니다");
      return;
    }
  }

  if (documentFiles.length === 0) return <></>;

  return (
    <>
      <h4>첨부파일</h4>
      <div className="table_normal_list table_write">
        <table>
        <caption className="sr_only">첨부파일</caption>
        <colgroup>
          <col width="12.5%"/>
          <col width="*"/>
        </colgroup>
        <tbody>
          {documentFiles.map((file: any, fileIdx: number) => (
            <tr key={file.document}>
              <th>{file.title}{file.is_required ? ' (필수)' : ''}</th>
              <td>
                {file.id === -1 ? (
                  <div className="items_area"><span className="disabled">파일을 등록해주세요</span></div>
                ) : (
                  <div className="items_area">
                    <a href="javascript:void(0);" className="item file del">{file.name}</a>
                  </div>
                )}
                <input
                  type="file"
                  ref={addToRefs}
                  id={`input_upload_copy_${fileIdx}`}
                  className="upload_hidden"
                  style={{display: 'none'}}
                  onClick={(e: any) => e.target.value = null}
                  onChange={(e: any) => onUploadHandler(e, fileIdx)}
                />
                {/* <button className="btn_xs btn_darkblue btn_outline btn_file_srch"
                  onClick={() => (refs as any).current[fileIdx].click()}>파일찾기</button> */}
                <label className="btn_xs btn_darkblue btn_outline btn_file_srch" htmlFor={`input_upload_copy_${fileIdx}`} >파일찾기</label>
              </td>
            </tr>
          ))}
        </tbody>
        </table>
      </div>  {/*table*/}
    </>
  );
});

export default UploadFileList;