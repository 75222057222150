import {action, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { getIntervalMonthDate, getTodayDate } from '../../utils/date';
import { BoardStore } from './boardStore';
import * as XLSX from 'xlsx';
import { LODGE_STATUS_CODE, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../constants';
import { LodgedHistoryInsertStore } from './lodgedHIstoryInsertStore';
import { addMonths, format } from 'date-fns';
import { isNull } from 'lodash';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';
import { addSyntheticTrailingComment } from 'typescript';
import { ComponentSource } from '@ag-grid-community/core/dist/cjs/components/framework/userComponentFactory';

// **** 입숙, 이숙, 퇴숙 코드값 확인하기!!!
export class LodgedHistoryStore extends BoardStore {
  ordering: string = '';
  option: string = 'guest_name';
  text: string = '';
  checkin_after: string = getIntervalMonthDate(6)[1];
  checkin_before: string = getIntervalMonthDate(6)[0];
  userInfo: any = [];
  bookingData: any = [];
  guestData: any = [];
  historyList: any = [];
  groupList: any = [];
  //guest: number = 0;
  files: any = [];
  contents: string = '';
  author: string = '';
  mngCreateAt: string = getTodayDate()[0];
  mngGuestId: number = 0;
  mngId: number = 0;
  mngList: any = [];
  guestMngList: any = [];
  roomType: any;
  insertRoomType: number = 2;
  isMultiType: boolean = false;
  worksite : any;
  calcular : any = [];
  authBuilding : any;

  selectWorkSite: number = 0;
  selectRoomType: number = 0;
  selectBuilding: string = '';
  selectDong: string = '';
  selectFloor: string = '';
  selectRoom: string = '';
  buildingList: any = [];
  dongList: any = [];
  floorsAndRoomsList : any = [];
  floorsList: any = [];
  roomsList:any = [];

  //리스트 또는 단일 객체
  list : any = [];

  guestTypes: any = [];
  insertStore: LodgedHistoryInsertStore;

  //loading
  headerStore : HeaderStore;

  //공제내역
  isDeductionPop : boolean = false;
  year = '';
  month = '';
  deductData : any;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);
    this.insertStore = new LodgedHistoryInsertStore(this);
    makeObservable(this, {
        ordering: observable,
        option: observable,
        text: observable,
        checkin_after: observable,
        checkin_before: observable,
        userInfo: observable,
        bookingData: observable,
        guestData: observable,
        historyList: observable,
        groupList: observable,
        //guest: observable,
        files: observable,
        contents: observable,
        author: observable,
        mngCreateAt: observable,
        mngGuestId: observable,
        mngId: observable,
        mngList: observable,
        guestMngList: observable,
        roomType: observable,
        insertRoomType: observable,
        isMultiType:observable,
        worksite:observable,
        list:observable,
        guestTypes:observable,
        calcular : observable,
        authBuilding : observable,
        selectWorkSite : observable,
        selectRoomType : observable,
        selectBuilding : observable,
        selectDong : observable,
        selectFloor : observable,
        selectRoom : observable,
        buildingList : observable,
        dongList : observable,
        floorsAndRoomsList : observable,
        floorsList : observable,
        roomsList:observable,
        isDeductionPop : observable,
        year : observable,
        month : observable,
        deductData : observable,
        

        getItems: action,
        downloadUserList: action,
        getItem: action,
        initData: action,
        managementUpdateFrm: action,
        managementSave: action,
        managementDelete: action,
        lodgeManagementList: action,
        
        getRoomsInfo : action,
        
    });

    this.init();
    this.initDatas();
  }

  // 리스트 초기화
  init() {
      this.checkeds = {};
      this.allCheckFlg = false;
      this.roomType = '';
      this.insertRoomType = 2;
  }

  // 상세 초기화
  initData() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('user') || '');
    this.mngId = 0;
    this.author = this.userInfo.username;
    this.mngCreateAt = getTodayDate()[0];
    this.category = 1;
    this.contents = '';
    this.files = [];
  }

  setRoomType(roomType: any) {
    this.insertRoomType = roomType;
    this.insertStore.newItem(roomType);
  }

  // 건물명 select 가져오기
  async getBuildingList() {
    const payload: any = {
      room_type : this.selectRoomType,
      work_site : this.selectWorkSite
    };

    if(Number(this.worksite) === 2) {
      if(!isNull(this.authBuilding) && this.authBuilding !== undefined && this.authBuilding.length > 0 && this.authBuilding !== 'null') {
        payload.authBuilding = this.authBuilding;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBuildings(payload);
      this.buildingList = data;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 건물 관련 동 select 가져오기
  async getBuildingDongList() {
    const payload: any = {
      room_type : this.selectRoomType,
      work_site : this.selectWorkSite,
      name : this.selectBuilding
    };
    
    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBuildingDongs(payload);
      this.dongList = data;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 호수 정보
  async getRoomsInfo(){
    const payload: any = {
      work_site: this.worksite,
      room_type: this.selectRoomType,
      building: this.selectBuilding,
      dong : this.selectDong
    };
   
    try{

      this.headerStore.isLoading = true;

      let { data } = await apis.build.getRoomsList(payload);

      this.floorsAndRoomsList = data;

      let floorList = this.floorsAndRoomsList;
      let floorInfo : any = [];
      let lastFloor = '';
      for(let i = 0; i < floorList.length; i++) {
        if(i === 0)    {
          floorInfo.push(Number(floorList[i].floor_name));
          lastFloor = floorList[i].floor_name;
        }else {
          if(lastFloor !== floorList[i].floor_name) {
            floorInfo.push(Number(floorList[i].floor_name));
            lastFloor = floorList[i].floor_name;
          }
        }
      }

      this.floorsList = floorInfo.sort((a: any, b: any) => a > b ? 1 : a < b ? -1 : 0);

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
    //this.selectFloor = 0;
  }

  @debounce(500)
  async getItems() {
    //console.log('getItems: ', this.option, this.text, this.limit, this.offset, this.ordering);
    //console.log('this.worksite>>>>', this.worksite);
    //console.log('this.roomType>>>>', this.roomType);
    const filter: any = {
        limit: this.limit,
        offset: this.offset,
        status: [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(','),
        checkin_after: this.checkin_after,
        checkin_before: this.checkin_before,
        worksite : this.worksite,
        roomtype : this.roomType,
    };

    // 건물, 동으로 검색용
    if(this.selectRoomType != 0) {
      filter.roomtype = this.selectRoomType;
    }
    if(this.selectBuilding != '') {
      filter.building_name = this.selectBuilding;
    }
    if(this.selectDong != '') {
      filter.dong = this.selectDong;
    }

    if(this.selectFloor != '') {
      filter.floor_name = this.selectFloor;
    }

    if(this.selectRoom != '') {
      filter.room_name = this.selectRoom;
    }

    if(this.option && this.text) {
        filter[this.option] = this.text;
    }

    if (this.ordering) {
      filter['ordering'] = this.ordering;
    }
    if(Number(this.worksite) === 2) {
      if(!isNull(this.authBuilding) && this.authBuilding !== undefined && this.authBuilding.length > 0 && this.authBuilding !== 'null') {
        filter.authBuilding = this.authBuilding;
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeGuests(filter);
      this.items = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;

      // return {
      //   'save' : true,
      //   'status' : status,
      //   'data' : data
      // };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      return;
    }
  }

  // 입숙자 현황 리스트 다운로드
  async downloadUserList() {
    let ids: any = this.items.map((itemsId: any) => itemsId.id);

    try {
      this.headerStore.isLoading = true;
      let { data, headers } = await apis.core.downloadByBookingIds(ids.join(','));

      let csv = XLSX.read(data, { type: "string" })
      XLSX.writeFile(csv, "userList.xlsx")

      this.init();
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async getItem(id: number) {
    this.initData();
    const payload: any = {
        //guest: this.guest
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeGuestsDetail(id, payload);

      if(data) {
          this.bookingData = data.booking;
          this.guestData = data;
          //this.guest = data.guest;
          this.mngGuestId = data.id;

          if(data.guest_employee_number){
            const historyData: any = await apis.lodge.lodgeGuestsHistory(payload, data.guest_employee_number);
            this.historyList = historyData.data.filter((obj: any) => obj.id != data.id);
          }
          else{
              this.historyList = [];
          }

          const tempList: any = await apis.lodge.lodgeManagement(data.id);
          this.mngList = tempList.data;
      }
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 관리내역 수정 폼 데이터
  async managementUpdateFrm(id: number) {
    try {
      this.headerStore.isLoading = true;
      let { data } = await apis.lodge.lodgeManagementDetail(id);
      this.mngId = data.id;
      this.author = data.author;
      this.category = data.category;
      this.contents = data.contents;
      this.files = data.files;
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 관리내역 저장
  async managementSave() {
    let fileIds : any = this.files.map((obj : any) => {return obj.id});

    if(this.mngId && this.mngId > 0) {
      try {
        this.headerStore.isLoading = true;
          await apis.lodge.lodgeManagementUpdate({
              'guest': this.mngGuestId,
              'category': this.category,
              'contents': this.contents,
              'files': fileIds
          }, this.mngId);
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }

          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }
    }else {
      try {
        this.headerStore.isLoading = true;
          await apis.lodge.lodgeManagementCreate({
              'guest': this.mngGuestId,
              'category': this.category,
              'contents': this.contents,
              'files': fileIds
          });
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }

          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }
    }
  }

  // 관리내역 삭제
  async managementDelete(id: number) {
    try {
      this.headerStore.isLoading = true;
      await apis.lodge.lodgeManagementDelete(id);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 관리내역 리스트 팝업
  async lodgeManagementList(id: number) {
    try {
      this.headerStore.isLoading = true;
      let { data } = await apis.lodge.lodgeManagement(id);
      this.guestMngList = data;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }



  //입숙자 현황 및 이력 (통계)
  async guestStatTotal(){
    //console.log('guestStatTotal>>>>>>', this.roomType);
    const payload: any = {
      worksite : this.worksite,
      roomtype : this.roomType,
      //authBuilding : this.authBuilding,
    }
    if(Number(this.worksite) === 2) {
      if(!isNull(this.authBuilding) && this.authBuilding !== 'null' && this.authBuilding.length > 0) {
        payload.authBuilding = this.authBuilding; 
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.guestTotalStat(payload);
      // console.log('입숙자현황 및 이력 통계=====>',data);
      this.list = data;
      this.calcular = []; 
      let totalRoom: number = 0; //총호실
      let cal1: number = 0; //이용중
      let cal2: number = 0; //이용가능
      let cal3: number = 0; //수용인원
      let cal4: number = 0; //이용인원
      let cal5: number = 0; //사내임직원A
      let cal6: number = 0; //사내임직원B
      let cal7: number = 0; //사내임직원C
      let cal8: number = 0; //계열사 임직원
      let cal9: number = 0; //외부인
      let cal10: number = 0; //이용불가
      data.map((item: any, idx: number) => 
      {
        if(idx >= 0 ){
          totalRoom += parseInt(item.checkin_room_total); //총호실
          cal1 += parseInt(item.checkin_using); //이용중
          cal2 += parseInt(item.checkin_room_available); //이용가능
          cal3 += parseInt(item.checkin_room_capacity); //수용인원
          cal4 += parseInt(item.checkin_room_guest); //이용인원
          cal5 += parseInt(item.checkin_corporation_a); //사내임직원A
          cal6 += parseInt(item.checkin_corporation_b); //사내임직원B
          cal7 += parseInt(item.checkin_corporation_c); //사내임직원B
          cal8 += parseInt(item.checkin_subsidiary); //계열사 임직원
          cal9 += parseInt(item.checkin_public); //외부인
          cal10 += parseInt(item.checkin_room_unavailable); //이용불가
        }
        //console.log('cal1==',cal1 + ', cal2==',cal2 +', cal3==',cal3 +', cal4==',cal4 +', cal5==',cal5+', cal6==',cal6 +', cal7==',cal7 +', cal8==',cal8 +', cal9==',cal9);
      }
      )
      this.calcular['cal1'] = cal1 === 0 || cal3 === 0? 0:cal1/cal3*100;
      this.calcular['cal2'] = cal2 === 0 || cal3 === 0? 0:cal2/cal3*100;
      this.calcular['cal3'] = cal3 === 0 || totalRoom === 0? 0:cal3/totalRoom*100;
      this.calcular['cal4'] = cal4 === 0 || cal3 === 0? 0:cal4/cal3*100;
      this.calcular['cal5'] = cal5 === 0 || cal3 === 0? 0:cal5/cal3*100;
      this.calcular['cal6'] = cal6 === 0 || cal3 === 0? 0:cal6/cal3*100;
      this.calcular['cal7'] = cal7 === 0 || cal3 === 0? 0:cal7/cal3*100;
      this.calcular['cal8'] = cal8 === 0 || cal3 === 0? 0:cal8/cal3*100;
      this.calcular['cal9'] = cal9 === 0 || cal3 === 0? 0:cal9/cal3*100;
      this.calcular['calX'] = cal10 === 0 || cal3 === 0? 0:cal10/cal3*100;
      // console.log(this.calcular['calX'], this.calcular['totalRoom'], this.calcular['cal1'], this.calcular['cal2'], this.calcular['cal3'], this.calcular['cal4'], this.calcular['cal5'], this.calcular['cal6'], this.calcular['cal7'], this.calcular['cal8'], this.calcular['cal9']);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }){
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  initDatas () {
    const date = format(addMonths(new Date(getTodayDate()[0]), -1), 'yyyy-MM-dd').split('-');
    this.year = date[0];
    this.month = date[1];
    //console.log(this.year + '??' + this.month);
  }

  async setMonth(month: string) {
    this.month = month;
    await this.getDeductList();
  }

  async setYear(year: string) {
    this.year = year;
    await this.getDeductList();
  }

  //공제
  async getDeductList() {
    
    //룸타임 : 기숙사,사택 , 사번, 동호수, 년월   
        //worksite, roomtype, employee_number, dong, ho, dedut_date
    const payload: any = {
      employee_number : this.guestData.guest_employee_number,
    }
    
    //사업장 
    
    //룸타입
    if(this.guestData.room_type == ROOM_TYPE_CODE.dormitory_room) { 
      //기숙사
      payload.roomtype = '기숙사'
    } else if(this.guestData.room_type == ROOM_TYPE_CODE.company_room) {
      //사택
      payload.roomtype = '사택'
    } else if(this.guestData.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[this.guestData.room_work_site] === WORK_SITE_CODE.울산공장) {
      //울산 의 출장자 숙소(개인)
      payload.roomtype = '출장자숙소';
    } else {
      this.isDeductionPop = false;
      return;
    }
    if(WORK_SITE_CODE[this.guestData.room_work_site] === WORK_SITE_CODE.울산공장) {
      payload.worksite = '울산'
    } else if(WORK_SITE_CODE[this.guestData.room_work_site] === WORK_SITE_CODE.남양연구소) {
      payload.worksite = '남양'
    } else if(WORK_SITE_CODE[this.guestData.room_work_site] === WORK_SITE_CODE.전주공장) {
      payload.worksite = '전주'
    } else if(WORK_SITE_CODE[this.guestData.room_work_site] === WORK_SITE_CODE.아산공장 ) {
      payload.worksite = '아산'
    } else {
      this.isDeductionPop = false;
      return;
    }

    //조회날짜
    if(this.guestData.status === LODGE_STATUS_CODE.checkin) {
      //이용중일때
      payload.deduct_date = `${this.year}${this.month}`;
    } else if(this.guestData.status === LODGE_STATUS_CODE.checkout) {
      payload.deduct_date = `${this.year}${this.month}`;
      payload.checkout_id = this.guestData.checkout_id;
    } else {
      this.isDeductionPop = false;
      return;
    }
    if(this.guestData.room_building_name) {
      payload.building_name = this.guestData.room_building_name;
    }

    if(this.guestData.room_building_dong && WORK_SITE_CODE[this.guestData.room_work_site] !== WORK_SITE_CODE.울산공장 && WORK_SITE_CODE[this.guestData.room_work_site] !== WORK_SITE_CODE.남양연구소) {
      let splitDong = this.guestData.room_building_dong.split('동');
      payload.dong = splitDong[0];
    }
    if(this.guestData.room_building_dong && (WORK_SITE_CODE[this.guestData.room_work_site] === WORK_SITE_CODE.울산공장 || WORK_SITE_CODE[this.guestData.room_work_site] === WORK_SITE_CODE.남양연구소)) {
      payload.dong = this.guestData.room_building_dong;
    }
    if(this.guestData.room_name) {
      payload.ho = this.guestData.room_name;
    } 
    try {
      //공제조회 api 호출
      let deductDataResult = await apis.lodge.getDeductDetail(payload);
      this.deductData = deductDataResult.data[0];

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }){
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
    
    
  }
}
