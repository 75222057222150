import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { useStores } from '../../../store/mobx';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { formatByString } from '../../../utils/date';
import Core from '../../../apis/core';
import { numberFormatter } from '../../../utils/common';
import Button from '../../../components/molecules/fileUpload/button';
import FileSaver from 'file-saver';

//전주 기숙사
const JeonjuDormi = observer((props: any) => {
  const dispatch = useDispatch();
  const {ulsanWithoutStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const [grid1, setGrid1] = useState<any>(null);
  const [grid2, setGrid2] = useState<any>(null);
  const fileRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [sources, setSources] = useState<any[]>([]);
  const baseUrl = '/guest/deductible';

  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>("");

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));

  useEffect(() => {
    store.worksite = '전주';
    store.fnName = 'jeonjudormi';
    (async () => {
      await store.getItems();
    })();
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, []);

  const { info }: MessageImpl = useContext(MessageContext);

  const onFileChange = async (e: any) => {
    let file = e.target.files[0]
    let splited = file.name.split('.')
    if (splited[splited.length - 1] !== 'xlsx' && splited[splited.length - 1] !== 'xls') {
      let msg = "업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 당월공제자관리 업로드에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다1.";
      info(msg);
      return;
    }
    let deductList: any = [];

    try {
      headerStore.isLoading = true;

      var excel = new FormData();
      
      excel.append("path", file);
      
      let { data } = await Core.fileJeonjuDormiDeductList(excel);
      deductList = data.data || [];

      headerStore.isLoading = false;
      if(deductList.length > 0) {
        let errorRows : String = '';
        
        if (deductList[0].number !== 'NO') {
          info("전주 기숙사용 엑셀파일이 아닙니다.");
          (fileRef.current as any).value = null;
          headerStore.isLoading = false;
          return;
        } else {
          const newDeductList = [];
          for(let idx=1; idx < deductList.length; idx++) {
            let excelRow : number = idx;
            //동
            if(!deductList[idx].dong) {
              errorRows = (excelRow + `행의 동 항목이 비어있습니다.`);
              info("업로드한 파일에 오류가 있습니다.\n"+"("+errorRows+")");
              (fileRef.current as any).value = null;
              headerStore.isLoading = false;
              return;
            } 
            //호수
            if(!deductList[idx].ho) {
              errorRows = (excelRow + `행의 호수 항목이 비어있습니다.`);
              info("업로드한 파일에 오류가 있습니다.\n"+"("+errorRows+")");
              (fileRef.current as any).value = null;
              headerStore.isLoading = false;
              return;
            } 
            //입숙일
            if(deductList[idx].stay_before) {
              const date = new Date(deductList[idx].stay_before);
              deductList[idx].stay_before = format(date, 'yyyy-MM-dd');
            }
            //퇴숙일
            if(deductList[idx].stay_after) {
              const date = new Date(deductList[idx].stay_after);
              deductList[idx].stay_after = format(date, 'yyyy-MM-dd');
            }
            
            
            newDeductList.push(deductList[excelRow]);
          }
          if(errorRows === '') {
            setSources(newDeductList);
            (fileRef.current as any).value = null;
          }
        }
      }
    } catch ({status, data}) {
      headerStore.isLoading = false;
      (fileRef.current as any).value = null;
      if (status === 401) {
        window.location.href = '/force/signout';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        info("전주 기숙사용 엑셀파일이 아닙니다.");
        (fileRef.current as any).value = null;
        return;
      }
      let msg = "업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 당월공제자관리 업로드에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.";
      info(msg);
    }
  };

  const onSourceGrid1Ready = (params: any) => {
    setGrid1(params.api);
  };

  const onSourceGrid2Ready = (params: any) => {
    setGrid2(params.api);
  };

  const onCreate = async () => {
    if (sources.length === 0) {
      info('당월공제 정보가 없습니다.');
      return;
    }
    try {
      headerStore.isLoading = true;
      const newData: any = {
        deduct_date: `${store.year}${store.month}`,
        worksite: '전주',
        roomtype: '기숙사',
        employee_number: store.user.employeeNumber,
        jeonjudormitory: [ ...sources ],
      };
      let data: any = await store.createJeonjuDormi(newData);
      headerStore.isLoading = false;

      setTimeout(function () {
        if(data.save) {
          info('당월공제 정보를 생성했습니다.');
        }else {
          info("문제가 발생했습니다");
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }
    }
  };

  const onComplete = async () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '현재 내역을 마감확정 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data: any = await store.worksiteFinal(selectedRow.id);
            headerStore.isLoading = false;
            
            setTimeout(function () {
              if(data.save) {
                selectedRow.is_finalize = 'Y';
                info('마감확정을 완료했습니다.');
              }else {
                info("문제가 발생했습니다");
                return;
              }
            }, 250);

          } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }else {
              info("문제가 발생했습니다");
              return;
            }
          }
        },
      }
    });
  };

  const onDeletehandler = async (item: any) => {
    if (!item) {
      info('선택된 항목이 없습니다.');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data: any = await store.deleteJeonjuDormi(item);
            headerStore.isLoading = false;

            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
              }else {
                info("문제가 발생했습니다");
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }else {
              info("문제가 발생했습니다");
              return;
            }
          }
        },
      }
    });
  };
  
  const cellDeleteRenderer = ({data}: {data: any}) => {
    return <button type="button" className="btn btn_xs btn_outline" onClick={() => onDeletehandler(data)}>삭제</button>;
  };
  
  const cellCreatedAtRenderer = ({data}: {data: any}) => (formatByString(data.created_at, 'yyyy.MM.dd'));

  const frameworkComponents = {
    cellDeleteRenderer,
    cellCreatedAtRenderer,
  };

  const onSelectionChanged = () => {
    const _selectedRows = grid2.getSelectedRows();
    if (_selectedRows.length > 0) {
      setSelectedRow(_selectedRows[0]);
      setSources(_selectedRows[0].jeonjudormitory);
      store.xlsParamDate = _selectedRows[0].deduct_date;
      store.xlsDwonFlag = true;
    } else {
      setSelectedRow(null);
      setSources([]);
      store.xlsDwonFlag = false;
    }
  };

  // 다운로드 
  const xlsDownload = async()=>{
    try {
        headerStore.isLoading = true;
        if(store.xlsDwonFlag) {
          const payload: any = {
            employee_number : userInfo.employeeNumber,
            worksite : '전주',
            roomtype: store.roomType,
            reason : downloadText,
            deduct_date : store.xlsParamDate,
          };
      
          let { data } = await Core.xlsDeductJeonjuDormiList(payload);
          if(data) {
              FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
          }
          setIsShowDownloadPop(false);
          setDownloadText('');
        } else {
          info('다운로드할 당월공제자관리 리스트를 선택해주세요.');
          setIsShowDownloadPop(false);
          setDownloadText('');
          headerStore.isLoading = false;
          return;
        }
        
        headerStore.isLoading = false;
    }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
    }
  }
  
 
  return (
    <>
      <h4>당월공제자관리 리스트
        <div className="right_area">
          <div>
            {headerStore.downRoleFlag  ? // && store.item && store.item.is_finalize === 'N'
              <>
                <button type="button" className="btn btn_md btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button>
              </>
              :
              <>
                <></>
              </>
            }
          </div>
        </div>
      </h4>

      <div className="upload_area mt0">
        <label htmlFor="input_copy">파일 업로드</label>
        <div className="wrap_file">
          <input id="input_copy" className="upload_name form_control" placeholder="파일찾기를 클릭하여 파일을 업로드 하세요" value=""/>
          <input ref={fileRef} type="file" id="input_upload_copy" className="upload_hidden"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
            onChange={onFileChange}/>
          <label htmlFor="input_upload_copy" className="btn btn_gray">파일찾기</label>
        </div>
        <Button
                src={process.env.REACT_APP_MEDIA + "static/jeonju_dormi_sample.xlsx"}
                value={"양식다운로드"}
                onlySrc
            />
      </div>

      <div className="table_normal_list">
        <div className="ag-theme-alpine" style={{height: '500px'}}>
          <AgGridReact
            modules={AllCommunityModules}
            defaultColDef={{
              width: 100,
              wrapText: true,
              resizable: true,
              cellStyle: { textAlign: 'center', fontSize: '13px' },
              headerClass: 'text-center',
              editable: true,
            }}
            suppressRowTransform={true}
            suppressRowClickSelection={true}
            onGridReady={onSourceGrid1Ready}
            multiSortKey={'ctrl'}
            rowSelection={'multiple'}
            rowData={sources}
          >
            <AgGridColumn headerName="부서" field="department" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="이름" field="name" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="직급" field="rank" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="사번" field="employee_number" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="입숙일" field="stay_before" width={110} filter="agTextColumnFilter"unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="동" field="dong" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="호실" field="ho" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="결혼여부" field="is_married" width={120} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="숙소유지비" field="lodge_cost" width={320} filter="agTextColumnFilter" valueFormatter={params => numberFormatter(params.data.lodge_cost)} unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="주소/본가" field="family_clan" width={130} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="비고" field="memo" width={200} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="입숙기간(년)" field="stay_year" width={150} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="인원" field="people" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="퇴숙일" field="stay_after" width={110} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="미혼" field="single" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="기혼" field="married" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="5년이상" field="five_over" width={120} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="계열사" field="affiliate" width={110} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="고충" field="difficulty" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="파견" field="dispatch" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="기술직" field="technical_post" width={110} filter="agTextColumnFilter" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="사무직" field="office_clerk" width={110} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="공가" field="official_vacation" width={100} filter="agTextColumnFilter" unSortIcon={true} sortable={true}/>
          </AgGridReact>
        </div>
      </div>

      <div className="submit ac">
        <button type="button" className="btn btn_lg btn_gray" onClick={() => setSources([])}>초기화</button>
        <button type="button" className="btn btn_lg btn_outline" onClick={onCreate}>저장</button>
        {selectedRow && selectedRow.is_finalize === 'N'
          ? <button type="button" className="btn btn_lg btn_darkblue" onClick={onComplete}>마감확정</button>
          : <></>}
      </div>

      <h4>당월공제자 등록 리스트</h4>
      <div className="table_normal_list">
        <div className="ag-theme-alpine" style={{height: '400px'}}>
          <AgGridReact
            modules={AllCommunityModules}
            defaultColDef={{
              flex: 1,
              width: 100,
              wrapText: true,
              resizable: true,
              cellStyle: { textAlign: 'center', fontSize: '13px' },
              headerClass: 'text-center',
              editable: false,
            }}
            frameworkComponents={frameworkComponents}
            onGridReady={onSourceGrid2Ready}
            rowSelection={'single'}
            onSelectionChanged={onSelectionChanged}
            rowData={store.items}
          >
            <AgGridColumn headerName="NO" field="index" width={60} flex={.5} unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="관리제목" field="deduct_date" width={300} flex={4} cellStyle={{ textAlign: 'left' }} unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="작성자" field="employee_number" width={120} flex={1} unSortIcon={true} sortable={true}/>
            <AgGridColumn headerName="작성일" field="created_at" flex={1} cellRenderer="cellCreatedAtRenderer" unSortIcon={true} sortable={true} />
            <AgGridColumn headerName="삭제" field="" width={100} flex={1} cellRenderer="cellDeleteRenderer"/>
          </AgGridReact>
        </div>
      </div>

      {/* 다운로드 사유 팝업 */}
      <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
              <dt>사유</dt>
              <dd>
                  <textarea className="form_control"
                  value={downloadText}
                  onChange={(e: any) => setDownloadText(e.target.value)}
                  >
                  </textarea>
              </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
          </div>
        </div>
      </div>
    </>
  );
});

export default JeonjuDormi;
