import React, { useState, useEffect, useContext } from 'react';

import DefaultTemplate from '../../components/templetes/default';

import StatusTag from '../../components/atoms/tag/statusTag'

import CheckPopup from '../../components/organismas/popup/check';
import Pagination from '../../components/organismas/pagination';
import LimitSelect from '../../components/atoms/select/limit';
import RoomType from '../../components/atoms/select/roomType';
import SearchForm from '../../components/molecules/search';
import Header from '../../components/organismas/header';


import { ROOM_TYPE, STATUS_CODE } from '../../constants';
import { getViewPageIdx } from '../../utils/common';

import { build, checkout } from '../../apis';
import { formatByString_YYYYMMDD, getTodayDate } from '../../utils/date';

import './index.scss';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';
import { MessageContext, MessageImpl } from '../../routes';

const LodgedGuestPage = observer ((props: any) => {
  const {headerStore: headerStore} = useStores();
  const today = getTodayDate();

  const [reload, setReload] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [roomType, setRoomType] = useState<number>(-1);

  const [guests, setGuests] = useState<any>([]);
  const [checkeds, setChecked] = useState<any>({});

  const [checkOutAt, setCheckOutAt] = useState<string>(today[0]); // 퇴숙 확정일
  const [checkOutKeyInAt, setCheckOutKeyInAt] = useState<string>(today[0]); // 키 반납일

  const [selectBackroom, setSelectBackroom] = useState<any>({});

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchOption, setSearchOption] = useState<{ option: string, text: string }>({ option: "", text: "" });
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  useEffect(() => {
    (async () => {
      setChecked({});
      let payload: any = {
        today: false,
        offset,
        limit,
        status: [STATUS_CODE.checkin_completed, STATUS_CODE.checkout_completed].join(',')
      }

      if (roomType > -1) {
        payload.room_type = roomType
      }
      if (searchOption.option) {
        payload[searchOption.option] = searchOption.text
      }
      try{
        let { data } = await build.getGuest(payload);
        let count: number = data.count
        setGuests(data.results);
        setTotalPage(Math.ceil(count / limit))
      }catch({status,data}){
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        info('조회중 문제 발생');
      }
    })();
  }, [roomType, offset, reload, searchOption])

  const onCheckedHandler = (e: any, guest: any) => {
    let temp: any = { ...checkeds }
    if (e.target.checked) {
      temp[guest.id] = guest
    } else {
      delete temp[guest.id]
    }
    setChecked(temp);
  };

  const onCloseModalHandler = () => {
    setIsShowModal(false);

    setChecked({})
  }

  const onCheckoutHandler = async (e: any) => {
    if (isEmptyChecked()) {
      alert("체크를 하지 않았습니다.")
      return
    }

    if (isCheckoutStatus()) {
      alert("입숙완료 할 수 없는 상태를 가진 항목이 선택되었습니다.");
      return;
    }

    let ids: any = Object.keys(checkeds).map(key => key);
    let payload = {
      checkout_at: checkOutAt,
      checkout_keyin_at: checkOutKeyInAt
    }

    try {
      headerStore.isLoading = true;
      let { data } = await checkout.complete(ids.join(','), payload);
      onCloseModalHandler();
      setChecked({});
      alert("퇴숙승인 완료")
      headerStore.isLoading = false;
    } catch (e) {
      headerStore.isLoading = false;
      let { status, data } = e;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (data.invalid_status && data.invalid_status.length) {
        alert(data.invalid_status[0]);
        return;
      }

      alert("퇴숙승인 실패");
      return;
    }

    setReload(!reload);
  }

  const onCheckedByAll = (e: any) => {
    let temp: any = { ...checkeds }

    guests.map((guest: any, guestIdx: number) => {
      if (e.target.checked) {
        temp[guest.id] = guest
      } else {
        delete temp[guest.id]
      }
    })
    // console.log(temp)
    setChecked(temp)
  }

  const onShowCompletePopup = () => {
    if (isEmptyChecked()) {
      alert("체크된 항목이 없습니다.");
      return;
    }

    if (isCheckoutStatus()) {
      alert("퇴숙완료 할 수 없는 상태를 가진 항목이 선택되었습니다.");
      return;
    }

    setIsShowModal(true);
  }

  const getCheckedStatus = () => Object.keys(checkeds).map((checkedKey: any, checkedsIdx: number) => checkeds[checkedKey].status)
  const isEmptyChecked = () => !Object.keys(checkeds).length

  const onSearchHandler = async (option: string, text: string) => {
    setOffset(0);
    setSearchOption({ option, text })
  }

  const isCheckoutStatus = () => {
    let status = getCheckedStatus()
    let filtered = status
      .filter((s: any, idx: number) => s !== STATUS_CODE.checkin_completed)

    return !!filtered.length
  }
  return (
    <>

      <DefaultTemplate
        className=""
        {...props}
        id="wrap"
        isLoading={headerStore.isLoading}
      >
        <h3>이용내역현황</h3>

        <div className="group_tab mt40">
          <RoomType
            className="tab_lg tabs clearfix"
            onChange={(roomType: number) => setRoomType(roomType)}
            value={roomType}
          />
          {/*tab*/}
          <div className="right_area">
            <LimitSelect className="form_control pc" limit={limit} onChange={(limit: number) => { setLimit(limit); setOffset(0); }} />
          </div>
        </div>

        <div className="group_tap clearfix mt40">
          <div className="search_form">
            <SearchForm
              options={[
                { value: "work_site", label: "신청지역" },
                { value: "applicant_employee_number", label: "신청자 사번" },
                { value: "applicant_name", label: "신청자 이름" },
                { value: "guest_name", label: "이용자 이름" }
              ]}
              onSearch={onSearchHandler}
              dateAfter={""}
              dateBefore={""}
              onChangeStartDateHandler={() => { }}
              onChangeEndDateHandler={() => { }}
            />
          </div>
        </div>

        <div className="tab_contents">전체</div>
        <div className="tab_contents">기숙사</div>
        <div className="tab_contents">사택</div>
        <div className="tab_contents">출장자숙소</div>
        <div className="tab_contents">R&D STAY</div>
        <div className="tab_contents">관심기업종목</div>
        <div className="tab_contents on">
          <div className="table_normal_list mt30" style={{ overflowX: "auto" }}>
            <table>
              <caption className="sr_only">입숙 신청 현황 목록</caption>
              {(guests.length) ? (
                <colgroup>
                  <col width="40px" />
                  <col width="120px" />
                  <col width="120px" />

                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />

                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />

                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />

                  <col width="120px" />
                  <col width="120px" />
                  <col width="120px" />

                  <col width="120px" />
                </colgroup>
              ) : ''}
              <thead>
                <tr>
                  <th rowSpan={2}>
                    <label className="input_check pop_notext">
                      <input type="checkbox" onChange={onCheckedByAll} />
                      <span className="label_text"></span>
                    </label>
                  </th>
                  <th rowSpan={2}>번호</th>
                  <th rowSpan={2}>서비스 구분</th>
                  <th colSpan={4}>신청 기본 정보</th>
                  <th colSpan={3}>신청자</th>
                  <th colSpan={17}>이용자</th>
                  <th colSpan={3}>신청자</th>
                  <th rowSpan={2}>비고</th>
                </tr>
                <tr>
                  <th>신청일자</th>
                  <th>숙소유형</th>
                  <th>신청지역</th>
                  <th>진행상태</th>

                  <th>성명</th>
                  <th>부서명</th>
                  <th>휴대폰</th>

                  <th>성명</th>
                  <th>성별</th>
                  <th>휴대폰</th>
                  <th>입숙예정일</th>
                  <th>퇴숙예정일</th>
                  <th>만료일자</th>
                  <th>공제내역</th>
                  <th>건물명</th>
                  <th>동</th>
                  <th>호</th>
                  <th>소속회사 유형</th>
                  <th>회사명/단체명</th>
                  <th>소속부서</th>
                  <th>사번</th>
                  <th>직급</th>
                  <th>직종</th>
                  <th>근무조</th>

                  <th>회사명</th>
                  <th>직급</th>
                  <th>사번</th>
                </tr>
              </thead>
              <tbody>
                {!(guests.length) ? (
                  <tr>
                    <td colSpan={31} className="blank_area type_white">
                      <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                  </tr>
                ) : (
                  guests.map((guest: any, guestIdx: number) => (
                    <tr className="" key={guestIdx}>
                      <td>
                        <label className="input_check pop_notext">
                          <input type="checkbox" onChange={(e: any) => onCheckedHandler(e, guest)} checked={checkeds[guest.id]} />
                          <span className="label_text"></span>
                        </label>
                      </td>
                      <td className="">{getViewPageIdx(offset, limit, guestIdx)}</td>
                      <td className=""><span className="state_room case3"><StatusTag status={guest.status} /></span></td>

                      <td>{formatByString_YYYYMMDD(guest.created_at)}</td>
                      <td>{ROOM_TYPE[guest.room_type]}</td>
                      <td className="">{guest.room_work_site}</td>
                      <td className=""><span className="state_room case3"><StatusTag status={guest.status} /></span></td>

                      <td className="">{guest.booking.applicant_name}</td>
                      <td className="">{guest.booking.applicant_department}</td>
                      <td className="">{guest.booking.applicant_cellphone}</td>

                      <td className="">{guest.guest_name}</td>
                      <td className="">{guest.guest_gender === 1 ? "남자" : "여자"}</td>
                      <td className="">{guest.guest_cellphone}</td>
                      <td className="">{guest.stay_after}</td>
                      <td className="">{guest.stay_before}</td>
                      <td className=""></td>
                      <td></td>
                      <td className="">{guest.room_building_name}</td>
                      <td className="">{guest.room_building_number}</td>
                      <td className="">{guest.room_name}</td>
                      <td>{guest.guest_type === 1 ? "사내임직원" : guest.guest_type === 2 ? "계열사 임직원" : "외부인"}</td>
                      <td className="">{guest.guest_company}</td>
                      <td className="">{guest.guest_department}</td>
                      <td className="">{guest.guest_employee_number}</td>
                      <td className="">{guest.guest_position}</td>
                      <td className=""></td>
                      <td className=""></td>

                      <td className="">현대자동차</td>
                      <td className="">{guest.booking.applicant_position}</td>
                      <td className="">{guest.booking.applicant_employee_number}</td>

                      <td className="">{guest.memo}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>  {/*table*/}
        </div>  {/*tab_contents*/}

        <div className="board_paging">
          <ul>
            <Pagination
              offset={offset}
              limit={limit}
              setOffset={setOffset}
              totalPage={totalPage}
            />
          </ul>

        </div>  {/*board_paging*/}

        <div className="submit-ar">
          <div className="fr">
            <button type="button" className="btn_md btn_outline btn_darkblue" onClick={onShowCompletePopup}>퇴숙완료</button>
          </div>
        </div>
      </DefaultTemplate>

      {/* <CheckPopup
        isShowModal={isShowCheckinModal}
        onCloseModalHandler={() => setIsShowCheckinModal(false)}
        onCompleteHander={onCheckinHandler}
        checkKeyAt={checkInKeyoutAt}
        checkAt={checkInAt}
        bookingData={guests}
        setCheckAt={setCheckInAt}
        setCheckKeyAt={setCheckInKeyoutAt}
        type="checkin"
      /> */}

    </>
  )
});

export default LodgedGuestPage;
