import React from 'react';

interface Props {
  floors: Floor[]
  onChangeFloor: Function
  isShowAllOption?: boolean
  value?: number
}

interface Floor {
  id: number
  step: string | number
  layout_image?: string
}

//층 select
const SelectFloor = (props: Props) => {
  return (
    <>
    {/* <div className="col">
      <label htmlFor="select_floor" className="title_form">
        층 수
      </label> */}
      <select
        name=""
        id="select_floor"
        className="form_control form_sm"
        value={props.value}
        onChange={e => {props.onChangeFloor(e)}}
        disabled={props.floors.length <= 0}
      >
        {props.isShowAllOption
          ? (
            <option value="-1">전체</option>
          ) : (
            <></>
          )}
        
        {props.floors.map((floor: Floor, floorIdx: number) => (
          <option value={floorIdx} label={`${floor.step} 층`} />
        ))}
      </select>
    {/* </div>*/}
    </>
  );
};

export default SelectFloor;
