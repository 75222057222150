import React, { useState, useEffect, useContext } from 'react';

import LimitSelect from '../../components/atoms/select/limit';
import StatusTag, { CHECKOUT } from '../../components/atoms/tag/statusTag'
import RoomType from '../../components/atoms/select/roomType';
import DefaultTemplete from '../../components/templetes/default';
import Pagination from '../../components/organismas/pagination';

import { ROOM_TYPE, GENDER, CHECKOUT_STATUS_TYPE_CODE } from '../../constants';

import { checkout } from '../../apis';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../routes';

const LodgedCheckoutPage = observer((props: any) => {
  const [reload, setReload] = useState<boolean>(false);
  const {headerStore: headerStore} = useStores();
  const [roomType, setRoomType] = useState<number>(-1);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchOption, setSearchOption] = useState<{ option: string; text: string }>({ option: '', text: '' });

  const [checkouts, setCheckouts] = useState([]);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  useEffect(() => {
    (async () => {
      headerStore.isLoading = true;
      let payload: any = {
        offset,
        limit,
        status: [
          CHECKOUT_STATUS_TYPE_CODE.default,
          CHECKOUT_STATUS_TYPE_CODE.applied,
          CHECKOUT_STATUS_TYPE_CODE.accepted,
          CHECKOUT_STATUS_TYPE_CODE.canceled,
          CHECKOUT_STATUS_TYPE_CODE.rejected,
          CHECKOUT_STATUS_TYPE_CODE.completed,
        ].join(','),
      };

      if (roomType > -1) {
        payload.room_type = roomType;
      }
      if (searchOption.option) {
        payload[searchOption.option] = searchOption.text;
      }
      try{
        let { data } = await checkout.findAll(payload);
        let count: number = data.count;
        // console.log(data);
        setCheckouts(data.results);
        setTotalPage(Math.ceil(count / limit));
      }catch({status, data}){
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        info('조회중 문제 발생');
      }
      headerStore.isLoading = false;
    })();
  }, [reload, roomType, offset, limit, searchOption]);

  return (
    <DefaultTemplete
      id="wrap"
      className=""
      {...props}
      isLoading={headerStore.isLoading}
    >

      <div className="group_tab">
        <RoomType
          className="tab_lg tabs clearfix"
          onChange={(roomType: number) => setRoomType(roomType)}
          value={roomType}
        />
       
        <div className="right_area">
          <LimitSelect className="form_control form_lg num" limit={limit} onChange={(limit: number) => {setLimit(limit);setOffset(0);}} />
        </div>
      </div>

      <div className="tab_contents">전체</div>
      <div className="tab_contents">기숙사</div>
      <div className="tab_contents on">
        <div className="table_normal_list box_scroll_x mt30">
          <table>
            <caption className="sr_only">퇴숙 목록</caption>
            <colgroup>
              <col width="120px" />
              <col width="120px" />
              <col width="150px" />
              <col width="120px" />
              <col width="80px" />
              <col width="80px" />
              <col width="120px" />
              <col width="150px" />
              <col width="80px" />
              <col width="80px" />
              <col width="80px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
            </colgroup>
            <thead>
              <tr>
                <th>숙소유형</th>
                <th>이용사업장</th>
                <th>상태</th>
                <th>사번(사용자)</th>
                <th>성명</th>
                <th>성별</th>
                <th>소속부서</th>
                <th>휴대폰번호</th>
                <th>건물명</th>
                <th>동</th>
                <th>호</th>
                <th>입숙일</th>
                <th>퇴숙요청일</th>
                <th>퇴숙희망일</th>
                <th>퇴숙사유</th>
              </tr>
            </thead>
            <tbody>
              {/*데이타 없을 시*/}
              {!checkouts.length
                ? (
                  <tr>
                    <td colSpan={18} className="blank_area type_white">
                      <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                  </tr>
                ): (
                  checkouts.map((guest: any, guestIdx: number) => (
                    <tr onClick={ () => props.history.push(`/lodged/checkout/${guest.id}/detail`, guest)}>
                      <td>{ROOM_TYPE[guest.room]}</td>
                      <td>{guest.room_work_site}</td>
                      <td><StatusTag type={CHECKOUT} status={guest.checkout_status}/></td>
                      <td>{guest.guest_employee_number}</td>
                      <td>{guest.guest_name}</td>
                      <td>{GENDER[guest.guest_gender]}</td>
                      <td>{guest.guest_occupation}</td>
                      <td>{guest.guest_cellphone}</td>
                      <td>{guest.room_building_name}</td>
                      <td>{guest.room_building_number}</td>
                      <td>{guest.room_name}</td>
                      <td>{guest.stay_after}</td>
                      <td>{guest.checkout_applied_at}</td>
                      <td>{guest.stay_before}</td>
                      <td>{ guest.checkout_reason}</td>
                    </tr>
                  ))
                )}
            </tbody>
          </table>

        </div>  {/*table*/}

        <div className="board_paging">
          <ul>
           <Pagination
              offset={offset}
              limit={limit}
              setOffset={setOffset}
              totalPage={totalPage}
            />
          </ul>
        </div>  {/*board_paging*/}
        <div className="submit ar">
          <div className="fl"></div>
          
          <div className="fr">
            <button type="button" className="btn_md btn_darkblue">다운로드</button>
            {/* <button type="button" className="btn_md btn_darkblue btn_outline">반려</button>
            <button type="button" className="btn_md btn_darkblue">승인</button> */}
          </div>
        </div>
      </div>
    </DefaultTemplete>
  );
});

export default LodgedCheckoutPage;
