import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/organismas/pagination';
import LimitSelect from '../../components/atoms/select/limit';
import DatePicker from '../../components/organismas/input/datepicker';
import TempleteDefault from '../../components/templetes/default';
import { LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, STATUS_CODE } from '../../constants';
import { showAlert, errorAlert, showConfirm } from '../../utils/common';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import './index.scss';
import { isEmpty, isNull } from 'lodash';
import { getIntervalMonthDate, getTodayDate } from '../../utils/date';
import Core from '../../apis/core';
import FileSaver from 'file-saver';
import Lodge from '../../apis/lodge';
import { MessageContext, MessageImpl } from '../../routes';
import CustomCalendar from '../../components/organismas/customCalendar';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { stripCommentTag } from 'xss';

const LodgedHistory = observer((props: any) => {
  const dispatch = useDispatch();
  const { lodgedHistoryStore: store } = useStores();
  const { headerStore: headerStore } = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string }>({ key: '', direction: '' });
  const [isExpirePop, setIsExpirePop] = useState<boolean>(false);
  const [isRoomTypePop, setIsRoomTypePop] = useState<boolean>(false);
  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>('');
  const [tmpRoomType, setTmpRoomType] = useState<any>();
  const [employeeNum, setEmployeeNum] = useState<string>('');
  const [guestId, setGuestId] = useState<number>(0);
  const [updateExDate, setUpdateExDate] = useState<string>('');
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const [room_types, setRoom_types] = useState<any[]>([]);

  //권한 가져오기
  useEffect(() => {
    const currentDate = new Date();

    currentDate.setMonth(currentDate.getMonth() - 1);
    currentDate.setDate(1);

    headerStore.getAuthMenuRole('2-1');
    headerStore.getAuthRoomRole();
    headerStore.getAuthWorkSiteRole();
    headerStore.getAuthBuilding();
    store.checkin_after = currentDate.toISOString().split('T')[0];
    //store.checkin_after = '1950-01-01'; // 입숙자 조회 시작시간을 고정
    store.checkin_before = getIntervalMonthDate(6)[0];
    setRoom_types(headerStore.paramAuthRoomTypeRole.split(','));
  }, []);

  // 상단 사업장 바뀔때
  useEffect(() => {
    store.buildingList = [];
    store.text = '';
    store.option = 'guest_name';
    store.selectWorkSite = headerStore.workSite;
    store.dongList = [];
    store.selectBuilding = '';
    store.selectDong = '';
    store.selectRoomType = 0;
    store.offset = 0;
  }, [headerStore.workSite]);

  useEffect(() => {
    (async () => {
      store.worksite = headerStore.workSite;
      store.roomType = headerStore.paramAuthRoomTypeRole;
      if (Number(headerStore.workSite) === 2) {
        if (
          !isNull(headerStore.paramAuthBuildingRole) &&
          headerStore.paramAuthBuildingRole !== 'null' &&
          headerStore.paramAuthBuildingRole.length > 0
        ) {
          store.authBuilding = headerStore.paramAuthBuildingRole;
        }
      }
      await store.getItems();
      await store.guestStatTotal(); //입숙자 현황(통계)

      // 숙소유형 권한에 맞춰서 default 주기
      let tmpRoomType = headerStore.paramAuthRoomTypeRole
        .split(',')
        .filter((r: any) => Number(r) !== 0 && Number(r) !== 7 && Number(r) !== 1 && Number(r) !== 3);
      setTmpRoomType(tmpRoomType);
      if (tmpRoomType && tmpRoomType.length > 0) {
        store.setRoomType(tmpRoomType[0]);
      }
    })();
    return () => {
      if (store.findTabs('/lodged/history') < 0) {
        store.destory();
      }
    };
  }, [store.checkin_after, store.checkin_before, headerStore.workSite]);

  // 건물 selectlist
  useEffect(() => {
    (async () => {
      try {
        headerStore.isLoading = true;
        store.buildingList = [];
        // store.text='';
        store.selectWorkSite = headerStore.workSite;
        store.dongList = [];
        store.selectBuilding = '';
        store.selectDong = '';
        if (store.selectRoomType != 0) {
          await store.getBuildingList(); // 건물명
        }
        console.log(store.selectRoomType);
        onSearchClick({});
        headerStore.isLoading = false;
      } catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem('isRetryLogin')) {
            window.localStorage.setItem('isRetryLogin', 'retry');
          }
          window.location.href = '/';
          return;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return;
        } else if (status === 500) {
          window.location.href = '/500';
          return;
        }
        return;
      }
    })();
  }, [store.selectRoomType]);

  // 건물 동 selectlist
  useEffect(() => {
    (async () => {
      try {
        headerStore.isLoading = true;
        store.selectWorkSite = headerStore.workSite;
        store.dongList = [];
        store.selectDong = '';
        let data: any = await store.getBuildingDongList(); // 건물 > 동
        headerStore.isLoading = false;
        setTimeout(function () {
          if (data.save) {
            onSearchClick({});
          } else {
            return;
          }
        }, 250);
      } catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem('isRetryLogin')) {
            window.localStorage.setItem('isRetryLogin', 'retry');
          }
          window.location.href = '/';
          return;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return;
        } else if (status === 500) {
          window.location.href = '/500';
          return;
        }
        return;
      }
    })();
  }, [store.selectBuilding]);

  useEffect(() => {
    (async () => {
      try {
        headerStore.isLoading = true;
        if (store.selectDong !== '') {
          let data: any = await store.getRoomsInfo();
          headerStore.isLoading = false;
          setTimeout(function () {
            if (data.save) {
              onSearchClick({});
              return;
            } else {
              return;
            }
          }, 250);
        } else {
          store.roomsList = [];
          store.floorsList = [];
          store.selectFloor = '';
          store.selectRoom = '';
        }
        onSearchClick({});
        headerStore.isLoading = false;
      } catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem('isRetryLogin')) {
            window.localStorage.setItem('isRetryLogin', 'retry');
          }
          window.location.href = '/';
          return;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return;
        } else if (status === 500) {
          window.location.href = '/500';
          return;
        }
        return;
      }
    })();
  }, [store.selectDong]);

  useEffect(() => {
    (async () => {
      try {
        headerStore.isLoading = true;
        if (store.selectFloor !== '' && store.selectRoom === '') {
          store.roomsList = store.floorsAndRoomsList.filter((item: any) => item.floor_name == store.selectFloor);
        } else if (store.selectFloor === '') {
          store.roomsList = [];
          store.selectRoom = '';
        }
        onSearchClick({});
        headerStore.isLoading = false;
      } catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem('isRetryLogin')) {
            window.localStorage.setItem('isRetryLogin', 'retry');
          }
          window.location.href = '/';
          return;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return;
        } else if (status === 500) {
          window.location.href = '/500';
          return;
        }
        return;
      }
    })();
  }, [store.selectFloor, store.selectRoom]);

  // 검색 버튼 클릭
  const onSearchClick = (e: any) => {
    store.worksite = headerStore.workSite;
    store.roomType = headerStore.paramAuthRoomTypeRole;
    store.offset = 0;
    store.getItems();
  };

  // 소팅
  const requestSort = (key: string) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });
    if (direction === 'asc') {
      store.ordering = '-' + key;
    } else {
      store.ordering = key;
    }

    store.getItems();
  };

  // 소팅 class
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  // 구분
  const cellStatusRenderer = (status: number) => {
    if (status === LODGE_STATUS_CODE.checkin) {
      return <span className="point green">입숙</span>;
    } else if (status === LODGE_STATUS_CODE.checkout) {
      return '퇴숙';
    }
    // 추가 될 예정
    // else if(status === '이숙') {
    //     return (<span className="point blue">이숙</span>)
    // }
    return <span>{status}</span>;
  };

  // 전체 체크박스 control
  const allCheckControl = (e: any) => {
    store.checkAll(e.target.checked);
  };

  // 체크박스 단건
  const onCheckControl = (e: any, booking: any) => {
    store.checkItem(e.target.checked, booking);
  };

  // 다운로드 버튼 클릭
  const onClickDownload = () => {
    //체크박스 삭제

    // if (Object.keys(store.checkeds).length <= 0) {
    //     showAlert('선택하신 체크 항목이 없습니다. \n 다운받으실 행을 선택해주세요', dispatch);
    //     return;
    // }

    showConfirm('다운로드 하시겠습니까?', dispatch, downloadFn);
  };

  const downloadFn = async () => {
    try {
      headerStore.isLoading = true;
      //로딩바 true
      await store.downloadUserList();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }
      errorAlert('다운로드 중 문제발생', { status, data });
    }
  };

  const goInsertPage = async () => {
    setIsRoomTypePop(false);
    props.history.replace(`/guest/history/${store.insertRoomType}/insert`);
  };

  // 다운로드
  const xlsDownload = async () => {
    if(downloadText.trim() == '') {
      info("다운로드 사유를 입력하지 않았습니다.");
      return;
    }
    
    try {
      headerStore.isLoading = true;
      const payload: any = {
        employee_number: userInfo.employeeNumber,
        reason: downloadText,
        status: [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(','),
        checkin_after: store.checkin_after,
        checkin_before: store.checkin_before,
        worksite: store.worksite,
        roomtype: store.roomType,
        /*선택한 옵션으로 필터링 후 다운로드 위해 추가*/
        selectRoomType: store.selectRoomType, //숙소유형
        selectBuilding: store.selectBuilding, //건물명
        selectDong: store.selectDong, //동
        selectFloor: store.selectFloor, //층
        selectRoom: store.selectRoom, //호
      };

      if (store.option && store.text) {
        payload[store.option] = store.text;
      }

      if (store.ordering) {
        payload['ordering'] = store.ordering;
      }
      if (Number(store.worksite) === 2) {
        if (
          !isNull(store.authBuilding) &&
          store.authBuilding !== undefined &&
          store.authBuilding.length > 0 &&
          store.authBuilding !== 'null'
        ) {
          payload.authBuilding = store.authBuilding;
        }
      }
      let { data } = await Core.xlsgeustlist1(payload);
      console.log(payload);
      if (data) {
        FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}` + data);
      }
      setIsShowDownloadPop(false);
      setDownloadText('');
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }
      // console.log(status, data);
    }
  };

  // 만료일 팝업 초기화
  const expireInit = () => {
    setUpdateExDate('');
    setEmployeeNum('');
    setGuestId(0);
  };

  // 만료일 변경
  const updateExpireDate = async () => {
    try {
      headerStore.isLoading = true;
      const payload = {
        guest_id: guestId,
        employee_number: employeeNum,
        expired_at: updateExDate,
      };

      let { data } = await Lodge.updateExpireDate(payload);
      info('만료일이 변경되었습니다.');
      setIsExpirePop(false);
      expireInit();
      await store.getItems();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }
      return;
    }
  };

  return (
    <TempleteDefault {...props} isLoading={headerStore.isLoading} id="wrap" className="">
      <h4>입숙자 현황</h4>
      <div className="table_modify_list">
        <dl className="info">
          <dt>건물 정보</dt>
          <dd>
            <select
              className="form_control mr10"
              value={store.selectRoomType}
              onChange={(e: any) => (store.selectRoomType = e.target.value)}
            >
              <option value="0">숙소유형</option>
              {room_types.map((item: any, index: number) => {
                if (Number(item) !== 0 && Number(item) !== 7) {
                  return (
                    <option key={index} value={item}>
                      {ROOM_TYPE[item]}
                    </option>
                  );
                }
              })}
            </select>
            <select
              className="form_control mr10"
              value={store.selectBuilding}
              onChange={(e: any) => (store.selectBuilding = e.target.value)}
              disabled={!store.selectRoomType || store.buildingList.length === 0 ? true : false}
            >
              <option value="">건물명</option>
              {store.buildingList.map((item: any, idx: number) => (
                <option key={idx} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
            <select
              className="form_control mr10"
              value={store.selectDong}
              onChange={(e: any) => (store.selectDong = e.target.value)}
              disabled={!store.selectRoomType || !store.selectBuilding || store.dongList.length === 0 ? true : false}
            >
              <option value="">동</option>
              {store.dongList.map((item: any, idx: number) => (
                <option key={idx} value={item.dong}>
                  {item.dong}
                </option>
              ))}
            </select>
            <select
              className="form_control mr10"
              value={store.selectFloor}
              onChange={(e: any) => (store.selectFloor = e.target.value)}
              disabled={
                !store.selectRoomType || !store.selectBuilding || !store.dongList || store.floorsList.length === 0
                  ? true
                  : false
              }
            >
              <option value="">층</option>
              {store.floorsList.map((item: any, idx: number) => (
                <option key={idx} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <select
              className="form_control mr10"
              value={store.selectRoom}
              onChange={(e: any) => (store.selectRoom = e.target.value)}
              disabled={
                !store.selectRoomType ||
                !store.selectBuilding ||
                !store.dongList ||
                !store.floorsList ||
                store.roomsList.length === 0
                  ? true
                  : false
              }
            >
              <option value="">호수</option>
              {store.roomsList.map((item: any, idx: number) => (
                <option key={idx} value={item.room_name}>
                  {item.room_name}
                </option>
              ))}
            </select>
          </dd>
        </dl>
        <dl className="info">
          <dt>정보</dt>
          <dd>
            <select
              className="form_control"
              value={store.option}
              name="option"
              onChange={(e: any) => (store.option = e.target.value)}
            >
              <option value="guest_name">성명</option>
              <option value="guest_employee_number">사번</option>
              <option value="guest_company">회사명</option>
              <option value="car_number">차량 번호</option>
            </select>
            <input
              type="text"
              className="form_control"
              name="text"
              value={store.text}
              onChange={(e: any) => (store.text = e.target.value)}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) onSearchClick(e);
              }}
            />
          </dd>
        </dl>
        <dl className="date">
          <dt>조회기간</dt>
          <dd>
            <div>
              {/* <input type="text" className="form_control date_picker" defaultValue="2021.04.05"/> */}
              {/* <DatePicker
                                value={store.checkin_after}
                                width={250}
                                onChange={(date: string) => store.checkin_after = date}
                                isPrevMonthView={true}
                                max={store.checkin_before}
                            /> */}
              <CustomCalendar
                max={store.checkin_before}
                value={store.checkin_after}
                onChange={(date: string) => (store.checkin_after = date)}
              ></CustomCalendar>
            </div>
            <span style={{ marginRight: '10px' }}> ~ </span>
            <div>
              {/* <input type="text" className="form_control date_picker" defaultValue="2021.04.05"/> */}
              {/* <DatePicker
                                value={store.checkin_before}
                                width={250}
                                onChange={(date: string) => store.checkin_before = date}
                                isPrevMonthView={true}
                                min={store.checkin_after}
                            /> */}
              <CustomCalendar
                min={store.checkin_after}
                value={store.checkin_before}
                onChange={(date: string) => (store.checkin_before = date)}
              ></CustomCalendar>
            </div>
            <button type="button" className="btn btn_sm btn_gray" onClick={onSearchClick}>
              검색
            </button>
          </dd>
        </dl>
      </div>
      <h4>
        입숙자 리스트
        <div className="right_area">
          <LimitSelect
            className="form_control mr10"
            limit={store.limit}
            onChange={(limit: number) => {
              store.setLimit(limit);
              store.setOffset(0);
            }}
          />
          {headerStore.downRoleFlag ? (
            <button
              className="btn btn_sm btn_darkblue"
              onClick={() => {
                setIsShowDownloadPop(true);
              }}
            >
              다운로드
            </button>
          ) : (
            <></>
          )}
        </div>
      </h4>
      <div className="table_normal_list event_on box_scroll_x">
        <table>
          <caption className="sr_only">숙소이용안내 등록현황</caption>
          <colgroup>
            {/*<col width="4%" />*/}
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="5%" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
          </colgroup>
          <thead>
            <tr>
              {/*<th>
                                <label className="input_check pop_notext">
                                    <input type="checkbox" name="check_notice" onChange={allCheckControl} checked={store.allCheckFlg} />
                                    <span className="label_text"></span>
                                </label>
                            </th>*/}
              <th>사업장</th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('room_type')}
                  className={`table_sortBtn ${getClassNamesFor('room_type')}`}
                >
                  숙소유형
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('status')}
                  className={`table_sortBtn ${getClassNamesFor('status')}`}
                >
                  구분
                </button>
              </th>
              <th>사번</th>
              <th>이름</th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('guest_gender')}
                  className={`table_sortBtn ${getClassNamesFor('guest_gender')}`}
                >
                  성별
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('guest_company')}
                  className={`table_sortBtn ${getClassNamesFor('guest_company')}`}
                >
                  소속회사
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('guest_work_site')}
                  className={`table_sortBtn ${getClassNamesFor('guest_work_site')}`}
                >
                  근무지
                </button>
              </th>
              <th>숙소정보</th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('checkin__managed_at')}
                  className={`table_sortBtn ${getClassNamesFor('checkin__managed_at')}`}
                >
                  입숙일
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('expired_at')}
                  className={`table_sortBtn ${getClassNamesFor('expired_at')}`}
                >
                  만료일
                </button>
              </th>
              <th>퇴숙(이숙) 확정일</th>
            </tr>
          </thead>
          <tbody>
            {store.items.length <= 0 ? (
              <tr>
                <td colSpan={12} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.items.map((booking: any, bookingIdx: number) => (
                <tr key={bookingIdx}>
                  {/*td>
                                        <label className="input_check pop_notext">
                                            <input type="checkbox"
                                                onChange={(e: any) => onCheckControl(e, booking)}
                                                checked={store.checkeds[booking.id] ? true : false}
                                            />
                                            <span className="label_text"></span>
                                        </label>
                                    </td>*/}
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.room_work_site}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {ROOM_TYPE[booking.room_type]}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.move_guest_id && booking.status === LODGE_STATUS_CODE.checkin ? (
                      <span className="point green">이숙</span>
                    ) : (
                      cellStatusRenderer(booking.status)
                    )}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.guest_employee_number}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    <span className="state_type type3">{booking.guest_name}</span>
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.guest_gender === 1 ? '남' : '여'}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.guest_company}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.guest_work_site}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.room_building_name} {booking.room_building_dong} {booking.room_name}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.checkin_at}
                  </td>
                  {/* 만료일 추가 해주시기로 함 */}
                  <td style={{ cursor: 'pointer' }} onClick={(e: any) => {}}>
                    {booking.room_type === ROOM_TYPE_CODE.dormitory_room ||
                    booking.room_type === ROOM_TYPE_CODE.company_room ? (
                      <>
                        {booking.expired_at}
                        {booking.expired_at && booking.status === LODGE_STATUS_CODE.checkin ? (
                          <button
                            className="btn btn_xs btn_gray"
                            onClick={() => {
                              setUpdateExDate(booking.expired_at);
                              setEmployeeNum(booking.guest_employee_number);
                              setGuestId(booking.id);
                              setIsExpirePop(true);
                            }}
                          >
                            변경
                          </button>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      props.history.replace(`/guest/history/${booking.id}/detail`);
                    }}
                  >
                    {booking.checkout_at}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>{' '}
      {/*table*/}
      <div className="board_paging">
        <ul>
          <Pagination
            offset={store.offset}
            limit={store.limit}
            setOffset={(offset: number) => store.setOffset(offset)}
            totalPage={store.totalPage}
          />
        </ul>
      </div>{' '}
      {/*board_paging*/}
      <div className="submit">
        {/*<div className="fl">
                    {headerStore.downRoleFlag ? <button className="btn btn_lg btn_gray" onClick={onClickDownload}>다운로드</button> : <></>}
                                </div>*/}
        <div className="fl">
          {(userInfo.classification === 'HMC' ||
            userInfo.classification === 'HEC' ||
            userInfo.classification === 'ETC') &&
          headerStore.writeRoleFlag &&
          tmpRoomType &&
          tmpRoomType.length > 0 ? (
            <button
              className="btn_lg btn_darkblue"
              onClick={async () => {
                setIsRoomTypePop(true);
              }}
            >
              신규등록
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <h4>입숙자 통계 {getTodayDate()[0]} 기준</h4>
      <div className="table_normal_list">
        <table>
          <caption className="sr_only">입숙자 통계</caption>
          <colgroup>
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
            <col width="*" />
          </colgroup>
          <thead>
            <tr>
              <th rowSpan={3}>숙소유형</th>
              <th colSpan={4}>숙소현황</th>
              <th colSpan={7}>이용자현황</th>
            </tr>
            <tr>
              <th rowSpan={2}>총 호실</th>
              <th rowSpan={2}>이용중</th>
              <th rowSpan={2}>이용가능</th>
              <th rowSpan={2}>이용불가</th>
              <th rowSpan={2}>수용인원</th>
              <th rowSpan={2}>이용인원</th>
              <th colSpan={3}>사내임직원</th>
              <th className="bdl" rowSpan={2}>
                계열사 임직원
              </th>
              <th rowSpan={2}>외부인</th>
            </tr>
            <tr>
              <th>사내임직원 A조</th>
              <th>사내임직원 B조</th>
              <th>사내임직원 C조</th>
            </tr>
          </thead>
          <tbody>
            {/*데이타 없을 시*/}
            {Object.keys(store.list).length === 0 ? (
              <tr>
                <td colSpan={12} className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
                </td>
              </tr>
            ) : (
              store.list.map((item: any, idx: number) =>
                item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar ? (
                  <tr key={idx}>
                    <td>{ROOM_TYPE[item.room_type]}</td>
                    <td>{item.checkin_room_total}</td>
                    <td>
                      <span className="point green">{item.checkin_using}</span>
                    </td>
                    <td>
                      <span className="point blue">{item.checkin_room_available}</span>
                    </td>
                    <td>
                      <span className="point red">{item.checkin_room_unavailable}</span>
                    </td>
                    <td>{item.checkin_room_capacity}</td>
                    <td>{item.checkin_room_guest}</td>
                    <td>{item.checkin_corporation_a}</td>
                    <td>{item.checkin_corporation_b}</td>
                    <td>{item.checkin_corporation_c}</td>
                    <td>{item.checkin_subsidiary}</td>
                    <td>{item.checkin_public}</td>
                  </tr>
                ) : (
                  ''
                ),
              )
            )}
          </tbody>
          <tfoot>
            <tr>
              <th>비율</th>
              <td>
                <span>100%</span>
              </td>
              <td>
                <span className="point green">
                  {store.list.length > 0 ? parseFloat(store.calcular['cal1']).toFixed(1) : '0'}%
                </span>
              </td>
              <td>
                <span className="point blue">
                  {store.list.length > 0 ? parseFloat(store.calcular['cal2']).toFixed(1) : '0'}%
                </span>
              </td>
              <td>
                <span className="point red">
                  {store.list.length > 0 ? parseFloat(store.calcular['calX']).toFixed(1) : '0'}%
                </span>
              </td>
              <td>
                <span>100%</span>
              </td>
              <td>
                <span>{store.list.length > 0 ? parseFloat(store.calcular['cal4']).toFixed(1) : '0'}%</span>
              </td>
              <td>
                <span>{store.list.length > 0 ? parseFloat(store.calcular['cal5']).toFixed(1) : '0'}%</span>
              </td>
              <td>
                <span>{store.list.length > 0 ? parseFloat(store.calcular['cal6']).toFixed(1) : '0'}%</span>
              </td>
              <td>
                <span>{store.list.length > 0 ? parseFloat(store.calcular['cal7']).toFixed(1) : '0'}%</span>
              </td>
              <td>
                <span>{store.list.length > 0 ? parseFloat(store.calcular['cal8']).toFixed(1) : '0'}%</span>
              </td>
              <td>
                <span>{store.list.length > 0 ? parseFloat(store.calcular['cal9']).toFixed(1) : '0'}%</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>{' '}
      {/*table*/}
      {/* 신규 등록 팝업 */}
      <div
        id="pop_alertsample"
        className="pop_dim_wrap pop_modal pop_alert"
        style={{ display: isRoomTypePop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <button className="pop_btn_close pop_close" onClick={() => setIsRoomTypePop(false)}>
            닫기
          </button>

          <div className="message_table">
            <div className="messageWrap">
              등록하실 숙소유형을 선택해주세요
              <select
                name=""
                id=""
                className="form_control"
                value={store.insertRoomType}
                onChange={(e: any) => store.setRoomType(Number(e.target.value))}
              >
                {/* <option value={-1}>숙소유형선택</option> */}
                {Object.keys(ROOM_TYPE).map((key: string, index: number) => {
                  if (
                    Number(key) !== 0 &&
                    Number(key) !== 7 &&
                    Number(key) !== 1 &&
                    Number(key) !== 3 &&
                    store.roomType.indexOf(key) >= 0
                  ) {
                    return (
                      <option key={index} value={key}>
                        {ROOM_TYPE[Number(key)]}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          </div>
          <div className="button_wrap">
            <button type="button" className="btn_pop_default btn_darkblue" onClick={goInsertPage}>
              확인
            </button>
          </div>
        </div>
      </div>
      {/* 다운로드 사유 팝업 */}
      <div
        id="pop_download"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isShowDownloadPop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => {
                setDownloadText('');
                setIsShowDownloadPop(false);
              }}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
                <dt>사유</dt>
                <dd>
                  <textarea
                    className="form_control"
                    value={downloadText}
                    onChange={(e: any) => setDownloadText(e.target.value)}
                  ></textarea>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_lg btn_darkblue pop_close"
              onClick={() => {
                xlsDownload();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
      {/* 만료일 변경 팝업 */}
      <div
        id="pop_alertsample2"
        className="pop_dim_wrap pop_modal pop_alert"
        style={{ display: isExpirePop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <button
            className="pop_btn_close pop_close"
            onClick={() => {
              expireInit();
              setIsExpirePop(false);
            }}
          >
            닫기
          </button>

          <div className="message_table">
            <div className="messageWrap">
              만료일을 선택해주세요
              <dl className="dateonly">
                <dt></dt>
                <dd>
                  {/* <DatePicker
                                    value={updateExDate}
                                    width={250}
                                    onChange={(date: string) => setUpdateExDate(date)}
                                    isPrevMonthView={true}
                                    min={getTodayDate()[0]}
                                /> */}
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    value={updateExDate}
                    onChange={(date: string) => {
                      setUpdateExDate(date);
                    }}
                  ></CustomCalendar>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap">
            <button
              type="button"
              className="btn_pop_default btn_darkblue"
              onClick={() => {
                updateExpireDate();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </TempleteDefault>
  );
});

export default LodgedHistory;
