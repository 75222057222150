import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;


export interface GetManagerPayload {
  is_active?: boolean;
  is_registered?: boolean;
  gender?: number;
  work_site?: string;
  progress?: number;
  
  limit?: number;
  offset?: number;
}

export interface CreatedAdminPayload {
  company: string;
  name: string;
  work_site: number;
  cellphone: string;
  employee_number: string;
}

export interface GetAuthorizePayload {
  adminId: number;
}

export interface CreateAuthorizedPayload {
  worksite: number[];
  roomtype: number[];
  menu: number[];
  building: number[];
}

class Manager {
  static URL = `${IP}`;


  //계정및권한관리 조회
  @apiHandler()
  static async getManagers(payload: GetManagerPayload) {
    const PATH = '/admin/auth/manager/';
    return await axios.get(`${Manager.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async createdAdmin(payload: CreatedAdminPayload) {
    const PATH = '/admin/auth/manager/';
    return await axios.post(`${Manager.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getAuthorize({adminId}: GetAuthorizePayload) {
    const PATH = `/admin/auth/manager/${adminId}/authorize/`;
    return await axios.get(`${Manager.URL}${PATH}`, {
      // params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async createAuthorizedPayload({adminId}: GetAuthorizePayload, payload: CreateAuthorizedPayload) {
    const PATH = `/admin/auth/manager/${adminId}/authorize/`;
    return await axios.post(`${Manager.URL}${PATH}`, payload, {
      // params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

}

export default Manager;
