import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Audit {
  static URL = `${IP}`;

  /**
   * 정기서류 내역 조회
   * @param payload
   */
  @apiHandler()
  static async getAuditList(payload: any) {
    const PATH = `/admin/board/audit/`;

    return await axios.get(`${Audit.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기서류 상세 조회
  @apiHandler()
  static async getAudit({ id }: { id: string }) {
    const PATH = `/admin/board/audit/${id}/`;

    return await axios.get(`${Audit.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기서류 상세 조회
  @apiHandler()
  static async getAuditExhibit(payload: any) {
    const PATH = `/admin/board/audit/exhibit/`;

    return await axios.get(`${Audit.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기서류 생성
  @apiHandler()
  static async createAudit(payload: any) {
    const PATH = `/admin/board/audit/`;

    return await axios.post(`${Audit.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기서류 생성
  @apiHandler()
  static async updateAudit(id: string, payload: any) {
    const PATH = `/admin/board/audit/${id}/`;

    return await axios.patch(`${Audit.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 삭제
  @apiHandler()
  static async deleteAudit(id: string) {
    const PATH = `/admin/board/audit/${id}/`;

    return await axios.delete(`${Audit.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async approveAudit(id: string) {
    const PATH = `/admin/board/audit/exhibit/${id}/approve/`;

    return await axios.post(`${Audit.URL}${PATH}`, null, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async rejectAudit(id: string, payload: any) {
    const PATH = `/admin/board/audit/exhibit/${id}/reject/`;

    return await axios.post(`${Audit.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async rollbackAudit(id: string) {
    const PATH = `/admin/board/audit/exhibit/${id}/rollback/`;

    return await axios.post(`${Audit.URL}${PATH}`, null, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기서류검사 등록현황 다운로드
  @apiHandler()
  static async fileDownExhibitexcel(payload: any) {
    const PATH = '/deduct/exhibitexcel/?';
    return await axios.get(`${Audit.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 정기서류검사 등록현황 비고 업데이트
  @apiHandler()
  static async updateExhibitNote(id: string, payload: any) {
    const PATH = `/admin/board/audit/exhibit/${id}/update_note/`;

    return await axios.post(`${Audit.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Audit;
