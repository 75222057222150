import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import DefaultTemplate from '../../../components/templetes/default';
import { useStores } from '../../../store/mobx';
import { ROOM_TYPE, ROOM_TYPE_CODE, WORK_SITE } from '../../../constants';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import { formatByString, getTodayDate } from '../../../utils/date';
import DatePicker from '../../../components/organismas/input/datepicker';
import UploadFileList from '../uploadFileList';

const CompanyType = observer((props: any) => {
    const {lodgedHistoryStore: store} = useStores();
    const {headerStore: headerStore} = useStores();
    const baseUrl = '/guest/history';
    
    const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    // console.log(store.insertStore.item);
  
    const subStore = store.insertStore;
    const guest = store.insertStore.item;
  
    const goBack = () => props.history.replace(baseUrl);
  return (
    <>
      <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
      >
        <h4>기본정보</h4>
        <ul className="lst_status">
          <li>
            <strong>등록일자</strong>
            <span>{formatByString(getTodayDate()[0], 'yyyy.MM.dd')}</span>
          </li>
          <li>
            <strong>숙소유형</strong>
            <span>{ROOM_TYPE[store.insertRoomType]}</span>
          </li>
          <li>
            <strong>신청지역</strong>
            <span>{WORK_SITE[store.user.work_site]}</span>
          </li>
          <li>
            <strong>배방정보</strong>
            <span className="room">
              <em>102동 506</em>
              <button type="button" className="btn btn_xs btn_darkblue">배방</button>
            </span>
          </li>
        </ul>

        <h4>이용자정보</h4>
        <div className="table_normal_list table_write form">
          <table>
          <caption className="sr_only">이용자정보</caption>
          <colgroup>
            <col width="12.5%"/>
            <col width="*"/>
            <col width="12.5%"/>
            <col width="*"/>
            <col width="12.5%"/>
            <col width="*"/>
          </colgroup>
          <tbody>
          {/*데이타 없을 시*/}
          {/*<tr>
              <td colspan="" className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
              </td>
          </tr>*/}
          <tr>
            <th><span className="emp_red">사번</span></th>
            <td>
              <div className="form_wrap">
                <input type="text" className="form_control"
                  value={guest.guest_company}
                  onChange={(e: any) => guest.guest_company = e.target.value}/>
                <button className="btn_sm btn_gray">적용</button>
              </div>
            </td>
            <th><span className="emp_red">성명</span></th>
            <td>
              <input type="text" className="form_control"
                value={guest.guest_name}
                onChange={(e: any) => guest.guest_name = e.target.value}/>
            </td>
            <th><span className="emp_red">휴대폰번호</span></th>
            <td>
              <input type="text" className="form_control"
                value={guest.guest_cellphone}
                onChange={(e: any) => guest.guest_cellphone = e.target.value}/>
            </td>
          </tr>
          <tr>
            <th><span className="emp_red">성별</span></th>
            <td>
              <div className="radio_box">
                <label className="input_radio" htmlFor="gender_man">
                  <input type="radio" id="gender_man" name="gender"
                    checked={guest.guest_gender === 1 ? true : false}
                    onChange={e => guest.guest_gender = e.target.checked ? 1 : 2}
                  />
                  <span className="label_text">남</span>
                </label>
                <label className="input_radio" htmlFor="gender_woman">
                  <input type="radio" id="gender_woman" name="gender"
                    checked={guest.guest_gender === 2 ? true : false}
                    onChange={e => guest.guest_gender = e.target.checked ? 2 : 1}
                  />
                  <span className="label_text">여</span>
                </label>
              </div>
            </td>
            <th><span className="emp_red">소속부서</span></th>
            <td>
              <input type="text" className="form_control"
                value={guest.guest_department}
                onChange={(e: any) => guest.guest_department = e.target.value}/>
            </td>
            <th>차량번호1</th>
            <td>
              <input type="text" className="form_control"
                value={guest.guest_car_number1}
                onChange={(e: any) => guest.guest_car_number1 = e.target.value}/>
            </td>
          </tr>
          <tr>
            <th>차량번호2</th>
            <td>
              <input type="text" className="form_control"
                value={guest.guest_car_number2}
                onChange={(e: any) => guest.guest_car_number2 = e.target.value}/>
            </td>
            <th>차량번호3</th>
            <td>
              <input type="text" className="form_control"
                value={guest.guest_car_number3}
                onChange={(e: any) => guest.guest_car_number3 = e.target.value}/>
            </td>
            <th><span className="emp_red">사내부부여부</span></th>
            <td>
              <div className="radio_box">
                <label className="input_radio" htmlFor="couple_yes">
                  <input type="radio" id="couple_yes" name="couple"
                    checked={guest.is_cc}
                    onChange={e => guest.is_cc = e.target.checked}
                  />
                  <span className="label_text">여</span>
                </label>
                <label className="input_radio" htmlFor="couple_no">
                  <input type="radio" id="couple_no" name="couple"
                    checked={guest.is_cc}
                    onChange={e => guest.is_cc = e.target.checked}
                  />
                  <span className="label_text">부</span>
                </label>
              </div>
            </td>
          </tr>

          <tr>
            <th>입숙예정일</th>
            <td className="br0">
              <div className="datepicker_searchwrap form_inline">
                <DatePicker
                  value={guest.stay_after}
                  width={250}
                  onChange={(date: string) => guest.stay_after = date}
                  isPrevMonthView={true}
                />
              </div>
            </td>
            <th><span className="emp_red">주거지원금 수혜여부</span></th>
            <td className="br0">
              <div className="radio_box">
                <label className="input_radio" htmlFor="beneficiary_yes">
                  <input type="radio" id="beneficiary_yes" name="beneficiary"
                    checked={guest.is_supported}
                    onChange={e => guest.is_supported = e.target.checked}
                  />
                  <span className="label_text">여</span>
                </label>
                <label className="input_radio" htmlFor="beneficiary_no">
                  <input type="radio" id="beneficiary_no" name="beneficiary"
                    checked={guest.is_supported}
                    onChange={e => guest.is_supported = e.target.checked}
                  />
                  <span className="label_text">부</span>
                </label>
              </div>
            </td>
            <td className="blank" colSpan={2}>&nbsp;</td>
          </tr>
          </tbody>
          </table>
        </div>  {/*table*/}

        {subStore.documents.length > 0 ? (
          <UploadFileList store={subStore} />
        ) : <></>}

        <div className="submit">
          <button type="button" className="btn btn_lg btn_gray fl" onClick={() => props.history.replace('/guest/history')}>목록</button>
          <button type="button" className="btn btn_lg btn_darkblue fr">완료</button>
        </div>

      </DefaultTemplate>
    </>
  );
});

export default CompanyType;
