import { observer } from 'mobx-react';
import React from 'react';

import DefaultTemplate from '../../../components/templetes/default';
import FaqDetail from '../../../components/templetes/faq/detail';
import { useStores } from '../../../store/mobx';

const FaqDetailPage = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <FaqDetail isEdit={true} {...props} />
    </DefaultTemplate>
  );
});

export default FaqDetailPage;
