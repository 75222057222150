import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import DefaultTemplate from '../../../components/templetes/default';
import { useStores } from '../../../store/mobx';
import { GUEST_TYPE, ROOM_TYPE, ROOM_TYPE_CODE, WORK_SITE } from '../../../constants';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import { includes, isNull } from 'lodash';
import { formatByString, getTodayDate } from '../../../utils/date';
import DatePicker from '../../../components/organismas/input/datepicker';
import { auth } from '../../../apis';
import SelectCompany from '../../../components/molecules/selectCompany';
import UploadFileList from '../uploadFileList';
import VipBackRoom from '../../../components/organismas/popup/vipBackroom';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TAB, CLICK_TAB } from '../../../store/modules/base';
import CustomCalendar from '../../../components/organismas/customCalendar';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const VipType = observer((props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const { lodgedHistoryStore: store } = useStores();
  const { applicantJudgeStore: roomStore } = useStores();
  const { headerStore: headerStore } = useStores();
  const baseUrl = '/guest/history';

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [roomName, setRoomName] = useState<string>('');
  const [newEmployeeNumber, setNewEmployeeNumber] = useState<string>('');

  const subStore = store.insertStore;
  const guest = store.insertStore.item;

  useEffect(() => {
    subStore.workSite = headerStore.workSite;
    
    if (Number(headerStore.workSite) === 2) {
      if (!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
        roomStore.paramAuthBuildingRole = headerStore.paramAuthBuildingRole;
      }
    }
    
    // 사내임직원으로 초기값 세팅
    store.insertStore.item.files = [];
    subStore.setGuestType(guest.guest_type ? guest.guest_type : 1, ROOM_TYPE_CODE.vip_room);

    if (guest.room) {
      setRoomName('');
      guest.room = -1;
    }
  }, [headerStore.workSite]);

  const goBack = () => props.history.replace(baseUrl);

  const onApplyProfile = async () => {
    try {
      headerStore.isLoading = true;
      const { data } = await auth.getProfileByMemberEmployeeNumber({ employeeNumber: guest.guest_employee_number });
      
      if (data) {
        setNewEmployeeNumber(guest.guest_employee_number);
        guest.guest_cellphone = data.cellphone;
        guest.guest_name = data.name;
        //guest.guest_gender = data. personnel.gender === 1 ? '남' : '여';
        guest.guest_gender = data.personnel.gender;
        guest.guest_department = data.personnel.department;
        if (guest.room) {
          setRoomName('');
          guest.room = -1;
        }
      } else {
        info("정확한 사번을 입력해주세요");
        store.insertStore.newVipRoom();
        if (guest.room) {
          setRoomName('');
          guest.room = -1;
        }
      }
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("정확한 사번을 입력해주세요");
      store.insertStore.newVipRoom();
      store.insertStore.setGuestType(1, ROOM_TYPE_CODE.vip_room);
      if (guest.room) {
        setRoomName('');
        guest.room = -1;
      }
    }

    // address1: "전라북도 안산시 상록구 학동가 (은지이마을) 상현김이아파트 P동 921호"
    // address2: "울산광역시 광진구 가락거리 (영길박동) 광수조안타워 476호"
    // birthdate: "160226"
    // department: "운영부"
    // gender: 1
    // is_married: false
    // joined_at: "2002-05-11"
    // occupation: "별정직"
    // position: "매니저"
    // rank: "대리"
    // working_period: 13
    // zipcode1: "84587"
    // zipcode2: "30586"
  };

  const guestInit =() => {
    store.insertStore.item = {
      stay_after: '',
      stay_before: '',
      room: -1,
      files: [],
      guest_name: '',
      guest_cellphone: '',
      guest_gender: 1,
      guest_company: '',
      guest_employee_number: '',
      guest_department: '',
      guest_car_number1: ''
    };
  }

  // 배방 팝업 
  const openRoomPop = () => {
    if (!guest.guest_type || !guest.guest_name || !guest.guest_cellphone || !guest.guest_gender || !guest.stay_after || !guest.stay_before) {
      info("이용자 정보를 입력해주세요");
      return false;
    }

    // 배방 정보 가져올때 필요한 데이터
    roomStore.selectRow = guest;
    roomStore.workSite = headerStore.workSite;
    roomStore.selectRow.room_work_site = headerStore.workSite;
    roomStore.selectRow.room_type = ROOM_TYPE_CODE.vip_room;
    roomStore.selectRow.id = 1; // 임의로 만든 id
    roomStore.manageCheckin = true;

    // 배방한 사업장 
    subStore.workSite = headerStore.workSite;

    roomStore.isVipRoomPop = true;
  }

  // 배방 완료 후, 방정보 가져오기
  const setRoomInfo = (room: any) => {
    guest.room = room.id;
    setRoomName(room.building_name + ' ' + room.name);
  }

  // 완료 버튼 클릭
  const onSave = async () => {
    if (!guest.guest_type || !guest.guest_name || !guest.guest_cellphone || !guest.guest_gender
      || !guest.stay_after || !guest.stay_before) {
      info("이용자 정보를 입력해주세요");
      return false;
    } else if (guest.guest_type === 1 && (!guest.guest_employee_number || !guest.guest_department)) {
      info("이용자 정보를 입력해주세요");
      return false;
    } else if(guest.guest_type === 1 && guest.guest_employee_number !== newEmployeeNumber) {
      info("사번(아이디) 적용버튼을 눌러주세요");
      return false;
    } else if (guest.guest_type === 2 && (!guest.guest_company || !guest.guest_department)) {
      info("이용자 정보를 입력해주세요");
      return false;
    } else if (guest.guest_cellphone.length !== 11) {
      info("휴대폰 번호를 확인해주세요");
      return false;
    }

    let isFileUploaded = store.insertStore.item.files.filter((file: any) => file.id < 1 && file.is_required)

    if (isFileUploaded.length) {
      info("필수 첨부파일을 업로드해주세요");
      return;
    }

    if (!guest.room || guest.room < 0) {
      info("배방을 진행해주세요");
      return;
    }

    try {
      headerStore.isLoading = true;
      guest.applicant_employee_number = userInfo.employeeNumber;
      let data : any = await store.insertStore.vipRoomBooked();
      headerStore.isLoading = false;
      setTimeout(function () {
        if(data.save) {
          info("신규등록이 완료되었습니다");
          store.init();
          roomStore.init();
          roomStore.roomInit();
          //props.history.replace('/guest/history');
          addTabHandler('/applicant/judge');
        }else {
          console.log('duplicate_guest : ', data);
          if (data.data.code === "already_exists") {
          info('이미 이용중인 숙소가 있습니다.');
          return;
        } else if (data.data.code === "duplicate_guest") {
          info('이미 이용중인 숙소가 있습니다.');
          return;
        }else{
          info('등록 중 문제가 발생했습니다');
          return;
        }
      }
    }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (data.code === "already_exists") {
        info('이미 이용중인 숙소가 있습니다.');
        return;
      } else if (data.code === "duplicate_guest") {
        info('이미 이용중인 숙소가 있습니다.');
        return;
      }

      info('등록 중 문제가 발생했습니다');
      return;
    }
  }

  // tab 이동용 --------------------
  const compMenu = (val: string) => {
    const vals = val.split('/');
    if (vals.length > 2) {
      return vals.slice(0, 3).join('/');
    } else {
      return val;
    }
  }

  const m: { [key: string]: number } = {
    '/applicant/judge': 0
  }

  const dispatch = useDispatch();
  
  const { opened = [], select = -1 } = useSelector((state: any) => ({
    opened: state.base.tab.opened,
    select: state.base.tab.select,
  }));
  
  const DEFAULT_TAB_PATH = '/home';

  const addTabHandler = (path = DEFAULT_TAB_PATH) => {
    const idx = opened.findIndex((item: string) => compMenu(item) === compMenu(path));
    // console.log('testing...');
    if (idx < 0) {
      dispatch({
        type: ADD_TAB,
        path,
      });
      // props.history.replace(path);
    } else {
      dispatch({
        type: CLICK_TAB,
        select: idx,
      });
    }
  };

  return (
    <>
      <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
      >
        <h4>기본정보</h4>
        <ul className="lst_status">
          <li>
            <strong>등록일자</strong>
            <span>{formatByString(getTodayDate()[0], 'yyyy.MM.dd')}</span>
          </li>
          <li>
            <strong>숙소유형</strong>
            <span>{ROOM_TYPE[store.insertRoomType]}</span>
          </li>
          <li>
            <strong>신청지역</strong>
            <span>{WORK_SITE[subStore.workSite]}</span>
          </li>
          <li>
            <strong>배방정보</strong>
            <span className="room">
              <em>{roomName}</em>
              <button type="button" className="btn btn_xs btn_darkblue" onClick={() => openRoomPop()}>배방</button>
            </span>
          </li>
        </ul>

        <h4>이용자정보</h4>
        <div className="table_normal_list table_write form">
          <table>
            <caption className="sr_only">이용자정보</caption>
            <colgroup>
              <col width="12.5%" />
              <col width="*" />
              <col width="12.5%" />
              <col width="*" />
              <col width="12.5%" />
              <col width="*" />
            </colgroup>
            <tbody>
              {/*데이타 없을 시*/}
              {/*<tr>
              <td colspan="" className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
              </td>
          </tr>*/}
              <tr>
                <th><span className="emp_red">소속 회사 유형</span></th>
                <td>
                  <select className="form_control"
                    value={guest.guest_type}
                    onChange={(e: any) => {
                      guestInit();
                      subStore.setGuestType(Number(e.target.value), ROOM_TYPE_CODE.vip_room);
                      //store.insertStore.newVipRoom();
                    }}
                  >
                    {Object.keys(GUEST_TYPE).map((key: string) =>
                      <option key={key} value={key}>{GUEST_TYPE[key]}</option>
                    )}
                  </select>
                </td>
                <th><span className={guest.guest_type === 2 ? "emp_red" : ''}>회사명(단체명)</span></th>
                <td>
                  {guest.guest_type === 2
                    ? <SelectCompany
                      value={guest.guest_company}
                      onChange={(e: any) => guest.guest_company = e.target.value} />
                    : <></>}
                </td>
                <th><span className={guest.guest_type === 1 ? "emp_red" : ''}>사번(아이디)</span></th>
                <td>
                  {guest.guest_type !== 3 ?
                    <>
                      <div className="form_wrap">
                        <input type="text" className="form_control"
                          maxLength={7}
                          value={guest.guest_employee_number}
                          onChange={(e: any) => guest.guest_employee_number = getEscapeString(e.target.value, INPUT_TYPE.employee)}
                        />
                        <button className="btn_sm btn_gray" onClick={onApplyProfile} style={{ display: guest.guest_type === 1 ? '' : 'none' }}>적용</button>
                      </div>
                    </> : <></>}
                </td>
              </tr>
              <tr>
                <th><span className="emp_red">성명</span></th>
                <td>
                  <input type="text" className="form_control"
                    maxLength={10}
                    value={guest.guest_name}
                    onChange={(e: any) => guest.guest_name = e.target.value} />
                </td>
                <th><span className="emp_red">휴대폰번호</span></th>
                <td>
                  <input type="text" className="form_control"
                    maxLength={11}
                    value={guest.guest_cellphone}
                    onChange={(e: any) => guest.guest_cellphone = getEscapeString(e.target.value, INPUT_TYPE.phoneNum)} />
                </td>
                <th><span className="emp_red">성별</span></th>
                <td>
                  <div className="radio_box">
                    <label className="input_radio" htmlFor="gender_man">
                      <input type="radio" id="gender_man" name="gender"
                        checked={guest.guest_gender === 1 ? true : false}
                        onChange={e => guest.guest_gender = e.target.checked ? 1 : 2}
                        disabled={guest.guest_type === 1}
                      />
                      <span className="label_text">남</span>
                    </label>
                    <label className="input_radio" htmlFor="gender_woman">
                      <input type="radio" id="gender_woman" name="gender"
                        checked={guest.guest_gender === 2 ? true : false}
                        onChange={e => guest.guest_gender = e.target.checked ? 2 : 1}
                        disabled={guest.guest_type === 1}
                      />
                      <span className="label_text">여</span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th><span className={guest.guest_type !== 3 ? "emp_red" : ''}>소속부서</span></th>
                <td>
                  <input type="text" className="form_control"
                    maxLength={20}
                    value={guest.guest_department}
                    onChange={(e: any) => guest.guest_department = e.target.value} />
                </td>
                <th><span className="emp_red">입숙예정일</span></th>
                <td>
                  {/* <DatePicker
                    value={guest.stay_after}
                    width={250}
                    onChange={(date: string) => guest.stay_after = date}
                    isPrevMonthView={true}
                    min={getTodayDate()[0]}
                    max={guest.stay_before ? guest.stay_before : ''}
                  /> */}
                  <CustomCalendar
                      //min={getTodayDate()[0]}
                      max={guest.stay_before ? guest.stay_before : ''}
                      value={guest.stay_after}
                      onChange={(date: string) => guest.stay_after = date}
                  ></CustomCalendar>
                </td>
                <th><span className="emp_red">퇴숙예정일</span></th>
                <td>
                  {/* <DatePicker
                    value={guest.stay_before}
                    width={250}
                    onChange={(date: string) => guest.stay_before = date}
                    isPrevMonthView={true}
                    min={guest.stay_after ? guest.stay_after : getTodayDate()[0]}
                  /> */}
                  <CustomCalendar
                      min={guest.stay_after ? guest.stay_after : ''}
                      value={guest.stay_before}
                      onChange={(date: string) => guest.stay_before = date}
                  ></CustomCalendar>
                </td>
              </tr>
              <tr>
                <th>차량번호</th>
                <td>
                  <input type="text" className="form_control"
                    maxLength={12}
                    value={guest.guest_car_number1}
                    onChange={(e: any) => guest.guest_car_number1 = e.target.value} />
                </td>
                <td colSpan={4} className="blank">&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>  {/*table*/}

        {/* {subStore.documents.length > 0 ? (
          <UploadFileList store={subStore} />
        ) : <></>} */}

        <div className="submit">
          <button type="button" className="btn btn_lg btn_gray fl" onClick={() => props.history.replace('/guest/history')}>목록</button>
          <button type="button" className="btn btn_lg btn_darkblue fr" onClick={onSave}>완료</button>
        </div>

        {/* 영빈관 배방 */}
        <VipBackRoom
          setRoomInfo={setRoomInfo}
          setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
        />

      </DefaultTemplate>
    </>
  );
});

export default VipType;
