import React, { useEffect, useState } from 'react';
import ViewContent from './ViewContent';
import WriteContent from './WriteContent';
import { useStores } from '../../../../store/mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';

const AuditDetail = observer((props: any) => {
  const {auditStore: store} = useStores();
  const {headerStore : headerStore} = useStores();
  const baseUrl = '/guest/audit';


  const [isEdit, setIsEdit] = useState<any>(props.isEdit);
  const isCreate = props.isEdit && !props.match.params.id;
  

  useEffect(() => {
    // console.log(store.isChange());
    // console.log('testing...');
    headerStore.isLoading = true;
    headerStore.getAuthMenuRole('2-5');
    if (!isCreate) {
      // if (!store.isChange()) {
        
        (async () => {
          await store.getItem(props.match.params.id);
          // await store.getItem(props.match.params.id);
          headerStore.isLoading = false;

          
        })();
      // }
      //  else {
      //   headerStore.isLoading = false;
      // }
      headerStore.isLoading = false;
    } else {
      // if (!store.item) {
        if (!store.item || (store.item && !_.includes(store.item.title, '복제'))) {
          store.newItem();
        }
      // }
      headerStore.isLoading = false;
    }
  }, [headerStore.workSite]);

  const onCloneHandler = () => {
    const refId = store.orgItem.id;
    store.clone();
    
    props.history.replace(`${baseUrl}/create?refId=${refId}`);
  };

  // if (headerStore.isLoading) return <></>;

  return (
    <>
      {isEdit ? (
        <WriteContent setIsEdit={setIsEdit} {...props} />
      ) : (
        <ViewContent setIsEdit={setIsEdit} onCopy={onCloneHandler}  {...props} />
      )}
    </>
  );
});

export default AuditDetail;
