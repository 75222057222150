import { useEffect, useState } from 'react';

import {
  manager, 
} from '../../apis';

import {
  GetManagerPayload
} from '../../apis/manager';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

export interface AdminInterface {
  id: number;
  
  employee_number: string;
  name: string;
  worksite: string;
  company: string;
  birthdate: string;
  gender: number;
  cellphone: string;
  duty: string;
  
  is_active: boolean;
  is_registered: boolean
}

const useAdminManager  = () => {
  // filter
  const [isActive, setIsActive] = useState<number>(-1);
  const [isRegisterd, setIsRegistered] = useState<number>(-1);
  const [workSite, setWorkSite] = useState<number>(-1);
  const [gender, setGender] = useState<number>(-1);

  // default filter
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [reload, setReload] = useState<boolean>(false);

  const [admins, setAdmins] = useState<AdminInterface[]>([]);

  useEffect(() => {
    ;(async () => {
      try {
        let payload: GetManagerPayload = {
          limit,
          offset,
        }
        if (isActive > -1) payload.is_active = isActive === 1 ? true : false;
        if (isRegisterd > -1) payload.is_registered = isRegisterd === 1 ? true : false;
        if (workSite > -1) payload.work_site = workSite+'';
        if (gender > -1) payload.gender = gender;

        let {data}  = await manager.getManagers(payload);

        setTotalPage(Math.ceil(data.count / limit));
        setAdmins(data.results);
      }catch ({ status, data }) {
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
      }
    })()
  }, [offset, limit, reload, isActive, isRegisterd, workSite, gender]);

  return {
    admins,
    
    isActive,
    isRegisterd,
    workSite,
    gender,

    setIsActive,
    setIsRegistered,
    setWorkSite,
    setGender,
    
    totalPage,
    offset,
    limit,
    setOffset,
  }
}

export default useAdminManager;