import { isNull } from 'lodash';
import {action, makeObservable, observable} from 'mobx';
import * as apis from '../../apis';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { BoardStore } from './boardStore';
import { HeaderStore } from './headerStore';

export class PledgeStore{

    work_site : any;
    room_type : any;
    getHisPledge : any = [];

    //사업장및 룸타입 권한때문에 작성
    paramAuthRoomTypeRole : any;
    paramAuthWorkSiteRole : any;
    paramAuthBuildingRole : any;

    //화면이 함수보다 먼저타서 선언해줌
    reloadTest: boolean = false;

    //loading
    headerStore : HeaderStore;

    constructor(root: any) {
      this.headerStore = new HeaderStore(this);
      makeObservable(this, {
        work_site : observable,
        room_type : observable,
        paramAuthRoomTypeRole : observable,
        paramAuthWorkSiteRole : observable,
        paramAuthBuildingRole : observable,
        getHisPledge : observable,
        reloadTest : observable,
        
        getHistoryPledge : action,
      });
    }


      //입숙서약서 히스토리 불러오기
      async getHistoryPledge(){

        const payload : any = {
            work_site : this.work_site,
            room_type : this.room_type
        }

        if(Number(this.work_site) === 2) {
            if(!isNull(this.paramAuthBuildingRole) && this.paramAuthBuildingRole !== undefined && this.paramAuthBuildingRole.length > 0) {
              payload.authBuilding = this.paramAuthBuildingRole
            }
          }

        try {
          this.headerStore.isLoading = true;
          const { data } = await apis.pledge.getHistoryPledges(payload);
          this.getHisPledge = data;

          this.reloadTest = !this.reloadTest;
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }

          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }
      }
}