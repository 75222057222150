import _, { forEach, map } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { dateDiff, getCountDaysByMonth, getTodayDate } from '../../utils/date';
import { BoardStore } from './boardStore';
import { addMonths, differenceInCalendarDays, format } from 'date-fns';
import { getOverlappingInterval } from '../../utils/common';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class DeductUlsanStore extends BoardStore {
  static BEDDING_AMT = 1;
  step = 1;
  year = '';
  month = '';
  electric = 0;
  water = 0;
  gas = 0;
  department = '';
  employee_number = '';
  guest_company = '';
  isDeductList = false;
  is_finalize = 'N';
  saveBtnFalg = true;

  //loading
  headerStore: HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      step: observable,
      year: observable,
      month: observable,
      electric: observable,
      water: observable,
      gas: observable,
      department: observable,
      employee_number: observable,
      guest_company: observable,
      isDeductList: observable,
      is_finalize: observable,

      initDatas: action,
      getItems: action,
      getUlsanDeductSelect: action,
      getMonthutil: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      calcPrics: action,
      setMonth: action,
      setYear: action,
      saveUtil: action,
      saveUlsan: action,
    });

    this.initDatas();
  }

  initDatas() {
    const date = format(addMonths(new Date(getTodayDate()[0]), -1), 'yyyy-MM-dd').split('-');
    this.year = date[0];
    this.month = date[1];
  }

  init() {
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.item = null;
    this.orgItem = null;
  }

  destory() {
    super.destory();
  }

  //월 체인지시 값 세팅
  async setMonth(month: string) {
    this.month = month;
    if (this.step === 1) {
      this.headerStore.isLoading = true;
      await this.getItems();
      this.headerStore.isLoading = false;
    } else {
      this.headerStore.isLoading = true;
      await this.getUlsanDeductList();
      if (this.items.length === 0) {
        await this.getUlsanDeductSelect();
      }
      this.headerStore.isLoading = false;
    }
  }

  //년 체인지시 값 세팅
  async setYear(year: string) {
    this.year = year;
    if (this.step === 1) {
      this.headerStore.isLoading = true;
      await this.getItems();
      this.headerStore.isLoading = false;
    } else {
      this.headerStore.isLoading = true;
      await this.getUlsanDeductList();
      if (this.items.length === 0) {
        await this.getUlsanDeductSelect();
      }
      this.headerStore.isLoading = false;
    }
  }

  @debounce(500)
  async getItems() {
    if (this.items.length === 0) {
      // console.log('getItems: ', this.search, this.limit, this.offset);
      const filter: any = {
        meter_date: `${this.year}${this.month}`,
      };

      try {
        this.headerStore.isLoading = true;

        const { data } = await apis.deduct.gets('monthutil', filter);
        this.items = data;
        this.headerStore.isLoading = false;
      } catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem('isRetryLogin')) {
            window.localStorage.setItem('isRetryLogin', 'retry');
          }
          window.location.href = '/';
          return;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return;
        } else if (status === 500) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      }
    }
  }

  async getUlsanUtilItems() {
    const filter: any = {
      // worksite: '울산',
      apply_date: `${this.year}${this.month}`,
    };

    if (this.department) {
      filter.department = this.department;
    }

    if (this.employee_number) {
      filter.employee_number = this.employee_number;
    }
    try {
      const { data } = await apis.deduct.gets('ulsanutil', filter);

      if (data.length > 0) {
        this.item = data[0];
        this.electric = this.item.electric;
        this.gas = this.item.gas;
        this.water = this.item.water;
        this.items = data[0].ulsanvolume;
      } else {
        this.item = null;
        this.items = [];
        this.electric = 0;
        this.gas = 0;
        this.water = 0;
      }
      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  
  async getUlsanDeductSelect() {
    // const date = getTodayDate()[0];
    const endDay = getCountDaysByMonth(this.month);

    const filter: any = {
      worksite: '울산',
      apply_date: `${this.year}${this.month}`,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.deduct.gets('ulsandeductselect', filter);
      if (data.length > 0) {
        this.is_finalize = '';
        this.saveBtnFalg = false;
        let grpItems: any = _.groupBy(data, item => item.key);
        // 그룹: 빌딩명:동명:호명
        Object.keys(grpItems)
          .filter((key: string) => grpItems[key].length > 1)
          .map((key: string) => grpItems[key])

          .forEach(item => {
            if (item.length === 1) {
              //1인으로 사용 했을경우
              item.forEach((item: any) => {
                item.use_count = 1;
              });
            } else {
              // 2인으로 사용했을경우
              item.forEach((item: any) => {
                item.use_count = 2;
              });
            }
          });
        this.items = data.map((item: any) => {
          //숙소 사용 일수 구하기
          const deduct_days =
            differenceInCalendarDays(new Date(item.tmp_checkout_at), new Date(item.tmp_checkin_at)) + 1;
          let room_charge = item.room_charge ? Number(item.room_charge) : 0;
          let util_charge = item.util_charge ? Number(item.util_charge) : 0;
          let keyPenelty = 0;
          const use_count = item.use_count;
          const is_married = item.is_married;
          const is_key_return = item.is_key_return === 1;
          const bedding = item.bedding;
          const etc_charge = item.etc_charge;
          let married = 1;
          if (item.room_building_name === '구관') {
            keyPenelty = is_key_return ? 0 : 0;
            switch (item.room_building_dong) {
              case '가동':
              case '나동':
              case '다동':
              case '라동':
              case '마동':
                util_charge = 0;
                married = is_married ? 2 : 1;
                if (deduct_days < endDay) {
                  //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                  room_charge = Math.round(((10000 * married * use_count) / endDay) * deduct_days);
                } else {
                  //해당월 꽉채웠을경우
                  room_charge = Math.round(10000 * married * use_count);
                }
                break;
              case '사동':
                if (is_married) {
                  //기혼
                  if (use_count > 1) {
                    //2명이상 숙박
                    if (deduct_days < endDay) {
                      room_charge = (22000 / endDay) * deduct_days;
                      util_charge = (8000 / endDay) * deduct_days;
                    } else {
                      room_charge = 22000;
                      util_charge = 8000;
                    }
                  } else {
                    //1명 숙박
                    if (deduct_days < endDay) {
                      room_charge = (44000 / endDay) * deduct_days;
                      util_charge = (24000 / endDay) * deduct_days;
                    } else {
                      room_charge = 44000;
                      util_charge = 24000;
                    }
                  }
                } else {
                  //미혼
                  if (use_count > 1) {
                    if (deduct_days < endDay) {
                      room_charge = (11000 / endDay) * deduct_days;
                      util_charge = 0;
                    } else {
                      room_charge = 11000;
                      util_charge = 0;
                    }
                  } else {
                    if (deduct_days < endDay) {
                      room_charge = (22000 / endDay) * deduct_days;
                      util_charge = (8000 / endDay) * deduct_days;
                    } else {
                      room_charge = 22000;
                      util_charge = 8000;
                    }
                  }
                }
                break;
              case '아동':
              case '차동':
                if (is_married) {
                  //기혼
                  if (use_count > 1) {
                    //2명이상 숙박
                    if (deduct_days < endDay) {
                      room_charge = (20000 / endDay) * deduct_days;
                      util_charge = (6000 / endDay) * deduct_days;
                    } else {
                      room_charge = 20000;
                      util_charge = 6000;
                    }
                  } else {
                    //1명 숙박
                    if (deduct_days < endDay) {
                      room_charge = (40000 / endDay) * deduct_days;
                      util_charge = (20000 / endDay) * deduct_days;
                    } else {
                      room_charge = 40000;
                      util_charge = 20000;
                    }
                  }
                } else {
                  //미혼
                  if (use_count > 1) {
                    //2명이상 숙박
                    if (deduct_days < endDay) {
                      room_charge = (10000 / endDay) * deduct_days;
                      util_charge = 0;
                    } else {
                      room_charge = 10000;
                      util_charge = 0;
                    }
                  } else {
                    //1명숙박
                    if (deduct_days < endDay) {
                      room_charge = (20000 / endDay) * deduct_days;
                      util_charge = (6000 / endDay) * deduct_days;
                    } else {
                      room_charge = 20000;
                      util_charge = 6000;
                    }
                  }
                }
                break;
            }
          } else if (item.room_building_name === '임대아파트'){
            // 임대아파트 -> 기숙사 전환으로 인한 공제 로직 추가
            util_charge = 0;
            married = is_married ? 2 : 1;

            // 아직 미반영
            //if (deduct_days < endDay) {
            //  //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
            //  room_charge = Math.round(((60000 / use_count) / endDay) * deduct_days);
            //} else {
            //  //해당월 꽉채웠을경우
            //  room_charge = Math.round(60000 / use_count);
            //}

            if(item.quota == 1) {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((60000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(60000 * married * use_count);
              }
            }
            else if(item.quota == 2) {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((30000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(30000 * married * use_count);
              }
            }
            else if(item.quota == 3) {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((20000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(20000 * married * use_count);
              }
            }
            else {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((10000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(10000 * married * use_count);
              }
            }
            
            //if (deduct_days < endDay) {
            //  //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
            //  room_charge = Math.round(((10000 * married * use_count) / endDay) * deduct_days);
            //} else {
            //  //해당월 꽉채웠을경우
            //  room_charge = Math.round(10000 * married * use_count);
            //}
            
          } else {
            keyPenelty = is_key_return ? 0 : 0;
            if (is_married) {
              //기혼
              if (use_count > 1) {
                //2명이상 숙박
                if (deduct_days < endDay) {
                  room_charge = (29000 / endDay) * deduct_days;
                  util_charge = ((util_charge * 0.5 - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 29000;
                  util_charge = util_charge * 0.5 - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              } else {
                //1명숙박
                if (deduct_days < endDay) {
                  room_charge = (58000 / endDay) * deduct_days;
                  util_charge = ((util_charge - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 58000;
                  util_charge = util_charge - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              }
            } else {
              //미혼
              if (use_count > 1) {
                //2명이상 숙박
                if (deduct_days < endDay) {
                  room_charge = (14500 / endDay) * deduct_days;
                  util_charge = ((util_charge * 0.25 - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 14500;
                  util_charge = util_charge * 0.25 - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              } else {
                if (deduct_days < endDay) {
                  room_charge = (29000 / endDay) * deduct_days;
                  util_charge = ((util_charge * 0.5 - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 29000;
                  util_charge = util_charge * 0.5 - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              }
            }
          }

          util_charge = util_charge > 0 ? util_charge : 0;
          const bedding_charge = DeductUlsanStore.BEDDING_AMT * bedding;
          room_charge = Math.round(room_charge);
          util_charge = Math.round(util_charge);

          const total_deduct = room_charge + util_charge + bedding_charge + keyPenelty;
          const temp = { ...item };
          temp.deduct_date = `${this.year}${this.month}`;
          temp.deduct_days = deduct_days;
          temp.room_charge = room_charge;
          temp.util_charge = util_charge;
          temp.bedding_charge = bedding_charge;
          //temp.etc_charge = keyPenelty;
          temp.etc_charge = etc_charge;
          temp.total_deduct = total_deduct;
          temp.memo = '';
          temp.status = '';
          temp.close_date = '';
          temp.check_in_out = `${item.checkin_managed_at} ~ ${
            item.checkout_managed_at ? item.checkout_managed_at : ''
          }`;
          temp.dongho = `${item.room_building_name}/${item.room_building_dong}/${item.room_floor_name}/${item.room_name}`;
          temp.use_count = use_count;
          return temp;
        });
      } else {
        this.items = [];
        this.saveBtnFalg = true;
      }
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }
  
  /*
  async getUlsanDeductSelect() {
    // const date = getTodayDate()[0];
    const endDay = getCountDaysByMonth(this.month);

    const filter: any = {
      worksite: '울산',
      apply_date: `${this.year}${this.month}`,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.deduct.gets('ulsandeductselect', filter);
      if (data.length > 0) {
        this.is_finalize = '';
        this.saveBtnFalg = false;
        let grpItems: any = _.groupBy(data, item => item.key);
        // 그룹: 빌딩명:동명:호명
        Object.keys(grpItems)
          .filter((key: string) => grpItems[key].length > 1)
          .map((key: string) => grpItems[key])

          .forEach(item => {
            if (item.length === 1) {
              //1인으로 사용 했을경우
              item.forEach((item: any) => {
                item.use_count = 1;
              });
            } else {
              // 2인으로 사용했을경우
              item.forEach((item: any) => {
                item.use_count = 2;
              });
            }
          });
        this.items = data.map((item: any) => {
          //숙소 사용 일수 구하기
          const deduct_days =
            differenceInCalendarDays(new Date(item.tmp_checkout_at), new Date(item.tmp_checkin_at)) + 1;
          let room_charge = item.room_charge ? Number(item.room_charge) : 0;
          let util_charge = item.util_charge ? Number(item.util_charge) : 0;
          let keyPenelty = 0;
          const use_count = item.use_count;
          const is_married = item.is_married;
          const is_key_return = item.is_key_return === 1;
          const bedding = item.bedding;
          const etc_charge = item.etc_charge;
          let married = 1;
          if (item.room_building_name === '구관') {
            keyPenelty = is_key_return ? 0 : 0;
            switch (item.room_building_dong) {
              case '가동':
              case '나동':
              case '다동':
              case '라동':
              case '마동':
                util_charge = 0;
                married = is_married ? 2 : 1;
                if (deduct_days < endDay) {
                  //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                  room_charge = Math.round(((10000 * married * use_count) / endDay) * deduct_days);
                } else {
                  //해당월 꽉채웠을경우
                  room_charge = Math.round(10000 * married * use_count);
                }
                break;
              case '사동':
                if (is_married) {
                  //기혼
                  if (use_count > 1) {
                    //2명이상 숙박
                    if (deduct_days < endDay) {
                      room_charge = (22000 / endDay) * deduct_days;
                      util_charge = (8000 / endDay) * deduct_days;
                    } else {
                      room_charge = 22000;
                      util_charge = 8000;
                    }
                  } else {
                    //1명 숙박
                    if (deduct_days < endDay) {
                      room_charge = (44000 / endDay) * deduct_days;
                      util_charge = (24000 / endDay) * deduct_days;
                    } else {
                      room_charge = 44000;
                      util_charge = 24000;
                    }
                  }
                } else {
                  //미혼
                  if (use_count > 1) {
                    if (deduct_days < endDay) {
                      room_charge = (11000 / endDay) * deduct_days;
                      util_charge = 0;
                    } else {
                      room_charge = 11000;
                      util_charge = 0;
                    }
                  } else {
                    if (deduct_days < endDay) {
                      room_charge = (22000 / endDay) * deduct_days;
                      util_charge = (8000 / endDay) * deduct_days;
                    } else {
                      room_charge = 22000;
                      util_charge = 8000;
                    }
                  }
                }
                break;
              case '아동':
              case '차동':
                if (is_married) {
                  //기혼
                  if (use_count > 1) {
                    //2명이상 숙박
                    if (deduct_days < endDay) {
                      room_charge = (20000 / endDay) * deduct_days;
                      util_charge = (6000 / endDay) * deduct_days;
                    } else {
                      room_charge = 20000;
                      util_charge = 6000;
                    }
                  } else {
                    //1명 숙박
                    if (deduct_days < endDay) {
                      room_charge = (40000 / endDay) * deduct_days;
                      util_charge = (20000 / endDay) * deduct_days;
                    } else {
                      room_charge = 40000;
                      util_charge = 20000;
                    }
                  }
                } else {
                  //미혼
                  if (use_count > 1) {
                    //2명이상 숙박
                    if (deduct_days < endDay) {
                      room_charge = (10000 / endDay) * deduct_days;
                      util_charge = 0;
                    } else {
                      room_charge = 10000;
                      util_charge = 0;
                    }
                  } else {
                    //1명숙박
                    if (deduct_days < endDay) {
                      room_charge = (20000 / endDay) * deduct_days;
                      util_charge = (6000 / endDay) * deduct_days;
                    } else {
                      room_charge = 20000;
                      util_charge = 6000;
                    }
                  }
                }
                break;
            }
          } else if (item.room_building_name === '임대아파트'){
            // 임대아파트 -> 기숙사 전환으로 인한 공제 로직 추가
            util_charge = 0;
            married = is_married ? 2 : 1;

            // 아직 미반영
            //if (deduct_days < endDay) {
            //  //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
            //  room_charge = Math.round(((60000 / use_count) / endDay) * deduct_days);
            //} else {
            //  //해당월 꽉채웠을경우
            //  room_charge = Math.round(60000 / use_count);
            //}

            if(item.quota == 1) {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((60000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(60000 * married * use_count);
              }
            }
            else if(item.quota == 2) {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((30000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(30000 * married * use_count);
              }
            }
            else if(item.quota == 3) {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((20000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(20000 * married * use_count);
              }
            }
            else {
              if (deduct_days < endDay) {
                //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
                room_charge = Math.round(((10000 * married * use_count) / endDay) * deduct_days);
              } else {
                //해당월 꽉채웠을경우
                room_charge = Math.round(10000 * married * use_count);
              }
            }
            
            //if (deduct_days < endDay) {
            //  //해당월 만큼 꽉채운게 아닌 중간에 퇴숙하거나 들어왔을 경우 사용 일자 만큼 나누어 계산되어야함
            //  room_charge = Math.round(((10000 * married * use_count) / endDay) * deduct_days);
            //} else {
            //  //해당월 꽉채웠을경우
            //  room_charge = Math.round(10000 * married * use_count);
            //}
            
          } else {
            keyPenelty = is_key_return ? 0 : 0;
            if (is_married) {
              //기혼
              if (use_count > 1) {
                //2명이상 숙박
                if (deduct_days < endDay) {
                  room_charge = (29000 / endDay) * deduct_days;
                  util_charge = ((util_charge * 0.5 - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 29000;
                  util_charge = util_charge * 0.5 - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              } else {
                //1명숙박
                if (deduct_days < endDay) {
                  room_charge = (58000 / endDay) * deduct_days;
                  util_charge = ((util_charge - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 58000;
                  util_charge = util_charge - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              }
            } else {
              //미혼
              if (use_count > 1) {
                //2명이상 숙박
                if (deduct_days < endDay) {
                  room_charge = (14500 / endDay) * deduct_days;
                  util_charge = ((util_charge * 0.25 - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 14500;
                  util_charge = util_charge * 0.25 - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              } else {
                if (deduct_days < endDay) {
                  room_charge = (29000 / endDay) * deduct_days;
                  util_charge = ((util_charge * 0.5 - 8000) / endDay) * deduct_days;
                } else {
                  room_charge = 29000;
                  util_charge = util_charge * 0.5 - 8000;
                }
                if (util_charge < 0) {
                  //8천원보다 작을 경우 0원처리
                  util_charge = 0;
                }
              }
            }
          }

          util_charge = util_charge > 0 ? util_charge : 0;
          const bedding_charge = DeductUlsanStore.BEDDING_AMT * bedding;
          room_charge = Math.round(room_charge);
          util_charge = Math.round(util_charge);

          const total_deduct = room_charge + util_charge + bedding_charge + keyPenelty;
          const temp = { ...item };
          temp.deduct_date = `${this.year}${this.month}`;
          temp.deduct_days = deduct_days;
          temp.room_charge = room_charge;
          temp.util_charge = util_charge;
          temp.bedding_charge = bedding_charge;
          //temp.etc_charge = keyPenelty;
          temp.etc_charge = etc_charge;
          temp.total_deduct = total_deduct;
          temp.memo = '';
          temp.status = '';
          temp.close_date = '';
          temp.check_in_out = `${item.checkin_managed_at} ~ ${
            item.checkout_managed_at ? item.checkout_managed_at : ''
          }`;
          temp.dongho = `${item.room_building_name}/${item.room_building_dong}/${item.room_floor_name}/${item.room_name}`;
          temp.use_count = use_count;
          return temp;
        });
      } else {
        this.items = [];
        this.saveBtnFalg = true;
      }
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }
  */
  async getMonthutil() {
    const filter: any = {
      meter_date: `${this.year}${this.month}`,
    };

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.deduct.gets('monthutil', filter);
      this.items = data;
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async getUlsanDeductList() {
    this.is_finalize = '';
    this.saveBtnFalg = true;

    const endDay = new Date(Number(this.year), Number(this.month), 0).getDate();
    const startYYYYMMDD = `${this.year}-${this.month}-01`;
    const endYYYYMMDD = `${this.year}-${this.month}-${endDay}`;
    const filter: any = {
      worksite: '울산',
      deduct_date: `${this.year}${this.month}`,
    };

    if (this.department) {
      filter.department = this.department;
    }

    if (this.employee_number) {
      filter.employee_number = this.employee_number;
    }

    //console.log(filter, this.department, this.employee_number);

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.deduct.gets('ulsandeductlist', filter);
      if (data.length > 0) {
        this.isDeductList = true;
        this.item = data[0];
        if (this.item.is_finalize === 'Y') {
          //마감 확정인경우
          this.is_finalize = 'Y';
        } else if (this.item.is_finalize === 'N') {
          //당월공제자 정보 저장하여 생성한경우
          this.is_finalize = 'N';
        } else {
          //검침량 등록후 계산 직후
          this.is_finalize = '';
        }

        let grpItems: any = _.groupBy(
          data[0].deductvolume,
          item => `${item.room_building_name}/${item.room_building_dong}/${item.room_floor_name}/${item.room_name}`,
        );

        // 그룹: 빌딩명:동명:호명
        Object.keys(grpItems)
          .filter((key: string) => grpItems[key].length > 1)
          .map((key: string) => grpItems[key])
          .forEach(item => {
            if (item.length === 1) {
              //1인으로 사용 했을경우
              item.forEach((item: any) => {
                item.use_count = 1;
              });
            } else {
              // 2인으로 사용했을경우
              item.forEach((item: any) => {
                item.use_count = 2;
              });
            }
          });

        this.items = data[0].deductvolume.map((item: any) => {
          let use_count = 1;
          if (item.use_count === 2) {
            use_count = 2;
          }
          let checkin_date = item.checkin_date;
          if (new Date(checkin_date) < new Date(startYYYYMMDD)) {
            checkin_date = startYYYYMMDD;
          }
          let checkout_date = item.checkout_date;
          if (!checkout_date) {
            checkout_date = endYYYYMMDD;
          } else {
            // 행당 월 보다 크면
            if (new Date(endYYYYMMDD) < new Date(checkout_date)) {
              checkout_date = endYYYYMMDD;
            }
          }
          item.check_in_out = `${checkin_date} ~ ${checkout_date ? checkout_date : ''}`;
          item.dongho = `${item.room_building_name}/${item.room_building_dong}/${item.room_floor_name}/${item.room_name}`;
          item.use_count = use_count;
          return item;
        });
      } else {
        this.isDeductList = false;
        this.item = null;
        this.items = [];
      }
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  cancelItem() {
    this.item = { ...this.orgItem };
    this.orgItem = {};
  }

  newItem() {
    this.item = {
      id: -1,
      created_at: new Date(),
    };
    this.orgItem = { ...this.item };
  }

  selectedItem(item: any) {
    if (item) {
      this.item = item;
      this.orgItem = { ...item };
    } else {
      this.item = null;
      this.orgItem = null;
    }
  }

  get isChange() {
    return this.item && this.orgItem ? JSON.stringify(this.item) !== JSON.stringify(this.orgItem) : false;
  }
  /*
  calcPrics() {
    //console.log('calcPrics');
    let unitAmt = 3500;
    const items: any[] = [];
    this.items.forEach(item => {
      const temp = { ...item };
      let room_charge = 0;
      let util_charge = 0;

      if(temp.buliding_name === '구관') {
        if(temp.is_married == 1) {
          if(temp.quota == 1) {
            switch (temp.dong) {
              case '가동':
              case '나동':
              case '다동':
              case '라동':
              case '마동':
                room_charge = 20000;
                break;
              case '사동':
                room_charge = 44000;
                util_charge = 24000;
                break;
              case '아동':
              case '차동':
                room_charge = 40000;
                util_charge = 20000;
                break;
            }
          }
          else if(temp.quota == 2) {
              switch (temp.dong) {
                case '사동':
                  room_charge = 22000;
                  util_charge = 8000;
                  break;
                case '아동':
                case '차동':
                  room_charge = 20000;
                  util_charge = 6000;
                  break;
              }
          }
          else {
            
          }  
        }
        else {
          if(temp.quota == 1) {
            switch (temp.dong) {
              case '가동':
              case '나동':
              case '다동':
              case '라동':
              case '마동':
                room_charge = 10000;
                break;
              case '사동':
                room_charge = 22000;
                util_charge = 8000;
                break;
              case '아동':
              case '차동':
                room_charge = 20000;
                util_charge = 6000;
                break;
            }
          }
          else if(temp.quota == 2) {
            switch (temp.dong) {
              case '사동':
                room_charge = 11000;
                util_charge = 0;
                break;
              case '아동':
              case '차동':
                room_charge = 10000;
                util_charge = 0;
                break;
            }
          }
          else {
            
          }
        }
      }
      else if(temp.building_name ==='신관') {
        if(temp.is_married == 1) {
          if(temp.quota == 1) {
            room_charge = 58000;
            util_charge = Math.round(this.electric * temp.electric + this.water * temp.water + this.gas * temp.gas) - 8000;
            util_charge = util_charge < 0 ? 0 : util_charge;
          }
          else if(temp.quota == 2) {
            room_charge = 29000;
            util_charge = (Math.round(this.electric * temp.electric + this.water * temp.water + this.gas * temp.gas)*0.5 - 8000);
            util_charge = util_charge < 0 ? 0 : util_charge;
          }
        }
        else {
          if(temp.quota == 1) {
            room_charge = 29000;
            util_charge = Math.round(this.electric * temp.electric + this.water * temp.water + this.gas * temp.gas) * 0.5 - 8000;
            util_charge = util_charge < 0 ? 0 : util_charge;
          }
          else if(temp.quota == 2) {
            room_charge = 14500;
            util_charge = (Math.round(this.electric * temp.electric + this.water * temp.water + this.gas * temp.gas) * 0.25 - 8000);
            util_charge = util_charge < 0 ? 0 : util_charge;
          }          
        }
      }
      else if(temp.building_name ==='임대아파트') {
        if(temp.quota == 1) {
          room_charge = 60000;
        }
        else if(temp.quota == 2) {
          room_charge = 30000;
        }
        else {
          room_charge = 20000;
        }
      }

      temp.room_charge = room_charge;
      temp.util_charge = util_charge;
      items.push({ ...temp });
    });
    this.items = items;
  }
  */
  // 2024-01-31 로직 변경
  calcPrics() {
    //console.log('calcPrics');
    let unitAmt = 3500;
    const items: any[] = [];
    this.items.forEach(item => {
      const temp = { ...item };
      let room_charge = 0;
      let util_charge = 0;
      if (temp.building_name === '구관') {
        switch (temp.dong) {
          case '가동':
          case '나동':
          case '다동':
          case '라동':
          case '마동':
            room_charge = 10000;
            break;
          case '사동':
            room_charge = 22000;
            util_charge = 32000;
            break;
          case '아동':
          case '차동':
            room_charge = 20000;
            util_charge = 28000;
            break;
        }
      } 
      else if(temp.building_name === '임대아파트') {
        if(temp.quota == 1) {
          room_charge = 60000;
        }
        else if(temp.quota == 2) {
          room_charge = 30000;
        }
        else if(temp.quota == 3) {
          room_charge = 20000;
        }
        else {
          room_charge = 29000;
        }
        // room_charge /= temp.quota;
        util_charge = Math.round(this.electric * temp.electric + this.water * temp.water + this.gas * temp.gas);

      } else {
        room_charge = 29000;
        // room_charge /= temp.quota;
        util_charge = Math.round(this.electric * temp.electric + this.water * temp.water + this.gas * temp.gas);
      }
      temp.room_charge = room_charge;
      temp.util_charge = util_charge;
      items.push({ ...temp });
    });
    this.items = items;
  }
  

  async saveUtil() {
    const date = getTodayDate()[0].split('-');
    const data: any = {
      apply_date: `${this.year}${this.month}`,
      worksite: '울산',
      employee_number: this.user.employeeNumber,
      electric: this.electric,
      water: this.water,
      gas: this.gas,

      ulsanvolume: this.items.map(item => ({
        apply_date: item.apply_date,
        worksite: item.worksite,
        roomtype: item.roomtype,
        building_name: item.building_name,
        dong: item.dong,
        floor: item.floor,
        ho: item.ho,
        quota: item.quota,
        room_charge: item.room_charge,
        util_charge: item.util_charge,
        classify: item.classify,
        new_div: item.new_div,
        regist_date: date[0].replace(/-/g, ''),
        closing_date: '',
      })),
    };
    //console.log(data);
    try {
      this.headerStore.isLoading = true;
      await apis.deduct.post('ulsanutil', data);
      //await this.getItems();
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async saveUlsan() {
    const date = getTodayDate()[0];
    // if (this.item) {
    //   await apis.deduct.put('ulsandeductlist', this.item.id, this.item);
    // } else {
    const data: any = {
      deduct_date: `${this.year}${this.month}`,
      worksite: '울산',
      employee_number: this.user.employeeNumber,
      is_finalize: 'N',
      deductvolume: this.items.map((item: any) => ({
        id: item.id,
        deduct_date: item.deduct_date,
        guest_department: item.guest_department,
        guest_employee_number: item.guest_employee_number,
        guest_name: item.guest_name,
        room_building_name: item.room_building_name,
        room_building_dong: item.room_building_dong,
        room_floor_name: item.room_floor_name,
        room_name: item.room_name,
        is_married: item.is_married,
        checkin_date: item.checkin_managed_at,
        checkout_date: item.checkout_managed_at,
        deduct_days: item.deduct_days,
        room_charge: item.room_charge,
        util_charge: item.util_charge,
        bedding_charge: item.bedding_charge,
        etc_charge: item.etc_charge,
        total_deduct: item.total_deduct,
        memo: item.memo,
        status: item.status,
        close_date: date,
      })),
    };

    try {
      this.headerStore.isLoading = true;
      await apis.deduct.post('ulsandeductlist', data);
      await this.getUlsanDeductList();
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async saveOneUlsan(data: any) {
    const dataOne: any = {
      id: data.data.id,
      room_charge: data.data.room_charge,
      util_charge: data.data.util_charge,
      bedding_charge: data.data.bedding_charge,
      etc_charge: data.data.etc_charge,
      total_deduct: data.data.total_deduct,
    };
    try {
      this.headerStore.isLoading = true;
      await apis.deduct.patch('ulsandeductlist', dataOne);
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }

  async ulsanDeductFinal(id: number) {
    try {
      this.headerStore.isLoading = true;
      await apis.deduct.put('ulsandeductfinal', id, { is_finalize: 'Y' });
      this.headerStore.isLoading = false;

      return {
        save: true,
        status: 200,
        data: '',
      };
    } catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          save: false,
          status: status,
          data: data,
        };
      } else if (status === 500) {
        window.location.href = '/500';
        return {
          save: false,
          status: status,
          data: data,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        save: false,
        status: status,
        data: data,
      };
    }
  }
}
