import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Faq {
  static URL = `${IP}`;

  /**
   * FAQ 내역 조회
   * @param payload
   */
  @apiHandler()
  static async getFaqs(payload: any) {
    const PATH = `/admin/board/faq/`;

    return await axios.get(`${Faq.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // FAQ 상세 조회
  @apiHandler()
  static async getFaq({ id }: { id: string }) {
    const PATH = `/admin/board/faq/`;

    return await axios.get(`${Faq.URL}${PATH}${id}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // FAQ 생성
  @apiHandler()
  static async createFaq(payload: any) {
    const PATH = `/admin/board/faq/`;

    return await axios.post(`${Faq.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // FAQ 생성
  @apiHandler()
  static async updateFaq(id: string, payload: any) {
    const PATH = `/admin/board/faq/`;

    return await axios.patch(`${Faq.URL}${PATH}${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // FAQ 삭제
  @apiHandler()
  static async deleteFaq(id: string) {
    const PATH = `/admin/board/faq/`;

    return await axios.delete(`${Faq.URL}${PATH}${id}/`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Faq;
