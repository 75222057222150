import {action, computed, makeObservable, observable} from 'mobx';
import { useSelector } from 'react-redux';
import * as apis from '../../apis';
import { WORK_SITE } from '../../constants';

export abstract class BoardStore {
  rootStore: any;
  category: number = -1;
  limit: number = 10;
  offset: number = 0;
  totalPage: number = 0;
  items: any[] = [];
  checkeds: any = {};
  allCheckFlg: boolean = false;
  item: any;
  orgItem: any;

  //loading
  //isLoading : boolean = false;

  constructor(root: any) {
    this.rootStore = root;
    makeObservable(this, {
      user: computed,
      category: observable,
      limit: observable,
      offset: observable,
      totalPage: observable,
      items: observable,
      checkeds: observable,
      allCheckFlg: observable,
      item: observable,
      orgItem: observable,
      //isLoading: observable,
      init: action,
      findTabs: action,
      checkAll: action,
      checkItem: action,
    });
  }
  
  abstract async getItems(): Promise<void>;

  abstract init(): void

  destory () {
    this.init();
    this.orgItem = {};
  }

  get user() {
    const user = JSON.parse(window.sessionStorage.getItem('user') || '{}');
    // console.log(user);
    return user;
  }

  async setCategory(category: number) {
    this.category = category;
    await this.getItems();
  }

  async setLimit(limit: number) {
    this.limit = limit;
    await this.getItems();
  }

  async setOffset(offset: number) {
    this.offset = offset;
    await this.getItems();
  }

  setItemValue(key: string, value: any) {
    const _temp = { ...this.item };
    _temp[key] = value;
    this.item = _temp;
  }

  findTabs(name: string) {
    let result = -1;
    if ((window as any).store) {
      const opened = (window as any).store.getState().base.tab.opened;
      if (opened.length > 0) {
        result = opened.find((item: string) => item && item.indexOf(name) > -1);
      }
    }
    return result;
  }

  checkAll(checked: boolean) {
    const temp: any = { ...this.checkeds };
    this.items.map((item: any, index: number) => {
      if (checked) {
        this.allCheckFlg = true;
        temp[item.id] = item;
      } else {
        this.allCheckFlg = false;
        delete temp[item.id];
      }
    });
    this.checkeds = temp;
  }

  checkItem(checked: boolean, item: any) {
    const temp: any = { ...this.checkeds };
    if (checked) {
      if (!temp[item.id]) {
        temp[item.id] = item;
      }
    } else {
      delete temp[item.id];
    }
    this.checkeds = temp;

    if (Object.keys(temp).length === this.items.length) {
        this.allCheckFlg = true;
    } else {
      this.allCheckFlg = false;
    }
  }
}
