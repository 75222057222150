import React, { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { MessageContext, MessageImpl } from '../../../routes';
import core from '../../../apis/core';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';

const MobileFileUpload = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext)
  const [fileList, setFileList] = useState<any[]>(props.items);
  const [maxCount, setMaxCount] = useState<number>(3);
  const [dataUri, setDataUri] = useState('');
  const fileRef = useRef(null);

  const onFileChange = async (e: any) => {
    const {
      target: { files },
    } = e;
    const tmpFileList = [...fileList];
    props.items.splice(0, props.items.length);

    if ((files.length + tmpFileList.length) > maxCount) {
      error(`최대 ${maxCount}개까지 첨부할 수 있습니다.`);
      return;
    }

    const actions: any[] = [];
    files.forEach((file: Blob) => actions.push(addFile(file)));
    try {
      headerStore.isLoading = true;
      const founds = await Promise.all(actions);

      founds.forEach((found: any) => {
        if(found){
          tmpFileList.push(found.data);
        }
      });

      (fileRef.current as any).value = null;
      setFileList(tmpFileList);
      props.onSetData(tmpFileList);
      headerStore.isLoading = false;
    }catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("파일 업로드 중 문제가 발생했습니다.");
      return;
    }
  };

  const addFile = async (file: Blob) => {
    const form = new FormData();
    // console.log('=======================',file);
    form.append('path', file);

    let extList : any = [];
    if(props.type === "image") {
      extList = ['gif', 'jpg', 'jpeg', 'png'];
    }else {
      extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];
    }

    let fileData: any = file;
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (file.size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }else {
      try {
        headerStore.isLoading = true;
        let tmpFile = await core.fileUpload<{
            id?: number;
            path?: string;
            name?: string;
            category?: string | number;
          }>(form);

        headerStore.isLoading = false;
        return tmpFile;
      } catch (error) {
        // console.log(error);
        headerStore.isLoading = false;

        const { status, data } = error;

        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        } 

        info("파일 업로드 중 문제가 발생했습니다.");
        return;
      }
    }

  };

  const deleteFile = (fileInfo: any) => {
    const { id } = fileInfo;
    const founds = fileList.filter(file => file.id !== Number(id));
    setFileList(founds);
    props.onSetData(founds);
    (fileRef.current as any).value = null;
  };
  
  const clicking = () => {
    let totalSize : number = 0;
    const tmpFileList = [...fileList];
    // console.log(process.env.REACT_APP_MEDIA);
    if (fileList.length > 2) {
      error(`최대 ${maxCount}개까지 첨부할 수 있습니다.`);
      return;
    }

    tmpFileList.map((item: any, idx: number) => {
      totalSize += parseInt(item.size);
      if (totalSize > 1024 * 1024 * 10) {
        error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
        return;
      }
    });

    (fileRef.current as any).click();
  }

  const pathString = (fileInfo: any) => {
    const path = fileInfo.path ? fileInfo.path : fileInfo.image;
    return `${process.env.REACT_APP_MEDIA}/${path}`;
  }

  function handleTakePhoto (dataUri : any) {
    // console.log('takePhoto');
    setDataUri(dataUri);
  }

  return (
    <>
      <div className="row">
        <div className="col w100">
            <span className="title_form">{props.imgTitle? props.imgTitle : '이미지'}</span>
            <div className="form_img">
              {fileList.length > 0 ? (
                fileList.map((fileInfo: any, index: number) => (
                  <div className="img_area">
                    <a key={index} href="javascript:void(0);" className="btn_del" onClick={() => deleteFile(fileInfo)}></a>
                      <img src={pathString(fileInfo)} alt="" />
                      {/* {fileInfo.name} */}
                  </div>
                ))
              ): ''}
            </div>
        </div>
    </div>
    <hr className="hr_inline" />

    <div className="row form_function">
        <div className="col w100">
            <div className="wrap_file mt15">
                <input ref={fileRef} type="file" multiple={true} id={props.fileId} className="upload_hidden" onChange={onFileChange} accept={"image/*;capture=camera"} />
                <label htmlFor="input_upload_img" className="btn btn_lg btn_outline" onClick={clicking}>이미지 파일선택<i className="ico_file"></i></label>
            </div>
        </div>
    </div>

    {/* <div className="row form_function">
        <div className="col">
            <button className="btn btn_lg btn_outline">촬영하기<i className="ico_camera"></i></button>
        </div>
        <div className="col">
            <div className="wrap_file">
                <input ref={fileRef} type="file" multiple={true} id={props.fileId} className="upload_hidden" onChange={onFileChange} accept={"image/*;capture=camera"} />
                <label htmlFor="input_upload_img" className="btn btn_lg btn_outline" onClick={clicking}>이미지 파일선택<i className="ico_file"></i></label>
            </div>
        </div> 
      </div> */}
    </>
  );
});

export default MobileFileUpload;
