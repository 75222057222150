import React, { useEffect, useState } from 'react';

import Select from '../../atoms/select';
import CustomCalendar from '../../organismas/customCalendar';
import DatePicker from '../../organismas/input/datepicker';


// dateAfter ~ dateBefore
interface props {
  options: { value: string, label: string }[];
  onSearch(option: string, text: string): Promise<void>;

  dateAfter: string;
  dateBefore: string

  onChangeStartDateHandler(date: string): void;
  onChangeEndDateHandler(date: string): void;
}

//검색 Form
const SearchForm = ({
  options, onSearch, dateAfter, dateBefore,
  onChangeStartDateHandler, onChangeEndDateHandler
}: props) => {
  const [option, setOption] = useState<string>(options[0].value);
  const [text, setText] = useState<string>("");

  const [startDate, setStartDate] = useState<string>(dateAfter);
  const [endDate, setEndDate] = useState<string>(dateBefore);

  const onClickHandler = (e: any) => onSearch(option, text)
  const onEnterHandler = (e: any) => {
    if (e.keyCode === 13) {
      onSearch(option, text)
    }
  }

  useEffect(() => {
    onChangeStartDateHandler(startDate)
  }, [startDate])

  useEffect(() => {
    onChangeEndDateHandler(endDate)
  }, [endDate])

  return (
    <>
      <div className="table_modify_list">
        <dl className="info">
          <dt>정보</dt>
          <dd>
            <Select
                className="form_control"
                options={options}
                onChange={(e: any) => setOption(e.target.value)}
            />
            <input
                type="text"
                className="form_control"
                onChange={(e: any) => setText(e.target.value)}
                onKeyDown={onEnterHandler}
                maxLength={10}
            />
          </dd>
        </dl>
        <dl className="date">
          <dt>조회기간</dt>
          <dd>
            <div>
              {/* <DatePicker
                  value={startDate}
                  width={250}
                  onChange={(date: string) => setStartDate(date)}
                  isPrevMonthView={true}
                  max={endDate}
              /> */}
              <CustomCalendar
                max={endDate}
                value={startDate}
                onChange={(date:string) => {setStartDate(date)}}
              ></CustomCalendar>
            </div>
            <div>
              {/* <DatePicker
                  value={endDate}
                  width={250}
                  onChange={(date: string) => setEndDate(date)}
                  isPrevMonthView={true}
                  min={startDate}
              /> */}
              <CustomCalendar
                min={startDate}
                value={endDate}
                onChange={(date:string) => {setEndDate(date)}}
              ></CustomCalendar>
            </div>
            <button type="button" className="btn btn_sm btn_gray" onClick={onClickHandler}>검색</button>
          </dd>
        </dl>
      </div>

      {/*<Select
        className="form_control"
        options={options}
        onChange={(e: any) => setOption(e.target.value)}
      />
      <input
        type="text"
        className="form_control"
        onChange={(e: any) => setText(e.target.value)}
        onKeyDown={onEnterHandler}
      />

      <div style={{ display: 'inline-block', verticalAlign: "middle", height: 40}}>
        <div style={{ display: 'inline-block', marginLeft: 5, marginRight: 5,  }}>
          <DatePicker
            value={startDate}
            width={250}
            onChange={(date: string) => setStartDate(date)}
            isPrevMonthView={true}
          />
        </div>
        <div style={{ display: 'inline-block', marginLeft: 5, marginRight: 5,  }}>
          <DatePicker
            value={endDate}
            width={250}
            onChange={(date: string) => setEndDate(date)}
            isPrevMonthView={true}
          />
        </div>
      </div>

      <a href="javascript:void(0);" style={{ textAlign: "center" }} className="btn_search" onClick={onClickHandler} >검색</a>*/}
    </>

  );
};

export default React.memo(SearchForm);
