import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { CLOSE_ALERT_MODAL } from '../../../store/modules/modal';

const Alter = (props: any) => {
  const {
    title,
    msg,
    redirectPath,
    statusCode,
    errorCode,
    type,
    isModalShow,
  } = useSelector((state: any) => ({
    title: state.modal.msgPopup.title,
    msg: state.modal.msgPopup.msg,
    redirectPath: state.modal.msgPopup.redirectPath,
    statusCode: state.modal.msgPopup.statusCode,
    errorCode: state.modal.msgPopup.errorCode,
    type: state.modal.msgPopup.type,
    isModalShow: state.modal.msgPopup.isModalShow,
  }));

  const dispatch = useDispatch();

  const close = () => {
    dispatch({ type: CLOSE_ALERT_MODAL });
    if (redirectPath) {
      props.history.push(redirectPath);
    }
  };

  const getMsg = () => msg && msg.split('\n').map((word: any) => <p>{word}</p>);

  return (
    <>
      <div
        id="pop_alertsample"
        className="pop_dim_wrap pop_modal pop_alert"
        style={{ display: isModalShow ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <button className="pop_btn_close pop_close" onClick={() => close()}>
            닫기
          </button>

          <div className="message_table">
            <div className="messageWrap">{getMsg()}</div>
          </div>
          <div className="button_wrap">
            <button
              type="button"
              className="btn_pop_default btn_darkblue"
              onClick={() =>close()}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(withRouter(Alter));
