import React, { useState, useEffect, useContext} from 'react';
import SelectBuilding from '../../atoms/select/buildings';
import { lodge } from '../../../apis';
import _ from 'lodash';
import { GUEST_TYPE, LODGE_STATUS_CODE, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../../constants';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import { MessageContext, MessageImpl } from '../../../routes';
import SelectFloor from '../../atoms/select/floor';
import './dormitoryBackroom.scss';
import DormitoryFloor from '../../molecules/dormitoryFloor';
import { getWorkSitePhoneNum } from '../../../utils/common';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

// 기숙사 배방오픈
// 배방오픈 팝업이 열린 날짜를 기준으로 방 표시
const BackroomDormitory = observer((props: any) => {
  const {applicantJudgeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const { info }: MessageImpl = useContext(MessageContext);
  const [badIdx, setSelectBadIdx] = useState<number>();
  const [roomBadIdx, setRoomBadIdx] = useState<number>(0);

  useEffect(() => {
    if (!store.isDormitoryPop) return;
    if (!store.selectRow.id) return;

    store.workSite = store.selectRow.room_work_site;
    
    (async () => {
      if(store.openRoomInfoPop){    // 배방 확인 팝업일 경우
        await store.getOneBuildInfo();
      }else {
        // 건물, 층 select 정보 가져오기
        await store.getBuildsInfo();
        await store.getFloorsInfo();
        await store.getFirstAssign();   // 우선순위표
      }
    })();
  }, [store.isDormitoryPop]);

  // 우선순위표
  useEffect(() => {
    if (!store.isDormitoryPop) return;
    if (!store.selectRow.id) return;
    
    (async () => {
      await store.getFirstAssign();
    })();
  }, [store.selectFloor]);

  // 배방시 room 칸 클릭
  const onRoomClickHandlerToSelect = (selectedRoom: any, idx: number) => {
    let tempRoom: any = _.cloneDeep(store.room);
    tempRoom = {};
    tempRoom[selectedRoom.id] = selectedRoom;
    tempRoom['room'] = selectedRoom;
    store.room = tempRoom;    // 선택한 방 담기
    
    setSelectBadIdx(idx);   // 선택한 방이 2인 이상인 경우때문에 badIdx 담기
  }

  const onRoomClickHandlerToUnSelect = (unSelectedRoom: any) => {
    store.room = [];
  }

  // 방 disabled처리
  const isDisable = (room: any, idx: number) => {
    // if(store.openRoomInfoPop && (store.selectRow.room === room.id)) {
    //   return false;
    // }else { 
      return ((room.roomstatus[idx] !== null && room.roomstatus[idx].status !== 1) || room.roomstatus[idx].guest_id || room.room_type !== ROOM_TYPE_CODE.dormitory_room 
              || (room.roomstatus[idx].guest && room.roomstatus[idx].guest.status !== LODGE_STATUS_CODE.checkout)
              || (room.gender_type !== 0 && room.gender_type !== store.selectRow.guest_gender))
    // }
  }
  
  // 선택한 방 체크
  const isSelect = (room: any, idx: number) => {
    /*if(store.openRoomInfoPop) {
      if(store.selectRow.room === room.id){
        console.log(".......................... ", room)
        return (store.selectRow.room === room.id);
      }
    }*/

    return (store.room[room.id] && badIdx === idx);
  }

  // 이용중인 게스트 있는 경우, 이름 표시
  const disableText = (room: any, badIdx: number) => {
    // console.log(room.roomstatus[badIdx]);
    //if (store.openRoomInfoPop && room.roomstatus[badIdx] && room.roomstatus[badIdx].guest_id === store.selectRow.guestIds[0]) {  // 배방 오픈 -> guestId 동일
    // && room.roomstatus[badIdx].guest.id === store.selectRow.guestIds[0]
    if (store.openRoomInfoPop && (room.roomstatus[badIdx].guest)) {
      return (
        <>
          <p>
              <span className="name">{room.roomstatus[badIdx].guest.guest_name}</span>
              <span className="rank">{room.roomstatus[badIdx].guest.guest_position}</span>
          </p>
          <p>
              <span className="date">{room.roomstatus[badIdx].guest.stay_after}</span>
              <span className="part">{room.roomstatus[badIdx].guest.guest_party}</span>
          </p>
        </>
      );
    }
    if (room.roomstatus[badIdx].guest && room.roomstatus[badIdx].guest.status === 3) {  // 이용중인 사용자 정보만 노츨
      return (
        <>
          <p>
              <span className="name">{room.roomstatus[badIdx].guest.guest_name}</span>
              <span className="rank">{room.roomstatus[badIdx].guest.guest_position}</span>
          </p>
          <p>
              <span className="date">{room.roomstatus[badIdx].guest.stay_after}</span>
              <span className="part">{room.roomstatus[badIdx].guest.guest_party}</span>
          </p>
        </>
      );
    } else return "";
  }
  
  // 완료 버튼 클릭
  const onCompleteHandler = async () => {
    if(Object.keys(store.room).length <= 0){
      info('배방정보를 입력해주세요');
      return false;
    }

    try {
      if(!store.manageCheckin && !store.manageCheckChange) {
          headerStore.isLoading = true;
          let { data } = await lodge.dormitorySingleAccept(store.selectRow.id, {
            room: Object.keys(store.room)[0]
          })
          
          // 배방 완료 문자 발송
          let smsPayload: any = {
            cellphone : store.selectRow.guest_cellphone,
            message : '서류승인이 완료되었습니다. 입숙예정일 입력 부탁드립니다. ( 메뉴 : 마이페이지>>신청관리 ) 배방 정보 : '+store.building.name + store.building.dong + store.room['room'].name
                    + '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(store.selectRow.room_work_site, store.selectRow.room_type)
          }
          const { data: sms } = await lodge.smsSendAPI(smsPayload);
          
          store.roomInit();
          headerStore.isLoading = false;
          info('배방이 완료되었습니다.');
          store.isDormitoryPop = false;
          store.getItems();
      }else if(store.manageCheckin){    // 관리자가 강제 입숙처리하는 경우
        props.setRoomInfo(store.room['room']);    // 선택한 방정보 전달하기
        store.isDormitoryPop = false;
        store.roomInit();
      }else if(store.manageCheckChange) {   // 강제 방변경
        let payload = {
          guest_id: store.selectRow.guest_id,
          room_id: Object.keys(store.room)[0]
        }

        headerStore.isLoading = true;
        let { data } = await lodge.lodgeGuestCheckChange(payload);

        store.roomInit();
        headerStore.isLoading = false;
        info('방변경이 완료되었습니다.');
        store.isDormitoryPop = false;
        props.moveComplete();
      }
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      // console.log(data, status);

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("배방 중 문제가 발생했습니다.");
      return;
    }
  }

  if (!store.isDormitoryPop) return<></>;

  return (
    <div id="pop_assign_room" className="pop_dim_wrap pop_modal pop_lg" style={{display: store.isDormitoryPop? '' : 'none'}}>
        <div className="pop_wrap">
            <div className="title_wrap">
                <h3>배방</h3>
                <button className="pop_btn_close pop_close" onClick={() => {store.roomInit(); store.isDormitoryPop = false}}>닫기</button>
            </div>
            <div className="contents_wrap">
                <div className="title_contents bd0">
                    <h3>이용자정보</h3>
                </div>
                <div className="table_normal_list table_write">
                    <table>
                        <caption className="sr_only">이용자정보 상세</caption>
                        <colgroup>
                            <col width="160"/>
                            <col width="*"/>
                            <col width="160"/>
                            <col width="*"/>
                            <col width="160"/>
                            <col width="*"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>소속회사유형</th>
                            <td>{store.selectRow.guest_type !== 4 ? GUEST_TYPE[store.selectRow.guest_type] : '신입(경력)'}</td>
                            <th>사번</th>
                            <td>{store.selectRow.guest_employee_number}</td>
                            <th>성명</th>
                            <td>{store.selectRow.guest_name}</td>
                        </tr>
                        <tr>
                            <th>성별</th>
                            <td>{store.selectRow.guest_gender === 1 ? '남' : '여'}</td>
                            <th>휴대폰</th>
                            <td>{store.selectRow.guest_cellphone}</td>
                            <th>회사명(단체명)</th>
                            <td>{store.selectRow.guest_company}</td>
                        </tr>
                        <tr>
                            <th>소속부서</th>
                            <td>{store.selectRow.guest_department}</td>
                            <th>근무조</th>
                            <td>{store.selectRow.guest_party}</td>
                            <th>차량번호</th>
                            <td>{store.selectRow.guest_car_number1}</td>
                        </tr>
                        <tr>
                            <th>배방정보</th>
                            {store.openRoomInfoPop ? (
                              <td colSpan={5}>{store.selectRow.room_building_name} {store.selectRow.room_building_dong} {store.selectRow.room_name}호</td>
                            ):(
                              <td colSpan={5}>{store.building && store.building.name && store.building.dong? store.building.name +' '+ store.building.dong :''} {store.room['room'] ? store.room['room'].name +'호' : ''}</td>
                            )}
                        </tr>
                        </tbody>
                    </table>
                </div>  {/*table*/}

                <div className="title_contents bd0">
                    <h3>배방정보 확인 {!store.openRoomInfoPop && store.floors.length <= 0? (<span style={{color:'#f1615d'}}>선택한 건물에 배방가능한 방이 없습니다.</span>) : ''}</h3>
                    {store.floors.length <= 0? (<p style={{color:'#f1615d'}} className="ar mt10">선택한 건물에 배방가능한 방이 없습니다.</p>) : ''}
                    <div className="right_area">
                      {store.openRoomInfoPop ? '' : (
                        <>
                          <SelectBuilding
                            changeBuildHandler={async(e: any) => {
                              store.selectFloor = 0;
                              store.building = store.buildings[parseInt(e.target.value)];
                              await store.getFloorsInfo();
                              await store.getFirstAssign();
                            }}
                            building={store.building}
                            buildings={store.buildings}
                            dongs={store.dongs}
                          />
                          <SelectFloor floors={store.floors} value={store.selectFloor}
                            onChangeFloor={(e:any) => {
                              store.selectFloor = e.target.value; 
                              store.floorData = store.floors[e.target.value];
                            }} />
                        </>
                      )}
                    </div>
                </div>
                <div className="partition">
                  <div className="left_area">
                    <div className="group_floor assign_room">
                      <div className="outer_floor">
                        <DormitoryFloor
                            floors={store.floors}
                            selectRoom={store.room}
                            selectFloor={store.selectFloor}
                            onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                            onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                            isUsable={() => { }}
                            isDisable={isDisable}
                            isSelect={isSelect}
                            disableText={disableText}
                          />
                      </div>
                    </div>
                  </div>
                  {!store.openRoomInfoPop && store.firstNumList && store.firstNumList.length > 0 ? (
                    <div className="right_area">
                      <h4>배방 우선순위 안내</h4>
                      <div className="lst_case">
                          <ul>
                              {store.firstNumList.map((item: any, idx: number) => (
                                  <li>
                                      <span className="num">{(idx + 1)}</span>
                                      <span>{item.dong} {item.room_name}호</span>
                                      <span>{item.stayBefore? item.stayBefore : ''}</span>
                                  </li>
                              ))}
                          </ul>
                      </div>
                    </div>
                  ) :''}
                </div>
            </div>
            <div className="button_wrap">
              {store.openRoomInfoPop ? (
                <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={() => {store.roomInit(); store.isDormitoryPop = false}}>확인</button>
              ) : (
                <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={onCompleteHandler}>완료</button>
              )}
            </div>
        </div>
    </div>
  );
});

export default BackroomDormitory;
