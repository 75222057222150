import React, {useState} from 'react'

import { WORK_SITE } from '../../../constants';
import { formatByString_YYYYMMDD } from '../../../utils/date';


//대상자 상세 정보 팝업
const MemberDetail = (props: any) => {
    const [user, setUser] = useState(props.user && props.user);
  
    return (
      <div id="" className="pop_dim_wrap pop_modal pop_lg" style={{ display: props.isShowModal ? "block" : "none" }}>
        <div className="pop_wrap">
            <button className="pop_btn_close" onClick={() => props.onCloseModalHandler()}>닫기</button>
            <div className="title_wrap" style={{borderBottom: 0}}>인사 DB 상세 항목</div>
            <div className="contents_wrap pt0">
              <div className="table_normal_list table_write">
                <table>
                  <caption className="sr_only">입숙 신청 현황 상세</caption>
                  <colgroup>
                    <col width="130" />
                    <col width="*" />
                    <col width="130" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>사번 및 아이디</th>
                      <td>{user.employee_number}</td>
                      <th>성명</th>
                      <td>{user.name}</td>
                    </tr>
                    <tr>
                      <th>생년월일</th>
                      <td>{user.personnel ? (user.personnel.birthdate ? user.personnel.birthdate : '') : ('')}</td>
                      <th>성별</th>
                      <td>{user.personnel ? (user.personnel.gender ? (user.personnel.gender === 1 ? "남" : "여") : '') : ('')} </td>
                    </tr>
                    <tr>
                      <th>회사명</th>
                      <td>현대자동차</td>
                      <th>부서</th>
                      <td>{user.personnel ? (user.personnel.department ? user.personnel.department : '') : ('')}</td>
                    </tr>
                    <tr>
                      <th>직위</th>
                      <td>{user.personnel ? (user.personnel.rank ? user.personnel.rank : '') : ('')}</td>
                      <th>직책</th>
                      <td>{user.personnel ? (user.personnel.position ? user.personnel.position : '') : ('')}</td>
                    </tr>
                    <tr>
                      <th>근무지명</th>
                      <td>{WORK_SITE[user.work_site]}</td>
                      <th>휴대폰번호</th>
                      <td>{user.cellphone}</td>
                    </tr>
                    <tr>
                      <th>이메일</th>
                      <td>{user.email}</td>
                      <th>주소</th>
                      <td>{`${user.personnel?.address1} ${user?.personnel?.address2}`}</td>
                    </tr>
                    <tr>
                      <th>결혼유무</th>
                      <td>{user.personnel?.is_married ? "유" : "무"}</td>
                      <th>직종</th>
                      <td>{user.personnel ? (user.personnel.occupation ? user.personnel.occupation : '') : ('')}</td>
                    </tr>
                    <tr>
                      <th>당사입사일</th>
                      <td>{user.personnel ? (user.personnel.joined_at ? (user.personnel.joined_at && formatByString_YYYYMMDD(user.personnel.joined_at)) : '') : ('')}</td>
                      <th>근속년</th>
                      <td>{user.personnel ? (user.personnel.working_period ? `${user.personnel.working_period}년` : '') : ('')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="button_wrap">
            <button type="button" className="btn_md btn_darkblue" onClick={() => props.onCloseModalHandler()}>확인</button>
            </div>
        </div>
        </div>
    );
  };
  
  export default MemberDetail;
  