import React, { useRef, useState } from 'react';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import DefaultTemplate from '../../../components/templetes/default';
import DeductTab from '../deductTab';

import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";


//사용안함
const DeductHistoryPage = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  const {meterStore: store} = useStores();
  const baseUrl = '/guest/deductible';

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <DeductTab {...props} />

      <div className="tab_contents">검침량</div>
      <div className="tab_contents">당월공제자관리</div>
      <div className="tab_contents on">
        <h4>공제이력 리스트
          <div className="right_area">
            <select name="" id="" className="form_control" style={{'width': 110+'px'}}>
              <option value="">2020</option>
            </select>
            <select name="" id="" className="form_control" style={{'width': 90+'px'}}>
              <option value="">12</option>
            </select>
            <select name="" id="" className="form_control" style={{'width': 140+'px'}}>
              <option value="">기숙사</option>
            </select>
            <select name="" id="" className="form_control" style={{'width': 110+'px'}}>
              <option value="">10</option>
            </select>
          </div>
        </h4>
        <div className="table_normal_list">
          <table>
          <caption className="sr_only">공제이력 리스트</caption>
          <colgroup>
            {/*<col width="60px"/>*/}
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <thead>
            <tr>
              {/*<th>
                  <label className="input_check pop_notext">
                      <input type="checkbox" id="" name="" />
                      <span className="label_text"></span>
                  </label>
              </th>*/}
              <th>사번</th>
              <th>성명</th>
              <th>성별</th>
              <th>부서</th>
              <th>건물명</th>
              <th>동</th>
              <th>호</th>
              <th>공제일</th>
              <th>이용료</th>
              <th>유틸리티</th>
              <th>침구대</th>
              <th>세탁비</th>
              <th>기타</th>
              <th>공제총비용</th>
              <th>처리상태</th>
              <th>마감확정일</th>
            </tr>
          </thead>
          <tbody>
          {/*데이타 없을 시*/}
          {/*<tr>
              <td colspan="" className="blank_area type_white">
                  <p className="txt_blank">데이터가 없습니다.</p>
              </td>
          </tr>*/}
          <tr>
            {/*<td>
                <label className="input_check pop_notext">
                    <input type="checkbox" id="" name="" />
                    <span className="label_text"></span>
                </label>
            </td>*/}
            <td>1234005</td>
            <td>홍길동</td>
            <td>남</td>
            <td>연구개발총무운영팀</td>
            <td>뉴타운</td>
            <td>201</td>
            <td>101</td>
            <td>2019.04.05</td>
            <td>150,000</td>
            <td></td>
            <td></td>
            <td>150,000</td>
            <td>150,000</td>
            <td>150,000</td>
            <td></td>
            <td>2019.04.05</td>
          </tr>
          </tbody>
          </table>
        </div>  {/*table*/}

        <div className="board_paging">
          <ul>
              <li><a href="javascript:void(0);" className="paging_prev">paging_prev</a></li>
              <li><a href="javascript:void(0);" className="active">1</a></li>
              <li><a href="javascript:void(0);">2</a></li>
              <li><a href="javascript:void(0);">3</a></li>
              <li><a href="javascript:void(0);">4</a></li>
              <li><a href="javascript:void(0);">5</a></li>
              <li><a href="javascript:void(0);">6</a></li>
              <li><a href="javascript:void(0);">7</a></li>
              <li><a href="javascript:void(0);">8</a></li>
              <li><a href="javascript:void(0);">9</a></li>
              <li><a href="javascript:void(0);">10</a></li>
              <li><a href="javascript:void(0);" className="paging_next">paging_next</a></li>
          </ul>
        </div>   {/*board_paging*/}

        <div className="submit ac">
            <button type="button" className="btn btn_lg btn_darkblue btn_outline">다운로드</button>
            <button type="button" className="btn btn_lg btn_darkblue">수정</button>
        </div>
      </div>
      <div className="tab_contents">출장자숙소 공제</div>
    </DefaultTemplate>
  );
});
export default DeductHistoryPage;
