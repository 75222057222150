/*

  관리자 메뉴별 탭 관리 파일
  
*/


import { includes } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStores } from '../../../store/mobx';

import { ADD_TAB, CLICK_TAB, CLOSE_TAB, CHANGE_TAB } from '../../../store/modules/base';

const DEFAULT_TAB_PATH = '/home';
const m: { [key: string]: string } = {
  '/home': 'Home',
  '/lodged/applicant': '신청관리 목록',
  '/lodged/guest': '이용관리 목록',
  '/lodged/checkout': '퇴숙관리 목록',
  '/applicant/judge': '심사 및 입숙처리',
  
  '/lodged/applicant/detail': '신청관리 상세',
  '/lodged/guest/detail': '이용관리 상세',
  '/lodged/checkout/detail': '퇴숙관리 상세',

  '/guest/history':'입숙자현황 및 이력',
  '/guest/history/detail':'입숙자현황 및 이력',
  '/guest/board' : '입숙자현황표',
  '/guest/status' : '입숙, 이숙, 퇴숙 현황',
  // '/lodged/type/state' : '입숙, 이숙, 퇴숙 현황',
  // '/lodged/type/checkin/list' : '입숙, 이숙, 퇴숙 현황',
  // '/lodged/type/checkout/list' : '입숙, 이숙, 퇴숙 현황',
  // '/lodged/type/checkchange/list' : '입숙, 이숙, 퇴숙 현황',
  '/guest/deductible': '공제내역',

  '/manage/room': '방 현황',
  '/manage/use/guide': '이용관리',
  '/manage/pledge': '입숙서약서 관리',
  
  '/lodged/maintenance': '유지운영 관리',
  
  '/manage/accounts': '고객관리 목록',
  '/manage/member': '회원현황',
  '/establish/bacisinfo': '사업장 관리',
  '/manage/faq': 'FAQ 관리',
  '/manage/notice': '공지 관리',
  '/manage/sms' : '문자발송 검색',
  
  '/guest/audit': '정기서류 관리',
  
  '/lodged/basicinfo': '기본정보',

  '/my/info': 'MyPage',
  
  '/locus/guest': '기준설정',

 /*  '/locus/guest/type/write': '현장관리-이용자범위설정 등록',
  '/locus/guest/type/detail': '현장관리-이용자범위설정 상세/수정',
  
  '/locus/document': '현장관리-입숙서류 목록',
  '/locus/document/write': '현장관리-입숙서류 등록',
  '/locus/document/detail': '현장관리-입숙서류 상세/수정',
 */
  '/admin/manage_auth': '관리자 권한관리',
  '/admin/regist_apply': '관리자 계정관리',

  '/mypage/info': '개인정보',
  '/account/permission': '계정 및 권한관리',
  '/account/application':'계정 신청 현황',
};

const Tab = observer((props: any) => {
  const dispatch = useDispatch();
  const { opened = [], select = -1 } = useSelector((state: any) => ({
    opened: state.base.tab.opened,
    select: state.base.tab.select,
  }));
  let current = props.location.pathname;
  const {headerStore: headerStore} = useStores();
  // const [ current, setCurrent ] = useState(props.location.pathname);
  
  useEffect(() => {
    // console.log(current);
    //headerStore.init();
    if (opened[select] && compMenu(opened[select]) !== compMenu(props.location.pathname)) {
      props.history.replace(opened[select]);
      // setCurrent(opened[select]);
      current = opened[select];
    }

    if (opened.length === select) {
      clickTabHandler(select - 1);
    }
    // console.log('tab testing....');
  }, [opened, select]);

  useEffect(() => {
    //headerStore.init();
    // console.log(current);
    // console.log(m)
    // console.log(props.location.pathname, m[props.location.pathname]);
    if (opened.length > 0) {
        dispatch({
          type: CHANGE_TAB,
          path: `${props.location.pathname}`,
        });
    } else {
      addTabHandler();
      if (DEFAULT_TAB_PATH !== props.location.pathname) {
        const tmp = setTimeout(() => {
          addTabHandler(props.location.pathname);
          clearTimeout(tmp);
        }, 1000);
      }
    }
  }, [props.location.pathname]);

  const addTabHandler = (path = DEFAULT_TAB_PATH) => {
    //headerStore.init();
    dispatch({
      type: ADD_TAB,
      path,
    });
    // props.history.replace(path);
  };

  const clickTabHandler = (idx: number) => {
    //headerStore.init();
    dispatch({
      type: CLICK_TAB,
      select: idx,
    });
    // props.history.replace(opened[idx]);
  };

  const closeTabHandler = (e: any, idx: number) => {
    //headerStore.init();
    e.preventDefault();
    if (opened.length === 1) return;
    dispatch({
      type: CLOSE_TAB,
      select: idx,
    });

    // if (opened.length - 1 > 0) props.history.replace(opened[0]);
  };

  const s = {
    // listStyle: 'none',
    // borderTop: '1px solid #eee',
    // borderBottom: '1px solid #eee',
    // display: 'inline-block',
    // padding: 12,
    cursor: 'pointer',
  };

  const compMenu = (val: string) => {
    const vals = val.split('/');
    if (vals.length > 2) {
      return vals.slice(0,3).join('/');
    } else {
      return val;
    }
  }

  return (
    <div className="borad_tab">
      <ul>
        {opened.map((item: string, idx: number) =>
          <li
            key={item}
            style={{...s}}
            onClick={() => clickTabHandler(idx)}
          >
            <a className={idx === select ? 'on' : ''}>
              {item ? m[compMenu(item)] : ''}
              {idx === 0 ? (<></>) : (
                <span
                  className="ico_close"
                  onClick={(e: any) => closeTabHandler(e, idx)}
                >
                  닫기
                </span>
              )}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
});

export default Tab;
