import React, { useState, useEffect, useReducer } from 'react';
import DefaultTemplate from '../../components/templetes/default';
import ApplicantForm from '../../components/organismas/form/applicant';
import GuestForm from '../../components/organismas/form/guest';
import FileForm from '../../components/organismas/form/file';
import { BOOKING_STATUS_CODE, LODGE_STATUS_CODE, ROOM_TYPE_CODE } from '../../constants';
import { useStores } from '../../store/mobx';
import './index.scss';
import { render } from 'react-dom';
import { observer } from 'mobx-react';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

const ApplicantJudgeDetailPage = observer((props: any) => {
  const {applicantJudgeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    headerStore.getAuthMenuRole('1-2');
    (async () => {
        await fetchData();
    })();
    return () => {
      if (store.findTabs('/applicant/judge') < 0) {
        store.destory();
      }
    }
  }, [isMounted]);

  const fetchData = async () => {
    try {
      headerStore.isLoading = true;
      const bookingId: number = parseInt(props.match.params.bookingId);
      await store.getItem(bookingId);
      setIsMounted(true);
      headerStore.isLoading = false;
    } catch (error) {
      headerStore.isLoading = false;
      // console.log(error);
      const { status, data } = error;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

    }
  }

  // 진행상태
  const cellStatusRenderer = (data: any) => {
      if(data.status === BOOKING_STATUS_CODE.applied){    // 신청자 신청완료
          return(
              <>
              <span className="point blue">신청완료</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; setIsShowReflect(true)}}>반려</button>
              <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; RoomSelectHandler();}}>승인</button> */}
              </>
          );
      }else if(data.status === BOOKING_STATUS_CODE.rejected){     // 반려 재요청
          return(
              <>
              <span className="point red">반려(재요청)</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; setIsShowReflect(true)}}>반려사유</button> */}
              </>
          );
      }else if(data.status === BOOKING_STATUS_CODE.terminated) {      // 반려 거절
          return(
              <>
              <span className="point red">승인거절</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; setIsShowReflect(true)}}>거절사유</button> */}
              </>
          );
      }else if(data.status === BOOKING_STATUS_CODE.canceled){        // 신청자 신청 취소
          return <span className="point red">예약취소</span>;
      }else if(data.status === BOOKING_STATUS_CODE.approved && data.guests[0].status === LODGE_STATUS_CODE.waiting){   // 모든 승인 처리 end 입숙대기 상태
          return(
              <>
              <span className="point green">예약완료</span>
              {/* <button type="button" className="btn btn_xs btn_green" onClick={() => {store.selectRow = data; setIsCheckpopupShow(true);}}>입숙처리</button> */}
              </>
          );
      }else if(data.status === BOOKING_STATUS_CODE.approved && data.room_type === ROOM_TYPE_CODE.business_room_group){    // 관리자 
          return <span className="point blue">서류승인완료</span>;
      }else if(data.status === BOOKING_STATUS_CODE.stayHold) {           // 입숙보류 상태
          return (
              <>
              <span className="point red">입숙보류</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; setIsShowReflect(true)}}>반려</button>
              <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; RoomSelectHandler();}}>승인</button> */}
              </>
          );
      }else if(data.status === BOOKING_STATUS_CODE.approved && data.guests[0].status === LODGE_STATUS_CODE.checkin){      // 입숙
          return <span className="point green">입숙</span>;
      }else if(data.status === BOOKING_STATUS_CODE.roomOpen && data.room_type === ROOM_TYPE_CODE.business_room_group) {       //단체, 사용자가 배방 진행 완료
          return (
              <>
              <span className="point blue">배방완료</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data;}}>변경요청</button> */}
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; showConfirm('승인 하시겠습니까?', dispatch, groupScheduleApprove);}}>승인</button> */}
              </>
          );
      }else if(data.room_type === ROOM_TYPE_CODE.company_room && data.status === BOOKING_STATUS_CODE.documentApproved) {        // 사택 입숙예정일 승인, 증빙서류 요청
          return <span className="point blue">승인완료</span>;   
      }else if(data.status === BOOKING_STATUS_CODE.roomOpen || data.status === BOOKING_STATUS_CODE.documentApproved) {        // 배방 오픈 or 서류 승인완료
          return <span className="point blue">서류승인완료</span>;   
      }else if(data.status === BOOKING_STATUS_CODE.stayAfter && data.room_type === ROOM_TYPE_CODE.dormitory_room) {   // 신청자가 입숙예정일 입력 (기숙사)
          return(
              <> 
              <span className="point blue">입숙예정일 : {data.stay_after}</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; setIsShowStayAfterPop(true)}}>변경요청</button>
              <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; showConfirm('최종 승인 하시겠습니까?', dispatch, scheduleApprove);}}>최종승인</button> */}
              </>
          );
      }else if(data.status === BOOKING_STATUS_CODE.stayAfter && data.room_type === ROOM_TYPE_CODE.company_room) {   // 신청자가 입숙예정일 입력 (사택)
          return(
              <> 
              <span className="point blue">입숙예정일 : {data.stay_after}</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; setIsShowStayAfterPop(true)}}>변경요청</button>
              <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; showConfirm('승인 하시겠습니까?', dispatch, scheduleApprove);}}>승인</button> */}
              </>
          );
      }else if(data.status === BOOKING_STATUS_CODE.stayAfterTerminated) {     // 관리자 - 입숙예정일 변경 요청
          return <span className="point red">입숙예정일 변경 요청</span>;
      }else if(data.status === BOOKING_STATUS_CODE.documentReject) {     // 관리자 - 입숙예정일 변경 요청
          return <span className="point red">증빙서류재요청</span>;
      }else if(data.status === BOOKING_STATUS_CODE.documentSubmit) {     // 관리자 - 증빙서류 제출상태
          return (
              <>
              <span className="point blue">증빙서류등록완료</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; showConfirm('증빙서류 변경요청을 하시겠습니까?', dispatch, documentReCheck);}}>변경요청</button>
              <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data; showConfirm('최종 승인 하시겠습니까?', dispatch, companyHouseApprove);}}>최종승인</button> */}
              </>
          )
      }else{
          return <></>;
      }
  };

  return (
    <>
      <DefaultTemplate {...props} id="wrap" className="" 
      isLoading={headerStore.isLoading}
      >
          
        <ApplicantForm
          applicant={store.applicant}
          roomType={store.roomType}
          workSite={store.detailWorkSite}
          createdAt={store.createdAt}
          status={store.status}
          cellStatusRenderer={(data:any) => cellStatusRenderer(data)}
          detailData={store.detailData}
        />
        {/* </div> */}

        <GuestForm guests={store.guests} isUpdateMode={isUpdateMode} setGuests={store.setGuests} roomType={store.roomType} mealTicket={store.mealTicket}/>

          {store.files.length > 0 ? (
            store.roomType !== ROOM_TYPE_CODE.business_room_group ? (
              <FileForm value={store.files} downFlag={headerStore.downRoleFlag} />
            ) : 
              store.files[0].document.name="협조전",
            (
              <FileForm value={store.files} downFlag={headerStore.downRoleFlag}/>
            )
          ):''}
        
        <div className="submit">
          <button type="button" className="btn btn_lg btn_gray fl" onClick={() => props.history.replace(`/applicant/judge/`)}>목록</button>
        </div>
      </DefaultTemplate>

      {/* <CheckPopup
        isShowModal={isShowCheckinModal}
        onCloseModalHandler={() => setIsShowCheckinModal(false)}
        onCompleteHander={onCheckinHandler}
        checkKeyAt={checkInKeyoutAt}
        checkAt={checkInAt}
        bookingData={guests}
        setCheckAt={setCheckInAt}
        setCheckKeyAt={setCheckInKeyoutAt}
        type="checkin"
      /> */}
    </>
  );
});

export default ApplicantJudgeDetailPage;
