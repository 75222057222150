import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../apis';
import { ERROR_CODE } from '../../../constants/error';
import { MessageContext } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { LOGIN_REQUEST, LOGOUT_REQUEST } from '../../../store/modules/auth';
import { CLEAN_TAB } from '../../../store/modules/base';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import { passwordValid } from '../../../utils/validation';
//import MobileSignUp from './signUpIndex';
import './index.scss';
import MobileSignUp from './signUpIndex';
import { observer } from 'mobx-react';

const MobileSearchPw = observer((props: any) => {
    const {headerStore: headerStore} = useStores();
    const [employeeNumber, setEmployeeNumber] = useState<string>("");
    const [phone, setPhone] = useState<string>("");

    const [number, setNumber] = useState<string>("");

    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    const [verifyToken1, setVerifyToken1] = useState<string>("");
    const [verifyToken2, setVerifyToken2] = useState<string>("");

    const { info } = useContext(MessageContext);

    //const ref = useRef<any>(null);

    const init = () => {
        setEmployeeNumber("");
        setPhone("");
        setNumber("");
        setPassword("");
        setPassword2("");
        setVerifyToken1("");
        setVerifyToken2("");
    }
    // 전송하기 버튼 클릭
    const onClickVerifySendHandler = async () => {
        if (phone.length !== 11) {
            info("휴대폰 번호를 확인해주세요.");
            return;
        } else if (employeeNumber.length !== 7) {
            info("아이디(사원번호)를 확인해주세요.");
            return;
        }

        try {
            headerStore.isLoading = true;
            let { data } = await auth.findPasswordAuth({
                employee_number: employeeNumber,
                cellphone: phone
            })
            info("인증번호를 발송했습니다.");
            setVerifyToken1(data.token);
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            // console.log(status, data);
            if (status === 400) {
                if(data?.code === "cannot_find_guest"){
                    info("입력하신 정보가 회원가입 시 입력한 정보와 일치하지 않습니다");
                    return;
                }
            }
            info("인증번호 발송 중 문제가 발생하였습니다");
            return;
        }
    }

    // 인증하기 버튼 클릭
    const onClickVerifyHandler = async () => {
        if (!verifyToken1) {
            info("인증번호를 발송하지 않았습니다.");
            return
        }
        if (number.length !== 6) {
            info("인증번호가 잘못되었습니다.");
            return
        }
        try {
            headerStore.isLoading = true;
            let { data } = await auth.findPasswordVerify({
                employee_number: employeeNumber,
                number,
                token: verifyToken1,
                cellphone: phone,
            })
            //ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            setVerifyToken2(data.token)
            headerStore.isLoading = false;
            info("새로운 비밀번호를 입력해주세요.")
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            // console.log(status, data)
            if (status === 404) {
                info("가입하지 않는 사용자입니다.")
                return;
            } else if (status === 400) {
                if (data?.code === "invalid_number") {
                    info("인증번호가 잘못되었습니다");
                    return;
                } else if (data?.code === 'not_requested') {
                    info("인증하기를 진행하지 않았습니다.");
                    return;
                }
            }

            info("문제가 발생했습니다.");
            return;
        }
    }

    // 비밀번호 변경
    const onFindPasswordChangeHandlre = async () => {
        if (!verifyToken2) {
            info("인증번호 확인 후 진행할 수 있습니다.");
            return
        }
        if (employeeNumber.length !== 7) {
            info("아이디(사원번호)를 확인해주세요.");
            return;
        }
        if (number.length !== 6) {
            info("인증번호가 잘못되었습니다.");
            return
        }
        if (passwordValid(password, password2)) {
            info("입력한 두 비밀번호가 일치하지 않거나 최소 8자리 넘어야 합니다.");
            return
        }
        try {
            headerStore.isLoading = true;
            let { data } = await auth.findPasswordChange({
                employee_number: employeeNumber,
                token: verifyToken2,
                cellphone: phone,
                password,
                password2
            })
            headerStore.isLoading = false;
            info("비밀번호 변경이 완료되었습니다.");
            init();
            props.onCloseSearchIdPopupHandler();
        } catch ({ status, data }) {
            // console.log(status, data);
            headerStore.isLoading = false;
            if (status === 404) {
                init();
                info("아이디와 휴대폰 번호로 사용자를 찾을 수 없습니다.");
                return
            } else if (status === 400) {
                if (data?.code === "invalid_number") {
                    info("인증번호가 잘못되었습니다.")
                    return;
                } else if (data?.code?.password) {
                    info("강력한 비밀번호로 바꿔주세요.");
                    return;
                } else if (data?.code === 'not_requested') {
                    info("인증하기를 진행하지 않았습니다.");
                    return;
                }
                return
            }

            info("비밀번호 변경중 문제가 발생했습니다.")
            return;
        }
    }

    if(!props.isShowSearchPassword)return <></>;

    return (
        <>
            {/* 비밀번호 찾기 팝업 */}
            <div id="pop_srch_id" className="pop_modal" style={{ display: props.isShowSearchPassword ? 'block' : 'none' }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>비밀번호 찾기</h3>
                        <button className="pop_btn_close pop_close" onClick={() => { init(); props.onCloseSearchIdPopupHandler(); }}>닫기</button>
                    </div>
                    <div className="contents_wrap">

                        <div className="form_write">
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_userid" className="title_form">아이디</label>
                                    <input type="text" 
                                        id="input_userid" 
                                        className="form_control form_sm" 
                                        placeholder="아이디를 입력해주세요" 
                                        maxLength={7}
                                        value={employeeNumber}
                                        onChange={(e: any) => {
                                            e.target.value = getEscapeString(e.target.value, INPUT_TYPE.employee);
                                            setEmployeeNumber(e.target.value);
                                        }}
                                        disabled={verifyToken1? true : false}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_phone_forpw" className="title_form">휴대폰 인증</label>
                                    <div className="form_phone">
                                        <input type="text" 
                                            id="input_phone_forpw" 
                                            className="form_control form_sm" 
                                            placeholder="숫자만 입력해주세요" 
                                            maxLength={11}
                                            value={phone}
                                            onChange={(e: any) => {
                                                e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum);
                                                setPhone(e.target.value);
                                            }}
                                            disabled={verifyToken1? true : false}
                                        />
                                        <button className="btn btn_sm btn_gray" onClick={onClickVerifySendHandler}>인증하기</button>
                                        {/*<button className="btn btn_sm btn_gray">재전송</button>*/}
                                    </div>
                                </div>
                            </div>

                            <ul className="lst_warning">
                                <li>회원가입 시 입력한 휴대폰 번호를 입력해주세요</li>
                            </ul>

                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_cnum_forpw" className="title_form">인증번호</label>
                                    <p className="form_phone">
                                        <input type="text" 
                                            id="input_cnum_forpw" 
                                            className="form_control form_sm" 
                                            placeholder="인증번호를 입력해주세요" 
                                            maxLength={6}
                                            value={number}
                                            onChange={(e: any) => {
                                                e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum);
                                                setNumber(e.target.value);
                                            }}
                                        />
                                        <button className="btn btn_sm btn_gray" onClick={onClickVerifyHandler}>확인</button>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr />

                        {/*비밀번호 결과*/}
                        <div className="form_write">
                            <div className="row">
                                <div className="col w100">
                                    <label className="title_form">새 비밀번호 등록</label>
                                    <dl className="lst_msg">
                                        <dd>{verifyToken2 && employeeNumber}님 새 비밀번호를 등록해주세요</dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_pw" className="title_form">비밀번호</label>
                                    <input type="password" 
                                        id="input_pw" 
                                        className="form_control form_sm" 
                                        placeholder="새 비밀번호를 입력해주세요" 
                                        maxLength={20}
                                        value={password}
                                        onChange={(e: any)=>setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_pw_re" className="title_form">비밀번호 확인</label>
                                    <input type="password" 
                                        id="input_pw_re" 
                                        className="form_control form_sm" 
                                        placeholder="비밀번호를 다시 한번 입력해주세요" 
                                        maxLength={20}
                                        value={password2}
                                        onChange={(e: any)=>setPassword2(e.target.value)}
                                    />
                                </div>
                            </div>
                            <ul className="lst_warning">
                                <li>비밀번호는 8~20자로 영문 , 특수문자, 숫자 중 세 가지를 조합하여 입력해주세요. 영문 대문자와 소문자를 구별해주세요</li>
                            </ul>
                        </div>

                    </div>
                    <div className="button_wrap">
                        <button type="button" className="btn btn_darkblue pop_close" onClick={onFindPasswordChangeHandlre}>완료</button>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MobileSearchPw;
