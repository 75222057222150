
import React from 'react';

const Footer = (props: any) => {
  return (
      <footer>
          
      </footer>
  )
}

export default Footer;
