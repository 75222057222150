import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MobileCheckLnb from '..';
import CustomCalendar from '../../../../components/organismas/customCalendar';
import MobileFileUpload from '../../../../components/organismas/form/mobileFile';
import DatePicker from '../../../../components/organismas/input/datepicker';
import { LODGE_MOVE_CODE, MOVE_STATUS_CODE, ROOM_TYPE } from '../../../../constants';
import { MessageContext, MessageImpl } from '../../../../routes';
import { useStores } from '../../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../../store/modules/modal';
import { showConfirm } from '../../../../utils/common';
import { formatByString_YYYYMMDD, getNextDay, getTodayDate } from '../../../../utils/date';
import '../../mb_common.scss';

const MobileCheckChange = observer((props: any) => {
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const { mobileTotalStore : store } = useStores();
    const { headerStore: headerStore } = useStores();
    const dispatch = useDispatch();
    const [isShowPop, setIsShowPop] = useState<boolean>(false);
    const [isShowConfirmPop, setIsShowConfirmPop] = useState<boolean>(false);
    const [room_types, setRoom_types] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                headerStore.getAuthMenuRole('2-3');
                headerStore.getAuthBuilding();
                headerStore.getAuthRoomRole();

                setRoom_types(headerStore.paramAuthRoomTypeRole.split(","));

                store.work_site = headerStore.workSite;
                store.roomtype = headerStore.paramAuthRoomTypeRole;
                if(Number(headerStore.workSite) === 2) {
                    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                        store.building = headerStore.paramAuthBuildingRole;
                    }    
                }
                await store.getGuestMoveList();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.limit, headerStore.workSite]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                store.buildingList = [];
                store.selectWorkSite = headerStore.workSite;
                store.dongList = [];
                store.selectBuilding = '';
                store.selectDong = '';

                if(store.selectRoomType != 0){
                    let resultData : any = await store.getBuildingList();   // 건물명
                    headerStore.isLoading = false;
                    setTimeout(function () {
                        if(resultData.save) {
                            return;
                        }else {
                            // info("입숙처리 중 문제가 발생하였습니다");
                            return;
                        }
                    }, 250);
                }

                let resultData2 : any = await store.getGuestMoveList();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(resultData2.save) {
                        return;
                    }else {
                        // info("입숙처리 중 문제가 발생하였습니다");
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectRoomType]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                store.selectWorkSite = headerStore.workSite;
                store.dongList = [];
                store.selectDong = '';

                let resultData: any = await store.getBuildingDongList();   // 건물 > 동
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(resultData.save) {
                        return;
                    }else {
                        // info("입숙처리 중 문제가 발생하였습니다");
                        return;
                    }
                }, 250);

                let resultData2 : any = await store.getGuestMoveList();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(resultData2.save) {
                        return;
                    }else {
                        // info("입숙처리 중 문제가 발생하였습니다");
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectBuilding]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                await store.getGuestMoveList();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectDong]);

    // 검색 클릭
    const onSearch = async() => {
        try {
            headerStore.isLoading = true;
            store.limit = 10;
            await store.getGuestMoveList();
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }

    const plusOffset = () => {
        store.limit += 10;
    }

    //role 핸들러
    const roleHandler = () => {
        info("권한이 없습니다.");
        return;
    }

    // 이숙처리
    const saveCheckout = async() => {
        if(store.managedAt === '' || store.managedAt === null){
            info('최종 이숙일을 선택해주세요');
            return false;
        }else if(!store.isKeyReturn && store.keySelect === '기타' && (store.keyDescription === '' || store.keyDescription === null)){
            info('열쇠 미 반납 이유를 적어주세요');
            return false;
        }else if(store.isEquipment && (store.equipmentDescription === '' || store.equipmentDescription === null)){
            info('비품 이상 여부를 적어주세요');
            return false;
        }

        try {
            headerStore.isLoading = true;
            let data : any = await store.moveAccess();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    setIsShowPop(false);
                    setIsShowConfirmPop(true);
                    store.getGuestMoveList();
                }else {
                    // info("이숙처리 중 문제가 발생하였습니다");
                    return;
                }
            }, 250);
        }catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }

    // 1:1 이숙처리
    const oneToOneMoveAccess = async()=> {
        showConfirm('이숙처리 하시겠습니까?', dispatch, async () => {
            try {
                headerStore.isLoading = true;
                let data : any = await store.oneToOneMoveAccess();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        info('이숙처리가 완료되었습니다');
                        store.popInit();
                        store.getGuestMoveList();
                    }else {
                        // info('이숙처리 중 문제가 발생되었습니다');
                      return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })
    }

    return (
        <>
            <MobileCheckLnb
                id=""
                className=""
                isLoading={headerStore.isLoading}
                {...props}
            >
                <div className="form_write">
                    <div className="row col_3ea">
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectRoomType}
                                onChange={(e:any) => store.selectRoomType = e.target.value}
                            >
                                <option value="0">숙소유형</option>
                                {room_types.map((item: any, index: number) => {
                                    if(Number(item) !== 0 && Number(item) !== 7) {
                                        return (<option key={index} value={item}>{ ROOM_TYPE[item] }</option>);
                                    }    
                                })}
                            </select>
                        </div>
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectBuilding}
                                onChange={(e:any) => store.selectBuilding = e.target.value}
                                disabled={!store.selectRoomType || store.buildingList.length === 0 ? true : false}
                            >
                                <option value="">건물명</option>
                                {store.buildingList.map((item: any, idx: number) => (
                                    <option key={idx} value={item.name}>{ item.name }</option>
                                ))}
                            </select>
                        </div>
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectDong}
                                onChange={(e:any) => store.selectDong = e.target.value}
                                disabled={!store.selectRoomType || !store.selectBuilding || store.dongList.length === 0 ? true : false}
                            >
                                <option value="">동</option>
                                {store.dongList.map((item: any, idx: number) => (
                                    <option key={idx} value={item.dong}>{ item.dong }</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col w100">
                        <div className="form_search">
                                <input type="text" className="form_control" placeholder="이름을 입력해주세요" 
                                    value={store.text}
                                    onChange={(e:any) => {store.text = e.target.value}}
                                    onKeyDown={(e: any) => {
                                        if (e.keyCode === 13) onSearch();
                                    }}
                                />
                                <button type="button" className="btn_srch" onClick={() => onSearch()}></button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                {/* 이숙처리 리스트 */}
                <div className="tab_contents on">  {/* 'on' 추가 시 해당 내용 show */}
                    <div className="group_title">
                        <h4 className="title_contents">이숙처리 리스트</h4>
                    </div>
                    <hr />

                    {store.list.length <= 0 ? (
                        <div className="blank_area">
                            <p>데이터가 없습니다</p>
                        </div>
                    ):(
                        store.list.map((item: any, idx: number) => (
                            <dl className="lst_checkin">
                                <dt>
                                    <ul>
                                        <li>{item.guest.guest_name}</li>
                                        <li>{item.guest.guest_cellphone}</li>
                                    </ul>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>
                                            <strong>신청일자</strong>
                                            <span>{item.created_at? formatByString_YYYYMMDD(item.created_at) : ''}</span>
                                        </li>
                                        <li>
                                            <strong>이숙예정일</strong>
                                            <span>{item.moved_at}</span>
                                        </li>
                                        <li>
                                            <strong>숙소정보</strong>
                                            <span>{item.guest.room_building_name} {item.guest.room_building_dong} {item.guest.room_name}호</span>
                                        </li>
                                        <li>
                                            <strong>진행상태</strong>
                                            <span>
                                                {item.status === MOVE_STATUS_CODE.approve && item.category !== LODGE_MOVE_CODE.one? (
                                                    //headerStore.finallyRoleFlag ? (
                                                        <button className="btn btn_xs btn_outline" onClick={()=>{store.selectData = item; setIsShowPop(true);}}>이숙처리</button>
                                                    //):(
                                                      //  <button className="btn btn_xs btn_outline" onClick={()=>{roleHandler();}}>이숙처리</button>
                                                    //)
                                                ) :
                                                    item.status === MOVE_STATUS_CODE.approve && item.category === LODGE_MOVE_CODE.one?
                                                      //  headerStore.finallyRoleFlag ? (
                                                            <button className="btn btn_xs btn_outline" onClick={()=>{store.selectData = item; oneToOneMoveAccess();}}>1:1 이숙처리</button>
                                                        //):(
                                                          //  <button className="btn btn_xs btn_outline" onClick={()=>{roleHandler();}}>1:1 이숙처리</button>
                                                        //)
                                                    :''
                                                }
                                            </span>
                                        </li>
                                    </ul>
                                </dd>
                            </dl>
                        ))
                    )}
                </div>

                {store.limit < store.totalCount ? (
                    <button className="btn btn_lg btn_outline btn_more" onClick={plusOffset}>
                        더보기<i className="ico_plus"></i>
                    </button>
                ) : ''}

            </MobileCheckLnb>

            {/* 이숙처리 팝업 */}
            <div id="wrap" className="wrap_mobile">
                <div id="" className="pop_modal" style={{display: isShowPop?'block':'none'}}>
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <button className="pop_btn_prev" onClick={()=>{setIsShowPop(false); store.popInit();}}></button>
                            <h3>이숙완료처리</h3>
                            <button className="pop_btn_close pop_close" onClick={()=>{setIsShowPop(false); store.popInit();}}>닫기</button>
                        </div>
                        <div className="contents_wrap">
                            <div className="form_write">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="input_date_movein" className="title_form">이숙요청일</label>
                                        {/* <input type="text" id="input_date_movein" className="form_control form_sm datepicker" placeholder="" /> */}
                                        {store.selectData.moved_at}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="input_date_request" className="title_form">신청일</label>
                                        {/* <input type="text" id="input_date_request" className="form_control form_sm datepicker" placeholder="" value="" /> */}
                                        {store.selectData.created_at? formatByString_YYYYMMDD(store.selectData.created_at) : ''}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">   {/* 열쇠 반납 체크 시 */}
                                    <div className="col w100">
                                        <label htmlFor="key_choice1" className="title_form">열쇠 반납 여부</label>
                                        <div className="form_radio">
                                            <label className="input_radio" htmlFor="key_choice1">
                                                <input type="radio" 
                                                    id="key_choice1" 
                                                    name="key_choice" 
                                                    checked={store.isKeyReturn} 
                                                    onClick={() => store.isKeyReturn = true}
                                                />
                                                <span className="label_text">반납 완료</span>
                                            </label>
                                            <label className="input_radio" htmlFor="key_choice2">
                                                <input type="radio" 
                                                    id="key_choice2" 
                                                    name="key_choice" 
                                                    checked={!store.isKeyReturn} 
                                                    onClick={() => store.isKeyReturn = false}
                                                />
                                                <span className="label_text">미 반납</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* <hr className="hr_inline" />
                                <div className="row mt20">
                                    <div className="col">
                                        <input type="text" id="input_date_key" className="form_control form_sm datepicker" placeholder="" />
                                    </div>
                                </div> */}

                                <hr className="hr_inline" />
                                {!store.isKeyReturn? (
                                    <>
                                        <div className="row mt20">
                                            <div className="col">
                                                <select 
                                                    className="form_control form_sm"
                                                    value={store.keySelect} 
                                                    onChange={(e:any) => store.keySelect = e.target.value}
                                                >
                                                    <option value="열쇠 분실">열쇠 분실</option>
                                                    <option value="기타">기타</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mt20">
                                            <div className="col w100">
                                                <textarea className="form_control" placeholder="미 반납 이유를 적어주세요"
                                                    value={store.keyDescription} 
                                                    onChange={(e:any) => store.keyDescription = e.target.value}
                                                    disabled={store.keySelect === "기타"? false : true}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <hr />
                                    </>
                                ): (
                                    <>
                                        {/* <div className="row mt20">
                                            <div className="col">      
                                                <DatePicker
                                                    value={store.keyoutAt}
                                                    width={250}
                                                    onChange={(date: string) => store.keyoutAt = date}
                                                    isPrevMonthView={true}
                                                    max={getNextDay(getTodayDate()[0],1)[0]}
                                                />
                                            </div>
                                        </div> */}
                                    </>
                                )}
                                
                                <div className="row">
                                    <div className="col w100">
                                        <label htmlFor="product_choice1" className="title_form">비품 이상 여부</label>
                                        <div className="form_radio">
                                            <label className="input_radio" htmlFor="product_choice1">
                                                <input type="radio" id="product_choice1" name="product_choice" 
                                                    checked={!store.isEquipment} onClick={() => store.isEquipment = false}
                                                />
                                                <span className="label_text">이상 없음</span>
                                            </label>
                                            <label className="input_radio" htmlFor="product_choice2">
                                                <input type="radio" id="product_choice2" name="product_choice" 
                                                    checked={store.isEquipment} onClick={() => store.isEquipment = true}
                                                />
                                                <span className="label_text">이상 있음</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr_inline" />

                                {store.isEquipment? (
                                    <>
                                    <div className="row mt20">
                                        <div className="col w100">
                                            <textarea name="" id="" className="form_control" placeholder="비품 이상 여부를 적어주세요"
                                                value={store.equipmentDescription} 
                                                onChange={(e:any) => store.equipmentDescription = e.target.value}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <hr />
                                    </>
                                ):''}
                                
                                <MobileFileUpload  
                                    type="image" 
                                    fileId="attacheFile" 
                                    items={store.files} 
                                    onSetData={(data: any[]) => store.files = ([...data])} 
                                    setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                                    imgTitle={"숙소 이미지 첨부"}
                                />
                                
                                <hr />
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="input_date_movein_final" className="title_form">최종 이숙일</label>
                                        {/* <DatePicker
                                            value={store.managedAt}
                                            width={250}
                                            onChange={(date: string) => store.managedAt = date}
                                            isPrevMonthView={true}
                                            max={getNextDay(getTodayDate()[0],1)[0]}
                                        /> */}
                                        <CustomCalendar
                                            max={getTodayDate()[0]}
                                            value={store.managedAt}
                                            onChange={(date: string) => store.managedAt = date}
                                        ></CustomCalendar>
                                    </div>
                                    {/* <div className="col">
                                        <label htmlFor="input_roominfo_movein" className="title_form">이숙숙소정보</label>
                                        <input type="text" id="input_roominfo_movein" className="form_control form_sm" placeholder="" value="102동 505호" />
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col w100">
                                        <label htmlFor="input_etc" className="title_form">비고 입력</label>
                                        <textarea name="" id="input_etc" className="form_control" placeholder="내용을 입력하세요"
                                            value={store.memo} onChange={(e:any) => store.memo = e.target.value}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={(e:any) => {saveCheckout()}}>완료</button>
                        </div>
                    </div>
                </div>

                {/* 이숙처리 완료 팝업 */}
                <div id="pop_complete_movein" className="pop_dim_wrap pop_modal" style={{display: isShowConfirmPop? 'block' : 'none'}}>
                    <div className="pop_wrap">
                        <div className="contents_wrap">
                            <div className="title_contents">이숙처리가 완료되었습니다</div>
                            <div className="form_write">
                                <dl className="lst_checkin">
                                    <dt>
                                        <ul>
                                            <li>{store.selectData? store.selectData.guest_name: ''}</li>
                                            <li>{store.selectData? store.selectData.guest_cellphone: ''}</li>
                                        </ul>
                                    </dt>
                                    <dd>
                                        <ul>
                                            <li>
                                                <strong>신청일자</strong>
                                                <span>{store.selectData.created_at? formatByString_YYYYMMDD(store.selectData.created_at) : ''}</span>
                                            </li>
                                            <li>
                                                <strong>숙소정보</strong>
                                                <span>
                                                    {store.selectData && store.selectData.guest?
                                                        store.selectData.guest.room_building_name + ' ' + store.selectData.guest.room_building_dong + ' ' + store.selectData.guest.room_name + '호' 
                                                    : ''}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>이숙요청일</strong>
                                                <span>{store.selectData.moved_at}</span>
                                            </li>
                                            <li>
                                                <strong>이숙완료일</strong>
                                                <span>{store.managedAt}</span>
                                            </li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>

                        </div>
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={()=>{store.popInit(); setIsShowConfirmPop(false);}}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MobileCheckChange;
