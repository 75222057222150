import {action, makeObservable, observable} from 'mobx';
import * as apis from '../../apis';
import { getTodayDate, getPrevDay, formatByString_YYYYMMDD, getIntervalMonthDate, rightBig } from '../../utils/date';
import { BoardStore } from './boardStore';
import * as XLSX from 'xlsx';
import { BOOKING_STATUS_CODE, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, STATUS_CODE, WORK_SITE } from '../../constants';
import { isNull } from 'lodash';
import { getWorkSitePhoneNum } from '../../utils/common';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class ApplicantJudgeStore extends BoardStore {
  roomType: number = -1;
  bookings: any = [];
  selectBackroom: any = [];                 // 출장자 단체 방 선택
  selectBackroomByDormitory: any = [];      // 기숙사 
  selectBackroomByCompanyHouse: any = [];   // 사택
  selectBackroomByFamilyHouse: any = [];    // 가족숙소
  selectBackroomByVipRoom: any = [];        // 영빈관
  option: string = 'guest_name';            //검색 옵션
  option_status: string = '0';               // 검색 옵션 2 ( 예약 상태 값 )
  text: string = '';                        // 검색 텍스트
  dateAfter: string = getIntervalMonthDate(2)[1];
  dateBefore: string = getIntervalMonthDate(2)[0];
  booking: any = [];
  files: any = [];
  viewFiles: any = []; 
  selectRow: any = [];    // 반려, 입숙처리 시 선택한 row

  // 배방 관련
  workSite: any;
  buildings: any = [];
  dongs: any = [];
  floors: any = [];
  selectFloor: number = 0;
  building: any = [];
  room: any = [];
  floorData: any = [];
  firstNumList: any = [];
  isVipRoomPop: boolean = false;
  isCompanyRoomPop:boolean = false;
  isDormitoryPop: boolean = false;
  isBusinessGroupPop: boolean = false;
  isFamilyPop: boolean = false;
  isBusinessSinglePop: boolean = false;
  selectRoomByGroup: any = [];
  selectDateGroup: any = [];
  popReload: boolean = false;
  openRoomInfoPop: boolean = false;
  manageCheckin: boolean = false;     // 관리자 강제 입숙인지 확인용
  manageCheckChange: boolean = false;   // 관리자 강제 이숙인지 확인용

  // 상세 관련
  guests: any = [];
  applicant: any = [];
  status: number = 0;
  createdAt: string = '';
  mealTicket: number = 0;
  detailWorkSite: string = '';
  detailData: any = [];

  // 입숙예정일
  statusDescription: string = '';

  readRoleFlag = true;
  writeRoleFlag = true;
  deleteRoleFlag = true;
  downRoleFlag = true;
  mobileRoleFlag = true;

  //api 호출시 보낼 사업장 및 장소
  paramAuthRoomTypeRole : any;
  paramAuthWorkSiteRole : any;
  paramAuthBuildingRole : any;
  mainbookings : any = [];

  //loading
  headerStore : HeaderStore;

  //authBuilding
  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      roomType: observable,
      bookings: observable,
      selectBackroom: observable,
      selectBackroomByDormitory: observable,
      selectBackroomByCompanyHouse: observable,
      selectBackroomByFamilyHouse: observable,
      selectBackroomByVipRoom: observable,
      option: observable,
      option_status: observable,
      text: observable,
      dateAfter: observable,
      dateBefore: observable,
      booking: observable,
      files: observable,
      viewFiles: observable,
      selectRow: observable,
      workSite: observable,
      buildings: observable,
      dongs: observable,
      floors: observable,
      selectFloor: observable,
      building: observable,
      room: observable,
      floorData: observable,
      firstNumList: observable,
      isVipRoomPop: observable,
      isCompanyRoomPop: observable,
      isDormitoryPop: observable,
      isBusinessGroupPop: observable,
      isFamilyPop: observable,
      isBusinessSinglePop: observable,
      selectRoomByGroup: observable,
      selectDateGroup: observable,
      popReload: observable,
      openRoomInfoPop: observable,
      manageCheckin: observable,
      manageCheckChange: observable,
      guests: observable,
      applicant: observable,
      status: observable,
      createdAt: observable,
      mealTicket: observable,
      detailWorkSite: observable,
      detailData: observable,
      statusDescription: observable,
      readRoleFlag : observable,
      writeRoleFlag : observable,
      deleteRoleFlag : observable,
      downRoleFlag : observable,
      mobileRoleFlag : observable,
      paramAuthRoomTypeRole : observable,
      paramAuthWorkSiteRole : observable,
      paramAuthBuildingRole : observable,
      mainbookings : observable,
      

      getItems: action,
      setGridData: action,
      downloadBookingList: action,
      reflectSubmit: action,
      getBuildsInfo: action,
      getFloorsInfo: action,
      getOneBuildInfo: action,
      roomInit: action,
      getItem: action,
      companyHouseApprove: action,
      documentReCheck: action,
      
    });

    this.init();
  }

  // 리스트 초기화
  init() {
    this.checkeds = {};
    this.allCheckFlg = false;
    this.roomType = -1;
    this.paramAuthBuildingRole = '';
  }
  // @debounce(500)
  async getItems() {
    const payload: any = {
      //  status: [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(','),
      //offset: this.offset,
      //limit: this.limit,
      guest_status: [LODGE_STATUS_CODE.opened, LODGE_STATUS_CODE.waiting, LODGE_STATUS_CODE.closed].join(','),
      created_after: this.dateAfter,
      created_before: this.dateBefore,
      worksite : this.workSite
    };

    if (this.roomType > -1) {
      payload.roomtype = this.roomType;
    } else {
      //전체 
      payload.roomtype = this.paramAuthRoomTypeRole;
    }
    if(Number(this.workSite) > -1 ) {
      payload.worksite = this.workSite;
    } else {
      payload.worksite = this.workSite;
    }
    
    if(this.option && this.text) {
      payload[this.option] = this.text;
    }

    console.log(this.option_status);
    
    if(this.option_status) {
      if(this.option_status == "0") {
        payload.option_status = BOOKING_STATUS_CODE.total;
      }
      else if(this.option_status == "1") {
        payload.option_status = BOOKING_STATUS_CODE.applied;
      }
      else if(this.option_status == "2") {
        payload.option_status = BOOKING_STATUS_CODE.rejected;
      }
      else if(this.option_status == "3") {
        payload.option_status = BOOKING_STATUS_CODE.terminated;
      }
      else if(this.option_status == "4") {
        payload.option_status = BOOKING_STATUS_CODE.canceled;
      }
      else if(this.option_status == "5" || this.option_status == "6" || this.option_status == "8") {
        payload.option_status = BOOKING_STATUS_CODE.approved;
      }
      else if(this.option_status == "7") {
        payload.option_status = BOOKING_STATUS_CODE.stayHold;
      }
      else if(this.option_status == "9") {
        payload.option_status = BOOKING_STATUS_CODE.roomOpen;
      }
      else if(this.option_status == "10") {
        payload.option_status = BOOKING_STATUS_CODE.approved;
      }
      else if(this.option_status == "11") {
        payload.option_status = BOOKING_STATUS_CODE.stayAfter;
      }
      else if(this.option_status == "12") {
        payload.option_status = BOOKING_STATUS_CODE.stayAfterTerminated;
      }
      else if(this.option_status == "13") {
        payload.option_status = BOOKING_STATUS_CODE.documentReject;
      }
      else if(this.option_status == "14") {
        payload.option_status = BOOKING_STATUS_CODE.documentSubmit;
      }
    }

    if(Number(this.workSite) === 2) {
      if(!isNull(this.paramAuthBuildingRole) && this.paramAuthBuildingRole !== undefined && this.paramAuthBuildingRole.length > 0) {
        payload.authBuilding = this.paramAuthBuildingRole
      }
    }
    
    if(payload.option_status != BOOKING_STATUS_CODE.total /*&& (Number(payload.roomtype) === ROOM_TYPE_CODE.dormitory_room || Number(payload.roomtype) === ROOM_TYPE_CODE.company_room)*/) {
      // tab - 사택, 기숙사인경우 대기순번 표시
      try{
        this.headerStore.isLoading = true;
        const { data } = await apis.build.getBookingRanks(payload);
        this.bookings = data.results;
        await this.setGridData(data);
        this.totalPage = Math.ceil(data.count / this.limit);

        this.headerStore.isLoading = false;
        
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
    }

    }else {
      try{
        this.headerStore.isLoading = true;
        const { data } = await apis.build.getBookings(payload);
        this.bookings = data.results;
        await this.setGridData(data);
        this.totalPage = Math.ceil(data.count / this.limit);
        this.headerStore.isLoading = false;
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
      }
    }
    //this.bookings = data.results.sort((a: any, b: any) => b.room_type - a.room_type);
    //console.log('mainbookings>>>>>>>>>>>>>>', this.mainbookings);
    //this.setGridData(data.results); // limit, offset용
    //await this.getRole();
    //await this.setGridData(data);
    //this.totalPage = Math.ceil(data.count / this.limit);
    // console.log('bookings', this.bookings);
  }

  // 그리드 데이터 담기
  setGridData(data: any[]) {
    const newData: any[] = [];
    
    data.forEach((item: any, idx: number) => {
      const deep1 = {...item};
      const deep2 = item.guests[0];   // 이용자 영역 담기
      
      deep1.created_at = formatByString_YYYYMMDD(deep1.created_at);
      deep1.modified_at = formatByString_YYYYMMDD(deep1.modified_at);

      deep1.guestIds = [];
      deep1.guestList = [];
      deep1.mobileRoleFlag = this.mobileRoleFlag;
      deep1.downRoleFlag = this.downRoleFlag;
      deep1.writeRoleFlag= this.writeRoleFlag;
      deep1.deleteRoleFlag = this.deleteRoleFlag;
      // console.log("mobileRoleFlag>>", deep1.mobileRoleFlag , "downRoleFlag>>", deep1.downRoleFlag, "writeRoleFlag>>", deep1.writeRoleFlag, "deleteRoleFlag>>", deep1.deleteRoleFlag );
      item.guests.map((item: any) => {
          deep1.guestIds.push(item.id);
          deep1.guestList.push(item);
      });

      if(item.guests.length > 1) {
          deep2.guest_name_total = item.guests[0].guest_name + ' 외 ' + (parseInt(item.guests.length)-1) + '명';
      }else {
        deep2.guest_name_total = item.guests[0].guest_name;
      }

      deep2.guest_status = item.guests[0].status;

      delete deep1.guests;
      //delete deep1.files;
      newData.push({...deep2, ...deep1});    // 이용자 먼저 담고 신청자 담기 (신청자 먼저 담으면 이용자랑 같은 컬럼명 덮어써짐)
    });

    this.bookings = [...newData];
    //if(gridApi) gridApi.deselectAll();  // 체크 박스 -> false
  }

  async downloadBookingList() {
    // console.log(ids, this.bookings);
    try{

      this.headerStore.isLoading = true;

      let ids: any = this.bookings.map((bookingId: any) => bookingId.id);
      let { data, headers } = await apis.core.downloadByBookingIds(ids.join(','));
      let csv = XLSX.read(data, { type: "string" })
      XLSX.writeFile(csv, "bookingList.xlsx")

      this.init();
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });

      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }

    
  }

  async reflectSubmit(reflectType: number, date: string, description: string) {
    const REJECT = BOOKING_STATUS_CODE.rejected       // 재요청
    const TERMINATED = BOOKING_STATUS_CODE.terminated // 거절

    let payload: any = {
      description
    }

    if (reflectType === REJECT) {
        payload.terminated_at = date
    }

    if (reflectType === TERMINATED) {
      try{
        this.headerStore.isLoading = true;

        let { data } = await apis.lodge.lodgeTerminated(this.selectRow.id, payload)

        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
        // console.log(data)
    } else if (reflectType === REJECT) {
        try{
          this.headerStore.isLoading = true;

          let { data } = await apis.lodge.lodgeReject(this.selectRow.id, payload)
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }

          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }
            // console.log(data)
      }
  }

  // 배방 초기화
  roomInit() {
    this.selectRow = [];
    this.buildings = [];
    this.building = [];
    this.floors = [];
    this.floorData = [];
    this.firstNumList = [];
    this.selectFloor = 0;
    this.room = [];
    this.openRoomInfoPop = false;
    this.manageCheckin = false;
    this.manageCheckChange = false;
  }

  // 배방 건물 정보
  async getBuildsInfo(){
    const payload: any = {
      work_site: this.workSite,
      room_type: this.selectRow.room_type
    };

    if(Number(this.workSite) === 2) {
      if(!isNull(this.paramAuthBuildingRole) && this.paramAuthBuildingRole !== undefined && this.paramAuthBuildingRole.length > 0) {
        payload.auth_Building = this.paramAuthBuildingRole
      }
    }
    try{
      this.headerStore.isLoading = true;

      let { data } = await apis.build.getBuilds(payload);
      this.buildings = data;
      // 단체같은 경우, 선택한 건물이 있다면 유지
      if(this.building.id){
        let tempBuilding = data.filter((b: any) => b.id === this.building.id);
        this.building = tempBuilding[0];
      }else {
        this.building = data.length? data[0] : [];
        this.selectFloor = 0;
      }

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
    //this.dongs = data.length? data[0].floors : [];
  }

  // 배방 층 정보
  async getFloorsInfo(stayAfter?:string, stayBefore?:string, gender?:number){
    const payload: any = {
      building: this.building.id,
      room_type: this.selectRow.room_type,
      stay_after: this.selectRow.stay_after,
      stay_before: this.selectRow.stay_before,
      gender_type: this.selectRow.guest_gender
    };
   
    // 단체의 경우
    if(stayAfter){
      payload['stay_after'] = stayAfter;
      payload['stay_before'] = stayBefore;
      payload['gender_type'] = gender;
    }
    try{

      this.headerStore.isLoading = true;

      let { data } = await apis.build.getFloors(payload);

      let tempFloors = data.map((floor: any) => {
        return {
          step: floor.name,
          rooms: floor.rooms.filter((room: any) => {
            //return room;    // 전체 숙소타입 보내기
            room.building_name = this.building.name + ' ' + this.building.dong
            return room.room_type === this.selectRow.room_type    // 해당 유형의 숙소타입만 보내기
          })
        }
      }).filter((floor: any) => floor.rooms.length)

      this.floors = tempFloors;
      this.floorData = tempFloors[0];
      
      // floor length가 selectFloor 보다 작으면 성별이 바뀌거나 같은경우라 0으로 하기
      if(tempFloors.length < this.selectFloor) {
        this.selectFloor = 0;
      }
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
    //this.selectFloor = 0;
  }

  // 기숙사 - 배방 우선순위표
  async getFirstAssign() {
    let payload: any = {
      work_site : this.workSite,
      //dong : this.building.dong,
      room_building_name: this.building.name,
      floor : this.floorData ? this.floorData.step : 0,
      room_type : this.selectRow.room_type,
      gender_type : this.selectRow.guest_gender
    }
    try{
      this.headerStore.isLoading = true;

      const { data } = await apis.lodge.getFirstAssign(payload);
      this.firstNumList = data;
      
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 방이 포함된 건물과 방만 가져오기 (배방확인용)
  async getOneBuildInfo(){
    // console.log('this.selectRow' , this.selectRow);

    const payload: any = {
      work_site: this.workSite,
      room_type: this.selectRow.room_type,
      room: this.selectRow.room
    };

    try{
      this.headerStore.isLoading = true;

      let { data } = await apis.build.getBuilds(payload);

      // floor 정보
      const payload2: any = {
        building: data[0].id,
        room_type: this.selectRow.room_type,
        stay_after: this.selectRow.stay_after,
        stay_before: this.selectRow.stay_before,
        gender_type: this.selectRow.guest_gender
      };

      let { data: floors } = await apis.build.getFloors(payload2);

      let floorNumber: number = 0;
      let tempFloors = floors.map((floor: any) => {
        return {
          step: floor.name,
          rooms: floor.rooms.filter((room: any) => {
            if(room.id === this.selectRow.room) floorNumber = parseInt(floor.name);
            room.building_name = this.building.name + ' ' + this.building.dong
            return room.room_type === this.selectRow.room_type    // 해당 유형의 숙소타입만 보내기
          })
        }
      });

      this.floors = tempFloors;
      let num = 0;

      tempFloors.map((fl:any, idx: number) => {
        if(Number(fl.step) === this.selectRow.room_floor_name){
          num = idx;
        }
      });

      this.selectFloor = num ? num : 0;  // 방 층 선택하기 (index로 비교중이기 때문)

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 상세
  async getItem(id: number){
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBooking(id, {
        //today: false,
      });
  
      this.detailData = data;
      this.guests = data.guests;
      this.applicant = ({
        employeeNumber: data.applicant_employee_number,
        cellphone: data.applicant_cellphone,
        department: data.applicant_department,
        name: data.applicant_name,
        gender: data.applicant_gender
      });
      this.files = (data.files || []);
      this.status = data.status;
      this.createdAt = formatByString_YYYYMMDD(data.created_at);
      this.roomType = data.room_type;
      this.detailWorkSite = WORK_SITE[data.room_work_site];
      this.mealTicket = data.meal_ticket;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  setGuests(data: any) {
    this.guests = data;
  }

  // 입숙예정일 변경 요청
  async reschedule() {
    if(this.selectRow.room_type === ROOM_TYPE_CODE.company_room) {
      try{
        this.headerStore.isLoading = true;
        const { data } = await apis.lodge.lodgeReshedule(this.selectRow.id, {
          status_description : this.statusDescription
        });
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }else  if(this.selectRow.room_type === ROOM_TYPE_CODE.dormitory_room) {
      try{
        this.headerStore.isLoading = true;

        const { data } = await apis.lodge.lodgeResheduleDormitory(this.selectRow.id, {
          status_description : this.statusDescription
        });

        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }

    // 예정일 변경 요청 문자 발송
    let smsPayload: any = {
      cellphone : this.selectRow.applicant_cellphone,
      message : '입숙신청이 다음과 같은 사항으로 변경요청되었습니다. \n< '+this.statusDescription+' 변경요청  >'
    }
    try{
      this.headerStore.isLoading = true;

      const { data: sms } = await apis.lodge.smsSendAPI(smsPayload);

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

   // 입숙 최종승인
   async scheduleApprove() {
    if(this.selectRow.room_type === ROOM_TYPE_CODE.company_room) {
      try{
        this.headerStore.isLoading = true;
        const { data } = await apis.lodge.lodgescheduleCheck(this.selectRow.id);  // 사택 -> 증빙서류 요청상태로 변경
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }else  if(this.selectRow.room_type === ROOM_TYPE_CODE.dormitory_room) {
      try{
        this.headerStore.isLoading = true;
        const { data } = await apis.lodge.lodgescheduleApproveDormitory(this.selectRow.id);

        // 기숙사 예약 완료 문자 발송
        let smsPayload: any = {
          cellphone : this.selectRow.guest_cellphone,
          message : '입숙신청이 최종승인되었습니다. \n< 최종승인 완료> \n입숙예정일 : ' + this.selectRow.stay_after + '\n호  실 : '+this.selectRow.room_building_name+' '+this.selectRow.room_building_dong +' '+this.selectRow.room_name
                  +' \n입숙예정일에 입숙관련 세부사항 조율을 위해 관리사무실로 꼭 방문부탁드립니다.'
                  + '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(this.selectRow.room_work_site, this.selectRow.room_type)
        }
        const { data: sms } = await apis.lodge.smsSendAPI(smsPayload);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  // 사택 증빙서류 재요청
  async documentReCheck() {
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeDocumentReCheck(this.selectRow.id, {
        status_description : this.statusDescription
      }); 
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 사택 증빙서류 후 최종승인
  async companyHouseApprove() {
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgescheduleApprove(this.selectRow.id); 

      // 사택 예약 완료 문자 발송
      let smsPayload: any = {
        cellphone : this.selectRow.guest_cellphone,
        message : '입숙신청이 최종승인되었습니다. \n< 최종승인 완료> \n입숙예정일 : ' + this.selectRow.stay_after + '\n호  실 : '+this.selectRow.room_building_name+' '+this.selectRow.room_building_dong +' '+this.selectRow.room_name
                  +' \n입숙예정일에 입숙관련 세부사항 조율을 위해 관리사무실로 꼭 방문부탁드립니다.'
                  + '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(this.selectRow.room_work_site, this.selectRow.room_type)
      }
      const { data: sms } = await apis.lodge.smsSendAPI(smsPayload);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 단체 배방진행 완료 - 승인
  async groupScheduleApprove() {
    if(this.selectRow.room_type === ROOM_TYPE_CODE.business_room_group) {
      try{
        this.headerStore.isLoading = true;
        const { data } = await apis.lodge.lodgeGroupscheduleApprove(this.selectRow.id);

        // 배방 오픈 문자 발송
        let guestsCellPhone = this.selectRow.guestList.map((item: any) => {
          return item.guest_cellphone;
        });

        let smsPayload: any = {
          cellphone : guestsCellPhone.join(','),
          message : 'AMS 플랫폼 숙소이용 예약이 완료되었습니다. 입숙예정일을 확인하시고 입숙예정일에 관리사무소로 방문하시기 바랍니다. 신청 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다'
        }
        const { data: sms } = await apis.lodge.smsSendAPI(smsPayload);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  //메인용
  async getMainItems() {
    const payload: any = {
      offset: this.offset,
      limit: this.limit,
      guest_status: [LODGE_STATUS_CODE.opened, LODGE_STATUS_CODE.closed].join(','),
      created_after: this.dateAfter,
      created_before: this.dateBefore,
      worksite : this.workSite
    };

    if (this.roomType > -1) {
      payload.roomtype = this.roomType;
    } else {
      //전체 
      payload.roomtype = this.paramAuthRoomTypeRole;
    }
    if(Number(this.workSite) > -1 ) {
      payload.worksite = this.workSite;
    } else {
      payload.worksite = this.workSite;
    }
    
    if(this.option && this.text) {
      payload[this.option] = this.text;
    }
    if(Number(this.workSite) === 2) {
      if(!isNull(this.paramAuthBuildingRole) && this.paramAuthBuildingRole !== undefined && this.paramAuthBuildingRole.length > 0 && this.paramAuthBuildingRole !== 'null') {
        payload.authBuilding = this.paramAuthBuildingRole
      }  
    }
    
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.build.getBookings(payload);
      this.mainbookings = data.results;
      // console.log('mainbookings>>>>>>>>>>>>>>', this.mainbookings);
      //this.setGridData(data.results); // limit, offset용
      //await this.getRole();
      this.totalPage = Math.ceil(data.count / this.limit);
      // console.log('bookings', this.bookings);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  //입숙취소
  async lodgeJudgeCancle() {
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeJudgeCancle(this.selectRow.id);
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  //배방 취소
  async lodgeRoomCancel() {
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.lodge.lodgeRoomCancel(this.selectRow.id);
      this.headerStore.isLoading = false;
      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }
}