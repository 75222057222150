import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useStores } from '../../../../store/mobx';
import ViewContent from './ViewContent';
import WriteContent from './WriteContent';
import _ from 'lodash';

const NoticeDetail = observer((props: any) => {
  const {noticeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/manage/notice'

  const [isEdit, setIsEdit] = useState<any>(props.isEdit);
  const isCreate = props.isEdit && !props.match.params.id;

  useEffect(() => {
    headerStore.isLoading = true;
    if (!isCreate) {
      if (!store.isChange()) {
        (async () => {
          await store.getItem(props.match.params.id);
          headerStore.isLoading = false;
          
        })();
      }
    } else {
      if (!store.isChange() || !store.item) {
        if (!store.item || (store.item && !_.includes(store.item.title, '복제'))) {
          store.newItem();
          headerStore.isLoading = false;
        }
      }
    }
    headerStore.isLoading = false;
  }, [headerStore.workSite]);

  const onCloneHandler = () => {
    const refId = store.orgItem.id;
    store.clone();
    props.history.replace(`${baseUrl}/create?refId=${refId}`);
  };

  // if (isLoading) return <></>;
 
  return (
    <>
      {isEdit ? (
        <WriteContent setIsEdit={setIsEdit} {...props} />
      ) : (
        <ViewContent setIsEdit={setIsEdit} onCopy={onCloneHandler} {...props} />
      )}
    </>
  );
});

export default NoticeDetail;
