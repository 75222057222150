import { observer } from 'mobx-react';
import React, { useState, useEffect, useContext } from 'react';
import { useStores } from '../../../../store/mobx';
import { voc as api, sms } from '../../../../apis';
import ViewContent from './ViewContent';
import WriteContent from './WriteContent';
import ChoiceManager from '../../../organismas/popup/choiceManager';
import PlaceOccurrence from '../../../organismas/popup/placeOccurrence';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../../routes';
import { SHOW_ALERT_MODAL } from '../../../../store/modules/modal';
import { formatByString, format_YYYYMMDDhhmm } from '../../../../utils/date';
import { useSelector } from 'react-redux';
import { stripCommentTag } from 'xss';

const VocDetail = observer((props: any) => {
  const {vocStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const item = store.item;
  const isCreate = props.isEdit && !props.match.params.id;

  const [isEdit, setIsEdit] = useState<any>(props.isEdit);
  const [isPlaceOpen, setIsPlaceOpen] = useState<boolean>(false);
  const [isChoiceManagerOpen, setIsChoiceManagerOpen] = useState<boolean>(false);

  const { info, warning, error }: MessageImpl = useContext(MessageContext)
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const [reload, setReload] = useState<boolean>(false);
  const baseUrl = '/lodged/maintenance';

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));

  useEffect(() => {
    // console.log('testing...');
    headerStore.isLoading = true;
    if (!isCreate) {
      if (!store.isChange) {
        (async () => {
          let data : any = await store.getItem(props.match.params.id);
          headerStore.isLoading = false;
          setTimeout(function () {
            if(data.save) {
              store.answerImages = [];
              return;
            }else {
              return;
            }
          }, 250);
        })();
      }
      headerStore.isLoading = false;
    } else {
      if (!store.isChange || !store.item) {
        store.newItem();
        headerStore.isLoading = false;
      }
    }
    headerStore.isLoading = false;
  }, [reload]);

  const onUpdateManagers = async (selectedManagers: any, contents: string) => {
    let temp = selectedManagers.map((user: any) => user);
    let cellMap = temp.map((item:any, index:number) => {
      return item.cellphone
    }).join(";")
    

    // if(contents !== "" && item.guests.length <= 0) {
    //   info("문자 내용 입력 또는 발송대상을 선택해주세요");
    //   return;
    // }

    let placeName = '';
    if(store.item.places_etc && store.item.places_etc != '') {
      placeName = '기타 > ' + store.item.places_etc + ' > ' + store.item.places_etc2;
    }else if (store.item.places.length > 0) {
      const place = store.item.places[0];
      if (store.item.places.length === 1) {
        placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}`;
      } else {
        placeName =  `${place.building_name} > ${place.building_dong} > ${place.room_name}외 ${store.item.places.length - 1}곳`;
      }
    }

    let category = store.item.category1;
    if(store.item.category2)  category = category + " > " + store.item.category2;
    if(store.item.category3)  category = category + " > " + store.item.category3;
    // if(store.item.category4)  category = category + " > " + store.item.category2;

    let msg = '업무이관\n'
            + '접수일 : ' + formatByString(store.item.created_at, 'yyyy.MM.dd HH:mm:ss') + '\n'
            + '접수유형 : ' + category + '\n'
            + '제목 : ' + store.item.title + '\n'
            + '발생 시간 : ' + formatByString(store.item.found_at, 'yyyy.MM.dd HH') + '시경\n'
            + '발생 장소 : ' + placeName + '\n'
            + '업무이관자 : ' + userInfo.username + '\n'
            + '업무이관자 전화번호 : ' + userInfo.phone + '\n';    

    const payload : any = {
      work_site: headerStore.workSite,
      employee_number: userInfo.employeeNumber,
      cellphones : ''+cellMap,
      message : msg,
      send_date : format_YYYYMMDDhhmm(),
      send_status : "즉시발송"
    }

    try{
      headerStore.isLoading = true;
      await sms.smsSend(payload);
      headerStore.isLoading = false;
      // goBack();
    }catch(err){
      headerStore.isLoading = false;
      // console.log(err);
      const { status, data } = err;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("문제가 발생했습니다.");
      return;
    }

    try {
      headerStore.isLoading = true;
      const data = { managers: selectedManagers.map((user: any) => user.id), contents : contents};
      await api.updateVoc(item.id, data);

      const result = await api.getVoc(item.id);
      // console.log(result);

      // setItem(result.data);
      info('담당자 설정이 완료되었습니다.');
      // setReload(true);
      back();
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("문제가 발생했습니다");
        return;
      }

      //errorHandler({ status, data }, '담당자 설정중 오류발생');
    }
  };

  const back = () => {
    props.history.replace(`${baseUrl}`);
  }
  const onSetPlacesHandler = (_places: any[], roomType: number, etc: string, etc2: string) => {
    item.places = _places;
    if(Number(roomType) === 7) {
      item.places = [];
      item.places_room_type= roomType;
      item.places_etc = etc;
      item.places_etc2 = etc2;
    }else {
      item.places = _places;
      item.places_room_type= roomType;
      item.places_etc = '';
      item.places_etc2 = '';
    }
  };

  // if (isLoading) return <></>;

  return (
    <>
      {isEdit ? (
        <WriteContent
          setIsEdit={setIsEdit}
          setIsPlaceOpen={setIsPlaceOpen}
          {...props}
        />
      ) : (
        <ViewContent
          setIsEdit={setIsEdit}
          setIsPlaceOpen={setIsPlaceOpen}
          setIsChoiceManagerOpen={setIsChoiceManagerOpen}
          {...props}
        />
      )}

      {isChoiceManagerOpen ? (
        <ChoiceManager
          isOpen={isChoiceManagerOpen}
          isView={!isEdit}
          managers={item.managers}
          setIsOpen={setIsChoiceManagerOpen}
          onUpdateManagers={onUpdateManagers}
          reload={setReload}
        />
      ) : (<></>)}

      {isPlaceOpen ? (
        <PlaceOccurrence
          isOpen={isPlaceOpen}
          isView={!isEdit}
          setIsOpen={setIsPlaceOpen}
          user={store.user}
          places={item.places}
          onApply={onSetPlacesHandler}
        />
      ) : (<></>)}
    </>
  );
});

export default VocDetail;
