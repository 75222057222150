import { isNull } from 'lodash';
import {action, makeObservable, observable} from 'mobx';
import { debounce } from 'typescript-debounce-decorator';
import * as apis from '../../apis';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class mainStore  {
//extends BoardStore
  userInfo: any = [];
  worksite: any;
  roomtype: any;
  authBuilding : any;
  checkinStatData: any = [];
  checkinOutStatData : any = [];
  
  //loading
  headerStore : HeaderStore;


  constructor(root: any) {
    //super(root);
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
        worksite : observable,
        userInfo : observable,
        roomtype : observable,
        authBuilding : observable,
        checkinStatData : observable,
        checkinOutStatData : observable,

        findTabs : action,
    });
  }

  findTabs(name: string) {
    let result = -1;
    if ((window as any).store) {
      const opened = (window as any).store.getState().base.tab.opened;
      if (opened.length > 0) {
        result = opened.find((item: string) => item && item.indexOf(name) > -1);
      }
    }
    return result;
  }

  destory () {
    //this.init();
  }
  
  //입숙, 이숙, 퇴숙 현황 통계
  async getItems() {

    const payload: any = {
      work_site : this.worksite,
    }
    if(Number(this.worksite) === 2) {
      if(!isNull(this.authBuilding) && this.authBuilding !== 'null' && this.authBuilding.length > 0) {
        payload.authBuilding = this.authBuilding; 
      }
    }

    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.auth.getMainCheckinStat(payload);
      this.checkinStatData = data;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  //입숙,퇴숙 시계열 통계
  async getItemsTime() {
    try {
      this.headerStore.isLoading = true;
      const { data } = await apis.auth.getMainCheckinOutStat();
      this.checkinOutStatData = data;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }
}
