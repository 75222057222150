import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CLOSE_CONFIRRM_MODAL } from '../../../store/modules/modal';

const Confirm = (props: any) => {
  const {
    isModalShow,
    title,
    msg,
    action,
  } = useSelector((state: any) => ({
    isModalShow: state.modal.confirmPopup.isModalShow,
    title: state.modal.confirmPopup.title,
    msg: state.modal.confirmPopup.msg,
    action: state.modal.confirmPopup.action,
  }));

  const dispatch = useDispatch();

  const close = () => {
    dispatch({ type: CLOSE_CONFIRRM_MODAL });
  };

  const confirm = async () => {
    await action()
    dispatch({ type: CLOSE_CONFIRRM_MODAL });
  }

  const getMsg = () => msg && msg.split('\n').map((word: any) => <p>{word}</p>);

  return (
    <div
      id="pop_alertsample"
      className="pop_dim_wrap pop_modal pop_alert"
      style={{ display: isModalShow ? 'block' : 'none' }}
    >
      <div className="pop_wrap">
        <button className="pop_btn_close pop_close" onClick={() => close()}>
          닫기
      </button>

        <div className="message_table">
          <div className="messageWrap">{getMsg()}</div>
        </div>
        <div className="button_wrap">
          <button
            type="button"
            className="btn_pop_default btn_white pop_close"
            onClick={close}
          >
            취소
          </button>
          <button
            type="button"
            className="btn_pop_default btn_darkblue pop_close"
            onClick={confirm}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
