import _, { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TempleteDefault from '../../components/templetes/default';
import { ROOM_TYPE, ROOM_TYPE_CODE } from '../../constants';
import { useStores } from '../../store/mobx';
import { getTodayDate } from '../../utils/date';
import ReactDOM from 'react-dom';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

const LodgedBoard = observer((props: any) => {
    const dispatch = useDispatch();
    const {headerStore: headerStore} = useStores();
    const [isManagePop, setIsManagePop] = useState<boolean>(false);
    const { lodgedBoardStore : store } = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [selectValue, setSelectValue] = useState<any>(headerStore.paramAuthRoomTypeRole);
    const showFamilies = [2, 4];
    const hideCompanyHouses = [1, 5, 6, 7];
    const isShowFamily = () => _.includes(showFamilies, userInfo.worksite)
    && (userInfo.lodging && (userInfo.lodging.room_type === 2 && userInfo.lodging.stay_after));

    useEffect(() => {
        // 숙소유형 가져오기
        (async () => {
            // store.init();
            headerStore.getAuthMenuRole('2-1');
            headerStore.getAuthWorkSiteRole();
            headerStore.getAuthRoomRole();
            store.worksite = headerStore.workSite;
            store.work_site_id = headerStore.workSite;
            store.room_type = selectValue;
            let options: any[] = [];
            options.push(<option value={headerStore.paramAuthRoomTypeRole}>전체</option>);
            for ( let i = 0; i < headerStore.paramAuthRoomTypeRole?.split(",").length; i++) {
                options.push(<option value={headerStore.paramAuthRoomTypeRole.split(",")[i]}>{ROOM_TYPE[headerStore.paramAuthRoomTypeRole.split(",")[i]]}</option>)
            }
            
            ReactDOM.render(options, document.getElementById("selectRoom"));
            //숙소유형 권한 별로 가져오기
            if(Number(headerStore.workSite) === 2) {
                if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' ) {
                    store.authBuilding = headerStore.paramAuthBuildingRole;
                }
            }
            try{
                headerStore.isLoading = true;
                await store.gsStatList();
                headerStore.isLoading = false;
            }catch({status, data}){
                headerStore.isLoading = false;
                    if (status === 401) {
                        if (!window.localStorage.getItem("isRetryLogin")) {
                        window.localStorage.setItem("isRetryLogin", "retry");
                        }
                        window.location.href = '/';
                        return ;
                    } else if (status === 403) {
                        (window as any).store.dispatch({
                        type: SHOW_ALERT_MODAL,
                        payload: {
                            title: '권한오류',
                            msg: '접근권한이 없습니다.',
                            redirectPath: false,
                            statusCode: status,
                            errorCode: '',
                            type: 'error',
                            isModalShow: true,
                        },
                        });
                        return ;
                    }else if ( status === 500 ) {
                        window.location.href = '/500';
                        return;
                    }
                }
        })();
    }, [selectValue, headerStore.workSite]);

    // 렌더링 이슈 : 페이지로드되기전 계산부터 되야하는데 페이지로드가 먼저되어서 오류가 발생함 그래서 페이지로드 되고 다시 계산식을 불러오기위해 작성
    useEffect(() => {

    }, [store.reloadTest]);

    return (
        <TempleteDefault
            {...props}
            id="wrap"
            className=""
            isLoading={headerStore.isLoading}
        >
            <h4>동별 이용 현황 <span>{getTodayDate()[0]} 기준</span>
                <div className="right_area">
                    <select id="selectRoom" className="form_control num" value={selectValue} onChange={(e: any)=>{setSelectValue(e.target.value)}}>
                    
                    {/*Object.keys(ROOM_TYPE).map((key: string, index: number) => {
                            if(Number(key) !== 0 && Number(key) !== 7) {
                                return (
                                    <option key={index} value={key}>
                                        {ROOM_TYPE[Number(key)]}
                                    </option>)
                            }
                        })*/}

                    </select>
                </div>
            </h4>
            <div className="table_normal_list event_on box_scroll_x">
                <table>
                    <caption className="sr_only">동별 이용 현황</caption>
                    <colgroup>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th rowSpan={3}>숙소유형</th>
                        <th rowSpan={3}>동</th>
                        <th colSpan={4}>숙소현황</th>
                        <th colSpan={7}>이용자현황</th>
                    </tr>
                    <tr>
                        <th rowSpan={2}>총 호실</th>
                        <th rowSpan={2}>이용중</th>
                        <th rowSpan={2}>이용가능</th>
                        <th rowSpan={2}>이용불가</th>
                        <th rowSpan={2}>수용인원</th>
                        <th rowSpan={2}>이용인원</th>
                        <th colSpan={3}>사내임직원</th>
                        <th className="bdl" rowSpan={2}>계열사 임직원</th>
                        <th rowSpan={2}>외부인</th>
                    </tr>
                    <tr>
                        <th>사내임직원 A조</th>
                        <th>사내임직원 B조</th>
                        <th>사내임직원 C조</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* 기숙사 */}
                    {/*데이타 없을 시*/}

                    {!store.item || store.item.length <= 0? (
                       <tr>
                            <td colSpan={13} className="blank_area type_white">
                                <p className="txt_blank">데이터가 없습니다.</p>
                            </td>
                        </tr>
                    ) : (
                        selectValue.indexOf(ROOM_TYPE_CODE.dormitory_room) > -1  ?
                        <>
                        {store.item.map((item : any, idx : number) => (
                        item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar && item.room_type === ROOM_TYPE_CODE.dormitory_room?
                        (<tr>
                            <td>{ROOM_TYPE[item.room_type]}</td>
                            <td style={{cursor:'pointer'}} onClick={() => {store.guestList=item; props.history.replace(`/guest/board/detail`)}}>{item.name} {item.dong}</td>
                            <td>{item.checkin_room_total}</td>
                            <td><span className="point green">{item.checkin_using}</span></td>
                            <td><span className="point blue">{item.checkin_room_available}</span></td>
                            <td><span className="point red">{item.checkin_room_unavailable}</span></td>
                            <td>{item.checkin_room_capacity}</td>
                            <td>{item.checkin_room_guest}</td>
                            <td>{item.checkin_corporation_a}</td>
                            <td>{item.checkin_corporation_b}</td>
                            <td>{item.checkin_corporation_c}</td>
                            <td>{item.checkin_subsidiary}</td>
                            <td>{item.checkin_public}</td>
                        </tr>): ''
                        
                        ))}
                        {/* 기숙사 */}
                        <tr id="domitorySum">
                            <td>합계</td>
                            <td>-</td>
                            <td>{store.calcular['totalRoom']}</td>
                            <td><span className="point green">{store.calcular['add1']}</span></td>
                            <td><span className="point blue">{store.calcular['add2']}</span></td>
                            <td><span className="point red">{store.calcular['addX']}</span></td>
                            <td>{store.calcular['add3']}</td>
                            <td>{store.calcular['add4']}</td>
                            <td>{store.calcular['add5']}</td>
                            <td>{store.calcular['add6']}</td>
                            <td>{store.calcular['add7']}</td>
                            <td>{store.calcular['add8']}</td>
                            <td>{store.calcular['add9']}</td>
                        </tr>

                        <tr className="tfoot" id="domitoryRatio">
                            <th>비율</th>
                            <td>-</td>
                            <td><span>100%</span></td>
                            <td><span className="point green">{store.item.length > 0? parseFloat(store.calcular['domitorycal1']).toFixed(1) : '0'}%</span></td>
                            <td><span className="point blue">{store.item.length > 0? parseFloat(store.calcular['domitorycal2']).toFixed(1) : '0'}%</span></td>
                            <td><span className="point red">{store.item.length > 0? parseFloat(store.calcular['domitorycalX']).toFixed(1) : '0'}%</span></td>
                            <td><span>100%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['domitorycal4']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['domitorycal5']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['domitorycal6']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['domitorycal7']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['domitorycal8']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['domitorycal9']).toFixed(1):'0'}%</span></td>
                        </tr>
                        </>
                     : '')}


                    {/* 사택 */}
                    {store.item.filter((data: any) => data.room_type === ROOM_TYPE_CODE.company_room).length === 0? (
                    <>
                        
                    </>
                    ) : (
                    selectValue.indexOf(ROOM_TYPE_CODE.company_room) > -1 ?
                    <>
                    {store.item.map((item : any, idx : number) => (
                        item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar && item.room_type === ROOM_TYPE_CODE.company_room?
                        (<tr>
                            <td>{ROOM_TYPE[item.room_type]}</td>
                            <td style={{cursor:'pointer'}} onClick={() => {store.guestList=item; props.history.replace(`/guest/board/detail`)}}>{item.name} {item.dong}</td>
                            <td>{item.checkin_room_total}</td>
                            <td><span className="point green">{item.checkin_using}</span></td>
                            <td><span className="point blue">{item.checkin_room_available}</span></td>
                            <td><span className="point red">{item.checkin_room_unavailable}</span></td>
                            <td>{item.checkin_room_capacity}</td>
                            <td>{item.checkin_room_guest}</td>
                            <td>{item.checkin_corporation_a}</td>
                            <td>{item.checkin_corporation_b}</td>
                            <td>{item.checkin_corporation_c}</td>
                            <td>{item.checkin_subsidiary}</td>
                            <td>{item.checkin_public}</td>
                        </tr>): ''
                        
                        ))}
                        {/* 사택 */}
                        <tr>
                            <td>합계</td>
                            <td>-</td>
                            <td>{store.calcular['companytotalRoom']}</td>
                            <td><span className="point green">{store.calcular['companyadd1']}</span></td>
                            <td><span className="point blue">{store.calcular['companyadd2']}</span></td>
                            <td><span className="point red">{store.calcular['companyaddX']}</span></td>
                            <td>{store.calcular['companyadd3']}</td>
                            <td>{store.calcular['companyadd4']}</td>
                            <td>{store.calcular['companyadd5']}</td>
                            <td>{store.calcular['companyadd6']}</td>
                            <td>{store.calcular['companyadd7']}</td>
                            <td>{store.calcular['companyadd8']}</td>
                            <td>{store.calcular['companyadd9']}</td>
                        </tr>

                        <tr className="tfoot">
                            <th>비율</th>
                            <td>-</td>
                            <td><span>100%</span></td>
                            <td><span className="point green">{store.item.length > 0? parseFloat(store.calcular['companycal1']).toFixed(1):'0'}%</span></td>
                            <td><span className="point blue">{store.item.length > 0? parseFloat(store.calcular['companycal2']).toFixed(1):'0'}%</span></td>
                            <td><span className="point red">{store.item.length > 0? parseFloat(store.calcular['companycalX']).toFixed(1):'0'}%</span></td>
                            <td><span>100%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['companycal4']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['companycal5']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['companycal6']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['companycal7']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['companycal8']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['companycal9']).toFixed(1):'0'}%</span></td>
                        </tr>
                    </>
                     : '')}


                    {/* 출장자개인 */}
                    {store.item.filter((data: any) => data.room_type === ROOM_TYPE_CODE.business_room).length === 0? (
                    <>
                       
                    </>
                    ) : (
                    selectValue.indexOf(ROOM_TYPE_CODE.business_room) > -1 ?
                    <>
                    {store.item.map((item : any, idx : number) => (
                        item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar && item.room_type === ROOM_TYPE_CODE.business_room?
                        (<tr>
                            <td>{ROOM_TYPE[item.room_type]}</td>
                            <td style={{cursor:'pointer'}} onClick={() => {store.guestList=item; props.history.replace(`/guest/board/detail`)}}>{item.name} {item.dong}</td>
                            <td>{item.checkin_room_total}</td>
                            <td><span className="point green">{item.checkin_using}</span></td>
                            <td><span className="point blue">{item.checkin_room_available}</span></td>
                            <td><span className="point red">{item.checkin_room_unavailable}</span></td>
                            <td>{item.checkin_room_capacity}</td>
                            <td>{item.checkin_room_guest}</td>
                            <td>{item.checkin_corporation_a}</td>
                            <td>{item.checkin_corporation_b}</td>
                            <td>{item.checkin_corporation_c}</td>
                            <td>{item.checkin_subsidiary}</td>
                            <td>{item.checkin_public}</td>
                        </tr>): ''
                        
                        ))}
                    
                        {/* 출장자개인 */}
                        <tr>
                            <td>합계</td>
                            <td>-</td>
                            <td>{store.calcular['businesstotalRoom']}</td>
                            <td><span className="point green">{store.calcular['businessadd1']}</span></td>
                            <td><span className="point blue">{store.calcular['businessadd2']}</span></td>
                            <td><span className="point red">{store.calcular['businessaddX']}</span></td>
                            <td>{store.calcular['businessadd3']}</td>
                            <td>{store.calcular['businessadd4']}</td>
                            <td>{store.calcular['businessadd5']}</td>
                            <td>{store.calcular['businessadd6']}</td>
                            <td>{store.calcular['businessadd7']}</td>
                            <td>{store.calcular['businessadd8']}</td>
                            <td>{store.calcular['businessadd9']}</td>
                        </tr>

                        <tr className="tfoot">
                            <th>비율</th>
                            <td>-</td>
                            <td><span>100%</span></td>
                            <td><span className="point green">{store.item.length > 0? parseFloat(store.calcular['businesscal1']).toFixed(1):'0'}%</span></td>
                            <td><span className="point blue">{store.item.length > 0? parseFloat(store.calcular['businesscal2']).toFixed(1):'0'}%</span></td>
                            <td><span className="point red">{store.item.length > 0? parseFloat(store.calcular['businesscalX']).toFixed(1):'0'}%</span></td>
                            <td><span>100%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businesscal4']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businesscal5']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businesscal6']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businesscal7']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businesscal8']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businesscal9']).toFixed(1):'0'}%</span></td>
                        </tr>
                    </>
                    : '')}


                    {/* 출장자단체 */}
                    {store.item.filter((data: any) => data.room_type === ROOM_TYPE_CODE.business_room_group).length === 0? (
                    <>
                        
                    </>
                    ) : (
                    selectValue.indexOf(ROOM_TYPE_CODE.business_room_group) > -1 ?
                    <>
                    {store.item.map((item : any, idx : number) => (
                        item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar && item.room_type === ROOM_TYPE_CODE.business_room_group?
                        (<tr>
                            <td>{ROOM_TYPE[item.room_type]}</td>
                            <td style={{cursor:'pointer'}} onClick={() => {store.guestList=item; props.history.replace(`/guest/board/detail`)}}>{item.name} {item.dong}</td>
                            <td>{item.checkin_room_total}</td>
                            <td><span className="point green">{item.checkin_using}</span></td>
                            <td><span className="point blue">{item.checkin_room_available}</span></td>
                            <td><span className="point red">{item.checkin_room_unavailable}</span></td>
                            <td>{item.checkin_room_capacity}</td>
                            <td>{item.checkin_room_guest}</td>
                            <td>{item.checkin_corporation_a}</td>
                            <td>{item.checkin_corporation_b}</td>
                            <td>{item.checkin_corporation_c}</td>
                            <td>{item.checkin_subsidiary}</td>
                            <td>{item.checkin_public}</td>
                        </tr>): ''
                        
                        ))}
                    
                        {/* 출장자단체 */}
                        <tr>
                            <td>합계</td>
                            <td>-</td>
                            <td>{store.calcular['businessGrouptotalRoom']}</td>
                            <td><span className="point green">{store.calcular['businessGroupadd1']}</span></td>
                            <td><span className="point blue">{store.calcular['businessGroupadd2']}</span></td>
                            <td><span className="point red">{store.calcular['businessGroupaddX']}</span></td>
                            <td>{store.calcular['businessGroupadd3']}</td>
                            <td>{store.calcular['businessGroupadd4']}</td>
                            <td>{store.calcular['businessGroupadd5']}</td>
                            <td>{store.calcular['businessGroupadd6']}</td>
                            <td>{store.calcular['businessGroupadd7']}</td>
                            <td>{store.calcular['businessGroupadd8']}</td>
                            <td>{store.calcular['businessGroupadd9']}</td>
                        </tr>

                        <tr className="tfoot">
                            <th>비율</th>
                            <td>-</td>
                            <td><span>100%</span></td>
                            <td><span className="point green">{store.item.length > 0? parseFloat(store.calcular['businessGroupcal1']).toFixed(1):'0'}%</span></td>
                            <td><span className="point blue">{store.item.length > 0? parseFloat(store.calcular['businessGroupcal2']).toFixed(1):'0'}%</span></td>
                            <td><span className="point red">{store.item.length > 0? parseFloat(store.calcular['businessGroupcalX']).toFixed(1):'0'}%</span></td>
                            <td><span>100%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businessGroupcal4']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businessGroupcal5']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businessGroupcal6']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businessGroupcal7']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businessGroupcal8']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['businessGroupcal9']).toFixed(1):'0'}%</span></td>
                        </tr>
                    </>
                    : '')}

                    {/* 영빈관 */}
                    {store.item.filter((data: any) => data.room_type === ROOM_TYPE_CODE.vip_room).length === 0? (
                    <>
                        
                    </>
                    ) : (
                    selectValue.indexOf(ROOM_TYPE_CODE.vip_room) > -1 ?
                    <>
                    {store.item.map((item : any, idx : number) => (
                        item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar && item.room_type === ROOM_TYPE_CODE.vip_room?
                        (<tr>
                            <td>{ROOM_TYPE[item.room_type]}</td>
                            <td style={{cursor:'pointer'}} onClick={() => {store.guestList=item; props.history.replace(`/guest/board/detail`)}}>{item.name} {item.dong}</td>
                            <td>{item.checkin_room_total}</td>
                            <td><span className="point green">{item.checkin_using}</span></td>
                            <td><span className="point blue">{item.checkin_room_available}</span></td>
                            <td><span className="point red">{item.checkin_room_unavailable}</span></td>
                            <td>{item.checkin_room_capacity}</td>
                            <td>{item.checkin_room_guest}</td>
                            <td>{item.checkin_corporation_a}</td>
                            <td>{item.checkin_corporation_b}</td>
                            <td>{item.checkin_corporation_c}</td>
                            <td>{item.checkin_subsidiary}</td>
                            <td>{item.checkin_public}</td>
                        </tr>): ''
                        
                        ))}
                    
                        {/* 영빈관 */}
                        <tr>
                            <td>합계</td>
                            <td>-</td>
                            <td>{store.calcular['viptotalRoom']}</td>
                            <td><span className="point green">{store.calcular['vipadd1']}</span></td>
                            <td><span className="point blue">{store.calcular['vipadd2']}</span></td>
                            <td><span className="point red">{store.calcular['vipaddX']}</span></td>
                            <td>{store.calcular['vipadd3']}</td>
                            <td>{store.calcular['vipadd4']}</td>
                            <td>{store.calcular['vipadd5']}</td>
                            <td>{store.calcular['vipadd6']}</td>
                            <td>{store.calcular['vipadd7']}</td>
                            <td>{store.calcular['vipadd8']}</td>
                            <td>{store.calcular['vipadd9']}</td>
                        </tr>

                        <tr className="tfoot">
                            <th>비율</th>
                            <td>-</td>
                            <td><span>100%</span></td>
                            <td><span className="point green">{store.item.length > 0? parseFloat(store.calcular['vipcal1']).toFixed(1):'0'}%</span></td>
                            <td><span className="point blue">{store.item.length > 0? parseFloat(store.calcular['vipcal2']).toFixed(1):'0'}%</span></td>
                            <td><span className="point red">{store.item.length > 0? parseFloat(store.calcular['vipcalX']).toFixed(1):'0'}%</span></td>
                            <td><span>100%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['vipcal4']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['vipcal5']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['vipcal6']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['vipcal7']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['vipcal8']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['vipcal9']).toFixed(1):'0'}%</span></td>
                        </tr>
                    </>
                    : '')}

                    {/* 가족숙소 */}
                    {store.item.filter((data: any) => data.room_type === ROOM_TYPE_CODE.family_room).length === 0? (
                    <>
                       
                    </>
                    ) : (
                    selectValue.indexOf(ROOM_TYPE_CODE.family_room) > -1 ?
                    <>
                    {store.item.map((item : any, idx : number) => (
                        item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar && item.room_type === ROOM_TYPE_CODE.family_room?
                        (<tr>
                            <td>{ROOM_TYPE[item.room_type]}</td>
                            <td style={{cursor:'pointer'}} onClick={() => {store.guestList=item; props.history.replace(`/guest/board/detail`)}}>{item.name} {item.dong}</td>
                            <td>{item.checkin_room_total}</td>
                            <td><span className="point green">{item.checkin_using}</span></td>
                            <td><span className="point blue">{item.checkin_room_available}</span></td>
                            <td><span className="point red">{item.checkin_room_unavailable}</span></td>
                            <td>{item.checkin_room_capacity}</td>
                            <td>{item.checkin_room_guest}</td>
                            <td>{item.checkin_corporation_a}</td>
                            <td>{item.checkin_corporation_b}</td>
                            <td>{item.checkin_corporation_c}</td>
                            <td>{item.checkin_subsidiary}</td>
                            <td>{item.checkin_public}</td>
                        </tr>): ''
                        
                        ))}
                    
                        {/* 가족숙소 */}
                        <tr>
                            <td>합계</td>
                            <td>-</td>
                            <td>{store.calcular['familytotalRoom']}</td>
                            <td><span className="point green">{store.calcular['familyadd1']}</span></td>
                            <td><span className="point blue">{store.calcular['familyadd2']}</span></td>
                            <td><span className="point red">{store.calcular['familyaddX']}</span></td>
                            <td>{store.calcular['familyadd3']}</td>
                            <td>{store.calcular['familyadd4']}</td>
                            <td>{store.calcular['familyadd5']}</td>
                            <td>{store.calcular['familyadd6']}</td>
                            <td>{store.calcular['familyadd7']}</td>
                            <td>{store.calcular['familyadd8']}</td>
                            <td>{store.calcular['familyadd9']}</td>
                        </tr>

                        <tr className="tfoot">
                            <th>비율</th>
                            <td>-</td>
                            <td><span>100%</span></td>
                            <td><span className="point green">{store.item.length > 0? parseFloat(store.calcular['familycal1']).toFixed(1):'0'}%</span></td>
                            <td><span className="point blue">{store.item.length > 0? parseFloat(store.calcular['familycal2']).toFixed(1):'0'}%</span></td>
                            <td><span className="point red">{store.item.length > 0? parseFloat(store.calcular['familycalX']).toFixed(1):'0'}%</span></td>
                            <td><span>100%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['familycal4']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['familycal5']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['familycal6']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['familycal7']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['familycal8']).toFixed(1):'0'}%</span></td>
                            <td><span>{store.item.length > 0? parseFloat(store.calcular['familycal9']).toFixed(1):'0'}%</span></td>
                        </tr>
                    </>
                    : '')}
                    </tbody>
                </table>
            </div>  {/*table*/}

            {/* <div className="board_paging">
                <ul>
                    <li><a href="javascript:void(0);" className="paging_prev">paging_prev</a></li>
                    <li><a href="javascript:void(0);" className="active">1</a></li>
                    <li><a href="javascript:void(0);">2</a></li>
                    <li><a href="javascript:void(0);">3</a></li>
                    <li><a href="javascript:void(0);">4</a></li>
                    <li><a href="javascript:void(0);">5</a></li>
                    <li><a href="javascript:void(0);">6</a></li>
                    <li><a href="javascript:void(0);">7</a></li>
                    <li><a href="javascript:void(0);">8</a></li>
                    <li><a href="javascript:void(0);">9</a></li>
                    <li><a href="javascript:void(0);">10</a></li>
                    <li><a href="javascript:void(0);" className="paging_next">paging_next</a></li>
                </ul>
            </div>*/} {/*board_paging */}

            {/* <div className="submit ac">
                <button className="btn btn_lg btn_darkblue">다운로드</button>
            </div> */}       
            
        </TempleteDefault>
    )
});

export default LodgedBoard;