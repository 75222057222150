import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LodgedTotal from '..';
import LimitSelect from '../../../components/atoms/select/limit';
import DatePicker from '../../../components/organismas/input/datepicker';
import Pagination from '../../../components/organismas/pagination';
import { CHECKOUT_STATUS, CHECKOUT_STATUS_CODE, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE } from '../../../constants';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { showConfirm } from '../../../utils/common';
import MngFileUpload from '../../../components/organismas/form/managementFile';
import { formatByString_YYYYMMDD, format_YYYYMMDD, getDate, getDays, getMonth, getMonths, getYear, getYear5 } from '../../../utils/date';
import { isNull } from 'lodash';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';
import ConfirmBtn from '../../../components/atoms/button/confirm';
import CustomCalendar from '../../../components/organismas/customCalendar';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';

const LodgedCheckOut = observer((props: any) => {
    const dispatch = useDispatch();
    const { loadgedTotalStore : store } = useStores();
    const {headerStore: headerStore} = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const [isCheckOutPop, setIsCheckOutPop] = useState<boolean>(false);
    const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
    const [downloadText, setDownloadText] = useState<string>("");
    const [isShowReflect, setIsShowReflect] = useState<boolean>(false);
    const [room_types, setRoom_types] = useState<any[]>([]);
    //rendering시 리스트 뽑아오기
    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                headerStore.getAuthMenuRole('2-3');
                headerStore.getAuthBuilding();
                headerStore.getAuthRoomRole();
                setRoom_types(headerStore.paramAuthRoomTypeRole.split(","));
                store.work_site = headerStore.workSite;
                store.roomtype = headerStore.paramAuthRoomTypeRole;
                store.option = 'guest_name';
                if(Number(headerStore.workSite) === 2) {
                    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                        store.building = headerStore.paramAuthBuildingRole;
                    }
                }
                await store.getCheckOutList();
                headerStore.isLoading = false;
            }  catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();

        return () => {
            if (store.findTabs('/lodged/type') < 0) {
                store.init();
            }
        }
    },[headerStore.workSite, store.selectRoomType]);

    const onCheckedByAll = (e: any) => store.checkAll(e.target.checked);

    const onCheckedHandler = (e: any, item: any) => store.checkItem(e.target.checked, item);

    const onSearchClick = (e: any) => {
        store.getCheckOutList();
    }

    const saveCheckOut = async() => {
        showConfirm('퇴숙처리 하시겠습니까?', dispatch, async () => {
            if(store.manageYear === '' || store.manageMonth === '' || store.manageDay === ''){
                info('필수 입력사항을 체크하여주세요');
                return false;
            }else if(!store.isKeyReturn && store.keySelect === '기타' && (store.keyDescription === '' || store.keyDescription === null)){
                info('열쇠 미 반납 이유를 적어주세요');
                return false;
            }else if(store.isEquipment && (store.equipmentDescription === '' || store.equipmentDescription === null)){
                info('비품 이상 여부를 적어주세요');
                return false;
            }else if((store.checkoutData.room_type === ROOM_TYPE_CODE.business_room || store.checkoutData.room_type === ROOM_TYPE_CODE.business_room_group) && 
                ((store.checkoutData.meal_ticket-store.mealTicket) < 0)) {
                info('반납수량은 식권수량보다 클 수 없습니다');
                return false;
            }else if(store.manageYear !== '' && store.manageMonth !== '' && store.manageDay !== '' ){
                if(formatByString_YYYYMMDD(store.checkoutData.checkin_at) > format_YYYYMMDD(store.manageYear, store.manageMonth, store.manageDay)){
                    info('퇴숙일이 입숙일보다 빠를 수 없습니다.');
                    return false;
                }
            }
            //if(!store.isKeyReturn)
            try {
                headerStore.isLoading = true;
                let data : any = await store.checkOut();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsCheckOutPop(false);
                        info('퇴숙처리가 완료되었습니다');
                        popInit();
                        store.getCheckOutList();
                    }else {
                      return;
                    }
                }, 250);
            }  catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        });
    }

    const popInit = () => {
        store.manageYear = getYear();
        store.manageMonth = getMonth();
        store.manageDay = getDate();
        store.isKeyReturn = true;
        store.keyDescription = ''
        store.keySelect = '열쇠 분실';
        store.isEquipment  = false;
        store.equipmentDescription = '';
        store.images = [];
        store.mealTicket = 0;
        store.status_description = '';
        store.checkoutStatus = 4;
    }

    /*const onDownloanHandler = async () => {
        console.log(store.checkeds);
        /*if(Object.keys(store.checkeds).length <= 0){
            showAlert('선택하신 체크 항목이 없습니다. \n 다운받으실 행을 선택해주세요', dispatch);
            return;
        }

        try {

            dispatch({
                type: SHOW_CONFIRM_MODAL,
                payload: {
                    isModalShow: true,
                    title: '확인',
                    msg: '다운로드 하시겠습니까?',
                    action: async () => {
                        try {
                            await store.downloadCheckOut();
                        } catch ({ status, data }) {
                            errorHandler({ status, data }, '다운로드 중 오류발생');
                            errorAlert("다운로드 중 문제가 발생했습니다.", dispatch);
                        }
                    },
                }
            });
        } catch (e) {
            console.log(e);
            errorAlert("다운로드 중 문제가 발생했습니다.", dispatch);
        }
    }*/
    // 다운로드 
    const xlsDownload = async()=>{
        try {
            headerStore.isLoading = true;
            store.status = [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(',');
            console.log(store.checkout_after);
            console.log(store.checkout_before);
            
            const payload: any = {
                employee_number : userInfo.employeeNumber,
                reason : downloadText,
                status : store.status,
                checkout_after : store.checkout_after,
                checkout_before : store.checkout_before,
                work_site : store.work_site,
                roomtype : store.roomtype,
            }

            if(store.option && store.text) {
                payload[store.option] = store.text;
            }
            if(Number(store.work_site) === 2) {
                if(!isNull(store.building) && store.building !== undefined && store.building.length > 0) {
                    payload.authBuilding = store.building;
                }
            }
            
            let { data } = await Core.xlsgeustlist3(payload);
            if(data) {
                FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
            }
            setIsShowDownloadPop(false);
            setDownloadText('');
            headerStore.isLoading = false;
        }catch ({status, data}){
            headerStore.isLoading = false;
            // console.log(status, data);
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
            info("문제가 발생했습니다");
                return;
        }
    }

    // 승인 처리
    const applySubmit = async() => {
        try {
            headerStore.isLoading = true;
            let data : any = await store.applySubmit();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    info('승인처리가 완료되었습니다');
                    store.getCheckOutList();
                }else {
                  return;
                }
            }, 250);
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            info("문제가 발생했습니다");
                return;
        }
    }

    // 반려, 재요청 처리
    const rejectSubmit = async() => {
        try {
            headerStore.isLoading = true;
            if(store.checkoutStatus === CHECKOUT_STATUS_CODE.terminate) {
                let data : any = await store.terminateSubmit();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsShowReflect(false);
                        info(CHECKOUT_STATUS[store.checkoutStatus]+'처리가 완료되었습니다');
                        popInit();
                        store.getCheckOutList();
                    }else {
                      return;
                    }
                }, 250);
            }else {
                let data : any = await store.rejectSubmit();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsShowReflect(false);
                        info(CHECKOUT_STATUS[store.checkoutStatus]+'처리가 완료되었습니다');
                        popInit();
                        store.getCheckOutList();
                    }else {
                      return;
                    }
                }, 250);
            }
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            info("문제가 발생했습니다");
                return;
        }
    }

    // 퇴숙 진행상태
    const getChekcoutStatus = (item: any) => {
        let status = parseInt(item.checkoutrequest.status);
        if(status === CHECKOUT_STATUS_CODE.apply) {
            return (
                <>
                    신청완료
                    <>
                        {headerStore.mobileRoleFlag ? 
                            <>
                                <button className="btn btn_xs btn_gray btn_outline" onClick={() => {store.setCheckoutData(item); setIsShowReflect(true);}}>반려</button>
                                <button className="btn btn_xs btn_gray btn_outline" onClick={()=> {store.setCheckoutData(item); showConfirm('승인 하시겠습니까?', dispatch, applySubmit);}}>승인</button>
                            </>
                        :   <>
                            </>
                        }
                    </>
                </>
            )
        }else if(status === CHECKOUT_STATUS_CODE.reject) {
            return( 
                <>
                    <>
                        승인거절
                        {headerStore.mobileRoleFlag ?
                        <>
                            <button className="btn btn_xs btn_gray btn_outline" onClick={()=>{store.setCheckoutData(item); setIsShowReflect(true)}}>거절사유</button>
                        </>
                        : <></>}
                    </>
                </>
            )
        }else if(status === CHECKOUT_STATUS_CODE.terminate) {
            return( 
                <>
                    <>
                        재요청
                        {headerStore.mobileRoleFlag ?
                        <>
                            <button className="btn btn_xs btn_gray btn_outline" onClick={()=>{store.setCheckoutData(item); setIsShowReflect(true)}}>사유</button>
                        </>
                        : <></>}
                    </>
                </>
            )
        }else if(status === CHECKOUT_STATUS_CODE.cancel) {
            return "신청취소"
        }

        return <></>;
    }

    return (
        <LodgedTotal
            id=""
            className=""
	        isLoading={headerStore.isLoading}
            {...props}
        >
        <h4>퇴숙 설정</h4>
            <div className="table_modify_list">
                <dl className="info">
                    <dt>정보</dt>
                    <dd>
                        <select name="" id="select_sex" className="form_control"
                            value={store.selectRoomType}
                            onChange={(e:any) => store.selectRoomType = e.target.value}
                        >
                            <option value="0">숙소유형</option>
                            {room_types.map((item: any, index: number) => {
                                if(Number(item) !== 0 && Number(item) !== 7) {
                                    return (<option key={index} value={item}>{ ROOM_TYPE[item] }</option>);
                                }    
                            })}
                        </select>
                        <select className="form_control ml10" value={store.option} onChange={(e: any) => store.option = e.target.value} >
                            <option value="guest_name">성명</option>
                            <option value="guest_employee_number">사번</option>
                            <option value="guest_department">소속부서</option>
                        </select>
                        <input type="text"
                            className="form_control"
                            value={store.text}
                            onChange={(e: any) => store.text = e.target.value}
                            onKeyDown={(e: any) => {
                                if (e.keyCode === 13) onSearchClick(e)
                            }} 
                        />
                    </dd>
                </dl>
                <dl className="date">
                    <dt>조회기간</dt>
                    <dd>
                       <div>
                            {/* <DatePicker
                                value={store.checkout_after}
                                width={250}
                                onChange={(date: string) => store.checkout_after = date}
                                isPrevMonthView={true}
                                max={store.checkout_before}
                            /> */}
                            <CustomCalendar
                                value={store.checkout_after}
                                width={250}
                                onChange={(date: string) => store.checkout_after = date}
                                isPrevMonthView={true}
                                max={store.checkout_before}
                            ></CustomCalendar>
                        </div>
                        <div>
                            {/* <DatePicker
                                value={store.checkout_before}
                                width={250}
                                onChange={(date: string) => store.checkout_before = date}
                                isPrevMonthView={true}
                                min={store.checkout_after}
                            /> */}
                            <CustomCalendar
                                value={store.checkout_before}
                                width={250}
                                onChange={(date: string) => store.checkout_before = date}
                                isPrevMonthView={true}
                                min={store.checkout_after}
                            ></CustomCalendar>
                        </div>
                        <button type="button" className="btn btn_sm btn_gray" onClick={onSearchClick}>검색</button>
                    </dd>
                </dl>
            </div>
            
            <h4>퇴숙 리스트
                <div className="right_area">
                    <LimitSelect
                        className="form_control mr10" 
                        limit={store.limit} 
                        onChange={(limit: number) => { store.limit = limit; store.offset = 0; store.getCheckOutList();}} 
                    />
                    {headerStore.downRoleFlag ? <button className="btn btn_sm btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button> : <></>}
                </div>
            </h4>
            <div className="table_normal_list event_on box_scroll_x">
                <table>
                    <caption className="sr_only">퇴숙 리스트</caption>
                    <colgroup>
                        {/*<col width="60px"/>*/}
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="110px"/>
                        {/* <col width="*"/> */}
                    </colgroup>
                    <thead>
                    <tr>
                        {/*<th>
                            <label className="input_check pop_notext">
                                <input type="checkbox" id="" name="" 
                                    onChange={onCheckedByAll}
                                    checked={store.allCheckFlg}
                                />
                                <span className="label_text"></span>
                            </label>
                        </th>*/}
                        <th>신청일자</th>
                        <th>숙소유형</th>
                        <th>퇴숙요청일</th>
                        <th>퇴숙사유</th>
                        <th>사번</th>
                        <th>성명</th>
                        <th>성별</th>
                        <th>휴대폰</th>
                        <th>소속부서</th>
                        <th>숙소정보(동호수)</th>
                        <th>입숙일</th>
                        <th>만료일</th>
                        <th>퇴숙일</th>
                        {/* <th>비고</th> */}
                    </tr>
                    </thead>
                    <tbody>
                    {store.list.length <= 0 ? (
                        <tr>
                            <td colSpan={13} className="blank_area type_white">
                                <p className="txt_blank">데이터가 없습니다.</p>
                            </td>
                        </tr>
                    ) : (
                        store.list.map((item, idx) => (
                            <tr>
                                {/*<td>
                                    <label className="input_check pop_notext">
                                        <input type="checkbox"
                                            checked={store.checkeds[item.id] ? true : false}
                                            onChange={(e: any) => onCheckedHandler(e, item)}
                                        />
                                        <span className="label_text"></span>
                                    </label>
                                </td>*/}
                                {!item.checkoutrequest || item.checkoutrequest === null? ( //&& !item.checkoutrequest.created_at? ( 
                                <>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`)}}></td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{ROOM_TYPE[item.room_type]}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.stay_before}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.checkout_reason_select}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.guest_employee_number}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.guest_name}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.guest_gender === 1 ? '남' : '여'}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.guest_cellphone}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.guest_department}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.room_building_name} {item.room_building_dong} {item.room_name}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{item.room_type === ROOM_TYPE_CODE.business_room_group? (<> {item.checkin_at}<br></br>(입숙예정일) </>) : item.checkin_at}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx) => {store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/history/detail`) }}>{Number(headerStore.workSite) === 1 && ROOM_TYPE[item.room_type] === '기숙사'? '' : item.expired_at}</td>{/** || item.room_type === ROOM_TYPE_CODE.company_room  */}
                                <td>
                                    {item.checkout_at? item.checkout_at : (
                                        <>
                                        {headerStore.finallyRoleFlag ?
                                        <button className="btn btn_xs btn_gray" 
                                            onClick={() => {store.setCheckoutData(item); setIsCheckOutPop(true)}}
                                        >
                                        퇴숙처리</button>
                                        : <></>}
                                    </>)}
                                </td>
                                </>
                                ) : (
                                <>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>
                                    {item.checkoutrequest.created_at? formatByString_YYYYMMDD(item.checkoutrequest.created_at) : ''}
                                </td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{ROOM_TYPE[item.room_type]}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.stay_before}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.checkoutrequest? item.checkoutrequest.reason_select : ''}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.guest_employee_number}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.guest_name}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.guest_gender === 1 ? '남' : '여'}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.guest_cellphone}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.guest_department}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.room_building_name} {item.room_building_dong} {item.room_name}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{item.room_type === ROOM_TYPE_CODE.business_room_group? (<> {item.checkin_at}<br></br>(입숙예정일) </>) : item.checkin_at}</td>
                                <td style={{'cursor':'pointer'}} onClick={(idx)=>{store.checkOutDetailItem=item; props.history.replace(`/guest/status/checkout/detail`)}}>{Number(headerStore.workSite) === 1 && ROOM_TYPE[item.room_type] === '기숙사'? '' : item.expired_at}</td>{/** || item.room_type === ROOM_TYPE_CODE.company_room */}
                                <td>
                                    {((item.room_type === ROOM_TYPE_CODE.dormitory_room || item.room_type === ROOM_TYPE_CODE.company_room) && item.checkoutrequest.status !== CHECKOUT_STATUS_CODE.approve ) ? (
                                        getChekcoutStatus(item)
                                    ) : 
                                        item.checkout_at? item.checkout_at : (
                                            <>
                                                {headerStore.finallyRoleFlag ?
                                                <button className="btn btn_xs btn_gray" 
                                                    onClick={() => {store.setCheckoutData(item); setIsCheckOutPop(true)}}
                                                >
                                                퇴숙처리</button>
                                                : <></>}
                                            </>
                                        )
                                    }
                                </td>
                                </>
                                )}
                                {/* <td>{item.memo}</td> */}
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>  {/*table*/}

            <div className="board_paging">
                <ul>
                    <Pagination
                    offset={store.offset}
                    limit={store.limit}
                    totalPage={store.totalPage}
                    setOffset={(offset: number) => {store.offset = offset; store.getCheckOutList();}} />
                </ul>
            </div>   {/*board_paging*/}

            {/*<div className="submit ac">
                {headerStore.downRoleFlag ? <button className="btn btn_lg btn_darkblue" onClick={onDownloanHandler}>다운로드</button> : <></>}
                                        </div>*/}

            <div id="pop_change_request" className="pop_dim_wrap pop_modal pop_sm" style={{display: isCheckOutPop? 'block': 'none'}}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>퇴숙완료처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() =>{popInit(); setIsCheckOutPop(false)}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <ul className="request_date">
                            <li>
                                <strong>퇴숙요청일</strong>
                                <span>{store.checkoutData.stay_before? store.checkoutData.stay_before : store.checkoutData.expired_at}</span>
                            </li>
                            <li>
                                <strong>신청일</strong>
                                <span>{store.checkoutData.checkoutrequest? (store.checkoutData.checkoutrequest.created_at? formatByString_YYYYMMDD(store.checkoutData.checkoutrequest.created_at) : '') : ''}</span>
                            </li>
                        </ul>
                        <div className="process_list">
                            <dl>
                                <dt>열쇠 반납 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="return_check">
                                            <input type="radio" id="return_check" name="return" checked={store.isKeyReturn} onClick={() => store.isKeyReturn = true}/> 
                                            <span className="label_text">반납 완료</span>
                                        </label>
                                        <label className="input_radio" htmlFor="return_check2">
                                            <input type="radio" id="return_check2" name="return" checked={!store.isKeyReturn} onClick={() => store.isKeyReturn = false}/> 
                                            <span className="label_text">미 반납</span>
                                        </label>
                                    </div>
                                    
                                    {!store.isKeyReturn? (
                                        <div className="input_box">
                                            <select className="form_control" value={store.keySelect} onChange={(e:any) => store.keySelect = e.target.value}>
                                                <option value="열쇠 분실">열쇠 분실</option>
                                                <option value="기타">기타</option>
                                            </select>
                                            <input type="text" className="form_control" 
                                                placeholder="미 반납 이유를 적어주세요."
                                                value={store.keyDescription} 
                                                onChange={(e:any) => store.keyDescription = e.target.value}
                                                disabled={store.keySelect !== "기타"}
                                            />
                                        </div>
                                    ) : ''}
                                </dd>

                                <dt>비품 이상 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="fixtures_check">
                                            <input type="radio" id="fixtures_check" name="fixtures" checked={!store.isEquipment} onClick={() => store.isEquipment = false}/> 
                                            <span className="label_text">이상 없음</span>
                                        </label>
                                        <label className="input_radio" htmlFor="fixtures_check2">
                                            <input type="radio" id="fixtures_check2" name="fixtures" checked={store.isEquipment} onClick={() => store.isEquipment = true}/> 
                                            <span className="label_text">이상 있음</span>
                                        </label>
                                    </div>

                                    {store.isEquipment? (
                                        <input type="text" className="form_control" placeholder="비품 이상 여부를 적어주세요."
                                            value={store.equipmentDescription} onChange={(e:any) => store.equipmentDescription = e.target.value}/>
                                    ) : ''}
                                </dd>

                                {store.checkoutData.room_type === ROOM_TYPE_CODE.business_room || store.checkoutData.room_type === ROOM_TYPE_CODE.business_room_group? (
                                    <>
                                        {/* <dt>식권 반납 수량</dt>
                                        <dd>
                                            <div className="input_box">
                                                <input type="text" className="form_control" placeholder="식권 반납 수량을 입력해주세요"
                                                    value={store.mealTicket} onChange={(e:any) => store.mealTicket = parseInt(e.target.value) || 0}/>
                                            </div>
                                        </dd> */}

                                        <ul className="request_date">
                                            <li>
                                                <strong>식권수량</strong>
                                                <span>{store.checkoutData.meal_ticket}</span>
                                            </li>
                                            <li>
                                                <strong>반납수량</strong>
                                                <span>
                                                    <input type="text" className="form_control" placeholder="식권 반납 수량을 입력해주세요" style={{'width':'50px'}}
                                                        value={store.mealTicket} onChange={(e:any) => store.mealTicket = parseInt(e.target.value) || 0}
                                                        maxLength={4}
                                                    />
                                                </span>
                                            </li>
                                            <li>
                                                <strong>사용수량</strong>
                                                <span>{(store.checkoutData.meal_ticket-store.mealTicket)}</span>
                                            </li>
                                        </ul>
                                    </>
                                ) : '' }
                            </dl>
                        </div>

                        <div className="table_normal_list mt10">
                            <table>
                                <caption className="sr_only">숙소 이미지 첨부</caption>
                                <colgroup>
                                    <col width="*"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="ac">숙소 이미지 첨부</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="al">
                                        <MngFileUpload type="image" fileId="attacheFile" items={store.images} onSetData={(data: any[]) => store.images = [...data]} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>  {/*table*/}

                        <div className="process_list mt20">
                            <dl>
                                <dt>최종 퇴숙일</dt>
                                <dd>
                                    <select className="form_control year" value={store.manageYear} onChange={(e:any) => store.manageYear = e.target.value}>
                                        {getYear5(true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                    <select className="form_control month" value={store.manageMonth} onChange={(e:any) => store.manageMonth = e.target.value}>
                                        {getMonths(store.manageYear, true).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}                     </select>
                                    <select className="form_control day" value={store.manageDay} onChange={(e:any) => store.manageDay = e.target.value}>
                                        {getDays(store.manageYear, store.manageMonth, false).map((year:any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </dd>
                                {/* <dt>비고입력</dt>
                                <dd>
                                    <textarea className="form_control"></textarea>
                                </dd> */}
                            </dl>
                        </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={saveCheckOut}>완료</button>
                    </div>
                </div>
            </div>

            {/* 다운로드 사유 팝업 */}
            <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                    <h3>다운로드 사유</h3>
                    <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                    <div className="process_list">
                        <dl>
                        <dt>사유</dt>
                        <dd>
                            <textarea className="form_control"
                            value={downloadText}
                            onChange={(e: any) => setDownloadText(e.target.value)}
                            >
                            </textarea>
                        </dd>
                        </dl>
                    </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                    <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
                    </div>
                </div>
            </div>

            {/* 거절 popup */}
            {store.checkoutData && store.checkoutData.checkoutrequest && store.checkoutData.checkoutrequest.status?(
                <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowReflect? "block": "none" }}>
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <h3>반려처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>닫기</button>
                        </div>
                        <div className="contents_wrap">
                        <div className="process_list">
                            <dl>
                                <dt>반려항목</dt>
                                <dd>
                                    {store.checkoutData.checkoutrequest.status === CHECKOUT_STATUS_CODE.apply? (
                                        <select className="form_control" value={store.checkoutStatus} onChange={(e: any) => store.checkoutStatus = (parseInt(e.target.value))}>
                                            <option value={CHECKOUT_STATUS_CODE.terminate}>재요청</option>
                                            {/* <option value={CHECKOUT_STATUS_CODE.reject}>거절</option> */}
                                        </select>
                                    ):(
                                        <select className="form_control" value={store.checkoutData.checkoutrequest.status} disabled>
                                            <option value={CHECKOUT_STATUS_CODE.terminate}>재요청</option>
                                            {/* <option value={CHECKOUT_STATUS_CODE.reject}>거절</option> */}
                                        </select>
                                    )}
                                </dd>
                                <dt>사유</dt>
                                <dd>
                                    {store.checkoutData.checkoutrequest.status === CHECKOUT_STATUS_CODE.apply? (
                                        <textarea className="form_control"
                                            value={store.status_description}
                                            onChange={(e: any) => store.status_description = e.target.value}
                                        >
                                        </textarea>
                                    ) : (
                                        <textarea className="form_control"
                                            value={store.checkoutData.checkoutrequest.status_description}
                                            disabled
                                        >
                                        </textarea>
                                    )}
                                </dd>
                            </dl>
                        </div>
                        </div>
                        <div className="button_wrap btn_length_1">
                            {store.checkoutData.checkoutrequest.status === CHECKOUT_STATUS_CODE.apply? (
                                <ConfirmBtn
                                    className="btn btn_lg btn_darkblue pop_close"
                                    action={rejectSubmit}
                                    title={""}
                                    label="확인"
                                    msg={CHECKOUT_STATUS[store.checkoutStatus]+"하시겠습니까?"}
                                />
                            ) : (
                                <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>확인</button>
                            )}
                        </div>
                    </div>
                </div>
            ):''}
        </LodgedTotal>
    )
});

export default LodgedCheckOut;