// base은 prefix로 _BASE_을 붙인다.
// 특수상황은 _BASE_를 뺸다

const en = {
  'day-0': 'Sun.',
  'day-1': 'Mon.',
  'day-2': 'Tue.',
  'day-3': 'Wed.',
  'day-4': 'Thu.',
  'day-5': 'Fri.',
  'day-6': 'Sat.',

  'kr': 'korean',
  'en': "english",

  '_BASE_signin': "Signin",
};

export default en;
