import { format } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';

import getDay from 'date-fns/getDay';
import startOfMonth from 'date-fns/startOfMonth';
import getDaysInMonth from 'date-fns/getDaysInMonth';

import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import add from 'date-fns/addDays';
import sub from 'date-fns/subDays';
import addMonth from 'date-fns/addMonths';
import subMonth from 'date-fns/subMonths';

import getOverlappingDaysInIntervals from 'date-fns/getOverlappingDaysInIntervals';

import { ko } from 'date-fns/locale';

const FORMAT_DATE = 'yyyy-MM-dd';
const FORMAT_DATE_HOUR = 'yyyy-MM-dd HH:mm';

// 일요일(0), 월요일(1), 화요일(2), 수요일(3), 목요일(4), 금요일(5), 토요일(6)
export const dayByNumber: string[] = ['일', '월', '화', '수', '목', '금', '토'];

// 오늘 날짜 가져오기
export function getTodayDate(): [string, number] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });

  const dayOfWeekNum: number = new Date(todayDate).getDay();

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];

  return [todayDate, dayOfWeekNum];
}

// 내일 날짜 가져오기
export function getNextDay(d: string, n = 1): [string, number] {
  const nextDate = format(new Date(add(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = new Date(nextDate).getDay();

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [nextDate, dayOfWeekNum];
}

// 어제 날짜 가져오기
export function getPrevDay(d: string, n = 1): [string, number] {
  const prevDate = format(new Date(sub(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = new Date(prevDate).getDay();

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [prevDate, dayOfWeekNum];
}

// 다음달 가져오기
export function getNextMonth(d: string, n = 1): [string, number] {
  const nextDate = format(new Date(addMonth(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = getDay(new Date(nextDate));

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [nextDate, dayOfWeekNum];
}

// 어제 날짜 가져오기
export function getPrevMonth(d: string, n = 1): [string, number] {
  const prevDate = format(new Date(subMonth(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = getDay(new Date(prevDate));

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [prevDate, dayOfWeekNum];
}

// 해당 년-월의 첫번째 요일
export function getFirstDayByMonth(d: string): number {
  const startDay = startOfMonth(new Date(d));

  return getDay(startDay);
}

// 날짜를 기준으로 n개만큼 가져오기
export function getRangeDate(d: string, range = 1): [[string, number]] {
  const temp: any = [];

  for (let i = 0; i < range; ++i) {
    const formated = format(new Date(add(new Date(d), i)), FORMAT_DATE, {
      locale: ko,
    });
    const dayOfWeekNum: number = getDay(new Date(formated));

    const dayOfWeek: string = dayByNumber[dayOfWeekNum];
    temp.push([d, dayOfWeekNum]);
  }
  return temp;
}

// 해당 달에 요일갯수
export function getCountDaysByMonth(focusDate: string): number {
  return getDaysInMonth(new Date(focusDate));
}

// 시작 ~ 마지막 날짜를 전달 받으면 그 사이의 날짜를 반환한다.
export function getRangeDates(startDate: string, endDate: string) {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  }).map(date => format(new Date(date), FORMAT_DATE));
}

//같은 날짜인지
export function isAfterAndSame(startDate: string, endDate: string) {
  if ((!startDate && endDate) || (startDate && !endDate)) {
    return true;
  }

  const _isAfter = isAfter(new Date(endDate), new Date(startDate));

  const _isBefore = isBefore(new Date(endDate), new Date(startDate));

  if (!_isAfter && !_isBefore) {
    return true;
  } else if (_isAfter) {
    return true;
  } else {
    return false;
  }
}

// 포맷 변환
export function formatByString(d: string, f: string) {
  if (!d) return '';
  return format(new Date(d), f);
}

// 포맷 변환
export function format_YYYYMMDD(y: any, m: any, d: any) {
  return format(new Date(y, m - 1, d), FORMAT_DATE);
}

// 포맷 변환
export function formatByString_YYYYMMDD(d: string) {
  return format(new Date(d), FORMAT_DATE);
}

export function isMonthSame(d1: string, d2: string) {
  return d1.split('-')[0] === d2.split('-')[0] || d1.split('-')[1] === d2.split('-')[1];
}

// 크기비교 source < target true
export function rightBig(source: any, target: any) {
  return compareAsc(new Date(source), new Date(target)) === -1;
}

// 크기비교 source > target false
export function leftBig(source: any, target: any) {
  return compareAsc(new Date(source), new Date(target)) !== -1;
}

interface T {
  stay_after: string;
  stay_before: string;
}

// 충돌비교
export function isRangeOverlapping(t1: T, t2: T): number {
  return getOverlappingDaysInIntervals(
    {
      start: new Date(t1.stay_after),
      end: new Date(t1.stay_before),
    },
    {
      start: new Date(t2.stay_after),
      end: new Date(t2.stay_before),
    },
  );
}

// 오늘로부터 n개월 전 날짜 가져오기
export function getIntervalMonthDate(n: number): [string, string] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });

  const tempDate: Date = new Date();
  tempDate.setMonth(tempDate.getMonth() - n);

  const BeforeDate: string = format(tempDate, FORMAT_DATE, { locale: ko });

  return [todayDate, BeforeDate];
}

// 오늘로부터 n개월 전 날짜 가져오기
export function getTwoLatersMonthDate(n: number): [string, string] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });

  const tempDate: Date = new Date();
  tempDate.setMonth(tempDate.getMonth() + n);

  const BeforeDate: string = format(tempDate, FORMAT_DATE, { locale: ko });

  return [todayDate, BeforeDate];
}

// 오늘 날짜 년 가져오기
export function getYear(): string {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });
  const getMonth: string = new Date(todayDate).getFullYear() + '';

  return getMonth;
}

// 오늘 날짜 달 가져오기
export function getMonth(): string {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });
  const getMonth: string = new Date(todayDate).getMonth() + 1 + '';

  return getMonth;
}

// 오늘 날짜 일 가져오기
export function getDate(): string {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });
  const getMonth: string = new Date(todayDate).getDate() + '';

  return getMonth;
}

// 5년 주기 년도 가져오기 (true: 과거 5년, false: 미래 5년)
export function getYear5(isPased: boolean): [any] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });
  let getYear: number = new Date(todayDate).getFullYear();

  if (isPased) {
    getYear = new Date(todayDate).getFullYear() - 4;
  }

  const yearDate: any = [];

  for (let i = 0; i < 5; i++) {
    yearDate.push(getYear + i);
  }

  return yearDate;
}

// 과거5년, 미래 5년도 가져오기
export function getYear10(): [any] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });
  let getYear: number = new Date(todayDate).getFullYear();
  getYear = new Date(todayDate).getFullYear() - 4;
  const yearDate: any = [];
  for (let i = 0; i < 10; i++) {
    yearDate.push(getYear + i);
  }
  return yearDate;
}

// 월 가져오기
// year - 선택된 년도
// isPased - 과거데이터인지 여부 -> true: 1월부터 오늘 달까지, false: 오늘 달부터 12월 까지
export function getMonths(year: string, isPased: boolean): [any] {
  const todayDate: Date = new Date();
  let getMonth: number = todayDate.getMonth() + 1;
  const monthData: any = [];

  if (parseInt(year) !== todayDate.getFullYear()) {
    getMonth = 1;
    isPased = false;
  }

  if (isPased) {
    for (let i = 0; i < getMonth; i++) {
      monthData.push(1 + i);
    }
  } else {
    for (let i = 0; i < 12 - getMonth + 1; i++) {
      monthData.push(getMonth + i);
    }
  }

  return monthData;
}

// 달별로 일자 가져오기
// year - 선택된 년도
// month - 선택된 달
// isFutured - 과거데이터인지 여부 -> true: 오늘부터 막일까지, false: 1일부터 시작
export function getDays(year: string, month: string, isFutured: boolean): [any] {
  const DayData: any = [];

  // 입력받은 날짜(년월)의 마지막날짜 구하기
  const inputLastDate: Date = new Date(parseInt(year), parseInt(month), 0);
  const lastDay: number = inputLastDate.getDate();

  // 오늘날짜 구하기(비교용)
  const todayDate: Date = new Date();

  // 입력 년월이 현재 년월과 같은지 확인
  if (inputLastDate.getFullYear() === todayDate.getFullYear() && inputLastDate.getMonth() === todayDate.getMonth()) {
    const getDay: number = todayDate.getDate();

    if (isFutured) {
      for (let i = 0; i <= lastDay - getDay; i++) {
        DayData.push(getDay + i);
      }
    } else {
      for (let i = 0; i < getDay; i++) {
        DayData.push(1 + i);
      }
    }
  } else {
    for (let i = 0; i < lastDay; i++) {
      DayData.push(1 + i);
    }
  }

  return DayData;
}

// 단체 배방용 - 입숙, 퇴숙 예정일 겹치는 부분 확인하기
export function getStayConfirm(
  roomStayAfter: string,
  roomStayBefore: string,
  stayAfter: string,
  stayBefore: string,
): boolean {
  let chk: boolean = false;

  let temp: any = getRangeDates(roomStayAfter, roomStayBefore);

  if (
    stayAfter === roomStayAfter ||
    stayAfter === roomStayBefore ||
    stayBefore === roomStayAfter ||
    stayBefore === roomStayBefore
  ) {
    chk = true;
  }

  for (let i = 0; i < temp.length; i++) {
    if (temp[i] === stayAfter || temp[i] === stayBefore) {
      chk = true;
    }
  }

  return chk;
}

// 포맷 변환
export function formatByString_YYYYMMDDHH(d: string) {
  return format(new Date(d), FORMAT_DATE_HOUR);
}

// 포맷 변환
export function format_YYYYMMDDHH(date: string, hour: string): string {
  if (hour.length === 1) {
    hour += '0' + hour;
  }
  let tmp: string = date + ' ' + hour + ':00';
  const tmpDate: string = format(new Date(tmp), FORMAT_DATE_HOUR, { locale: ko });

  return tmpDate;
}

// 포맷 변환
export function format_YYYYMMDDhhmm() {
  let date: any = new Date();
  let year: any = date.getFullYear();
  let month: any = date.getMonth() + 1;
  month = Number(month) >= 10 ? month : '0' + month;

  let day = date.getDate();
  day = day >= 10 ? day : '0' + day;

  let hour = date.getHours();
  hour = hour >= 10 ? hour : '0' + hour;

  let min = date.getMinutes();
  min = min >= 10 ? min : '0' + min;

  let purchaseDay = year + '-' + month + '-' + day + ' ' + hour + ':' + min;
  return purchaseDay;
}

// 포맷 변환
export function format_YYYYMMDDhhmm2() {
  let date: any = new Date();
  let year: any = date.getFullYear();
  let month: any = date.getMonth() + 1;
  month = Number(month) >= 10 ? month : '0' + month;

  let day = date.getDate();
  day = day >= 10 ? day : '0' + day;

  let hour = date.getHours();
  hour = hour >= 10 ? hour : '0' + hour;

  let min = date.getMinutes();
  min = min >= 10 ? min : '0' + min;

  let purchaseDay = year + month + day + hour + min;
  return purchaseDay;
}

//날짜간 일수
export function dateDiff(_date1: string, _date2: string) {
  var diffDate_1 = new Date(_date1);
  var diffDate_2 = new Date(_date2);

  diffDate_1 = new Date(diffDate_1.getFullYear(), diffDate_1.getMonth(), diffDate_1.getDate());
  diffDate_2 = new Date(diffDate_2.getFullYear(), diffDate_2.getMonth(), diffDate_2.getDate());

  var diff = Math.abs(diffDate_2.getTime() - diffDate_1.getTime());
  diff = Math.ceil(diff / (1000 * 3600 * 24));

  return diff;
}
