import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useStores } from '../../store/mobx';
import Lodge from '../../apis/lodge';
import TempleteDefault from '../../components/templetes/default';
import './index.scss';
import { observer } from 'mobx-react';
import { useDispatch, useSelector } from 'react-redux';
import { GUEST_TYPE, LODGE_STATUS, ROOM_STATUS, ROOM_TYPE, WORK_SITE } from '../../constants';
import { getTodayDate } from '../../utils/date';
import { isNull } from 'lodash';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

const LodgedBoardDetail = observer((props: any) => {
    const dispatch = useDispatch();
    const [isManagePop, setIsManagePop] = useState<boolean>(false);
    const {lodgedBoardStore : store } = useStores();
    
    const {headerStore: headerStore} = useStores();
    const {userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [prevSelectValue, setPrevSelectValue] = useState<any>(headerStore.paramAuthRoomTypeRole);
    const [selectValue, setSelectValue] = useState<number>(store.guestList.room_type);
    // const [selectValue, setSelectValue] = useState<any>(headerStore.paramAuthRoomTypeRole);
    const [selectValue2, setSelectValue2] = useState<any>();
    const [selected, setSelected] = useState<any>([]);
    const [floorRoomList, setFloorRoomList] = useState<any[]>([]);
    const [roomStatusChg, setRoomStatusChg] = useState<number>(1);
    const [roomsId, setId] = useState<number>(0);
    // const []
    const [contents, setContents] = useState<any>();
    const [reload, setReload] = useState<boolean>(false);
    const [positions, setPositions] = useState({
        left: 400,
        top: 400
    });

   

    // 방상태 변경 API
    // 방상태 status  1: 입숙가능, 2: 창고, 3: 공사, 4: 하자, 5: 청소, 6: 기타
    const roomStatusChange = () => {
        let id = roomsId;
        (async () => {
            const payload = {
                room: roomsId,
                status: Number(roomStatusChg),
                employee_number: userInfo.employeeNumber,
                // is_available : is_available,
                memo:contents,
            }
            try {
                headerStore.isLoading = true;
                await Lodge.roomStatusChange(id,payload);
                setReload(!reload);
                popInit();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }else if(status === 400) {
                    (window as any).store.dispatch({
                        type: SHOW_ALERT_MODAL,
                        payload: {
                          title: '권한오류',
                          msg: data,
                          redirectPath: false,
                          statusCode: status,
                          errorCode: '',
                          type: 'error',
                          isModalShow: true,
                        },
                    });
                    return ;
                }
            }
        })();
    }

    
    const popInit = () => {
        setRoomStatusChg(1);
        setContents('');
    }
    
    const onSetPositions = (e: any) => {
        if (e.target !== e.currentTarget) return;

        setPositions({
            left : e.target.getBoundingClientRect().left,
            top: e.target.getBoundingClientRect().top + 90
        });
    };

    useEffect(() => {
        (async () => {
            headerStore.getAuthMenuRole('2-2');
            headerStore.getAuthWorkSiteRole();
            headerStore.getAuthRoomRole();
            store.work_site_id = headerStore.workSite;
            store.room_type = selectValue;
            let options: any[] = [];
            // options.push(<option value={headerStore.paramAuthRoomTypeRole}>전체</option>);
            for ( let i = 0; i < headerStore.paramAuthRoomTypeRole?.split(",").length; i++) {
                options.push(<option value={headerStore.paramAuthRoomTypeRole.split(",")[i]}>{ROOM_TYPE[headerStore.paramAuthRoomTypeRole.split(",")[i]]}</option>)
            }
            
            ReactDOM.render(options, document.getElementById("selectRoom"));

            if(Number(headerStore.workSite) === 2) {
                if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null') {
                    store.authBuilding = headerStore.paramAuthBuildingRole;
                }
            }
            try{
                headerStore.isLoading = true;
                await store.selectRoomType();
                // 숙소유형을 바꾼 경우 건물 0번째로 아니면 선택한 건물로
                
                if(!store.selectVal || store.selectVal.length <=0 ){
                    store.selectVal2 = [];   
                    setFloorRoomList([]);
                    headerStore.isLoading = false;
                    return;
                }

                if(selectValue2){
                    setSelectValue2(store.selectVal[0]);
                }else {
                    setSelectValue2(store.guestList);
                }
                headerStore.isLoading = false;
            }catch({status, data}){
                headerStore.isLoading = false;
                if (status === 401) {
                    if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                    }
                    window.location.href = '/';
                    return ;
                } else if (status === 403) {
                    (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                    },
                    });
                    return ;
                }else if ( status === 500 ) {
                    window.location.href = '/500';
                    return;
                }
            }
        })();
    }, [selectValue, headerStore.workSite]);
    
    useEffect(() => {
        (async () => {
            if(selectValue2){
                store.work_site_id = headerStore.workSite;
                store.room_type = selectValue2.room_type;
                store.name = selectValue2.name;
                store.dong = selectValue2.dong;
                if(Number(headerStore.workSite) === 2) {
                    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                        store.authBuilding = headerStore.paramAuthBuildingRole;
                    }
                }
                try{
                    headerStore.isLoading = true;
                    await store.selectRoomType2();
                    await store.gsDetailList();
                    setReload(true);
                    if(store.list.length > 0) {
                        const floorList = store.list;
                        setSelected(floorList.length);
                    }
                    headerStore.isLoading = false;
                }catch({status, data}){
                    headerStore.isLoading = false;
                    if (status === 401) {
                        if (!window.localStorage.getItem("isRetryLogin")) {
                        window.localStorage.setItem("isRetryLogin", "retry");
                        }
                        window.location.href = '/';
                        return ;
                    } else if (status === 403) {
                        (window as any).store.dispatch({
                        type: SHOW_ALERT_MODAL,
                        payload: {
                            title: '권한오류',
                            msg: '접근권한이 없습니다.',
                            redirectPath: false,
                            statusCode: status,
                            errorCode: '',
                            type: 'error',
                            isModalShow: true,
                        },
                        });
                        return ;
                    }else if ( status === 500 ) {
                        window.location.href = '/500';
                        return;
                    }
                }
            }
        })();
    }, [selectValue2, reload]);
    
    useEffect(() => {
        
        const floorList = store.list;
        // console.log(floorList);
        //rendering시 리스트 뽑아오기
        // if(store.list.length === 0) {
        //     props.history.replace(`/guest/board`);
        //     return;
        // }
                
        if(floorList.length > 0) {
            
            /* 층별 객실 리스트 추출 */
            // let total_floor = parseInt(store.list[0].total_floor);
            let floor: any [] = new Array(0);
            let rooms: any [] = new Array(0);
            let roomArr: any [] = new Array(0);
            let floorRooms: any [] = new Array(0);
            var floorSort = [];
            let lastFloor = '';
            let lastRoomName = '';
            let roomMaxCount = 0;
            let roomLiveCount = 0;
            for(let i = 0; i < floorList.length; i++) {
                let room_name = floorList[i].room_name;
                let quota = floorList[i].quota;
                let floor_name = floorList[i].floor_name;
                //console.log("room_name = " + room_name);                     
                if(i === 0 && lastRoomName === '') {
                    lastRoomName = room_name;
                }
                if(room_name != lastRoomName) {
                    
                    roomArr.push({'room_name':lastRoomName, 'room_cnt':roomMaxCount, 'quota':quota, 'live':roomLiveCount, 'rooms':rooms});
                    
                    if(lastFloor != '' && lastFloor != floor_name) {
                        floorRooms.push({'floor': lastFloor, 'roomArr':roomArr});
                        floor.push(floorRooms);
                        lastFloor = floor_name;
                        floorSort.push(parseInt(lastFloor));
                        floorRooms = [];
                        roomArr = [];
                    }
                    lastRoomName = room_name;
                    rooms = [];
                    roomMaxCount = 0;
                    roomLiveCount = 0;
                }
                rooms.push(floorList[i]);
                roomMaxCount++;
                if(floorList[i].guest_name != null) roomLiveCount++;
                if(i === floorList.length-1) {
                    roomArr.push({'room_name':lastRoomName, 'room_cnt':roomMaxCount, 'quota':quota, 'live':roomLiveCount, 'rooms':rooms});
                    floorRooms.push({'floor': lastFloor, 'roomArr':roomArr});
                    floor.push(floorRooms);
                    roomLiveCount = 0;
                }
                if(i ===0 || lastFloor != floorList[i].floor_name) {
                    lastFloor = floorList[i].floor_name;
                    floorSort.push(parseInt(lastFloor));
                }
            }
            /* 층수 정렬 인덱스 추출 */
            var floorSortResult = [];
            let floorInfo = floorSort.slice();
            floorSort.sort((a: any, b: any) => a > b ? 1 : a < b ? -1 : 0);
            for(let i = 0; i < floorSort.length; i++) {
                for(let j = 0; j < floorInfo.length; j++) {
                    let floorNum = floorSort[i];
                    if(floorNum === floorInfo[j]) {
                        floorSortResult.push(j);
                        break;
                    }
                }
            }
            
            let tables: any[] = [];
            let floorTitle: any[] = [];
            
            let floorSortList:any [] = [];
            for(let i = 0; i < floorSortResult.length; i++) {
                floorSortList.push(floor[floorSortResult[i]]);
            }
            setFloorRoomList(floorSortList);
        }
    }, [selectValue2, selected, reload]);

    

    const changeStatus = (quota : number, live:number) => {
        //console.log("quota = " + quota + ", idx = " + idx);
        let changeStatusHTML :any[] = [];
        for(let i =0 ; i < (quota - live); i++) {
            changeStatusHTML.push(
            <li>
                <button className="btn btn_xs btn_outline" onClick={()=>{}}>상태변경</button>
            </li>
            );
        }
        return (
            changeStatusHTML
        )
    }

    const getClassName = (available: number, status: number) => {
        if(status === 4){
            return 'type4';
        }else if(status !== 1) {
            return 'type1';    
        }
        // else if(available === 0) {
        //     return 'type1';    
        // }
        return '';
    }

    return (
        <TempleteDefault
            {...props}
            id="wrap"
            className=""
            isLoading={headerStore.isLoading}
        >
            <h4>동별 이용 현황 <span>{getTodayDate()[0]} 기준</span>
                <div className="right_area">
                    <select className="form_control num" id="selectRoom" value={selectValue} onChange={(e: any)=>{setSelectValue(e.target.value)}}>
                    {/* {Object.keys(ROOM_TYPE).map((key: string, index: number) => {
                            if(Number(key) !== 0 && Number(key) !== 7) {
                                return (
                                    <option key={index} value={key}>
                                        {ROOM_TYPE[Number(key)]}
                                    </option>)
                            }
                        })} */}
                    </select>
                    <select className="form_control num" onChange={(e: any)=>{setSelectValue2(store.selectVal[e.target.value])}}>
                        {store.selectVal.map((item: any, idx:number)=>(
                            <option value={idx} selected={selectValue2 ? selectValue2.dong === item.dong? true:false : false}>{item.name} {item.dong}</option>
                        ))}
                    </select>
                </div>
            </h4>
            <div className="table_normal_list">
                <table>
                    <caption className="sr_only">동별 이용 현황</caption>
                    <colgroup>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th rowSpan={3}>숙소유형</th>
                        <th colSpan={4}>숙소현황</th>
                        <th colSpan={7}>이용자현황</th>
                    </tr>
                    <tr>
                        <th rowSpan={2}>총 호실</th>
                        <th rowSpan={2}>이용중</th>
                        <th rowSpan={2}>이용가능</th>
                        <th rowSpan={2}>이용불가</th>
                        <th rowSpan={2}>수용인원</th>
                        <th rowSpan={2}>이용인원</th>
                        <th colSpan={3}>사내임직원</th>
                        <th rowSpan={2} className="bdl">계열사 임직원</th>
                        <th rowSpan={2}>외부인</th>
                    </tr>
                    <tr>
                        <th>사내임직원 A조</th>
                        <th>사내임직원 B조</th>
                        <th>사내임직원 C조</th>
                    </tr>
                    </thead>
                    {!store.selectVal2 || store.selectVal2.length <= 0 ? (
                        <tbody>
                    {/*데이타 없을 시*/}
                    <tr>
                        <td colSpan={12} className="blank_area type_white">
                            <p className="txt_blank">데이터가 없습니다.</p>
                        </td>
                    </tr>
                    </tbody>
                    ):(
                    <>
                    <tbody>
                    <tr>
                        <td>{store.selectVal2.name} {store.selectVal2.dong}</td> {/* 숙소유형 */}
                        <td>{store.selectVal2.checkin_room_total}</td> {/* 총호실 */}
                        <td><span className="point green">{store.selectVal2.checkin_using}</span></td> {/* 이용중 */}
                        <td><span className="point blue">{store.selectVal2.checkin_room_available}</span></td> {/* 이용가능 */}
                        <td><span className="point red">{store.selectVal2.checkin_room_unavailable}</span></td> {/* 이용불가 */}
                        <td>{store.selectVal2.checkin_room_capacity}</td> {/* 수용인원 */}
                        <td>{store.selectVal2.checkin_room_guest}</td> {/* 아용인원 */}
                        <td>{store.selectVal2.checkin_corporation_a}</td> {/* 사내A */}
                        <td>{store.selectVal2.checkin_corporation_b}</td> {/* 사내B */}
                        <td>{store.selectVal2.checkin_corporation_c}</td> {/* 사내C */}
                        <td>{store.selectVal2.checkin_subsidiary}</td> {/* 계열사 임직원 */}
                        <td>{store.selectVal2.checkin_public}</td> {/* 외부인 */}
                    </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>비율</th>
                            <td><span>100%</span></td> {/* 총호실 */}
                            <td><span className="point green">{store.selectVal2.length > 0? (store.selectVal2.checkin_using / store.selectVal2.checkin_room_capacity * 100).toFixed(1): '0'}%</span></td> {/* 이용중 */}
                            <td><span className="point blue">{store.selectVal2.length > 0? (store.selectVal2.checkin_room_available /store.selectVal2.checkin_room_capacity * 100).toFixed(1): '0'}%</span></td> {/* 이용가능 */}
                            <td><span className="point red">{store.selectVal2.length > 0? (store.selectVal2.checkin_room_unavailable / store.selectVal2.checkin_room_capacity * 100).toFixed(1):'0'}%</span></td> {/* 이용불가 */}
                            <td><span>100%</span></td> {/* 수용인원 */}
                            <td><span>{store.selectVal2.length > 0? (store.selectVal2.checkin_room_guest / store.selectVal2.checkin_room_capacity * 100).toFixed(1):'0'}%</span></td> {/* 이용인원 */}
                            <td><span>{store.selectVal2.length > 0? (store.selectVal2.checkin_corporation_a / store.selectVal2.checkin_room_capacity * 100).toFixed(1):'0'}%</span></td> {/* 사내A */}
                            <td><span>{store.selectVal2.length > 0? (store.selectVal2.checkin_corporation_b / store.selectVal2.checkin_room_capacity * 100).toFixed(1):'0'}%</span></td> {/* 사내B */}
                            <td><span>{store.selectVal2.length > 0? (store.selectVal2.checkin_corporation_c / store.selectVal2.checkin_room_capacity * 100).toFixed(1):'0'}%</span></td> {/* 사내C */}
                            <td><span>{store.selectVal2.length > 0? (store.selectVal2.checkin_subsidiary / store.selectVal2.checkin_room_capacity * 100).toFixed(1):'0'}%</span></td> {/* 계열사 임직원 */}
                            <td><span>{store.selectVal2.length > 0? (store.selectVal2.checkin_public / store.selectVal2.checkin_room_capacity * 100).toFixed(1):'0'}%</span></td> {/* 외부인 */}
                        </tr>
                    </tfoot>
                    </>
                    )}
                </table>
            </div>  {/*table*/}
            
            {floorRoomList.length > 0 ? floorRoomList.map((item: any, idx:number)=>(
                <>
                <h4>{item[0].floor}층({item[0].roomArr.length})</h4>
                <div className="group_floor assign_room">
                    <div className="outer_floor">
                    
                    {item[0].roomArr.length > 0 ? Object.keys(item[0].roomArr).map((rooms: any, roomsIdx:number)=>(
                        <>
                        <ul className="lst_ho">
                            <li>
                                {item[0].roomArr[rooms]["room_name"]}
                            </li>
                            {Object.keys(item[0].roomArr[rooms]["rooms"]).map((roomInfo: any, roomInfoIdx:number) => 
                            item[0].roomArr[rooms]["rooms"][roomInfo]["guest_name"] != null ?
                            (
                            <li className={item[0].roomArr[rooms]["rooms"][roomInfo]["guest_status"] !== 3? "type3" : "type2"} onMouseEnter={(e) => onSetPositions(e)}>
                                <p>
                                    <span className="name">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_name"]}</span>
                                    <span className="rank">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_rank"]}</span>
                                </p>
                                <p>
                                    <span className="date">{item[0].roomArr[rooms]["rooms"][roomInfo]["managed_at"]}</span>
                                    <span className="part">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_party"] != null ? (!item[0].roomArr[rooms]["rooms"][roomInfo]["guest_party"]? item[0].roomArr[rooms]["rooms"][roomInfo]["guest_party"] : item[0].roomArr[rooms]["rooms"][roomInfo]["guest_party"] +'조'): ''}</span>
                                </p>
                                <p>
                                    <span className="name">{LODGE_STATUS[item[0].roomArr[rooms]["rooms"][roomInfo]["guest_status"]]}</span>
                                </p>
                                <div className="user_detail" style={{left: positions.left, top: positions.top}}>
                                    <ul className="lst_desc">
                                        <li>
                                            <span className="tit">소속회사유형</span>
                                            <span className="desc">{GUEST_TYPE[item[0].roomArr[rooms]["rooms"][roomInfo]["guest_type"]]}</span>
                                        </li>
                                        <li>
                                            <span className="tit">회사명(단체명)</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_company"]}</span>
                                        </li>
                                        <li>
                                            <span className="tit">사번(아이디)</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_employee_number"]}</span>
                                        </li>
                                        <li>
                                            <span className="tit">성명</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_name"]}</span>
                                        </li>
                                        <li>
                                            <span className="tit">성별</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_gender"] === 1 ? "남성" : item[0].roomArr[rooms]["rooms"][roomInfo]["guest_gender"] === 2 ? "여성" : "-"}</span>
                                        </li>
                                        <li>
                                            <span className="tit">휴대폰번호</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_cellphone"]}</span>
                                        </li>
                                        <li>
                                            <span className="tit">소속 부서</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_department"]}</span>
                                        </li>
                                        <li>
                                            <span className="tit">근무조</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["guest_party"]}조</span>
                                        </li>
                                        <li>
                                            <span className="tit">차량번호</span>
                                            <span className="desc">
                                                {item[0].roomArr[rooms]["rooms"][roomInfo]["guest_car_number1"] != '' ? item[0].roomArr[rooms]["rooms"][roomInfo]["guest_car_number1"] : ''}
                                                {item[0].roomArr[rooms]["rooms"][roomInfo]["guest_car_number2"] != '' ? item[0].roomArr[rooms]["rooms"][roomInfo]["guest_car_number2"] : ''}
                                                {item[0].roomArr[rooms]["rooms"][roomInfo]["guest_car_number3"] != '' ? item[0].roomArr[rooms]["rooms"][roomInfo]["guest_car_number3"] : ''}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="tit">관리내역</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["memo"]}</span>
                                        </li>
                                    </ul>        
                                </div>
                            </li>
                            ) : 
                            <li className={getClassName(item[0].roomArr[rooms]["rooms"][roomInfo]["is_available"], item[0].roomArr[rooms]["rooms"][roomInfo]["status"])}  onMouseEnter={(e) => onSetPositions(e)}>
                                <p>
                                    <span className="name">방 상태 : {item[0].roomArr[rooms]["rooms"][roomInfo]["status"] === 1 ? '이용가능' : item[0].roomArr[rooms]["rooms"][roomInfo]["status"] === 8? '예약 신청중' : '이용불가'}</span>
                                </p>
                                <p>
                                    <span className="name">방 정보 : {ROOM_STATUS[item[0].roomArr[rooms]["rooms"][roomInfo]["status"]]}</span>
                                </p>

                                {item[0].roomArr[rooms]["rooms"][roomInfo]["status"] === 8? 
                                <></>
                                 : 
                                <button className="btn btn_xs btn_outline" onClick={()=> {setIsManagePop(true); setId(item[0].roomArr[rooms]["rooms"][roomInfo]["id"]);}}>상태변경</button>
                                }

                                <div className="user_detail" style={{left: positions.left, top: positions.top}}>
                                    <ul className="lst_desc">
                                        <li>
                                            <span className="tit">비고내역</span>
                                            <span className="desc">{item[0].roomArr[rooms]["rooms"][roomInfo]["memo"]}</span>
                                        </li>
                                    </ul>        
                                </div>
                            </li>
                            )
                            }
                            {
                            /*
                            changeStatus(item[0].roomArr[rooms]["quota"], item[0].roomArr[rooms]["live"])
                            */}
                        </ul>
                        </>
                    )) : ''}
                    </div>
                </div>
                </>
            )) : ''}
            <div className="submit">
                <div className="fl">
                    <button className="btn btn_lg btn_gray" onClick={() => {props.history.replace('/guest/board')}}>목록</button>
                </div>
            </div>  

            <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{ display: isManagePop ? 'block' : 'none' }}>
            <div className="pop_wrap">
                <div className="title_wrap">
                    <h3>상태변경</h3>
                    <button className="pop_btn_close pop_close" onClick={() => {setIsManagePop(false); popInit();}}>닫기</button>
                </div>
                <div className="contents_wrap">
                    {/* <div className="title_contents bd0">
                        <h3></h3>
                    </div> */}
                    <div className="process_list">
                        <dl>
                            <dt>상태</dt>
                            <dd>
                                <select className="form_control" value={roomStatusChg} onChange={(e:any) => {setRoomStatusChg(e.target.value)}}>
                                    <option value={1}>입숙가능</option>
                                    <option value={2}>창고</option>
                                    <option value={3}>공사</option>
                                    <option value={4}>하자</option>
                                    <option value={5}>청소</option>
                                    <option value={6}>기타</option>
                                </select>
                            </dd>
                            <dt>비고</dt>
                            <dd>
                                <textarea className="form_control" value={contents} onChange={(e:any) => setContents(e.target.value)}></textarea>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="button_wrap btn_length_1">
                    {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
                    {headerStore.writeRoleFlag ? 
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={() => {setIsManagePop(false); roomStatusChange();} }>완료</button>
                        : <>완료</>
                    }
                </div>
            </div>
        </div> 
            
        </TempleteDefault>
    )
})

export default LodgedBoardDetail;