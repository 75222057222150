import React, { useEffect, useState } from 'react';

import DefaultTemplate from '../../components/templetes/default';
import { Building, Room } from '../../components/templetes/building';
import { useSelector } from 'react-redux';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';

// import './index.scss';

const BuildingPage = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  const {buildingStore: store} = useStores();
  const [reload, setReload] = useState<boolean>(false);
  const baseUrl = '/lodged/basicinfo';
  useEffect(() => {
    // if (store.items.length === 0) {}
      (async () => {
        getRole();
      })();
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.init();
        store.destory(); 

      }
    }
  }, [reload, headerStore.workSite]);

  const getRole = async () => {
    headerStore.isLoading = true;
    headerStore.getAuthMenuRole('3-1');
    headerStore.getAuthRoomRole();
    store.offset = 0;     // 초기값
    store.work_site = headerStore.workSite;
    await store.getItems();
    store.init();
    setReload(true);
    headerStore.isLoading = false;
  }
  
  const [ tab, setTab ] = useState<number>(0);
  const [ item, setItem ] = useState<number>(-1);
  const [ buildingId, setBuildingId ] = useState<number>(-1);

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <div className="inner">
        <div className="group_tab mt30">
          <ul className="tab_lg tabs clearfix">
            <li><a href="javascript:void(0);" className={store.tabIndex === 0 ? 'on' : ''} onClick={() => store.tabIndex = 0}>건물관리</a></li>
            <li><a href="javascript:void(0);" className={store.tabIndex === 1 ? 'on' : ''} onClick={() => store.tabIndex = 1}>객실관리</a></li>
          </ul>
        </div>
        <Building setItem={setItem}/>
        <Room item={item}/>
      </div>
    </DefaultTemplate>
  );
});

export default BuildingPage;
