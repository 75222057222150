// 잘못됬을 때 true 반환

// 영문자
const _PASSWORD_REGEXP_ALPHABET = "[a-zA-Z]"
// 특수문자
const _PASSWORD_REGEXP_SPECIAL_CHAR = "[!@#$%^*+=-]"
// 숫자
const _PASSWORD_REGEXP_NUMNER = "[0-9]"

export const phone = (target: string) => target.length !== 11;

export const passwordValid = (p1: string, p2: string): boolean => {
  const isNotEqual = p1 !== p2;

  if (isNotEqual) return isNotEqual;

  const range = (p1.length < 8 || p1.length > 20);
  
  if (range) return range

  const valids = [isPasswordRegAlphabet(p1), , isPasswordRegSpecialChar(p1), isPasswordRegNumber(p1)]
  const checkCnt = 3;
  
  let passValid = isPasswordRegAlphabet(p1) ? 0 : 1;
  passValid += isPasswordRegSpecialChar(p1) ? 0 : 1
  passValid += isPasswordRegNumber(p1) ? 0 : 1

  const isValid  = passValid < checkCnt;

  // console.log(
  //   `[VALIDATION] PASSWORD: ${p1} and ${p2} compare => \n`,
  //   `isNotEqual: ${isNotEqual} , range: ${range}, valids(소,대,특,숫): ${valids}, passValid: ${passValid}, checkCnt: ${checkCnt}, isValid: ${isValid}`
  // );
  
  return isValid
}

export const employeeNumberValid = (target: string): boolean => target.length !== 7;

export const verifyNumber = (target: string): boolean => target.length !== 6;

export const isPasswordRegAlphabet = (p: string): boolean => p.search(_PASSWORD_REGEXP_ALPHABET) < 0;
export const isPasswordRegSpecialChar = (p: string): boolean => p.search(_PASSWORD_REGEXP_SPECIAL_CHAR) < 0;
export const isPasswordRegNumber = (p: string): boolean => p.search(_PASSWORD_REGEXP_NUMNER) < 0;
