import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { WORK_SITE } from '../../../constants';
import { useStores } from '../../../store/mobx';
import { formatByString, formatByString_YYYYMMDD, getMonth, getYear } from '../../../utils/date';
import MobileHeader from '../header';
import '../mb_common.scss';

const MobileMain = observer((props: any) => {
    const { headerStore: headerStore } = useStores(); //권한
    const { mainStore: mainStore } = useStores(); //메인
    const { vocStore: vocStore } = useStores();  //유지운영
    const { mobileTotalStore : mobileStore } = useStores();
    const [showVoc, setShowVoc] = useState<boolean>(false);
    const [checkinRole, setCheckinRole] = useState<boolean>(false);
    const [checkmoveRole, setCheckmoveRole] = useState<boolean>(false);
    const [checkoutRole, setCheckoutRole] = useState<boolean>(false);
    const [checkoutTotal, setCheckoutTotal] = useState<boolean>(false);
    const [isShowInfo, setIsShowInfo] = useState<boolean>(false);
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));

    useEffect(() => {
        (async () => {
            headerStore.getAuthWorkSiteRole();  //사업장권한
            headerStore.getAuthRoomRole();      //숙소권한
            headerStore.getAuthBuilding();      //건물 권한

            //입숙처리
            headerStore.getAuthMenuRole('1-2');
            if(headerStore.menuRoleFlag) {
                setCheckinRole(true);
            } else {
                setCheckinRole(false);
            }

            //이숙,퇴숙 처리
            headerStore.getAuthMenuRole('2-3');
            if(headerStore.menuRoleFlag) {
                setCheckmoveRole(true);
                setCheckoutRole(true);
                setCheckoutTotal(true);
            } else {
                setCheckmoveRole(false);
                setCheckoutRole(false);
                setCheckoutTotal(false);
            }

            // 월별 현황
            mainStore.worksite = headerStore.workSite;
            if(Number(headerStore.workSite) === 2) {
                if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0 && headerStore.paramAuthBuildingRole !== undefined) {
                    mainStore.authBuilding = headerStore.paramAuthBuildingRole; 
                }
            }
            await mainStore.getItems();

            //유지운영 가져오기
            headerStore.getAuthMenuRole('3-2');
            vocStore.work_site = headerStore.workSite; //사업장 셋팅
            //건물권한이 존재할경우 건물 내려보냄(남양에만 한하며 남양(2)판단은 서버에서 진행)
            if(Number(headerStore.workSite) === 2) {
                if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0 && headerStore.paramAuthBuildingRole !== undefined) {
                    vocStore.authBuilding = headerStore.paramAuthBuildingRole; 
                }
            }
            if(headerStore.mobileRoleFlag === true){
                vocStore.auth_Menu = 'Y';
            }else{
                vocStore.auth_Menu = 'N';
            }
            vocStore.employee_number = userInfo.employeeNumber;
            vocStore.roomtype = headerStore.paramAuthRoomTypeRole;
            vocStore.limit = 5;
            await vocStore.getItems();

            const authMenu = window.localStorage.getItem('auth_menu')?.replace(/\"/gi,"");
            let len: any[] = [authMenu?.split(",")];

            if(len[0]){
                len[0].map((menu: any, idx: number) => {
                    if(menu.indexOf('3-2') != (-1)){
                        setShowVoc(true);
                    }
                })
            }
        })();
    }, [headerStore.workSite]);
   
    return (
        <>
            <div id="wrap" className={headerStore.isLoading ? "wrap_mobile wrap_mobile_main loading" : "wrap_mobile wrap_mobile_main"}>
                <MobileHeader {...props} history={props.history} />
                <div id="container">
                    <div className="group_title">
                        <h4 className="title_contents">월별 현황</h4>
                        <div className="right_area">
                            {getYear()}년 {getMonth()}월
                        </div>
                    </div>
                    <ul className="lst_counts">
                        <li onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkTotal")) {mobileStore.init(); props.history.push("/mobile/guest/checkTotal")}}}>
                            <strong className="color_green">{mainStore.checkinStatData.checkin_use}</strong>
                            <span>{checkoutTotal ? <Link to={"/mobile/guest/checkTotal"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkTotal")) {mobileStore.init();}}}>입숙</Link> : <>입숙</>}</span>
                        </li>
                        <li><strong className="color_blue">{mainStore.checkinStatData.move_use}</strong><span>이숙</span></li>
                        <li><strong>{mainStore.checkinStatData.checkout_use}</strong><span>퇴숙</span></li>
                    </ul>

                    <div className="group_title">
                        <h4 className="title_contents">업무처리</h4>
                    </div>
                    <ul className="lst_counts">
                        <li onClick={()=> {
                            if(!props.history.location.pathname.includes("/mobile/guest/checkin")) {
                                mobileStore.init();
                                props.history.push("/mobile/guest/checkin");
                            }}}
                        >
                            <strong className="">{mainStore.checkinStatData.booking_apply}</strong>
                            <span>
                                {checkinRole ? <Link to={"/mobile/guest/checkin"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkin")) {mobileStore.init();}}}>입숙신청</Link> : <>입숙신청</>}
                            </span>
                        </li>
                        <li onClick={()=> {
                            if(!props.history.location.pathname.includes("/mobile/guest/checkchange")) {
                                mobileStore.init();
                                props.history.push("/mobile/guest/checkchange");
                            }}}
                        >
                            <strong className="">{mainStore.checkinStatData.move_apply}</strong>
                            <span>
                                {checkmoveRole ? <Link to={"/mobile/guest/checkchange"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkchange")) {mobileStore.init();}}}>이숙신청</Link> : <>이숙신청</>}
                            </span>
                        </li>
                        <li onClick={()=> {
                            if(!props.history.location.pathname.includes("/mobile/guest/checkout")) {
                                mobileStore.init();
                                props.history.push("/mobile/guest/checkout");
                            }}}
                        >
                            <strong className="">{mainStore.checkinStatData.checkout_apply}</strong>
                            <span>
                                {checkoutRole ? <Link to={"/mobile/guest/checkout"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkout")) {mobileStore.init();}}}>퇴숙신청</Link> : <>퇴숙신청</>}
                            </span>
                        </li>
                    </ul>

                    {showVoc? (
                        <>
                            <div className="group_title">
                                <h4 className="title_contents">유지운영</h4>
                                <div className="right_area">
                                    <button type="button" className="btn btn_xs btn_outline"
                                        onClick={()=>{mobileStore.init(); props.history.push('/mobile/lodged/maintenance');}}
                                    >더보기</button>
                                </div>
                            </div>
                            <hr/>
                            <div className="group_manage">
                                {!vocStore.items.length ? (
                                    <div className="blank_area">
                                        <p>데이터가 없습니다</p>
                                    </div>
                                ): (
                                    vocStore.items.map((item: any, index: number) => (
                                        <ul className="lst_manage" key={index}>
                                            <li><strong>{WORK_SITE[item.work_site]}</strong><span>{item.is_maintenance ? '유지보수' : 'Voc'}</span></li>
                                            <li><strong>[{item.category1}]</strong><span className="txt_ellipsis">{item.title}</span></li>
                                            <li><strong className="color_red fwb">{item.is_complete ? '완료' : '처리중'}</strong><span>{formatByString(item.created_at, 'yyyy.MM.dd')}</span></li>
                                        </ul>
                                    ))
                                )}
                            </div>
                        </>
                    ) : ''}
                </div>

                <p className="fixed_text">
                    {userInfo.loginHistory && userInfo.loginHistory.created_at? '최근 접속 일자: '+formatByString_YYYYMMDD(userInfo.loginHistory.created_at) : ''} 
                    <span className="ml10">{userInfo.loginHistory && userInfo.loginHistory.ip_address? 'IP: '+userInfo.loginHistory.ip_address : ''}</span>
                    <span className="ml10" onClick={() => {setIsShowInfo(true)}}>보안 정책 및 유의사항</span>
                </p>
                
                {/* 보안 정책 및 유의사항 팝업 */}
                <div id="pop_srch_id" className="pop_modal" style={{ display: isShowInfo ? 'block' : 'none' }}>
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <h3>보안 정책 및 유의사항</h3>
                            <button className="pop_btn_close pop_close" onClick={() => { setIsShowInfo(false); }}>닫기</button>
                        </div>
                        <div className="contents_wrap">

                            <div className="form_write">
                                <div className="row">
                                    <div className="col w100">
                                        <label htmlFor="input_userid" className="title_form">
                                            1. 본 프로그램은 회사의 업무를 지원하기 위해 제공되는 프로그램으로, 이용자는 이를 회사 업무 처리 목적으로만 사용하여야 하며, 개인의 사적인 용도로는 사용할 수 없습니다.
                                            <br /><br />
                                            2. 임직원은 당사 모바일 기기 준수 사항에 따라 모바일 기기 보호를 위한 화면 잠금 설정, 임의 개조 금지, 백신 설치 및 시스템 패치 등을 따라야 하며, 타인에게 ID와 Password를 공유해서는 안 됩니다.
                                            <br /><br />
                                            3. 이용자는 회사의 보안 규정(모바일 보안 지침 등)에 따라 본 프로그램 상의 제반 정보가 유출되지 않도록 보안을 철저히 유지해야 합니다.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={() => setIsShowInfo(false)}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MobileMain;
