import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import MobileCheckLnb from '..';
import CustomCalendar from '../../../../components/organismas/customCalendar';
import MobileFileUpload from '../../../../components/organismas/form/mobileFile';
import DatePicker from '../../../../components/organismas/input/datepicker';
import { BOOKING_STATUS_CODE, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE } from '../../../../constants';
import { MessageContext, MessageImpl } from '../../../../routes';
import { useStores } from '../../../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../../../store/modules/modal';
import { formatByString_YYYYMMDD, getNextDay, getTodayDate } from '../../../../utils/date';
import '../../mb_common.scss';

const MobileCheckIn = observer((props: any) => {
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const { mobileTotalStore : store } = useStores();
    const { headerStore: headerStore } = useStores();
    const [isShowPop, setIsShowPop] = useState<boolean>(false);
    const [isShowConfirmPop, setIsShowConfirmPop] = useState<boolean>(false);
    const [room_types, setRoom_types] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                headerStore.getAuthMenuRole('1-2');
                headerStore.getAuthBuilding();
                headerStore.getAuthRoomRole();
                
                setRoom_types(headerStore.paramAuthRoomTypeRole.split(","));

                store.work_site = headerStore.workSite;
                store.roomtype = headerStore.paramAuthRoomTypeRole;
                if(Number(headerStore.workSite) === 2) {
                    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                        store.paramAuthBuildingRole = headerStore.paramAuthBuildingRole;
                    }
                }
                await store.getCheckInList();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.limit, headerStore.workSite]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                store.buildingList = [];
                store.selectWorkSite = headerStore.workSite;
                store.dongList = [];
                store.selectBuilding = '';
                store.selectDong = '';
                
                if(store.selectRoomType != 0){
                    let resultData : any = await store.getBuildingList();   // 건물명
                    headerStore.isLoading = false;
                    setTimeout(function () {
                        if(resultData.save) {
                            // setReload(true);
                            return;
                        }else {
                            //info("문제가 발생했습니다.");
                            return;
                        }
                    }, 250);
                }

                let resultData2:any = await store.getCheckInList();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(resultData2.save) {
                        // setReload(true);
                        return;
                    }else {
                        //info("문제가 발생했습니다.");
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectRoomType]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                store.selectWorkSite = headerStore.workSite;
                store.dongList = [];
                store.selectDong = '';

                let resultData :any = await store.getBuildingDongList();   // 건물 > 동
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(resultData.save) {
                        // setReload(true);
                        return;
                    }else {
                        //info("문제가 발생했습니다.");
                        return;
                    }
                }, 250);

                let resultData2 : any = await store.getCheckInList();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(resultData2.save) {
                        // setReload(true);
                        return;
                    }else {
                        //info("문제가 발생했습니다.");
                        return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectBuilding]);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                await store.getCheckInList();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[store.selectDong]);

    // 검색 클릭
    const onSearch = async() => {
        try {
            headerStore.isLoading = true;
            store.limit = 10;
            await store.getCheckInList();
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            info("문제가 발생했습니다");
                return;
        }
    }

    const plusOffset = () => {
        store.limit += 10;
    }

    //role 핸들러
    const roleHandler = () => {
        info("권한이 없습니다.");
        return;
    }

    // 입숙처리
    const saveCheckin = async() => {
        if(store.managedAt === '' || store.managedAt === null) {
            info('입숙일을 선택해주세요');
            return false;
        }else if(store.keyoutAt === '' || store.keyoutAt === null) {
            info('키불출일을 선택해주세요');
            return false;
        }

        try {
            headerStore.isLoading = true;
            let data : any = await store.checkIn();
            headerStore.isLoading = false;
            setTimeout(function () {
                if(data.save) {
                    setIsShowPop(false);
                    setIsShowConfirmPop(true);
                    store.getCheckInList();
                }else {
                    info("입숙처리 중 문제가 발생하였습니다");
                    return;
                }
            }, 250);
            //info('입숙처리가 완료되었습니다');
            //store.popInit();
        } catch (e) {
            headerStore.isLoading = false;
          let { status, data } = e;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }else if (data.invalid_status && data.invalid_status.length) {
            info(data.invalid_status[0]);
            return;
          }

          info("문제가 발생했습니다");
                return;
        }
    }

    return (
        <>
            <MobileCheckLnb
                id=""
                className=""
                isLoading = {headerStore.isLoading}
                {...props}
            >
                <div className="form_write">
                    <div className="row col_3ea">
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectRoomType}
                                onChange={(e:any) => store.selectRoomType = e.target.value}
                            >
                                <option value="0">숙소유형</option>
                                {room_types.map((item: any, index: number) => {
                                    if(Number(item) !== 0 && Number(item) !== 7) {
                                        return (<option key={index} value={item}>{ ROOM_TYPE[item] }</option>);
                                    }    
                                })}
                            </select>
                        </div>
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectBuilding}
                                onChange={(e:any) => store.selectBuilding = e.target.value}
                                disabled={!store.selectRoomType || store.buildingList.length === 0 ? true : false}
                            >
                                <option value="">건물명</option>
                                {store.buildingList.map((item: any, idx: number) => (
                                    <option key={idx} value={item.name}>{ item.name }</option>
                                ))}
                            </select>
                        </div>
                        <div className="col">
                            <select name="" id="select_sex" className="form_control"
                                value={store.selectDong}
                                onChange={(e:any) => store.selectDong = e.target.value}
                                disabled={!store.selectRoomType || !store.selectBuilding || store.dongList.length === 0 ? true : false}
                            >
                                <option value="">동</option>
                                {store.dongList.map((item: any, idx: number) => (
                                    <option key={idx} value={item.dong}>{ item.dong }</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col w100">
                            <div className="form_search">
                                <input type="text" className="form_control" placeholder="이름을 입력해주세요" 
                                    value={store.text}
                                    onChange={(e:any) => {store.text = e.target.value}}
                                    onKeyDown={(e: any) => {
                                        if (e.keyCode === 13) onSearch();
                                    }}
                                />
                                <button type="button" className="btn_srch" onClick={() => onSearch()}></button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                {/* 입숙처리 리스트 */}
                <div className="tab_contents on">  {/* 'on' 추가 시 해당 내용 show */}
                    <div className="group_title">
                        <h4 className="title_contents">입숙처리 리스트</h4>
                    </div>
                    <hr />

                    {store.list.length <= 0 ? (
                        <div className="blank_area">
                            <p>데이터가 없습니다</p>
                        </div>
                    ) : (
                        store.list.map((item: any, idx: number) => (
                            <dl className="lst_checkin">
                                <dt>
                                    <ul>
                                        <li>{item.applicant_name}</li>
                                        <li>{ROOM_TYPE[item.room_type]}</li>
                                        <li>{item.applicant_cellphone}</li>
                                    </ul>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>
                                            <strong>신청일자</strong>
                                            <span>{item.created_at? formatByString_YYYYMMDD(item.created_at) : ''}</span>
                                        </li>
                                        <li>
                                            <strong>숙소정보</strong>
                                            <span>{item.guests? item.guests[0].room_building_name+ ' ' +item.guests[0].room_building_dong +' '+ item.guests[0].room_name + '호' : ''}</span>
                                        </li>
                                        <li>
                                            <strong>진행상태</strong>
                                            <span>
                                                {item.status === BOOKING_STATUS_CODE.approved && item.guests[0].status === LODGE_STATUS_CODE.waiting? 
                                                    //headerStore.finallyRoleFlag? (
                                                        <button className="btn btn_xs btn_outline" onClick={()=> {
                                                            store.selectData = item; 
                                                            if(store.selectData.meal_ticket != 0 && store.selectData.meal_ticket != ''){
                                                                store.mealTicket = store.selectData.meal_ticket;
                                                            }
                                                            setIsShowPop(true);
                                                        }}>
                                                            입숙처리
                                                        </button>
                                                    //) :(
                                                      //  <button className="btn btn_xs btn_outline" onClick={() => {roleHandler();}}>입숙처리</button>
                                                    //)
                                                 :''}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>이용자</strong>
                                            <span>{item.guests[0].guest_name}</span>
                                        </li>
                                        <li>
                                            <strong>이용자휴대폰</strong>
                                            <span>{item.guests[0].guest_cellphone}</span>
                                        </li>
                                        <li>
                                            <strong>입숙예정일</strong>
                                            <span>{item.guests[0].stay_after}</span>
                                        </li>
                                        <li>
                                            <strong>퇴숙예정일 (만료일)</strong>
                                            <span>{item.guests[0].stay_before}</span>
                                        </li>
                                    </ul>
                                </dd>
                            </dl>
                        ))
                    )}
                </div>

                {store.limit < store.totalCount ? (
                    <button className="btn btn_lg btn_outline btn_more" onClick={plusOffset}>
                        더보기<i className="ico_plus"></i>
                    </button>
                ) : ''}

            </MobileCheckLnb>

            {/* 입숙완료 처리 팝업 */}
            <div id="wrap" className="wrap_mobile">
                <div id="" className="pop_modal" style={{display: isShowPop? 'block' : 'none'}}>
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <button className="pop_btn_prev" onClick={() =>{ setIsShowPop(false); store.popInit();}}></button>
                            <h3>입숙완료처리</h3>
                            <button className="pop_btn_close pop_close" onClick={()=> {setIsShowPop(false); store.popInit();}}>닫기</button>
                        </div>
                        <div className="contents_wrap">
                            <div className="form_write">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="input_date_checkin" className="title_form">입숙일</label>
                                        {/* <input type="text" id="input_date_checkin" className="form_control form_sm datepicker" placeholder="" /> */}
                                        {/* <DatePicker
                                            value={store.managedAt}
                                            width={250}
                                            onChange={(date: string) => store.managedAt = date}
                                            isPrevMonthView={true}
                                            max={getNextDay(getTodayDate()[0],1)[0]}
                                        /> */}
                                        <CustomCalendar
                                            max={getTodayDate()[0]}
                                            value={store.managedAt}
                                            onChange={(date:string) => store.managedAt = date}
                                        ></CustomCalendar>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="input_date_key" className="title_form">키 불출일</label>
                                        {/* <DatePicker
                                            value={store.keyoutAt}
                                            width={250}
                                            onChange={(date: string) => store.keyoutAt = date}
                                            isPrevMonthView={true}
                                            max={getNextDay(getTodayDate()[0],1)[0]}
                                        /> */}
                                        <CustomCalendar
                                            max={getTodayDate()[0]}
                                            value={store.keyoutAt}
                                            onChange={(date: string) => store.keyoutAt = date}
                                        ></CustomCalendar>
                                    </div>
                                </div>
                                <div className="row">
                                {store.selectData.room_type === ROOM_TYPE_CODE.business_room 
                                    || store.selectData.room_type === ROOM_TYPE_CODE.business_room_group ? (
                                    <div className="col">
                                        <label htmlFor="input_count_coupon" className="title_form">식권수량</label>
                                        <input type="text" 
                                            id="input_count_coupon" 
                                            className="form_control form_sm" 
                                            value={store.mealTicket}
                                            onChange={(e: any) => {
                                                store.mealTicket = (parseInt(e.target.value) || 0)
                                            }}
                                        />
                                    </div>
                                ) :''}
                                    <div className="col">
                                        <label htmlFor="input_pdt_bed" className="title_form">침구수령여부</label>
                                        <input type="text" 
                                            id="input_pdt_bed" 
                                            className="form_control form_sm"
                                            value={store.bedding}
                                            onChange={(e: any) => {store.bedding = (parseInt(e.target.value) || 0)}}
                                        />
                                    </div>
                                </div>
                                <hr />

                                <MobileFileUpload 
                                    type="image" 
                                    fileId="attacheFile" 
                                    items={store.files} 
                                    onSetData={(data: any[]) => store.files = ([...data])} 
                                    setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
                                    imgTitle={"이용자 입숙서류 스캔본 업로드"}
                                />
                                
                                <div className="row">
                                    <div className="col w100">
                                        <label htmlFor="input_etc" className="title_form">비고 입력</label>
                                        <textarea name="" 
                                            id="input_etc" 
                                            className="form_control" 
                                            placeholder="내용을 입력하세요"
                                            value={store.memo}
                                            onChange={(e) => store.memo = e.target.value}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={(e:any) => {saveCheckin()}}>완료</button>
                        </div>
                    </div>
                </div>

                {/* 입숙처리 완료 팝업 */}             
                <div id="pop_complete_checkin" className="pop_dim_wrap pop_modal" style={{'display':isShowConfirmPop? 'block' :'none'}}>
                    <div className="pop_wrap">
                        <div className="contents_wrap">
                            <div className="title_contents">입숙처리가 완료되었습니다</div>
                            <div className="form_write">
                                <dl className="lst_checkin">
                                    <dt>
                                        <ul>
                                            <li>{store.selectData? store.selectData.applicant_name: ''}</li>
                                            <li>{store.selectData? ROOM_TYPE[store.selectData.room_type]:''}</li>
                                            <li>{store.selectData? store.selectData.applicant_cellphone: ''}</li>
                                        </ul>
                                    </dt>
                                    <dd>
                                        <ul>
                                            <li>
                                                <strong>신청일자</strong>
                                                <span>{store.selectData.created_at? formatByString_YYYYMMDD(store.selectData.created_at) : ''}</span>
                                            </li>
                                            <li>
                                                <strong>이용자</strong>
                                                <span>{store.selectData.guests? store.selectData.guests[0].guest_name :''}</span>
                                            </li>
                                            <li>
                                                <strong>이용자휴대폰</strong>
                                                <span>{store.selectData.guests? store.selectData.guests[0].guest_cellphone :''}</span>
                                            </li>
                                            <li>
                                                <strong>숙소정보</strong>
                                                <span>
                                                    {store.selectData.guests? store.selectData.guests[0].room_building_name: ''} 
                                                    {store.selectData.guests? store.selectData.guests[0].room_building_dong : ''} 
                                                    {store.selectData.guests? store.selectData.guests[0].room_name :''}호
                                                </span>
                                            </li>
                                            <li>
                                                <strong>입숙완료일</strong>
                                                <span>{store.selectData? store.managedAt :''}</span>
                                            </li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>

                        </div>
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={()=>{store.popInit(); setIsShowConfirmPop(false);}}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MobileCheckIn;
