import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { formatByString } from '../../../../utils/date';
import { NOTICE_TYPE } from '../../../../constants';
import { useStores } from '../../../../store/mobx';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../../routes';
import { SHOW_CONFIRM_MODAL } from '../../../../store/modules/modal';
import { useDispatch } from 'react-redux';
import { observer } from 'mobx-react';
import xss from 'xss';
import { parserEscape } from '../../../../utils/common';
import { core } from '../../../../apis';
import FileSaver from 'file-saver';

const ViewContent = observer((props: any) => {
  const dispatch = useDispatch();
  const {noticeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/manage/notice'
  // const [item, setItem] = useState<any>(props.history.location.state || {});
  const item: any | null = store.item;
  
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');

  const goBack = () => props.history.replace(baseUrl);

  const onEditHandler = () => {
    props.history.replace(`${baseUrl}/${item.id}/update`);
  };

  const onDeletehandler = async () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '현재 항목을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data : any = await store.deleteItem();
            headerStore.isLoading = false;
            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
                goBack();
                return;
              }else {
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            errorHandler({ status, data }, '삭제중 오류발생');
          }
        },
      }
    });
  };

  let [openTabIdx, setOpenTabIdx] = useState<number>(-1);

  const [guideType, setGuideType] = useState<number>();
  const [prevType, setPrevType] = useState<number>();

  let onClickChangeGuideTypeHandler = (idx: number) => {
      if (idx === prevType){
          setGuideType(-1);
          setPrevType(-1);
      } else{
          setGuideType(idx);
          setPrevType(idx);
      }
  }

  // useEffect(() => {
  //   if (item.id > -1) {
  //     (async () => {
  //       const { data } = await api.getNoticeExhibit({
  //         audit: item.id
  //       });
  //       if (data.length > 0) {
  //         const _item = { ...item };
  //         _item.guests = data;
  //         setItem(_item);
  //       }
  //     })();
  //   }
  // }, []);
  if (!item) return <></>;

  return (
    <>
      <h4>공지사항 상세</h4>
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">공지 관리 상세</caption>
          <colgroup>
            <col width="130" />
            <col width="*" />
            <col width="130" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>작성자</th>
              <td>{item.author_name} ({item.author_employee_number})</td>
              <th>작성일시</th>
              <td>{formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}</td>
            </tr>

              <tr>
                <th>기간설정</th>
                {item.display_after && item.display_before ? (
                  <td colSpan={3}>{item.display_after} ~ {item.display_before}</td>
                ) : (
                  <td>&nbsp;</td>
                )}
              </tr>

            <tr>
              <th>유형</th>
              <td colSpan={3}>{NOTICE_TYPE[item.category]}</td>
            </tr>

            <tr>
              <th>제목</th>
              <td colSpan={3}>{item.title}</td>
            </tr>

            <tr>
              <th>내용</th>
              <td colSpan={3}>
                <div className="editor" dangerouslySetInnerHTML={{ __html: parserEscape(item.contents) }}></div>
              </td>
            </tr>

            <tr>
              <th>이미지</th>
              <td colSpan={3}>
                <div className="images_area">
                  {item.images.map((image: any, index: number) => (
                    <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                        <a href="javascript:void(0);">
                          <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                        </a>
                    </div>
                  ))}
              </div>
              </td>
            </tr>

            <tr>
              <th>첨부파일</th>
              <td colSpan={3}>
                {item.files.map((file: any, index: number) => (
                  <a key={index} href={`${process.env.REACT_APP_MEDIA}/${file.path}`} className="item file"
                  target={`${file.path}`.split('.').pop() 
                          && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                          && !isMobile? '_blank' : ''}
                  >
                    {file.name}
                  </a>
                ))}
              </td>
            </tr>    
            <tr>
              <th>문자</th>
              <td colSpan={3}>{item.sms_message}</td>
            </tr>
          
            <tr>
              <th>메일</th>
              <td colSpan={3}>
                <p className="title fwb mb10">{item.mail_title}</p>
                <div className="editor" dangerouslySetInnerHTML={{ __html: parserEscape(item.mail_message) }}></div>
              </td>
            </tr>
            {item.guests && item.guests.length > 0 ? (
              <tr>
                <th>발송 대상</th>
                <td colSpan={3}>
                  <span className="item">{item.guests.length === 0 ? '' : `${item.guests.length} 명`}</span>
                </td>
              </tr>
            ) : (<></>)}
          </tbody>
        </table>
        <dl className="lst_terms left_area">
          <dt 
            onClick={() => {openTabIdx === 0 ? setOpenTabIdx(-1) : setOpenTabIdx(0); onClickChangeGuideTypeHandler(0)}}
            className={`${openTabIdx === 0 ? 'on' : ''} `}
          >
            <p className="title">{`${openTabIdx === 0 ? '발송 대상 목록(접기)' : '발송 대상 목록(펼치기)'}`}</p>
            <a href=" javascript:void(0)" className="btn_toggle_type2 ir">
              {'펼치기/접기'}
            </a>
          </dt>
          <dd className={`${openTabIdx === 0 ? 'on' : ''}`}>
            {item.guests && Object.keys(item.guests).map((guests: any, index: number) => { 
              return <a className="item">{item.guests[guests].guest_name}</a>
            })}
          </dd>
        </dl>
      </div>{' '}
      {/*table*/}
      <div className="submit">
        <div className="fl">
          <button type="button" className="btn_lg btn_darkgray" onClick={goBack}>
            목록
          </button>
          {headerStore.writeRoleFlag? 
          <button type="button" className="btn_lg btn_darkblue btn_outline" onClick={props.onCopy}>
            복사 생성
          </button>
          : 
          <></> 
          }
        </div>
        <div className="fr">
          {headerStore.writeRoleFlag? 
          <button type="button" className="btn_lg btn_darkblue btn_outline" onClick={onEditHandler}>
            수정
          </button>
          : 
        <></> 
        }
          {headerStore.deleteRoleFlag?
          <button type="button" className="btn_lg btn_darkblue" onClick={onDeletehandler}>
            삭제
          </button>
          :
            <></>
          }
        </div>
      </div>

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
          <div className="pop_wrap">
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
});

export default ViewContent;
