import React from 'react';
/*
    번호 페이징
    property: props.totalPage
    property: props.offset
    property: props.limit
    method:   props.setOffset()
*/
const Pagination = (props: any) => {
    let range = 10;
    let pages = []

    let currentPage: number = Math.floor(props.offset / props.limit)
    let lastPage = props.totalPage
    
    let viewUnit = Math.floor(currentPage / range);

    for (let i: number = 0; i < range ; ++i) {
        let viewPage = viewUnit * range + i
        if (viewUnit * range + i < lastPage) {
            pages.push(
                <li key={i}>
                    <a style={{'cursor':'pointer'}}
                        className={viewPage === currentPage ? "active" : ""}
                        onClick={() => props.setOffset(viewPage * props.limit)}
                    > {viewPage + 1} </a>
                </li>
            )
        }
    }

    

    const getPrevOffset = (offset: number, limit: number) => {
        let prevOffset = (Math.floor(offset / limit) - 1) * limit
        if (!(prevOffset < 0)) props.setOffset(prevOffset)
    }

    const getNextOffset = (offset: number, limit: number, totalPage: number) => {
        let nextOffset = (Math.floor(offset / limit) + 1) * limit
        if (nextOffset < (totalPage * limit)) props.setOffset(nextOffset)
    }

    return (
        <>
            <li><a style={{'cursor':'pointer'}} className="paging_prev" onClick={() => getPrevOffset(props.offset, props.limit)}>paging_prev</a></li>
            {pages.length ? pages : <li><a style={{'cursor':'pointer'}}> 1 </a></li>}
            <li><a style={{'cursor':'pointer'}} className="paging_next" onClick={() => getNextOffset(props.offset, props.limit, props.totalPage)}>paging_next</a></li>
        </>
    )
}

export default Pagination;