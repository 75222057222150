import { AuditStore } from './auditStore';
import { FaqStore } from './faqStore';
import { NoticeStore } from './noticeStore';
import { LoadgedTotalStore } from './loadgedTotalStore';
import { LodgedHistoryStore } from './lodgedHIstoryStore';
import { LodgedBoardStore } from './lodgedBoardStore';

import { BuildingStore } from './buildingStore';
import { VocStore } from './vocStore';
import { MemberStore } from './memberStore';
import { ApplicantJudgeStore } from './applicantJudgeStore';
import { DeductMeterStore } from './deductMeterStore';
import { DeductUlsanWithoutStore } from './deductUlsanWithoutStore';
import { DeductUlsanStore } from './deductUlsanStore';
import { HeaderStore } from './headerStore';
import { AccountPermissionStore } from './accountPermissionStore';
import { mainStore } from './mainStore';
import { MobileTotalStore } from './mobileTotalStore';
import { SmsStore } from './smsStore';
import { DeductUlsanDormitoryStore } from './deductUlsanDormitoryStore';
import { PledgeStore } from './pledgeStore';
import { DeductDeductibleListPage } from './deductDeductibleListPage';

//모든 store 등록 
export class RootStore{
    faqStore: FaqStore;
    noticeStore: NoticeStore;
    auditStore: AuditStore;
    loadgedTotalStore : LoadgedTotalStore;
    lodgedHistoryStore: LodgedHistoryStore;
    lodgedBoardStore: LodgedBoardStore;
    buildingStore: BuildingStore;
    vocStore: VocStore;
    memberStore: MemberStore;
    applicantJudgeStore: ApplicantJudgeStore;
    meterStore: DeductMeterStore;
    ulsanStore: DeductUlsanStore;
    ulsanDormitoryStore: DeductUlsanDormitoryStore;
    ulsanWithoutStore: DeductUlsanWithoutStore;
    headerStore: HeaderStore;
    accountPermissionStore: AccountPermissionStore;
    mainStore : mainStore;
    mobileTotalStore: MobileTotalStore;
    smsStore: SmsStore;
    pledgeStore: PledgeStore;
    deductDeductibleListPage : DeductDeductibleListPage;
    constructor() {
        this.faqStore = new FaqStore(this);
        this.noticeStore = new NoticeStore(this);
        this.auditStore = new AuditStore(this);
        this.loadgedTotalStore = new LoadgedTotalStore(this);
        this.lodgedHistoryStore = new LodgedHistoryStore(this);
        this.lodgedBoardStore = new LodgedBoardStore(this);

        this.buildingStore = new BuildingStore(this);
        this.vocStore = new VocStore(this);
        this.memberStore = new MemberStore(this);
        this.applicantJudgeStore = new ApplicantJudgeStore(this);
        this.meterStore = new DeductMeterStore(this);
        this.ulsanStore = new DeductUlsanStore(this);
        this.ulsanDormitoryStore = new DeductUlsanDormitoryStore(this);
        this.deductDeductibleListPage = new DeductDeductibleListPage(this);
        this.ulsanWithoutStore = new DeductUlsanWithoutStore(this);
        this.headerStore = new HeaderStore(this);
        this.accountPermissionStore = new AccountPermissionStore(this);
        this.mainStore = new mainStore(this);
        this.mobileTotalStore = new MobileTotalStore(this);
        this.smsStore = new SmsStore(this);
        this.pledgeStore = new PledgeStore(this);
    }
}