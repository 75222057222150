import React, { useState, useEffect, useContext} from 'react';
import SelectBuilding from '../../atoms/select/buildings';
import Floors from '../../molecules/floor';
import _ from 'lodash';
import { GUEST_TYPE, LODGE_STATUS_CODE, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../../constants';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import { MessageContext, MessageImpl } from '../../../routes';
import SelectFloor from '../../atoms/select/floor';
import './dormitoryBackroom.scss';
import DormitoryFloor from '../../molecules/dormitoryFloor';


//기숙사 강제 이숙 팝업
const MoveBackroomDormitory = observer((props: any) => {
  const {applicantJudgeStore: store} = useStores();
  const { info }: MessageImpl = useContext(MessageContext);
  const [badIdx, setSelectBadIdx] = useState<number>();

  useEffect(() => {
    if (!store.isDormitoryPop) return;

    (async () => {
      // 건물, 층 select 정보 가져오기
      store.selectRow.stay_before = '';
      await store.getBuildsInfo();  
      await store.getFloorsInfo();
    })();
  }, [store.isDormitoryPop])

  // 배방시 room 칸 클릭
  const onRoomClickHandlerToSelect = (selectedRoom: any, idx: number) => {
    let tempRoom: any = _.cloneDeep(store.room);
    tempRoom = {};
    tempRoom[selectedRoom.id] = selectedRoom;
    tempRoom['room'] = selectedRoom;
    store.room = tempRoom;    // 선택한 방 담기
    
    setSelectBadIdx(idx);   // 선택한 방이 2인 이상인 경우때문에 badIdx 담기
  }
  const onRoomClickHandlerToUnSelect = (unSelectedRoom: any) => {
    store.room = [];
  }
  const isDisable = (room: any, idx: number) => {
    return ((room.roomstatus[idx] !== null && room.roomstatus[idx].status !== 1) || room.roomstatus[idx].guest_id || room.room_type !== ROOM_TYPE_CODE.dormitory_room 
            || (room.roomstatus[idx].guest && room.roomstatus[idx].guest.status !== LODGE_STATUS_CODE.checkout)
            || (room.gender_type !== 0 && room.gender_type !== store.selectRow.guest_gender))
  }
  
  const isSelect = (room: any, idx: number) => {
    return (store.room[room.id] && badIdx === idx)
  }

  const disableText = (room: any, badIdx: number) => {
    if ((room.roomstatus[badIdx].guest && room.roomstatus[badIdx].guest.id)) {  // 이용중인 사용자 정보만 노츨
      return (
        <>
          <p>
              <span className="name">{room.roomstatus[badIdx].guest.guest_name}</span>
              <span className="rank">{room.roomstatus[badIdx].guest.guest_position}</span>
          </p>
          <p>
              <span className="date">{room.roomstatus[badIdx].guest.stay_after}</span>
              <span className="part">{room.roomstatus[badIdx].guest.guest_party}</span>
          </p>
        </>
      );
    } else return "";
  }

  const onCompleteHandler = async () => {
    if(Object.keys(store.room).length <= 0){
      info('방을 선택해주세요');
      return false;
    }
    props.moveApprove();
    store.isDormitoryPop = false;
  }

  if (!store.isDormitoryPop) return<></>;

  return (
    <div id="pop_assign_room" className="pop_dim_wrap pop_modal pop_lg" style={{display: store.isDormitoryPop? 'block' : 'none'}}>
        <div className="pop_wrap">
            <div className="title_wrap">
                <h3>배방</h3>
                <button className="pop_btn_close pop_close" onClick={() => {store.roomInit(); store.isDormitoryPop = false}}>닫기</button>
            </div>
            <div className="contents_wrap">
              <div className="table_normal_list table_write">
                    <table>
                        <caption className="sr_only">이용자정보</caption>
                        <colgroup>
                            <col width="160"/>
                            <col width="*"/>
                            <col width="160"/>
                            <col width="*"/>
                            <col width="160"/>
                            <col width="*"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>이용자 희망 객실</th>
                            <td colSpan={5}>{props.desired_room}</td>
                        </tr>
                        </tbody>
                    </table>
              </div>
              
              <div className="title_contents bd0">
                <h3>공실현황 및 배방 {!store.openRoomInfoPop && store.floors.length <= 0? (<span style={{color:'#f1615d'}}>선택한 건물에 배방가능한 방이 없습니다.</span>) : ''}</h3>
                  <div className="right_area">
                    <SelectBuilding
                      changeBuildHandler={(e: any) => {
                        store.selectFloor = 0;
                        store.building = store.buildings[parseInt(e.target.value)];
                        store.getFloorsInfo();
                      }}
                      building={store.building}
                      buildings={store.buildings}
                      dongs={store.dongs}
                    />
                    <SelectFloor floors={store.floors} value={store.selectFloor}
                      onChangeFloor={(e:any) => {
                          store.selectFloor = e.target.value; //store.room = store.floors[e.target.value]
                      }} />
                    {store.floors.length <= 0? (<li style={{color:'#f1615d'}}>선택한 건물에 배방가능한 방이 없습니다.</li>) : ''}
                  </div>
                </div>

                <div className="group_floor assign_room">
                  <div className="outer_floor">
                    <DormitoryFloor
                        floors={store.floors}
                        selectRoom={store.room}
                        selectFloor={store.selectFloor}
                        onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                        onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                        isUsable={() => { }}
                        isDisable={isDisable}
                        isSelect={isSelect}
                        disableText={disableText}
                      />
                  </div>
                </div>
            </div>
            <div className="button_wrap">
                <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={onCompleteHandler}>완료</button>
            </div>
        </div>
    </div>
  );
});

export default MoveBackroomDormitory;
