import React, { useContext, useEffect, useState } from 'react';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';
import DefaultTemplate from '../../../components/templetes/default';
import { ApiErrorHandleImpl, ApiErrorHandleContext, MessageImpl, MessageContext } from '../../../routes';
import DeductTab from '../deductTab';

import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { numberFormatter } from '../../../utils/common';
import YearSelect from '../yearSelect';
import MonthSelect from '../monthSelect';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';


const DeductDormitoryPage = observer((props: any) => {
  const dispatch = useDispatch();
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const {ulsanDormitoryStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/guest/deductible';
  const [grid, setGrid] = useState<any>(null);

  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>("");
  

  useEffect(() => {
    // console.log('Ulsan');
    headerStore.getAuthMenuRole('2-4');
    (async () => {
      headerStore.isLoading = true;
      let data : any = await store.getUlsanSingleSelect();
      if(data.save) {
        headerStore.isLoading = false;
      } else {
        headerStore.isLoading = false;
      }
    })();
    return () => {
      if (!store.findTabs(baseUrl)) {
        store.destory();
      }
    }
  }, [store.is_finalize, store.year, store.month]);

  const onSourceGridReady = (params: any) => {
    setGrid(params.api);
  };

  // employee_number:      사번       "1234006",
  // name:                 출장자     "최봉헌",
  // department:           소속       "운영부",
  // rank:                 직위       "책임매니저",
  // stay_before_after:    출장기간   "2021/02/16 ~ 2021/02/18",
  // day_count:            출장       "2",
  // meal_ticket:          식         3,
  // is_key_return:        키반납     "Y",
  // room_charge:          숙박비     "20,000",
  // meal_charge:          식권금액   "10,000",
  // unreturn_charge:      미반납액   "10,000",
  // total_deduct:         공제총액   "46,000",
  // is_finalize:          상태       "Y",

  const cellIsKeyReturnRenderer = (data: any) => {
    return data.value === 'Y' ? '예' : '아니오';
  };

  const cellIsFinalizeRenderer = (data: any) => {
    return data.value === 'Y' ? '확정' : '미확정';
  };

  const frameworkComponents = {
    cellIsKeyReturnRenderer,
    cellIsFinalizeRenderer,
  };

  const onSave = async () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '선택한 내역을 확정 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            const nodes = grid.getSelectedNodes();
            for (let index = 0; index < nodes.length; index++) {
              if(nodes[index].data.is_finalize === 'Y') {
                info('확정 완료된 사용자는 재확정 할 수 없습니다.');
                headerStore.isLoading = false;
                return;
              }
            }
            const data = nodes.map((node: any) => node.data);
            let check: any = await store.save(data);
            headerStore.isLoading = false;
            
            setTimeout(function () {
              if(check.save) {
                info('확정 완료했습니다.');
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            errorHandler({ status, data }, '확정중 오류발생');
          }
        },
      }
    });
  };

  // 다운로드 
  const xlsDownload = async()=>{
    try {
        headerStore.isLoading = true;
        const payload: any = {
          employee_number : userInfo.employeeNumber,
          worksite: '울산',
          search_date : `${store.year}${store.month}`,
          department: store.department,
          reason : downloadText,
        };
    
        let { data } = await Core.xlsDeuctUlsanBusinessList(payload);
        if(data) {
          FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
        }
        setIsShowDownloadPop(false);
        setDownloadText('');
        headerStore.isLoading = false;
    }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        // console.log(status, data);
    }
  }

  //검색
  const onSearch = async () => {
    headerStore.isLoading = true;
    let data : any = await store.getUlsanSingleSelect();
    if(data.save) {
      headerStore.isLoading = false;
    } else {
      headerStore.isLoading = false;
    }
  }

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <DeductTab {...props} />

      <div className="tab_contents">검침량</div>
      <div className="tab_contents">당월공제자관리</div>
      <div className="tab_contents">공제이력 리스트</div>
      <div className="tab_contents on">
        <h4>출장자숙소 공제 검색</h4>
        <div className="table_modify_list">
          <dl className="autowidth">
            <dt>검색기준</dt>
            <dd>
              <select name="" id="select_rooltype" className="form_control"
                  value={store.date_type}
                  onChange={(e:any) => store.setDatetype(e.target.value)}
              >
                <option value="">전체</option>
                <option value="checkout_date">퇴숙년월</option>
                <option value="deduct_date">공제년월</option>
              </select>
              
            </dd>
          </dl>
          <dl className="autowidth">
            <dt>공제(퇴숙)년월</dt>
            <dd>
              <YearSelect year={store.year} onChange={(e: any) => store.year = e.target.value} />
            </dd>
          </dl>
          <dl className="autowidth">
            <dt></dt>
            <dd>
              <MonthSelect month={store.month} onChange={(e: any) => store.month = e.target.value} />
            </dd>
          </dl>
          <dl className="autowidth">
            <dt>상태</dt>
            <dd>
              <select name="" id="select_rooltype" className="form_control"
                  value={store.is_finalize}
                  onChange={(e:any) => store.setFinalize(e.target.value)}
              >
                <option value="">전체</option>
                <option value="Y">확정</option>
                <option value="N">미확정</option>
              </select>
              
            </dd>
          </dl>
          <dl className="autowidth">
            <dt>부서명</dt>
            <dd>
              <input type="text" className="form_control col_md"
                value={store.department}
                onChange={(e: any) => store.department = e.target.value}
              />
            </dd>
          </dl>
          <dl className="autowidth">
            <dt>사번</dt>
            <dd>
              <input type="text" className="form_control col_md"
                value={store.employee_number}
                onChange={(e: any) => store.employee_number = e.target.value}
              />
              <button className="btn btn_sm btn_gray ml10" onClick={async () => onSearch()}>검색</button>
            </dd>
          </dl>
        </div>

        <h4>출장자숙소 공제 리스트
          <div className="right_area">
            <div>
                {headerStore.downRoleFlag && (userInfo.classification === 'HEC' || userInfo.classification === 'HMC') ?
                  <>
                    <button type="button" className="btn btn_md btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button>
                  </>
                  :
                  <>
                  </>
                }
            </div>
          </div>
        </h4>

        <div className="table_normal_list">
          <div className="ag-theme-alpine" style={{height: '500px'}}>
            <AgGridReact
              modules={AllCommunityModules}
              defaultColDef={{
                width: 100,
                wrapText: true,
                resizable: true,
                cellStyle: { textAlign: 'center', fontSize: '13px' },
                headerClass: 'text-center',
                editable: false,
              }}
              frameworkComponents={frameworkComponents}
              onGridReady={onSourceGridReady}
              rowSelection={'multiple'}
              rowDeselection={true}
              rowMultiSelectWithClick={true}
              rowData={store.items}
            >
              <AgGridColumn
                headerName=""
                editable={false}
                headerCheckboxSelection={true}
                headerCheckboxSelectionFilteredOnly={true}
                checkboxSelection={true}
                sortable={false}
                width={60}
                resizable={false}
                field=""
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn headerName="사번" field="employee_number" width={110} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="출장자" field="name" width={110} unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="소속" field="department" width={150} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="직위" field="rank" width={100} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="출장기간" field="stay_before_after" width={200} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="출장" field="day_count" width={90} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="식" field="meal_ticket" width={90} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="키반납" field="is_key_return" width={100} cellRenderer="cellIsKeyReturnRenderer" unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="숙박비" field="room_charge" width={120} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.room_charge)} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="식권금액" field="meal_charge" width={120} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.meal_charge)} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="미반납액" field="unreturn_charge" width={120} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.unreturn_charge)} unSortIcon={true} sortable={true} />
              <AgGridColumn headerName="공제총액" field="total_deduct" width={120} cellStyle={{ justifyContent: 'flex-end' }} valueFormatter={params => numberFormatter(params.data.total_deduct)} unSortIcon={true} sortable={true}/>
              <AgGridColumn headerName="상태" field="is_finalize" width={100} cellRenderer="cellIsFinalizeRenderer" unSortIcon={true} sortable={true} />
            </AgGridReact>
          </div>
        </div>
        <div className="submit ac">
          {headerStore.writeRoleFlag && (userInfo.classification === 'HEC' || userInfo.classification === 'HMC') ?
            <>
            {store.item ? <></> : <button type="button" className="btn btn_lg btn_darkblue" onClick={onSave}>확정</button>}
            </>
            : <></>
          }
        </div>
      </div>

      {/* 다운로드 사유 팝업 */}
      <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
              <dt>사유</dt>
              <dd>
                  <textarea className="form_control"
                  value={downloadText}
                  onChange={(e: any) => setDownloadText(e.target.value)}
                  >
                  </textarea>
              </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
          </div>
        </div>
      </div>

    </DefaultTemplate>
  );
});
export default DeductDormitoryPage;
