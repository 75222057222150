import React from 'react';

import { GENDER } from '../../../constants';

import Bad from '../bad';

const FacilityRoom = (props: any) => {
  if (props.room.name === '계단') return <a className="stair"></a>;
  else if (props.room.name === '샤워실') return <a className="wash"></a>;
  else return <a className=""> </a>;
};

const Room = (props: any) => {
  // const onClickHandler = (e: any) => props.onClick(props.room);

  const getBad = () => {
    const temp: any = [];
    for (let i = 0; i < props.room.quota; ++i) {
      temp.push(
        <Bad
          key={i}
          // onClick={onClickHandler}
          onRoomClickHandlerToSelect={props.onRoomClickHandlerToSelect}
          onRoomClickHandlerToUnSelect={props.onRoomClickHandlerToUnSelect}
          room={props.room}
          guestIdx={i}
          selectRoom={props.selectRoom}
          selected={props.isSelect(props.room, i)}
          isUseable={props.isUsable(props.room)}
          isDisable={props.isDisable(props.room, i)}
          isDetail={props.isDetail && props.room?.guests[i]?.id}
          disableText={() => props.disableText(props.room, i)}
        />
      );
    }

    return temp;
  };
  //const g: number = props.room?.gender_type? props.room?.gender_type : 1;
  return (
    <li key={props.key}>
      <p className="num"> {props.room.name} 
        {props.room?.gender_type === 1? '(남)' : ''}
        {props.room?.gender_type === 2? '(여)' : ''}
      </p>
      { getBad()}
    </li>
  );
};

export default Room;
