import {action, makeObservable, observable} from 'mobx';
import Lodge from '../../apis/lodge';
import { isNull } from 'lodash';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class LodgedBoardStore {
    rootStore: any;

    //리스트 또는 단일 객체
    list : any = [];
    item : any = [];
    guestList : any = [];
    gridList : any = [];

    //입숙자 현황표 상세
    floors__rooms__room_type : number = 0;    //숙소 유형
    name : string = '';     //숙소건물 이름
    dong : string = '';     //숙소 동
    floors__name : string = '';    //층수
    floors__rooms__name : string = '';  //호수
    floors__rooms__guests__guest_name : string = '';    //이용자 이름
    floors__rooms__guests__guest_party : string = '';   //이용자 근무조
    floors__rooms__guests__checkin_managed_at : string = '';    //입숙일
    floors__rooms__guests__guest_type : string = '';    //소속회사 유형
    floors__rooms__guests__guest_company : string = ''; //소속회사
    floors__rooms__guests__guest_employee_number : string = ''; //사번
    floors__rooms__guests__guest_position : string = '';    //직책
    floors__rooms__guests__guest_rank : string = '';    //직위
    floors__rooms__guests__guest_gender : string = '';  //성별
    floors__rooms__guests__guest_cellphone : string = ''; //전화번호
    floors__rooms__guests__guest_department : string = ''; //소속부서
    floors__rooms__guests__guest_car_number1 : string = ''; //차량번호1
    floors__rooms__guests__guest_car_number2: string = ''; //차량번호2
    floors__rooms__guests__guest_car_number3: string = ''; //차량번호3
    floors__rooms__guests__checkin__memo: string = ''; //비고

    work_site_id : number = 0;
    worksite : number = 0;
    calcular : any = []; //계산
    room_type : any; //숙소유형
    selectVal : any = []; //셀렉트 박스
    selectVal2 : any = []; //셀렉트 박스2

    //건물 권한
    authBuilding : any;
    //방상태변경
    id : number = 0; //방 id

    reloadTest: boolean = false;

    //loading
    headerStore : HeaderStore;

    constructor(root: any) {
        this.rootStore = root;
        this.headerStore = new HeaderStore(this);
        makeObservable(this, {
            floors__rooms__room_type : observable,
            name : observable,
            dong : observable,
            floors__name : observable,
            floors__rooms__name : observable,
            floors__rooms__guests__guest_name : observable,
            floors__rooms__guests__guest_party : observable,
            floors__rooms__guests__checkin_managed_at : observable,
            floors__rooms__guests__guest_type : observable,
            floors__rooms__guests__guest_company : observable,
            floors__rooms__guests__guest_employee_number : observable,
            floors__rooms__guests__guest_position : observable,
            floors__rooms__guests__guest_rank : observable,
            floors__rooms__guests__guest_gender : observable,
            floors__rooms__guests__guest_cellphone : observable,
            floors__rooms__guests__guest_department : observable,
            floors__rooms__guests__guest_car_number1 : observable,
            floors__rooms__guests__guest_car_number2 : observable,
            floors__rooms__guests__guest_car_number3 : observable,
            floors__rooms__guests__checkin__memo : observable,
            worksite : observable,
            calcular : observable,
            guestList : observable,
            item : observable,
            selectVal : observable,
            selectVal2 : observable,
            id : observable,
            authBuilding : observable,
            gridList : observable,
            reloadTest: observable,
            
            gsDetailList: action,

        });
    }

    init(){
        this.item = [];
        this.calcular = [];
    }

    //입숙자 현황표 리스트
    async gsStatList(){
        // this.init();
        const payload: any = {
            worksite : this.worksite,
            authBuilding: this.authBuilding,
        }
        
        payload['roomtype'] = this.room_type;
        
        if(Number(this.worksite) === 2) {
            if(!isNull(this.authBuilding) && this.authBuilding !== 'null' && this.authBuilding.length > 0) {
                payload.authBuilding = this.authBuilding; 
            }
        }

        try{
          this.headerStore.isLoading = true;
            const {data} = await Lodge.guestStatusList(payload);
    
            this.item = data;
            // this.calcular = []; 
            
            //↓↓↓↓입숙자 현황표 비율 및 합계↓↓↓↓
            //기숙사
            let domitoryTotalRoom : number = 0;
            let domitoryCal1 : number = 0;
            let domitoryCal2 : number = 0;
            let domitoryCal3 : number = 0;
            let domitoryCal4 : number = 0;
            let domitoryCal5 : number = 0;
            let domitoryCal6 : number = 0;
            let domitoryCal7 : number = 0;
            let domitoryCal8 : number = 0;
            let domitoryCal9 : number = 0;
            let domitoryCal10 : number = 0;
    
            //사택
            let companyTotalRoom : number = 0;
            let companyCal1 : number = 0;
            let companyCal2 : number = 0;
            let companyCal3 : number = 0;
            let companyCal4 : number = 0;
            let companyCal5 : number = 0;
            let companyCal6 : number = 0;
            let companyCal7 : number = 0;
            let companyCal8 : number = 0;
            let companyCal9 : number = 0;
            let companyCal10 : number = 0;
    
            //출장자 개인
            let businessTotalRoom : number = 0;
            let businessCal1 : number = 0;
            let businessCal2 : number = 0;
            let businessCal3 : number = 0;
            let businessCal4 : number = 0;
            let businessCal5 : number = 0;
            let businessCal6 : number = 0;
            let businessCal7 : number = 0;
            let businessCal8 : number = 0;
            let businessCal9 : number = 0;
            let businessCal10 : number = 0;

            //출장자 단체
            let businessGroupTotalRoom : number = 0;
            let businessGroupCal1 : number = 0;
            let businessGroupCal2 : number = 0;
            let businessGroupCal3 : number = 0;
            let businessGroupCal4 : number = 0;
            let businessGroupCal5 : number = 0;
            let businessGroupCal6 : number = 0;
            let businessGroupCal7 : number = 0;
            let businessGroupCal8 : number = 0;
            let businessGroupCal9 : number = 0;
            let businessGroupCal10 : number = 0;

            //영빈관
            let vipTotalRoom : number = 0;
            let vipCal1 : number = 0;
            let vipCal2 : number = 0;
            let vipCal3 : number = 0;
            let vipCal4 : number = 0;
            let vipCal5 : number = 0;
            let vipCal6 : number = 0;
            let vipCal7 : number = 0;
            let vipCal8 : number = 0;
            let vipCal9 : number = 0;
            let vipCal10 : number = 0;

            //가족숙소
            let familyTotalRoom : number = 0;
            let familyCal1 : number = 0;
            let familyCal2 : number = 0;
            let familyCal3 : number = 0;
            let familyCal4 : number = 0;
            let familyCal5 : number = 0;
            let familyCal6 : number = 0;
            let familyCal7 : number = 0;
            let familyCal8 : number = 0;
            let familyCal9 : number = 0;
            let familyCal10 : number = 0;
    
            data.map((item: any, idx: number) => 
                {
                    if(item.room_type === 2 ){ //기숙사
                        domitoryTotalRoom += parseInt(item.checkin_room_total); //총호실
                        domitoryCal1 += parseInt(item.checkin_using); //이용중
                        domitoryCal2 += parseInt(item.checkin_room_available); //이용가능
                        domitoryCal3 += parseInt(item.checkin_room_capacity); //수용인원
                        domitoryCal4 += parseInt(item.checkin_room_guest); //이용인원
                        domitoryCal5 += parseInt(item.checkin_corporation_a); //사내임직원A
                        domitoryCal6 += parseInt(item.checkin_corporation_b); //사내임직원B
                        domitoryCal7 += parseInt(item.checkin_corporation_c); //사내임직원B
                        domitoryCal8 += parseInt(item.checkin_subsidiary); //계열사 임직원
                        domitoryCal9 += parseInt(item.checkin_public); //외부인
                        domitoryCal10 += parseInt(item.checkin_room_unavailable)//이용불가
        
                        //기숙사비율
                        this.calcular['domitorycal1'] = domitoryCal1 === 0 || domitoryCal3 === 0 ? 0:domitoryCal1/domitoryCal3*100; // 이용중
                        this.calcular['domitorycal2'] = domitoryCal2 === 0 || domitoryCal3 === 0 ? 0:domitoryCal2/domitoryCal3*100; //이용가능
                        this.calcular['domitorycal3'] = domitoryCal3 === 0 || domitoryTotalRoom === 0? 0:domitoryCal3/domitoryTotalRoom*100; //수용인원
                        this.calcular['domitorycal4'] = domitoryCal4 === 0 || domitoryCal3 === 0? 0:domitoryCal4/domitoryCal3*100; //이용인원
                        this.calcular['domitorycal5'] = domitoryCal5 === 0 || domitoryCal3 === 0? 0:domitoryCal5/domitoryCal3*100; //사내a
                        this.calcular['domitorycal6'] = domitoryCal6 === 0 || domitoryCal3 === 0? 0:domitoryCal6/domitoryCal3*100; //사내b
                        this.calcular['domitorycal7'] = domitoryCal7 === 0 || domitoryCal3 === 0? 0:domitoryCal7/domitoryCal3*100; //사내c
                        this.calcular['domitorycal8'] = domitoryCal8 === 0 || domitoryCal3 === 0? 0:domitoryCal8/domitoryCal3*100; //계열사
                        this.calcular['domitorycal9'] = domitoryCal9 === 0 || domitoryCal3 === 0? 0:domitoryCal9/domitoryCal3*100; //외부인
                        this.calcular['domitorycalX'] = domitoryCal10 === 0 || domitoryCal3 === 0? 0:domitoryCal10/domitoryCal3*100; //이용불가
        
                        //기숙사합계
                        this.calcular['totalRoom'] = domitoryTotalRoom;
                        this.calcular['add1'] = domitoryCal1;
                        this.calcular['add2'] = domitoryCal2;
                        this.calcular['add3'] = domitoryCal3;
                        this.calcular['add4'] = domitoryCal4;
                        this.calcular['add5'] = domitoryCal5;
                        this.calcular['add6'] = domitoryCal6;
                        this.calcular['add7'] = domitoryCal7;
                        this.calcular['add8'] = domitoryCal8;
                        this.calcular['add9'] = domitoryCal9;
                        this.calcular['addX'] = domitoryCal10;
                        }
        
                    if(item.room_type === 3 ){ //사택
                        companyTotalRoom += parseInt(item.checkin_room_total); //총호실
                        companyCal1 += parseInt(item.checkin_using); //이용중
                        companyCal2 += parseInt(item.checkin_room_available); //이용가능
                        companyCal3 += parseInt(item.checkin_room_capacity); //수용인원
                        companyCal4 += parseInt(item.checkin_room_guest); //이용인원
                        companyCal5 += parseInt(item.checkin_corporation_a); //사내임직원A
                        companyCal6 += parseInt(item.checkin_corporation_b); //사내임직원B
                        companyCal7 += parseInt(item.checkin_corporation_c); //사내임직원B
                        companyCal8 += parseInt(item.checkin_subsidiary); //계열사 임직원
                        companyCal9 += parseInt(item.checkin_public); //외부인
                        companyCal10 += parseInt(item.checkin_room_unavailable);//이용불가
        
                        //사택비율
                        this.calcular['companycal1'] = companyCal1 === 0 || companyCal3 === 0? 0:companyCal1/companyCal3*100; 
                        this.calcular['companycal2'] = companyCal2 === 0 || companyCal3 === 0? 0:companyCal2/companyCal3*100;
                        this.calcular['companycal3'] = companyCal3 === 0 || companyTotalRoom === 0? 0:companyCal3/companyTotalRoom*100;
                        this.calcular['companycal4'] = companyCal4 === 0 || companyCal3 === 0? 0:companyCal4/companyCal3*100;
                        this.calcular['companycal5'] = companyCal5 === 0 || companyCal3 === 0? 0:companyCal5/companyCal3*100;
                        this.calcular['companycal6'] = companyCal6 === 0 || companyCal3 === 0? 0:companyCal6/companyCal3*100;
                        this.calcular['companycal7'] = companyCal7 === 0 || companyCal3 === 0? 0:companyCal7/companyCal3*100;
                        this.calcular['companycal8'] = companyCal8 === 0 || companyCal3 === 0? 0:companyCal8/companyCal3*100;
                        this.calcular['companycal9'] = companyCal9 === 0 || companyCal3 === 0? 0:companyCal9/companyCal3*100;
                        this.calcular['companycalX'] = companyCal10 === 0 || companyCal3 ===0? 0:companyCal10/companyCal3*100;
        
                        //사택합계
                        this.calcular['companytotalRoom'] = companyTotalRoom;
                        this.calcular['companyadd1'] = companyCal1;
                        this.calcular['companyadd2'] = companyCal2;
                        this.calcular['companyadd3'] = companyCal3;
                        this.calcular['companyadd4'] = companyCal4;
                        this.calcular['companyadd5'] = companyCal5;
                        this.calcular['companyadd6'] = companyCal6;
                        this.calcular['companyadd7'] = companyCal7;
                        this.calcular['companyadd8'] = companyCal8;
                        this.calcular['companyadd9'] = companyCal9;
                        this.calcular['companyaddX'] = companyCal10;
                    }
                    if(item.room_type === 5 ){ //출장자개인
                        businessTotalRoom += parseInt(item.checkin_room_total); //총호실
                        businessCal1 += parseInt(item.checkin_using); //이용중
                        businessCal2 += parseInt(item.checkin_room_available); //이용가능
                        businessCal3 += parseInt(item.checkin_room_capacity); //수용인원
                        businessCal4 += parseInt(item.checkin_room_guest); //이용인원
                        businessCal5 += parseInt(item.checkin_corporation_a); //사내임직원A
                        businessCal6 += parseInt(item.checkin_corporation_b); //사내임직원B
                        businessCal7 += parseInt(item.checkin_corporation_c); //사내임직원B
                        businessCal8 += parseInt(item.checkin_subsidiary); //계열사 임직원
                        businessCal9 += parseInt(item.checkin_public); //외부인
                        businessCal10 += parseInt(item.checkin_room_unavailable);//이용불가
                        //출장자개인 비율
                        this.calcular['businesscal1'] = businessCal1 === 0 || businessCal3 === 0? 0:businessCal1/businessCal3*100;
                        this.calcular['businesscal2'] = businessCal2 === 0 || businessCal3 === 0? 0:businessCal2/businessCal3*100;
                        this.calcular['businesscal3'] = businessCal3 === 0 || businessTotalRoom === 0? 0:businessCal3/businessTotalRoom*100;
                        this.calcular['businesscal4'] = businessCal4 === 0 || businessCal3 === 0? 0:businessCal4/businessCal3*100;
                        this.calcular['businesscal5'] = businessCal5 === 0 || businessCal3 === 0? 0:businessCal5/businessCal3*100;
                        this.calcular['businesscal6'] = businessCal6 === 0 || businessCal3 === 0? 0:businessCal6/businessCal3*100;
                        this.calcular['businesscal7'] = businessCal7 === 0 || businessCal3 === 0? 0:businessCal7/businessCal3*100;
                        this.calcular['businesscal8'] = businessCal8 === 0 || businessCal3 === 0? 0:businessCal8/businessCal3*100;
                        this.calcular['businesscal9'] = businessCal9 === 0 || businessCal3 === 0? 0:businessCal9/businessCal3*100;
                        this.calcular['businesscalX'] = businessCal10 === 0 || businessCal3 ===0? 0:businessCal10/businessCal3*100;
        
                        //출장자개인 합계
                        this.calcular['businesstotalRoom'] = businessTotalRoom;
                        this.calcular['businessadd1'] = businessCal1;
                        this.calcular['businessadd2'] = businessCal2;
                        this.calcular['businessadd3'] = businessCal3;
                        this.calcular['businessadd4'] = businessCal4;
                        this.calcular['businessadd5'] = businessCal5;
                        this.calcular['businessadd6'] = businessCal6;
                        this.calcular['businessadd7'] = businessCal7;
                        this.calcular['businessadd8'] = businessCal8;
                        this.calcular['businessadd9'] = businessCal9;
                        this.calcular['businessaddX'] = businessCal10;
                    }
                    if(item.room_type === 6 ){ //출장자단체
                        businessGroupTotalRoom += parseInt(item.checkin_room_total); //총호실
                        businessGroupCal1 += parseInt(item.checkin_using); //이용중
                        businessGroupCal2 += parseInt(item.checkin_room_available); //이용가능
                        businessGroupCal3 += parseInt(item.checkin_room_capacity); //수용인원
                        businessGroupCal4 += parseInt(item.checkin_room_guest); //이용인원
                        businessGroupCal5 += parseInt(item.checkin_corporation_a); //사내임직원A
                        businessGroupCal6 += parseInt(item.checkin_corporation_b); //사내임직원B
                        businessGroupCal7 += parseInt(item.checkin_corporation_c); //사내임직원B
                        businessGroupCal8 += parseInt(item.checkin_subsidiary); //계열사 임직원
                        businessGroupCal9 += parseInt(item.checkin_public); //외부인
                        businessGroupCal10 += parseInt(item.checkin_room_unavailable);//이용불가
                        //출장자단체 비율
                        this.calcular['businessGroupcal1'] = businessGroupCal1 === 0 || businessGroupCal3 === 0? 0:businessGroupCal1/businessGroupCal3*100;
                        this.calcular['businessGroupcal2'] = businessGroupCal2 === 0 || businessGroupCal3 === 0? 0:businessGroupCal2/businessGroupCal3*100;
                        this.calcular['businessGroupcal3'] = businessGroupCal3 === 0 || businessGroupTotalRoom === 0? 0:businessGroupCal3/businessGroupTotalRoom*100;
                        this.calcular['businessGroupcal4'] = businessGroupCal4 === 0 || businessGroupCal3 === 0? 0:businessGroupCal4/businessGroupCal3*100;
                        this.calcular['businessGroupcal5'] = businessGroupCal5 === 0 || businessGroupCal3 === 0? 0:businessGroupCal5/businessGroupCal3*100;
                        this.calcular['businessGroupcal6'] = businessGroupCal6 === 0 || businessGroupCal3 === 0? 0:businessGroupCal6/businessGroupCal3*100;
                        this.calcular['businessGroupcal7'] = businessGroupCal7 === 0 || businessGroupCal3 === 0? 0:businessGroupCal7/businessGroupCal3*100;
                        this.calcular['businessGroupcal8'] = businessGroupCal8 === 0 || businessGroupCal3 === 0? 0:businessGroupCal8/businessGroupCal3*100;
                        this.calcular['businessGroupcal9'] = businessGroupCal9 === 0 || businessGroupCal3 === 0? 0:businessGroupCal9/businessGroupCal3*100;
                        this.calcular['businessGroupcalX'] = businessGroupCal10 === 0 || businessGroupCal3 === 0? 0:businessGroupCal10/businessGroupCal3*100;
        
                        //출장자단체 합계
                        this.calcular['businessGrouptotalRoom'] = businessGroupTotalRoom;
                        this.calcular['businessGroupadd1'] = businessGroupCal1;
                        this.calcular['businessGroupadd2'] = businessGroupCal2;
                        this.calcular['businessGroupadd3'] = businessGroupCal3;
                        this.calcular['businessGroupadd4'] = businessGroupCal4;
                        this.calcular['businessGroupadd5'] = businessGroupCal5;
                        this.calcular['businessGroupadd6'] = businessGroupCal6;
                        this.calcular['businessGroupadd7'] = businessGroupCal7;
                        this.calcular['businessGroupadd8'] = businessGroupCal8;
                        this.calcular['businessGroupadd9'] = businessGroupCal9;
                        this.calcular['businessGroupaddX'] = businessGroupCal10;
                    }
                    if(item.room_type === 4 ){ //영빈관
                        vipTotalRoom += parseInt(item.checkin_room_total); //총호실
                        vipCal1 += parseInt(item.checkin_using); //이용중
                        vipCal2 += parseInt(item.checkin_room_available); //이용가능
                        vipCal3 += parseInt(item.checkin_room_capacity); //수용인원
                        vipCal4 += parseInt(item.checkin_room_guest); //이용인원
                        vipCal5 += parseInt(item.checkin_corporation_a); //사내임직원A
                        vipCal6 += parseInt(item.checkin_corporation_b); //사내임직원B
                        vipCal7 += parseInt(item.checkin_corporation_c); //사내임직원B
                        vipCal8 += parseInt(item.checkin_subsidiary); //계열사 임직원
                        vipCal9 += parseInt(item.checkin_public); //외부인
                        vipCal10 += parseInt(item.checkin_room_unavailable);//이용불가
                        //영빈관 비율
                        this.calcular['vipcal1'] = vipCal1 === 0 || vipCal3 === 0? 0:vipCal1/vipCal3*100;
                        this.calcular['vipcal2'] = vipCal2 === 0 || vipCal3 === 0? 0:vipCal2/vipCal3*100;
                        this.calcular['vipcal3'] = vipCal3 === 0 || vipTotalRoom === 0? 0:vipCal3/vipTotalRoom*100;
                        this.calcular['vipcal4'] = vipCal4 === 0 || vipCal3 === 0? 0:vipCal4/vipCal3*100;
                        this.calcular['vipcal5'] = vipCal5 === 0 || vipCal3 === 0? 0:vipCal5/vipCal3*100;
                        this.calcular['vipcal6'] = vipCal6 === 0 || vipCal3 === 0? 0:vipCal6/vipCal3*100;
                        this.calcular['vipcal7'] = vipCal7 === 0 || vipCal3 === 0? 0:vipCal7/vipCal3*100;
                        this.calcular['vipcal8'] = vipCal8 === 0 || vipCal3 === 0? 0:vipCal8/vipCal3*100;
                        this.calcular['vipcal9'] = vipCal9 === 0 || vipCal3 === 0 ? 0:vipCal9/vipCal3*100;
                        this.calcular['vipcalX'] = vipCal10 === 0 || vipCal3 === 0? 0:vipCal10/vipCal3*100;
        
                        //영빈관 합계
                        this.calcular['viptotalRoom'] = vipTotalRoom;
                        this.calcular['vipadd1'] = vipCal1;
                        this.calcular['vipadd2'] = vipCal2;
                        this.calcular['vipadd3'] = vipCal3;
                        this.calcular['vipadd4'] = vipCal4;
                        this.calcular['vipadd5'] = vipCal5;
                        this.calcular['vipadd6'] = vipCal6;
                        this.calcular['vipadd7'] = vipCal7;
                        this.calcular['vipadd8'] = vipCal8;
                        this.calcular['vipadd9'] = vipCal9;
                        this.calcular['vipaddX'] = vipCal10;
                    }
                    if(item.room_type === 1 ){ //가족숙소
                        familyTotalRoom += parseInt(item.checkin_room_total); //총호실
                        familyCal1 += parseInt(item.checkin_using); //이용중
                        familyCal2 += parseInt(item.checkin_room_available); //이용가능
                        familyCal3 += parseInt(item.checkin_room_capacity); //수용인원
                        familyCal4 += parseInt(item.checkin_room_guest); //이용인원
                        familyCal5 += parseInt(item.checkin_corporation_a); //사내임직원A
                        familyCal6 += parseInt(item.checkin_corporation_b); //사내임직원B
                        familyCal7 += parseInt(item.checkin_corporation_c); //사내임직원B
                        familyCal8 += parseInt(item.checkin_subsidiary); //계열사 임직원
                        familyCal9 += parseInt(item.checkin_public); //외부인
                        familyCal10 += parseInt(item.checkin_room_unavailable);//이용불가
                        //가족숙소 비율
                        this.calcular['familycal1'] = familyCal1 === 0 || familyCal3 === 0 ? 0:familyCal1/familyCal3*100;
                        this.calcular['familycal2'] = familyCal2 === 0 || familyCal3 === 0? 0:familyCal2/familyCal3*100;
                        this.calcular['familycal3'] = familyCal3 === 0 || familyTotalRoom === 0? 0:familyCal3/familyTotalRoom*100;
                        this.calcular['familycal4'] = familyCal4 === 0 || familyCal3 === 0? 0:familyCal4/familyCal3*100;
                        this.calcular['familycal5'] = familyCal5 === 0 || familyCal3 === 0? 0:familyCal5/familyCal3*100;
                        this.calcular['familycal6'] = familyCal6 === 0 || familyCal3 ===0 ? 0:familyCal6/familyCal3*100;
                        this.calcular['familycal7'] = familyCal7 === 0 || familyCal3 === 0? 0:familyCal7/familyCal3*100;
                        this.calcular['familycal8'] = familyCal8 === 0 || familyCal3 === 0? 0:familyCal8/familyCal3*100;
                        this.calcular['familycal9'] = familyCal9 === 0 || familyCal3 === 0? 0:familyCal9/familyCal3*100;
                        this.calcular['familycalX'] = familyCal10 === 0 || familyCal3 === 0? 0:familyCal10/familyCal3*100;
        
                        //가족숙소 합계
                        this.calcular['familytotalRoom'] = familyTotalRoom;
                        this.calcular['familyadd1'] = familyCal1;
                        this.calcular['familyadd2'] = familyCal2;
                        this.calcular['familyadd3'] = familyCal3;
                        this.calcular['familyadd4'] = familyCal4;
                        this.calcular['familyadd5'] = familyCal5;
                        this.calcular['familyadd6'] = familyCal6;
                        this.calcular['familyadd7'] = familyCal7;
                        this.calcular['familyadd8'] = familyCal8;
                        this.calcular['familyadd9'] = familyCal9;
                        this.calcular['familyaddX'] = familyCal10;
                    }
                }
            )
    
            this.reloadTest = !this.reloadTest;
            this.headerStore.isLoading = false;

            return {
              'save' : true,
              'status' : 200,
              'data' : ''
            };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            }
      
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
        }
    }

    //입숙현황표 상세 리스트    //geustStatusDetailList 약어 gsDetailList
    async gsDetailList(){
        const payload: any = {
            work_site_id : this.work_site_id,
            name: this.name,
            dong : this.dong,
            room_type : this.room_type,
        }

        if(Number(this.work_site_id) === 2) {
            if(!isNull(this.authBuilding) && this.authBuilding !== 'null') {
                payload.authBuilding = this.authBuilding
            }
        }

        // if(this.room_type !== -1){
        //     payload['roomtype'] = this.guestList.room_type;
        // }

        try{
          this.headerStore.isLoading = true;
          const {data} = await Lodge.guestStatusDetail(payload);
          this.list = data;
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            }
      
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
        }
    }

    //룸타입 별로 건물 가져오기
    async selectRoomType(){
        const payload: any = {
            worksite : this.worksite,
            roomtype : this.room_type,
        }

        if(Number(this.work_site_id) === 2) {
            if(!isNull(this.authBuilding) && this.authBuilding !== 'null') {
                payload.authBuilding = this.authBuilding
            }
        }

        try{
          this.headerStore.isLoading = true;
          const {data} = await Lodge.guestStatusList(payload);
  
          this.selectVal = data;
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }
    
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

    }

    async selectRoomType2(){
        const payload: any = {
            worksite : this.worksite,
            roomtype : this.room_type,
            name : this.name,
            dong : this.dong,
        }

        if(Number(this.work_site_id) === 2) {
            if(!isNull(this.authBuilding) && this.authBuilding !== 'null') {
                payload.authBuilding = this.authBuilding
            }
        }

        try{
          this.headerStore.isLoading = true;
          const {data} = await Lodge.guestStatusList(payload);
  
          this.selectVal2 = data[0];
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return {
                'save' : false,
                'status' : status,
                'data' : data
              };
            }
      
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
        }
    }
}