import React from 'react';
import '../mb_common.scss';

const MobileNotFound = (props: any) => {
    return (
        <>
            <div id="wrap" className="wrap_mobile error">
                <div id="" className="pop_modal">
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <h3>오류안내</h3>
                        </div>
                        <div className="contents_wrap">
                            <div className="error_message">
                                <p className="ico_error">죄송합니다 <br /><span>요청하신 페이지를 찾을 수 없습니다.</span></p>
                                <p className="detail">
                                    방문하시려는 페이지의 주소가 잘못 입력되었거나 <br />
                                    페이지 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다. <br /><br />
                                    감사합니다.
                                </p>
                            </div>
                        </div>
                        <div className="button_wrap">
                            <button type="button" className="btn btn_darkblue pop_close" onClick={() => props.history.push("/mobile/main")}>Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileNotFound;
