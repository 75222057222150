import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_REQUEST } from '../../../store/modules/base';
import { WORK_SITE } from '../../../constants';
import { useStores } from '../../../store/mobx';
import ReactDOM from 'react-dom';
//import '../mb_common.scss';
import { LOGOUT_REQUEST } from '../../../store/modules/auth';
import { Link } from 'react-router-dom';

const MobileHeader = observer((props: any) => {
    const { headerStore: headerStore } = useStores();
    const { mobileTotalStore : mobileStore } = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const dispatch = useDispatch();
    const [onNav, setOnNav] = useState<boolean>(false);
    const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
    const [showCheckOut, setShowCheckOut] = useState<boolean>(false);
    const [showVoc, setShowVoc] = useState<boolean>(false);
    const [showCheckTotal, setShowCheckTotal] = useState<boolean>(false);

    useEffect(() => {
        if (!window.localStorage.getItem('accessToken') ) {
            props.history.push('/')
            return
        }
        
        // 접근 가능한 사업장 가져오기
        headerStore.getAuthWorkSiteRole();
        
        let options: any[] = [];
        for ( let i = 0; i < headerStore.authWorkSiteLen[0].length; i++) {
            options.push(<option value={headerStore.authWorkSiteLen[0][i]}>{WORK_SITE[headerStore.authWorkSiteLen[0][i]]}</option>)
        }
        //최초 설정   
        headerStore.setWorkSite(headerStore.selectWorkSite? headerStore.selectWorkSite : headerStore.authWorkSiteLen[0][0]);
        headerStore.firstWorkSite(headerStore.selectWorkSite? headerStore.selectWorkSite : headerStore.authWorkSiteLen[0][0]);
        ReactDOM.render(options, document.getElementById("worksite"));

        const authMenu = window.localStorage.getItem('auth_menu')?.replace(/\"/gi,"");
        let len: any[] = [authMenu?.split(",")];

        if(len[0]){
            len[0].map((menu: any, idx: number) => {
                if(menu.indexOf('1-2') != (-1) && menu.indexOf('F') != (-1)){
                    setShowCheckIn(true);
                }else if(menu.indexOf('2-3') != (-1) && menu.indexOf('F') != (-1)){
                    setShowCheckOut(true);
                }else if(menu.indexOf('3-2') != (-1)){
                    setShowVoc(true);
                }else if(menu.indexOf('2-3') != (-1)) {
                    setShowCheckTotal(true);
                }
            })
        }
        
        let payload = {
            accessToken: window.localStorage.getItem('accessToken'),
            userInfo : window.localStorage.getItem('userInfo'),
        }

        dispatch({
            type: INIT_REQUEST,
            payload: payload,
            history: props.history,
        });
    }, []);

    return (
        <header id="mb_header" className={onNav? "on" : ""}>    {/* 'on' 추가 시 allmenu show */}
            <h1><Link to={"/mobile/main"}></Link></h1>
            {/* <h1><a href="javascript:void(0);"></a></h1>*/ }
            <select id="worksite" className="form_control select_place"
                value={headerStore.selectWorkSite}
                onChange={(e:any) => {headerStore.setWorkSite(e.target.value); headerStore.setSelectWorkSite(e.target.value);}}
                //style={{display: userInfo.classification === "HEC"? 'block' : 'none'}}
            >
            </select>
            <h2>{WORK_SITE[headerStore.workSite]} 숙소 관리</h2>
            <button type="button" className="btn_allmenu" onClick={()=>setOnNav(true)}></button>

            <nav className="mb_nav">
                <div className="group_allmenu">
                    <div className="log_area">
                        <button type="button" className="btn_home"
                            onClick={()=>{props.history.push("/mobile/main")}}
                        ></button>
                        <button type="button" className="btn_logout"
                            onClick={(e: any) => { 
                                headerStore.init();
                                dispatch({ type: LOGOUT_REQUEST, history: props.history });
                            }}
                        >
                            Log-Out
                        </button>
                        <button type="button" className="btn_close" onClick={()=>setOnNav(false)}></button>
                    </div>
                    <div className="user_area">
                        <div className="desc">
                            <span className="ico_user"></span>
                            {userInfo.username} 님은<br/>
                            {WORK_SITE[userInfo.work_site]}에서 근무 중 입니다.
                        </div>
                    </div>
                    <ul className="lst_allmenu">
                        {showCheckTotal? (<li><Link to={"/mobile/guest/checkTotal"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkTotal")) {mobileStore.init();}}}>입숙현황</Link></li>) : ''}
                        {showCheckIn? (<li><Link to={"/mobile/guest/checkin"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkin")) {mobileStore.init();}}}>입숙처리</Link></li>) : ''}
                        {showCheckOut? (<li><Link to={"/mobile/guest/checkchange"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkchange")) {mobileStore.init();}}}>이숙처리</Link></li>) : ''}
                        {showCheckOut? (<li><Link to={"/mobile/guest/checkout"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/guest/checkout")) {mobileStore.init();}}}>퇴숙처리</Link></li>) : ''}
                        {showVoc? (<li><Link to={"/mobile/lodged/maintenance"} onClick={()=> {if(!props.history.location.pathname.includes("/mobile/lodged/maintenance")) {mobileStore.init();}}}>유지운영</Link></li>) : ''}
                    </ul>
                </div>
            </nav>
        </header>
    );
});

export default MobileHeader;
