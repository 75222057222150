import React from 'react';

interface Props {
  value: string
  onChange: any
  disabled?: boolean
  name?: string
}


//소속 회사 select
const SelectCompany = ({value, onChange, name, disabled}: Props) => {
  let defaultClass = "form_control"
  return (
    <select
      value={ value}
      className={defaultClass}
      onChange={onChange}
      name={name}
      disabled={disabled}
    >
      <option value="">회사명을 선택하세요</option>
      <option value="현대자동차"> 현대자동차</option>
      <option value="기아자동차"> 기아자동차</option>
      <option value="현대다이모스"> 현대다이모스</option>
      <option value="현대모비스"> 현대모비스</option>
      <option value="현대트랜시스"> 현대트랜시스</option>
      <option value="현대로템"> 현대로템</option>
      <option value="현대제철"> 현대제철</option>
      <option value="현대카드"> 현대카드</option>
      <option value="현대캐피탈"> 현대캐피탈</option>
      <option value="현대엔지니어링"> 현대엔지니어링</option>
      <option value="현대건설"> 현대건설</option>
      <option value="현대오트론"> 현대오트론</option>
      <option value="현대오토에버"> 현대오토에버</option>
      <option value="현대스틸산업"> 현대스틸산업</option>
      <option value="현대위아"> 현대위아</option>
      <option value="현대종합설계">현대종합설계</option>
    </select>
  );
};

export default SelectCompany;
