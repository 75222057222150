import React from 'react';
import { ROOM_TYPE, ROOM_TYPE_CODE } from '../../../constants';
import { useStores } from '../../../store/mobx';
interface Props {
  value: number
  onChange: Function 
}

//숙소 타입 select
const RoomTypeSelect = (props: Props) => {
  const {headerStore: headerStore} = useStores();
  return (
    <select
      name=""
      id=""
      className="form_control"
      value={props.value}
      onChange={(e: any) => props.onChange(parseInt(e.target.value))}
    >
      {headerStore.roomRoleBusiness_room? 
                <option value={ROOM_TYPE_CODE.business_room}>
                  {ROOM_TYPE[ROOM_TYPE_CODE.business_room]}
                </option> :
                <></>
              }
              {headerStore.roomRoleBusiness_room_group? 
              <option value={ROOM_TYPE_CODE.business_room_group}>
                {ROOM_TYPE[ROOM_TYPE_CODE.business_room_group]}
              </option> : <></>
              }
              {headerStore.roomRoleVip_room? 
              <option value={ROOM_TYPE_CODE.vip_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.vip_room]}
              </option> : <></>
              } 
              {headerStore.roomRoleDormitory_room? 
              <option value={ROOM_TYPE_CODE.dormitory_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}
              </option> : <></>
              }
              {headerStore.roomRoleCompany_room? 
              <option value={ROOM_TYPE_CODE.company_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.company_room]}
              </option> : <></>
              }
              {headerStore.roomRoleFamily_room? 
              <option value={ROOM_TYPE_CODE.family_room}>
                {ROOM_TYPE[ROOM_TYPE_CODE.family_room]}
              </option> : <></>
              } 
    </select>
  );
};

export default RoomTypeSelect;
