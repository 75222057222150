import React, { useState } from 'react';

import { ROOM_TYPE_CODE } from '../../../constants';

//  기숙사
const Type1 = (props: any) => {
  return (
    <>
    <h4>이용자정보</h4>
    <div className="table_normal_list table_write">
        <table>
        <caption className="sr_only">이용자정보</caption>
        <colgroup>
            <col width="12.5%"/>
            <col width="*"/>
            <col width="12.5%"/>
            <col width="*"/>
            <col width="12.5%"/>
            <col width="*"/>
        </colgroup>
        <tbody>
        <tr>
            <th>소속 회사 유형</th>
            <td>{props.guest.guest_type === 1 ? "사내임직원" : props.guest.guest_type === 2 ? "계열사 임직원" : props.guest.guest_type === 3 ?"외부인" : "신입(경력)"}</td>
            <th>회사명(단체명)</th>
            <td>{props.guest.guest_company}</td>
            <th>사번</th>
            <td>{props.guest.guest_employee_number}</td>
        </tr>
        <tr>
            <th>성명</th>
            <td>{props.guest.guest_name}</td>
            <th>휴대폰번호</th>
            <td>{props.guest.guest_cellphone}</td>
            <th>성별</th>
            <td>{props.guest.guest_gender === 1 ? "남" : "여"}</td>
        </tr>
        <tr>
            <th>소속부서</th>
            <td>{props.guest.guest_department}</td>
            <th>근무조</th>
            <td>{props.guest.guest_party}</td>
            <th>차량번호</th>
            <td>{props.guest.guest_car_number1}</td>
        </tr>
        <tr>
            <th>입숙예정일</th>
            <td className="br0">{props.guest.stay_after}</td>
            <td colSpan={4} className="blank">&nbsp;</td>
        </tr>
        </tbody>
        </table>
    </div>
    </>
  )
}

// 사택
const Type2 = (props: any) => {
  return (
    <>
      <h4>이용자정보</h4>
      <div className="table_normal_list table_write">
          <table>
          <caption className="sr_only">이용자정보</caption>
          <colgroup>
              <col width="12.5%"/>
              <col width="*"/>
              <col width="12.5%"/>
              <col width="*"/>
              <col width="12.5%"/>
              <col width="*"/>
          </colgroup>
          <tbody>
          <tr>
              <th>사번</th>
              <td>{props.guest.guest_employee_number}</td>
              <th>성명</th>
              <td>{props.guest.guest_name}</td>
              <th>휴대폰번호</th>
              <td>{props.guest.guest_cellphone}</td>
          </tr>
          <tr>
              <th>성별</th>
              <td>{props.guest.guest_gender === 1 ? "남" : "여"}</td>
              <th>소속 부서</th>
              <td>{props.guest.guest_department}</td>
              <th>차량번호1</th>
              <td>{props.guest.guest_car_number1}</td>
          </tr>
          <tr>
              <th>차량번호2</th>
              <td>{props.guest.guest_car_number2}</td>
              <th>차량번호3</th>
              <td>{props.guest.guest_car_number3}</td>
              <th>사내부부여부</th>
              <td>{props.guest.is_cc? '여' : '부'}</td>
          </tr>
          <tr>
              <th>입숙예정일</th>
              <td>{props.guest.stay_after}</td>
              <th>주거지원금 수혜여부</th>
              <td className="br0">{props.guest.is_supported? '여':'부'}</td>
              <td className="blank" colSpan={2}>&nbsp;</td>
          </tr>
          </tbody>
          </table>
      </div>
    </>
  )
}

// 출장자 숙소 개인
const Type3 = (props: any) => {
  return (
    <>
      <h4>이용자정보</h4>
      <div className="table_normal_list table_write">
          <table>
          <caption className="sr_only">이용자정보</caption>
          <colgroup>
              <col width="12.5%"/>
              <col width="*"/>
              <col width="12.5%"/>
              <col width="*"/>
              <col width="12.5%"/>
              <col width="*"/>
          </colgroup>
          <tbody>
          <tr>
              <th>소속 회사 유형</th>
              <td>{props.guest.guest_type === 1 ? "사내임직원" : props.guest.guest_type === 2 ? "계열사 임직원" : "외부인"}</td>
              <th>회사명(단체명)</th>
              <td>{props.guest.guest_company}</td>
              <th>사번</th>
              <td>{props.guest.guest_employee_number}</td>
          </tr>
          <tr>
              <th>성명</th>
              <td>{props.guest.guest_name}</td>
              <th>휴대폰번호</th>
              <td>{props.guest.guest_cellphone}</td>
              <th>성별</th>
              <td>{props.guest.guest_gender === 1 ? "남" : "여"}</td>
          </tr>
          <tr>
              <th>소속부서</th>
              <td>{props.guest.guest_department}</td>
              <th>식권 수(장)</th>
              <td className="br0">{props.mealTicket}</td>
              <th>차량번호</th>
              <td>{props.guest.guest_car_number1}</td>
          </tr>
          <tr>
              <th>입숙예정일</th>
              <td>{props.guest.stay_after}</td>
              <th>퇴숙예정일</th>
              <td>{props.guest.stay_before}</td>
              <td className="blank" colSpan={2}></td>
          </tr>
          </tbody>
          </table>
      </div>
    </>
  )
}

// 출장자 숙소 단체
const Type4 = (props: any) => {
  return (
    <>
      <div className="user_info"><span className="user_name">이용자</span>{props.guest.guest_name}</div>
      <div className="table_normal_list table_write">
          <table>
          <caption className="sr_only">이용자정보</caption>
          <colgroup>
              <col width="10%"/>
              <col width="*"/>
              <col width="10%"/>
              <col width="*"/>
              <col width="10%"/>
              <col width="*"/>
              <col width="10%"/>
              <col width="*"/>
          </colgroup>
          <tbody>
          <tr>
              <th>소속회사유형</th>
              <td>{props.guest.guest_type === 1 ? "사내임직원" : props.guest.guest_type === 2 ? "계열사 임직원" : "외부인"}</td>
              <th>회사명(단체명)</th>
              <td>{props.guest.guest_company}</td>
              <th>사번</th>
              <td>{props.guest.guest_employee_number}</td>
              <th>성별</th>
              <td>{props.guest.guest_gender === 1 ? "남" : "여"}</td>
          </tr>
          <tr>
              <th>휴대폰번호</th>
              <td>{props.guest.guest_cellphone}</td>
              <th>소속부서</th>
              <td>{props.guest.guest_department}</td>
              <th>차량번호</th>
              <td>{props.guest.guest_car_number1}</td>
              <th>인솔자여부</th>
              <td>{props.guest.is_leader? "여" : "부"}</td>
          </tr>
          <tr>
              <th>입숙예정일</th>
              <td>{props.guest.stay_after}</td>
              <th>퇴숙예정일</th>
              <td className="br0">{props.guest.stay_before}</td>
              <td className="blank" colSpan={4}></td>
          </tr>
          </tbody>
          </table>
      </div> 
    </>
  )
}

// 영빈관
const Type5 = (props: any) => {
  return (
    <>
      <h4>이용자정보</h4>
      <div className="table_normal_list table_write">
          <table>
          <caption className="sr_only">이용자정보</caption>
          <colgroup>
              <col width="12.5%"/>
              <col width="*"/>
              <col width="12.5%"/>
              <col width="*"/>
              <col width="12.5%"/>
              <col width="*"/>
          </colgroup>
          <tbody>
          <tr>
              <th>소속회사유형</th>
              <td>{props.guest.guest_type === 1 ? "사내임직원" : props.guest.guest_type === 2 ? "계열사 임직원" : "외부인"}</td>
              <th>회사명(단체명)</th>
              <td>{props.guest.guest_company}</td>
              <th>사번</th>
              <td>{props.guest.guest_employee_number}</td>
          </tr>
          <tr>
              <th>성명</th>
              <td>{props.guest.guest_name}</td>
              <th>휴대폰번호</th>
              <td>{props.guest.guest_cellphone}</td>
              <th>성별</th>
              <td>{props.guest.guest_gender === 1 ? "남" : "여"}</td>
          </tr>
          <tr>
              <th>소속 부서</th>
              <td>{props.guest.guest_department}</td>
              <th>입숙예정일</th>
              <td>{props.guest.stay_after}</td>
              <th>차량번호</th>
              <td>{props.guest.guest_car_number1}</td>
          </tr>
          <tr>
              <th>퇴숙예정일</th>
              <td className="br0">{props.guest.stay_before}</td>
              <td className="blank" colSpan={4}></td>
          </tr>
          </tbody>
          </table>
      </div> 
    </>
  )
}

// 가족숙소
const Type6 = (props: any) => {
  return (
    <>
      <div className="user_info"><span className="user_name">이용자</span>{props.guest.guest_name}</div>
        <div className="table_normal_list table_write">
            <table>
            <caption className="sr_only">이용자정보</caption>
            <colgroup>
                <col width="12.5%"/>
                <col width="*"/>
                <col width="12.5%"/>
                <col width="*"/>
                <col width="12.5%"/>
                <col width="*"/>
            </colgroup>
            <tbody>
            <tr>
                <th>성별</th>
                <td>{props.guest.guest_gender === 1 ? "남" : "여"}</td>
                <th>휴대폰번호</th>
                <td>{props.guest.guest_cellphone}</td>
                <th>신청자와의 관계</th>
                <td>{props.guest.guest_relation}</td>
            </tr>
            <tr>
                <th>차량번호</th>
                <td>{props.guest.guest_car_number1}</td>
                <th>입숙예정일</th>
                <td>{props.guest.stay_after}</td>
                <th>퇴숙예정일</th>
                <td>{props.guest.stay_before}</td>
            </tr>
            </tbody>
            </table>
        </div>
    </>
  )
}

const GuestForm = (props: any) => {
 if(props.roomType === ROOM_TYPE_CODE.company_room) {
    return props.guests.map((guest: any, guestIdx: number) => 
      <Type2 isUpdateMode={props.isUpdateMode} guest={guest} guestIdx={guestIdx} key={guestIdx} setGuests={props.setGuests} guests={props.guests} roomType={props.roomType} />
    )
  }else if(props.roomType === ROOM_TYPE_CODE.business_room) {
    return props.guests.map((guest: any, guestIdx: number) => 
      <Type3 isUpdateMode={props.isUpdateMode} guest={guest} guestIdx={guestIdx} key={guestIdx} setGuests={props.setGuests} guests={props.guests} roomType={props.roomType} mealTicket={props.mealTicket} />
    )
  }else if(props.roomType === ROOM_TYPE_CODE.business_room_group) {   // 단체
    return props.guests.map((guest: any, guestIdx: number) => 
      <Type4 isUpdateMode={props.isUpdateMode} guest={guest} guestIdx={guestIdx} key={guestIdx} setGuests={props.setGuests} guests={props.guests} mealTicket={props.mealTicket} />
    )
  }else if(props.roomType === ROOM_TYPE_CODE.vip_room) {
    return props.guests.map((guest: any, guestIdx: number) => 
      <Type5 isUpdateMode={props.isUpdateMode} guest={guest} guestIdx={guestIdx} key={guestIdx} setGuests={props.setGuests} guests={props.guests} roomType={props.roomType} />
    )
  }else if(props.roomType === ROOM_TYPE_CODE.family_room) {   // 가족숙소
    return props.guests.map((guest: any, guestIdx: number) => 
      <Type6 isUpdateMode={props.isUpdateMode} guest={guest} guestIdx={guestIdx} key={guestIdx} setGuests={props.setGuests} guests={props.guests} />
    )
  }

  return props.guests.map((guest: any, guestIdx: number) => 
    <Type1 isUpdateMode={props.isUpdateMode} guest={guest} guestIdx={guestIdx} key={guestIdx} setGuests={props.setGuests} guests={props.guests} roomType={props.roomType} />
  )
}

export default GuestForm;