import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../../components/templetes/default';
import { useStores } from '../../../store/mobx';
import { ROOM_TYPE, WORK_SITE } from '../../../constants';
import DatePicker from '../../../components/organismas/input/datepicker';
import { formatByString, getTodayDate } from '../../../utils/date';

const Guest = observer((props: any) => {
  const guest = props.guest;
  return (
    <div className="table_normal_list">
      <table>
      <caption className="sr_only">이용자정보</caption>
      <colgroup>
          <col width="*"/>
          <col width="*"/>
          <col width="*"/>
          <col width="*"/>
          <col width="*"/>
          {/* <col width="*"/>
          <col width="*"/> */}
      </colgroup>
      <thead>
      <tr>
        <th><span className="emp_red">성명</span></th>
        <th><span className="emp_red">성별</span></th>
        <th><span className="emp_red">휴대폰번호</span></th>
        <th><span className="emp_red">신청자와의 관계</span></th>
        <th>차량번호</th>
        {/* <th><span className="emp_red">입숙예정일</span></th>
        <th><span className="emp_red">퇴숙예정일</span></th> */}
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <input type="text" className="form_control"
            value={guest.guest_name}
            onChange={(e: any) => guest.guest_name = e.target.value}
          />
        </td>
        <td>
          <select name="" id="" className="form_control"
            value={guest.guest_gender}
            onChange={(e: any) => guest.guest_gender = Number(e.target.value)}
            >
            <option value="1">남</option>
            <option value="2">여</option>
          </select>
        </td>
        <td>
          <input type="text" className="form_control"
             value={guest.guest_cellphone}
             onChange={(e: any) => guest.guest_cellphone = e.target.value}
          />
        </td>
        <td>
          <input type="text" className="form_control"
             value={guest.guest_relation}
             onChange={(e: any) => guest.guest_relation = e.target.value}
          />
        </td>
        <td>
          <input type="text" className="form_control"
             value={guest.guest_car_number1}
             onChange={(e: any) => guest.guest_car_number1 = e.target.value}
          />
        </td>
        {/* <td><input type="text" className="form_control datepicker"/></td>
        <td><input type="text" className="form_control datepicker"/></td> */}
      </tr>
      </tbody>
      </table>
    </div>
  );
});

const FamilyType = observer((props: any) => {
    const {lodgedHistoryStore: store} = useStores();
    const {headerStore: headerStore} = useStores();
    const subStore = store.insertStore;
    const item = subStore.item;



    const onSave = () => {
      // console.log(item);
    }
  return (
    <>
      <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
      >
        <h4>기본정보</h4>
        <ul className="lst_status">
          <li>
            <strong>신청일자</strong>
            <span>{formatByString(getTodayDate()[0], 'yyyy.MM.dd')}</span>
          </li>
          <li>
            <strong>숙소유형</strong>
            <span>{ROOM_TYPE[store.insertRoomType]}</span>
          </li>
          <li>
            <strong>신청지역</strong>
              <span>{WORK_SITE[store.user.work_site]}</span>
          </li>
          <li>
            <strong>배방정보</strong>
            <span className="room">
              <em>102동 506</em>
              <button type="button" className="btn btn_xs btn_darkblue">배방</button>
            </span>
          </li>
        </ul>

        <h4></h4>
        <div className="table_normal_list table_write">
          <table>
            <caption className="sr_only">기본정보</caption>
            <colgroup>
              <col width="130" />
              <col width="*" />
              <col width="130" />
              <col width="*" />
            </colgroup>
            <tbody>

            <tr>
                <th><span className="emp_red">입숙/퇴숙일</span></th>
                <td colSpan={3}>
                  <div className="datepicker_searchwrap form_inline">
                    <DatePicker
                      value={item.stay_after}
                      width={250}
                      onChange={(date: string) => item.stay_after = date}
                      isPrevMonthView={true}
                      max={item.stay_before}
                    />
                    <span className="dash">~</span>
                    <DatePicker
                      value={item.stay_before}
                      width={250}
                      onChange={(date: string) => item.stay_before = date}
                      isPrevMonthView={true}
                      min={item.stay_after}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h4>
          이용자정보
          <div className="right_area">
            <select name="" id="" className="form_control"
              value={subStore.numberPeople}
              onChange={(e: any) => subStore.newFamilyRoom(Number(e.target.value))}
            >
              <option value="1">인원수 1</option>
              <option value="2">인원수 2</option>
              <option value="3">인원수 3</option>
            </select>
          </div>
        </h4>
        {subStore.item.guests.length > 0
          ? subStore.item.guests.map((guest: any, index: number) =>
            <Guest key={index} guest={guest} store={subStore} />)
          : <></>}

        <div className="submit">
          <button type="button" className="btn btn_lg btn_gray fl" onClick={() => props.history.replace('/guest/history')}>목록</button>
          <button type="button" className="btn btn_lg btn_darkblue fr" onClick={onSave}>완료</button>
        </div>
      </DefaultTemplate>
    </>
  );
});

export default FamilyType;
