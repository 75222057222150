import React, { useContext, useEffect, useState } from 'react';
import DefaultTemplate from '../../components/templetes/default';
import MemberDetail from '../../components/organismas/popup/memberDetail';
import { STAFF_CODE, WORK_SITE } from '../../constants';
import { formatByString_YYYYMMDD } from '../../utils/date';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import './index.scss';
import User from '../../apis/user';
import ConfirmBtn from '../../components/atoms/button/confirm';
import { MessageContext, MessageImpl } from '../../routes';
import { useSelector } from 'react-redux';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';

const AccountDetailPage = observer((props: any) => {
  const {memberStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const { userInfo = {} } = useSelector((state: any) => ({
      userInfo: state.user
  }));
  // const [user, setUser] = useState(props.history.location.state && props.history.location.state);
  const { info, warning, error }: MessageImpl = useContext(MessageContext); //메세지 핸들러
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowUseModal, setIsShowUseModal] = useState<boolean>(false);
  const [isShowUseStopModal, setIsShowUseStopModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  if (!store.userData.id) {
    props.history.push('/manage/member');
    return <></>
  }
  // if (!props.history.location.state) {
  //   props.history.push('/manage/member');
  //   return <></>
  // }

  useEffect(() => {
    headerStore.getAuthMenuRole('4-1');
    store.userId = store.userData.id;
    (async () => {
      try {
        headerStore.isLoading = true;
        // console.log('넘어온 데이타 =========>',store.userData.id);
        await store.suspendedGetId();
        headerStore.isLoading = false;
        // console.log('회원 상태 히스토리 =====>',store.userList);
      }catch ({ status, data }) {
        headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
      }
    })();
  }, [reload]);

  const divPcMobile = (navigator: any) => {
    // 접속한 디바이스 환경
    if (navigator) {
      if (navigator.indexOf("iPhone") > 0 || navigator.indexOf("iPad") > 0
        || navigator.indexOf("iPot") > 0 || navigator.indexOf("Android") > 0) {
        return "모바일";
      } else {
        return "PC";
      }
    }
  }
  const init = () => {
    store.memo = '';
  }

  // 이용정지 등록
  const onAcceptHandler = async () => {
    try {
      headerStore.isLoading = true;
      const payload : any = {
        user: store.userId,
        user_status: "Y",
        memo: store.memo,
      }
      // console.log(payload);
      await User.suspendedId(store.userId, payload);
      info('이용정지 등록에 성공하였습니다.');
      setIsShowUseModal(false);
      init();
      setReload(!reload);
      headerStore.isLoading = false;
    } catch (err) {
      headerStore.isLoading = false;
      const { status, data } = err;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("이용정지 등록에 실패하였습니다.");
        init();
        setReload(true);
        return;
      }
    }
  };

  const onUnAcceptHandler = async () => {
    try {
      headerStore.isLoading = true;
      const payload : any = {
        user: store.userId,
        user_status: "N",
        memo: store.memo,
      }
      // console.log(payload);
      await User.suspendedId(store.userId, payload);
      info('이용정지 해제에 성공하였습니다.');
      setIsShowUseModal(false);
      init();
      setReload(!reload);
      headerStore.isLoading = false;
    } catch (err) {
      headerStore.isLoading = false;
      const { status, data } = err;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else {
        info("이용정지 해제에 실패하였습니다.");
        init();
        setReload(!reload);
        return;
      }

    }
  };

  const updateAdmin = async () => {
    try {
      headerStore.isLoading = true;
      const payload : any = {
        id: store.userData.id,
        is_staff: STAFF_CODE.ADMIN_CODE
      }

      await User.updateAdmin(payload);
      info('관리자로 등록되었습니다.');
      store.userData.is_staff = !store.userData.is_staff;
      setReload(!reload);
      headerStore.isLoading = false;
    } catch (error) {
      headerStore.isLoading = false;
      // console.log(error);
      const { status, data } = error;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

    }
  }

  const noAdmin = async () => {
    try {
      headerStore.isLoading = true;
      const payload : any = {
        id: store.userData.id,
        is_staff: STAFF_CODE.USER_CODE
      }

      await User.updateAdmin(payload);
      info('관리자가 해제되었습니다.');
      store.userData.is_staff = !store.userData.is_staff;
      setReload(!reload);
      headerStore.isLoading = false;
    } catch (error) {
      headerStore.isLoading = false;
      // console.log(error);
      const { status, data } = error;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

    }
  }

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <h4>인사DB정보</h4>
      <div className="table_normal_list table_write mt20">
        <table>
          <caption className="sr_only">인사 DB 정보</caption>
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tbody>
            <tr>
              <th>사번(아이디)</th>
              <td>{store.userData.employee_number}</td>
              <th>성명</th>
              <td>{store.userData.name}</td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>{store.userData.personnel ? (store.userData.personnel.birthdate ? store.userData.personnel.birthdate : '' ) : ('') }</td>
              <th>성별</th>
              <td>{store.userData.personnel ? (store.userData.personnel.gender ? (store.userData.personnel.gender === 1 ? "남" : "여") : '' ) : ('')}</td>
            </tr>
            <tr>
              <th>회사명</th>
              <td>현대자동차</td>
              <th>부서</th>
              <td>{store.userData.personnel ? (store.userData.personnel.department ? store.userData.personnel.department : '') : ('')}</td>
            </tr>
            <tr>
              <th>직위/직책</th>
              <td>{store.userData.personnel ? (store.userData.personnel.rank ? store.userData.personnel.rank : '') : ('')}/{store.userData.personnel ? (store.userData.personnel.position ? store.userData.personnel.position : '') : ('')}</td>
              <th>근무지명</th>
              <td>{WORK_SITE[store.userData.work_site]}</td>
              
            </tr>
            <tr>
              <th>휴대폰</th>
              <td>{store.userData.cellphone}</td>
              <th>이메일</th>
              <td>{store.userData.email}</td>
            </tr>
            <tr>
              <th>주민등록지 주소</th>
              <td>
                  {store.userData.personnel ? (store.userData.personnel.zipcode1 && store.userData.personnel.zipcode1 !== undefined ? `(${store.userData.personnel.zipcode1})` : '' ) : ('')}
                  {store.userData.personnel ? (store.userData.personnel.address1 && store.userData.personnel.address1 !== undefined ? `${store.userData.personnel.address1}` : '' ) : ('')}
              </td>
              {/*`(${store.userData.personnel?.zipcode1}) ${store.userData.personnel?.address1}`*/}
              <th>거주지 주소</th>
              <td>
                  {store.userData.personnel ? (store.userData.personnel.zipcode2 && store.userData.personnel.zipcode2 !== undefined ? `(${store.userData.personnel.zipcode2})` : '' ) : ('')}
                  {store.userData.personnel ? (store.userData.personnel.address2 && store.userData.personnel.address2 !== undefined ? `${store.userData.personnel.address2}` : '' ) : ('')}
              </td>
              {/*`(${store.userData.personnel?.zipcode2}) ${store.userData.personnel?.address2}`*/}
            </tr>
            <tr>
              <th>결혼유무</th>
              <td>{store.userData.personnel?.is_married ? "유" : "무"}</td>
              <th>직군</th>
              <td>{store.userData.personnel ? (store.userData.personnel.occupation ? store.userData.personnel.occupation : '') : ('')}</td>
            </tr>
            <tr>  
              <th>당사입사일</th>
              <td>{store.userData.personnel ? (store.userData.personnel.joined_at ? (store.userData.personnel.joined_at && formatByString_YYYYMMDD(store.userData.personnel.joined_at)) : '') : ('')}</td>
              <th>근속년</th>
              <td>{store.userData.personnel ? ( store.userData.personnel.working_period ? `${store.userData.personnel.working_period}년` : '') : ('')}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ul className="lst_warning gray mt30">
        <li>위 정보는 인사 DB에서 끌어오는 항목으로, 현대자동차의 인사정보에서 수정하시면 수정하실 수 있습니다.</li>
      </ul>

      {/* <h4 className="mt20">추가 정보</h4>
      <div className="table_normal_list table_write mt30">
        <table>
          <caption className="sr_only">입숙 신청 현황 상세</caption>
          <colgroup>
            <col width="130" />
            <col width="*" />
            <col width="130" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>비고</th>
              <td colSpan={3}>소리에 예민함</td>
            </tr>
            <tr>
              <th>이용자 상태</th>
              <td>{user.is_active ? "이용중" : "이용정지"}</td>
              <th>계정 등록일</th>
              <td>{formatByString_YYYYMMDD(user.date_joined)}</td>
            </tr>
            <tr>
              <th>최근 접속일</th>
              <td>{user.last_login_history?.created_at && formatByString_YYYYMMDD(user.last_login_history?.created_at)}</td>
              <th>최근 접속매체</th>
              <td>{divPcMobile(user.last_login_history?.user_agent)}</td>
            </tr>
            <tr>
              <th>접속 IP</th>
              <td colSpan={3}>{user.last_login_history?.ip_address}</td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div className="submit">
        <div className="fl">
            <button className="btn btn_lg btn_darkblue" onClick={() => props.history.replace('/manage/member')}>목록</button>
            {/* <button className="btn btn_lg btn_outline">인사 DB 더보기</button> */}
        </div>
        <div className="fr">
          {headerStore.mobileRoleFlag && userInfo.classification === "HEC" ?
                <>
                {store.userData.is_staff ? (
                  <ConfirmBtn
                      className="btn btn_lg btn_outline"
                      action={() => {noAdmin()}}
                      label="관리자 해제"
                      title=""
                      msg={"관리자를 헤제하시겠습니까?"}
                  />
                ) : (
                  <ConfirmBtn
                      className="btn btn_lg btn_darkblue"
                      action={() => {updateAdmin()}}
                      label="관리자 지정"
                      title=""
                      msg={"관리자로 지정하시겠습니까?"}
                  />
                )}
                </>
              :<></>
          }
          {headerStore.writeRoleFlag ?
              <>
                <button className="btn btn_lg btn_darkblue" onClick={() => setIsShowUseStopModal(true)}>이용정지</button>
                <button className="btn btn_lg btn_outline" onClick={() => setIsShowUseModal(true)}>이용정지 해제</button>
              </>
            :<></>
          }
        </div>
    </div>

    <h4>회원 상태 히스토리</h4>
      <div className="table_normal_list event_on box_scroll_x">
          <table>
              <caption className="sr_only">숙소이용안내 등록현황</caption>
              <colgroup>
                  <col width="15%"/>
                  <col width="25%"/>
                  <col width="*"/>
              </colgroup>
              <thead>
              <tr>
                  <th>날짜</th>
                  <th>상태</th>
                  <th>사유</th>
              </tr>
              </thead>
              <tbody>
              {/*데이타 없을 시*/}
              {store.userList.length === 0 ? (
                  <tr>
                    <td colSpan={3} className="blank_area type_white">
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
                ) : (  
                  store.userList.map((user: any, userIdx: number) => (
                    <tr key={userIdx}>
                      <td>{formatByString_YYYYMMDD(user.created_at)}</td>
                      <td>{user.user_status === 'Y' ? '이용 정지' : '이용 정지 해제'}</td>
                      <td className="al">{user.memo}</td>
                    </tr>
                  ))
                )}
              </tbody>
          </table>
      </div> 

      {/* <div className="submit ar">
        <div className="fl">
          <button type="button" className="btn_md btn_darkgray" onClick={() => props.history.replace('/manage/member')}>목록</button>
        </div>
        <div className="fr">
            <button type="button" className="btn_md btn_darkblue" onClick={() => setIsShowUseModal(true)}>{store.userData.is_active ? "이용정지" : "이용정지 해제"}</button>
        </div> 
      </div> */}

      <MemberDetail
        isShowModal={isShowModal}
        onCloseModalHandler={() => setIsShowModal(false)}
        user={store.userData}
      />
      {/* <MemberUseYn
        isShowUseModal={isShowUseModal}
        onCloseModalHandler={() => setIsShowUseModal(false)}
        user={store.userData}
      /> */}

      <div id="pop_stop_account" className="pop_dim_wrap pop_modal pop_alert" style={{ display: isShowUseStopModal? 'block' : 'none'}}>
          <div className="pop_wrap">
              <button className="pop_btn_close" onClick={() => {setIsShowUseStopModal(false); init()}}>닫기</button>
              <div className="message_table">
                  <div className="messageWrap">
                      해당 계정의 사용을<br/>
                      정지시키겠습니까?
                  </div>
              </div>
              <div className="form_write">
                  <ul className="lst_warning gray">
                      <li>정지사유 입력 :</li>
                  </ul>
                  <div className="mt10">
                      <input type="text" className="form_control form_sm" value={store.memo} onChange={(e: any)=> store.memo = e.target.value}/>
                  </div>
              </div>
              {/* 버튼 '예/아니오' 두개 일 경우 */}
              <div className="button_wrap">
                  <button type="button" className="btn_pop_default btn_white" onClick={() => {setIsShowUseStopModal(false); init()}}>아니오</button>
                  <button type="button" className="btn_pop_default btn_darkblue" onClick={() => {onAcceptHandler(); setIsShowUseStopModal(false)}}>예</button>
              </div>
              {/* 버튼 / 하나 일 경우 */}
              {/*<div className="button_wrap">
                  <button type="button" className="btn_pop_default btn_darkblue">예</button>
              </div>*/}
          </div>
      </div>

      <div id="pop_use_account" className="pop_dim_wrap pop_modal pop_alert" style={{ display: isShowUseModal? 'block' : 'none'}}>
          <div className="pop_wrap">
              <button className="pop_btn_close" onClick={() => {setIsShowUseModal(false); init()}}>닫기</button>
              <div className="message_table">
                  <div className="messageWrap">
                      해당 계정을 다시<br/>
                      사용 가능하도록 하시겠습니까?
                  </div>
              </div>
              <div className="form_write">
                  <ul className="lst_warning gray">
                      <li>해제사유 입력 :</li>
                  </ul>
                  <div className="mt10">
                      <input type="text" className="form_control form_sm" value={store.memo} onChange={(e: any)=> store.memo = e.target.value}/>
                  </div>
              </div>
              {/* 버튼 '예/아니오' 두개 일 경우 */}
              <div className="button_wrap">
                  <button type="button" className="btn_pop_default btn_white" onClick={() => {setIsShowUseModal(false); init()}}>아니오</button>
                  <button type="button" className="btn_pop_default btn_darkblue" onClick={() => {onUnAcceptHandler(); setIsShowUseModal(false)}}>예</button>
              </div>
              {/* 버튼 / 하나 일 경우 */}
              {/*<div className="button_wrap">
                  <button type="button" className="btn_pop_default btn_darkblue">예</button>
              </div>*/}
          </div>
      </div>
    </DefaultTemplate>
  );
});

export default AccountDetailPage;
