import React, { useEffect, useState } from 'react';
import ViewContent from './ViewContent';
import WriteContent from './WriteContent';
import { useStores } from '../../../../store/mobx';
import { observer } from 'mobx-react';

const FaqDetail = observer((props: any) => {
  const {faqStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  
  const [isEdit, setIsEdit] = useState<any>(props.isEdit);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isCreate = props.isEdit && !props.match.params.id;
  
  useEffect(() => {
    // console.log('testing...' ,headerStore.workSite);
    store.work_site = headerStore.workSite;
    if (!isCreate) {
      if (!store.isChange()) {
        (async () => {
          await store.getItem(props.match.params.id);
          setIsLoading(false);
        })();
      } else {
        setIsLoading(false);
      }
    } else {
      if (!store.isChange() || !store.item) {
        store.newItem();
      }
      setIsLoading(false);
    }
  }, [headerStore.workSite]);


  if (isLoading) return <></>;

  return (
    <>
      {isEdit ? (
        <WriteContent setIsEdit={setIsEdit} {...props} />
      ) : (
        <ViewContent setIsEdit={setIsEdit} {...props} />
      )}
    </>
  );
});

export default FaqDetail;
