// type AuthState = {};

const initialState: any = {
  accessToken: window.localStorage.getItem('accessToken'),
  isLogin: !!window.localStorage.getItem('accessToken'),
  userInfo : window.localStorage.getItem('accessToken') ,
};

export const LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST';
export const LOGIN_REQUEST_SSO = 'AUTH/LOGIN_REQUEST_SSO';
export const LOGIN_COMPLETE = 'AUTH/LOGIN_COMPLETE';
export const LOGIN_FAIL = 'AUTH/LOGIN_FAIL';

export const SNS_LOGIN_REQUEST = 'AUTH/SNS_LOGIN_REQUEST';
export const SNS_LOGIN_COMPLETE = 'AUTH/SNS_LOGIN_COMPLETE';
export const SNS_LOGIN_FAIL = 'AUTH/SNS_LOGIN_FAIL';

export const SNS_INTEGRATE_REQUEST = 'AUTH/SNS_INTEGRATE_REQUEST';
export const SNS_INTEGRATE_COMPLETE = 'AUTH/SNS_INTEGRATE_COMPLETE';
export const SNS_INTEGRATE_FAIL = 'AUTH/SNS_INTEGRATE_FAIL';

export const SIGNUP_REQUEST = 'AUTH/SIGNUP_REQUEST';
export const SIGNUP_COMPLETE = 'AUTH/SIGNUP_COMPLETE';
export const SIGNUP_FAIL = 'AUTH/SIGNUP_FAIL';

export const LOGOUT_REQUEST = 'AUTH/LOGOUT_REQUEST';
export const LOGOUT_COMPLETE = 'AUTH/LOGOUT_COMPLETE';
export const LOGOUT_FAIL = 'AUTH/LOGOUT_FAIL';

export const AUTH_CLEAR = 'AUTH/CLEAR';

export const TEST = 'AUTH/TEST';

export const onTest1 = (payload: any): any => (dispatch: any) => {
  dispatch({
    type: TEST,
  });
};

export const onTest2 = (payload: any): any => (dispatch: any) => {
  dispatch({
    type: TEST,
  });
};

type AuthAction = ReturnType<typeof onTest1> | ReturnType<typeof onTest2>;

function authReducer(state: any = initialState, action: AuthAction) {
  switch (action.type) {
    case LOGIN_COMPLETE:
      const { payload } = action;
      const { accessToken, refreshToken } = payload;
      window.localStorage.setItem('accessToken', accessToken);
      window.localStorage.setItem('userInfo', payload.userInfo);
      window.localStorage.setItem('work_site', payload.userInfo.work_site);
      window.localStorage.setItem('auth_worksite', payload.userInfo.auth_worksite);  //사업장권한
      window.localStorage.setItem('auth_building', payload.userInfo.auth_building);  //방권한
      window.localStorage.setItem('auth_menu', payload.userInfo.auth_menu);  //메뉴권한
      window.localStorage.setItem('auth_roomtype', payload.userInfo.auth_roomtype);  //숙소권한
      return { ...state, ...payload };
    case LOGIN_FAIL:
      return { ...state };
    case LOGOUT_COMPLETE:
      return { ...state };
    case LOGOUT_FAIL:
      return { ...state };

    case AUTH_CLEAR:
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('isRetryLogin');
      window.localStorage.removeItem('userInfo');
      window.localStorage.removeItem('auth_worksite');
      window.localStorage.removeItem('auth_building');
      window.localStorage.removeItem('auth_menu');
      window.localStorage.removeItem('auth_roomtype');

      return {
        ...state,
        accessToken: null,
        isLogin: false
      };
    default:
      return state;
  }
}

export default authReducer;
