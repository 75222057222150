import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/organismas/pagination';
import DefaultTemplate from '../../components/templetes/default';
import ReactApexChart from "react-apexcharts";
import { BOOKING_STATUS_CODE, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, WORK_SITE } from '../../constants';
import { useStores } from '../../store/mobx';
import { ADD_TAB, CLICK_TAB } from '../../store/modules/base';
import { formatByString, getMonth, getYear } from '../../utils/date';
import './index.scss';
import ReactDOM from 'react-dom';

//차트 - 기본
const ApexChart = (props : any) => {
    if(props.list.length <= 0) {
        const series: any = [{
            name: '이용중',
            data: [0],
            color: '#7cd4c3'
        }, {
            name: '이용가능',
            data: [0],
            color: '#6ba7e1'
        }, {
            name: '이용불가',
            data: [0],
            color: '#f89997'
        }]
        
        const options: any = {
            chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
            },
            responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
                }
            }
            }],
            plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0
            },
            },
            xaxis: {
            type: 'string',
            categories: ['데이터가 없습니다.']
            },
            legend: {
            position: 'right',
            offsetY: 40
            },
            fill: {
            opacity: 1,
            colors: ['#7cd4c3', '#6ba7e1', '#f89997']
            },
        }  
        return (
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" width={980} height={350} />
            </div>
        );
    } else {
        //숙소유형 
        let roomTypes: any = props.list.map((item:any) => {
            return ROOM_TYPE[item.room_type];
        });

        //이용중
        let checkinUsing: any = props.list.map((item:any) => {
            return item.checkin_using;
        });

        //이용가능
        let checkinRoomAvailable: any = props.list.map((item:any) => {
            return item.checkin_room_available;
        });

        //이용불가
        let checkinRooomNotAvailable: any = props.list.map((item:any) => {
            return item.checkin_room_unavailable; //item.checkin_room_total - item.checkin_using - 
        });
        
        const series: any = [{
            name: '이용중',
            data: checkinUsing,
            color: '#7cd4c3'
        }, {
            name: '이용가능',
            data: checkinRoomAvailable,
            color: '#6ba7e1'
        }, {
            name: '이용불가',
            data: checkinRooomNotAvailable,
            color: '#f89997'
        }]
        
        const options: any = {
            chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
            },
            responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
                }
            }
            }],
            plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0
            },
            },
            xaxis: {
            type: 'string',
            categories: roomTypes
            },
            legend: {
            position: 'right',
            offsetY: 40
            },
            fill: {
            opacity: 1,
            colors: ['#7cd4c3', '#6ba7e1', '#f89997']
            },
        }  
        return (
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" width={980} height={350} />
            </div>
        );
    }
};

const TimeApexChart = (props : any) => {
    if(props.list.length <= 0) {
        const series: any = [{
            name: '남양기숙사(입숙)',
            data : [0],
            color: '#ad2427'
        }, {
            name: '남양사택(입숙)',
            data : [0],
            color: '#683f22'
        }, {
            name: '마북기숙사(입숙)',
            data : [0],
            color: '#fddd00'
        }, {
            name: '아산기숙사(입숙)',
            data : [0],
            color: '#ff8200'
        }, {
            name: '아산사택(입숙)',
            data : [0],
            color: '#00a3e0'
        }, {
            name: '울산기숙사(입숙)',
            data : [0],
            color: '#e69cff'
        }, {
            name: '전주기숙사(입숙)',
            data : [0],
            color: '#519e30'
        }, {
            name: '전주사택(입숙)',
            data : [0],
            color: '#023ea7'
        }, {
            name: '남양기숙사(퇴숙)',
            data : [0],
            color: '#f67599'
        }, {
            name: '남양사택(퇴숙)',
            data : [0],
            color: '#9063cd'
        }, {
            name: '마북기숙사(퇴숙)',
            data : [0],
            color: '#5e2b3e'
        }, {
            name: '아산기숙사(퇴숙)',
            data : [0],
            color: '#d72024'
        }, {
            name: '아산사택(퇴숙)',
            data : [0],
            color: '#0f37b7'
        }, {
            name: '울산기숙사(퇴숙)',
            data : [0],
            color: '#0080b3'
        }, {
            name: '전주기숙사(퇴숙)',
            data : [0],
            color: '#aa6a79'
        }, {
            name: '전주사택(퇴숙)',
            data : [0],
            color: '#5d6c39'
        }]
        
        const options: any = {
            chart: {
                type: 'line',
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 
                curve: 'straight', 
                dashArray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            /*title: {
                text: '시계열',
                align: 'left'
            },*/
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                    }
                }
            }],
            legend: {
                tooltipHoverFormatter: function(val : any, opts : any) {
                  return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                tickAmount : 12,
                categories: ['데이터가 없습니다'],
            },
            tooltip: {
                y: [
                  /*{
                    title: {
                      formatter: function (val : any) {
                    return val;
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val : any) {
                    return val;
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val : any) {
                    return val;
                      }
                    }
                  }*/
                ]
            },
            grid: { borderColor: '#f1f1f1',}
        }  
        return (
            <div id="chart">
                <ReactApexChart options={options} series={series} type="line" width={980} height={350} />
            </div>
        );
    } else {
        
        //년월 checkDate
        let checkDate : any = props.list.map((item:any) => {
            return item.연월;
        });
        
        //남양기숙사(입숙) : namyangDormiCheckin
        let namyangDormiCheckin : any = props.list.map((item:any) => {
            return item.남양기숙사
        });

        //남양사택(입숙) : namyanCompanyCheckin
        let namyanCompanyCheckin : any = props.list.map((item:any) => {
            return item.남양사택
        });

        //마북기숙사(입숙) : mabukDormiCheckin
        let mabukDormiCheckin : any = props.list.map((item:any) => {
            return item.마북기숙사
        });

        //아산기숙사(입숙) : asanDormiCheckin
        let asanDormiCheckin : any = props.list.map((item:any) => {
            return item.아산기숙사
        });

        //아산사택(입숙) : asanCompanyCheckin
        let asanCompanyCheckin : any = props.list.map((item:any) => {
            return item.아산사택
        });

        //울산기숙사(입숙) ulsanDormiCheckin
        let ulsanDormiCheckin : any = props.list.map((item:any) => {
            return item.울산기숙사
        });

        //전주기숙사(입숙) : jeonjuDormiCheckin
        let jeonjuDormiCheckin : any = props.list.map((item:any) => {
            return item.전주기숙사
        });

        //전주사택(입숙) : jeonjuCompanyCheckin
        let jeonjuCompanyCheckin : any = props.list.map((item:any) => {
            return item.전주사택
        });

        //남양기숙사(퇴숙) : namyangDormiCheckout
        let namyangDormiCheckout : any = props.list.map((item:any) => {
            return item.남양기숙사퇴숙
        });

        //남양사택(퇴숙) : namyanCompanyCheckout
        let namyanCompanyCheckout : any = props.list.map((item:any) => {
            return item.남양사택퇴숙
        });

        //마북기숙사(퇴숙) : mabukDormiCheckout
        let mabukDormiCheckout : any = props.list.map((item:any) => {
            return item.마북기숙사퇴숙
        });

        //아산기숙사(퇴숙) : asanDormiCheckout
        let asanDormiCheckout : any = props.list.map((item:any) => {
            return item.아산기숙사퇴숙
        });

        //아산사택(퇴숙) : asanCompanyCheckout
        let asanCompanyCheckout : any = props.list.map((item:any) => {
            return item.아산사택퇴숙
        });

        //울산기숙사(퇴숙) : ulsanDormiCheckout
        let ulsanDormiCheckout : any = props.list.map((item:any) => {
            return item.울산기숙사퇴숙
        });

        //전주기숙사(퇴숙) : jeonjuDormiCheckout
        let jeonjuDormiCheckout : any = props.list.map((item:any) => {
            return item.전주기숙사퇴숙
        });

        //전주사택(퇴숙) : jeonjuCompanyCheckout
        let jeonjuCompanyCheckout : any = props.list.map((item:any) => {
            return item.전주사택퇴숙
        });
        const series: any = [{
            name: '남양기숙사(입숙)',
            data : namyangDormiCheckin,
            color: '#ad2427'
        }, {
            name: '남양사택(입숙)',
            data : namyanCompanyCheckin,
            color: '#683f22'
        }, {
            name: '마북기숙사(입숙)',
            data : mabukDormiCheckin,
            color: '#fddd00'
        }, {
            name: '아산기숙사(입숙)',
            data : asanDormiCheckin,
            color: '#ff8200'
        }, {
            name: '아산사택(입숙)',
            data : asanCompanyCheckin,
            color: '#00a3e0'
        }, {
            name: '울산기숙사(입숙)',
            data : ulsanDormiCheckin,
            color: '#e69cff'
        }, {
            name: '전주기숙사(입숙)',
            data : namyangDormiCheckin,
            color: '#519e30'
        }, {
            name: '전주사택(입숙)',
            data : jeonjuDormiCheckin,
            color: '#023ea7'
        }, {
            name: '남양기숙사(퇴숙)',
            data : jeonjuCompanyCheckin,
            color: '#f67599'
        }, {
            name: '남양사택(퇴숙)',
            data : namyangDormiCheckout,
            color: '#9063cd'
        }, {
            name: '마북기숙사(퇴숙)',
            data : namyanCompanyCheckout,
            color: '#5e2b3e'
        }, {
            name: '아산기숙사(퇴숙)',
            data : mabukDormiCheckout,
            color: '#d72024'
        }, {
            name: '아산사택(퇴숙)',
            data : asanDormiCheckout,
            color: '#0f37b7'
        }, {
            name: '울산기숙사(퇴숙)',
            data : ulsanDormiCheckout,
            color: '#0080b3'
        }, {
            name: '전주기숙사(퇴숙)',
            data : jeonjuDormiCheckout,
            color: '#aa6a79'
        }, {
            name: '전주사택(퇴숙)',
            data : jeonjuCompanyCheckout,
            color: '#5d6c39'
        }]
        
        const options: any = {
            chart: {
                type: 'line',
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], 
                curve: 'straight', 
                dashArray: [0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 3, 3, 3, 3]
            },
            /*title: {
                text: 'Page Statistics',
                align: 'left'
            },*/
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                    }
                }
            }],
            legend: {
                tooltipHoverFormatter: function(val : any, opts : any) {
                  return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                tickAmount : 12,
                categories: checkDate,
            },
            tooltip: {
                y: [
                  /*{
                    title: {
                      formatter: function (val : any) {
                    return val+'?';
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val : any) {
                    return val+'!';
                      }
                    }
                  },
                  {
                    title: {
                      formatter: function (val : any) {
                    return val+'@';
                      }
                    }
                  }*/
                ]
            },
            grid: { borderColor: '#f1f1f1',}
        }  
        return (
            <div id="chart">
                <ReactApexChart options={options} series={series} type="line" width={980} height={350} />
            </div>
        );
    }
};

    
const DEFAULT_TAB_PATH = '/home';

const whatap_monitoring = (w: any, h: any, _a: any, t: any, a: any, b: any) => {
    w = w[a] = w[a] || {
      config: {
        projectAccessKey: "x238gublme6ts-z77jfivl2k686s-z7ka5nm9khq151",
        pcode: 13,
        sampleRate: 100,
        proxyBaseUrl: "http://10.176.228.100:2002/13/",
      },
    };
    a = h.createElement(_a);
      a.async = 1;
      a.src = t;
      t = h.getElementsByTagName(_a)[0];
      t.parentNode.insertBefore(a, t);
}

//관리자 home 화면 
const HomePage = observer((props: any) => {
    const {headerStore: headerStore} = useStores(); //권한
    const {mainStore: mainStore} = useStores(); //메인
    const {vocStore: vocStore} = useStores();  //유지운영
    const {lodgedHistoryStore: lodgedHistoryStore} = useStores(); //입숙자현황
    const {applicantJudgeStore: applicantJudgeStore} = useStores();   //입숙신쳥현황
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [barChartFalg, setBarChartFalg] = useState<boolean>(true);  //통계 bar 차트 default 

    useEffect(() => {
        (async () => {
            MainRolad();
        })();
        return () => {
            if (mainStore.findTabs('/home') < 0) {
                mainStore.destory();
            }
        }
    }, [headerStore.workSite]);

    //입숙자 신청 현황 신청구분 상태값 설정
    const cellStatusRenderer = (data: any) => {
        if(data.status === BOOKING_STATUS_CODE.applied){    // 신청자 신청완료
            return(
                <>
                    <p><span className="state_type type4">신청완료</span></p>
                </>  
            );
        }else if(data.status === BOOKING_STATUS_CODE.rejected){     // 반려 재요청
            return(
                <>
                    <p><span className="state_type type1">반려(재요청)</span></p>
                </>
            );
        }else if(data.status === BOOKING_STATUS_CODE.terminated) {      // 반려 거절
            return(
                <>
                    <p><span className="state_type type1">승인거절</span></p>
                </>
            );
        }else if(data.status === BOOKING_STATUS_CODE.canceled){        // 신청자 신청 취소
            return <p><span className="state_type type1">예약취소</span></p>;
        }else if(data.status === BOOKING_STATUS_CODE.approved && data.guests[0].guest_status === LODGE_STATUS_CODE.waiting){      // 모든 승인 처리 end 입숙대기 상태
            return(
                <>
                    <p><span className="state_type type3">예약완료</span></p>
                </>   
            );
        }else if(data.status === BOOKING_STATUS_CODE.approved && data.room_type === ROOM_TYPE_CODE.business_room_group){    // 관리자 
            return <p><span className="state_type type4">서류승인완료</span></p>;
        }else if(data.status === BOOKING_STATUS_CODE.stayHold) {           // 입숙보류 상태
            return (
                <>
                    <p><span className="state_type type1">입숙보류</span></p>
                </>
            );
        }else if(data.status === BOOKING_STATUS_CODE.approved && data.guests[0].guest_status === LODGE_STATUS_CODE.checkin){      // 입숙
            return <p><span className="state_type type3">입숙</span></p>;
        }else if(data.status === BOOKING_STATUS_CODE.roomOpen && data.room_type === ROOM_TYPE_CODE.business_room_group) {       //단체, 사용자가 배방 진행 완료
            return (
                <>
                    <p><span className="state_type type4">배방완료</span></p>
                </>
            );
        }else if(data.room_type === ROOM_TYPE_CODE.company_room && data.status === BOOKING_STATUS_CODE.documentApproved) {        // 사택 입숙예정일 승인, 증빙서류 요청
            return <p><span className="state_type type4">승인완료</span></p>;   
        }else if(data.status === BOOKING_STATUS_CODE.roomOpen || data.status === BOOKING_STATUS_CODE.documentApproved) {        // 배방 오픈 or 서류 승인완료
            return <p><span className="state_type type4">서류승인완료</span></p>;   
        }else if(data.status === BOOKING_STATUS_CODE.stayAfter && data.room_type === ROOM_TYPE_CODE.dormitory_room) {   // 신청자가 입숙예정일 입력 (기숙사)
            return(
                <> 
                    <p><span className="state_type type4">입숙예정일 : {data.stay_after}</span></p>
                </>
            );
        }else if(data.status === BOOKING_STATUS_CODE.stayAfter && data.room_type === ROOM_TYPE_CODE.company_room) {   // 신청자가 입숙예정일 입력 (사택)
            return(
                <> 
                    <p><span className="state_type type4">입숙예정일 : {data.stay_after}</span></p>
                </>
            );
        }else if(data.status === BOOKING_STATUS_CODE.stayAfterTerminated) {     // 관리자 - 입숙예정일 변경 요청
            return <p><span className="state_type type1">입숙예정일 변경 요청</span></p>;
        }else if(data.status === BOOKING_STATUS_CODE.documentReject) {     // 관리자 - 입숙예정일 변경 요청
            return <p><span className="state_type type1">증빙서류재요청</span></p>;
        }else if(data.status === BOOKING_STATUS_CODE.documentSubmit) {     // 관리자 - 증빙서류 제출상태
            return (
                <>
                    <p><span className="state_type type4">증빙서류등록완료</span></p>
                </>
            )
        }else{
            return <></>;
        }
        
    }
    
    const compMenu = (val: string) => {
        const vals = val.split('/');
        if (vals.length > 2) {
          return vals.slice(0,3).join('/');
        } else {
          return val;
        }
      }

    const m:{[key: string]: number} = {
        '/manage/room': 0,
        '/locus/guest/type': 0,
        '/locus/document': 0,
        '/manage/use/guide': 0,
        '/manage/pledge': 0,
        '/lodged/applicant': 0,
        '/applicant/judge': 0,

        '/guest/history':1,
        '/guest/board' :1,
        '/guest/status' : 1,
        '/lodged/guest': 1,
        '/lodged/checkout': 1,
        '/guest/audit': 1,
        '/guest/deductible': 1,

        '/lodged/basicinfo': 2,
        '/lodged/maintenance': 2,

        '/manage/member': 3,
        '/manage/notice': 3,
        '/manage/faq': 3,

        '/account/permission':5,
        '/account/application':5,

        '/admin/regist_apply': 6,
        '/admin/manage_auth': 6,
    }

    const dispatch = useDispatch();
    const [stateLnbSelect, setStateLnbSelect] = useState<number>();
    const { opened = [], select = -1 } = useSelector((state: any) => ({
      opened: state.base.tab.opened,
      select: state.base.tab.select,
    }));
    const currentSelectedMenu = () => {
        setStateLnbSelect(m[props.history.location.pathname])
    }

    const addTabHandler = (path = DEFAULT_TAB_PATH) => {
        const idx = opened.findIndex((item: string) => compMenu(item) === compMenu(path));
        if (idx < 0) {
          dispatch({
            type: ADD_TAB,
            path,
          });
          // props.history.replace(path);
        } else {
          dispatch({
            type: CLICK_TAB,
            select: idx,
          });
        }
      };

    const MainRolad = async () => {
        //whatap_monitoring(window, document, 'script', 'https://repo.whatap-browser-agent.io/rum/prod/v1/whatap-browser-agent.js', 'WhatapBrowserAgent', '');

        headerStore.isLoading = true;
        headerStore.getAuthWorkSiteRole();  //사업장권한
        headerStore.getAuthRoomRole();      //숙소권한
        headerStore.getAuthBuilding();      //건물 권한

        //이번달
        let year = getYear();
        let month = getMonth();
        const todayDate: Date = new Date();
        let startDay: number = new Date(todayDate.getFullYear(), parseInt(month) , 1).getDate();   //이번달 시작일
        let lastDay: number = new Date(todayDate.getFullYear(), parseInt(month) , 0).getDate();     //이번달 종료일
        
        let stratDate = year+"-"+month+"-"+startDay;
        let lastDate = year+"-"+month+"-"+lastDay;

        mainStore.worksite = headerStore.workSite;
        if(Number(headerStore.workSite) === 2) {
            if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                mainStore.authBuilding = headerStore.paramAuthBuildingRole; 
            }
        }
        setTimeout(async function() {
            await mainStore.getItems();
        }, 250);
        const menuSettingSuccRender = function() {
            headerStore.isLoading = false;
            return <button className="btn btn_xs btn_outline" onClick={() => addTabHandler('/guest/status/setting')}>전체보기</button>
        }
        const menuSettingFailRender = function() {
            headerStore.isLoading = false;
            return <></>
        }
        //권한에 따른 버튼 처리
        headerStore.getAuthMenuRole('2-3');
        if(headerStore.menuRoleFlag) {
            ReactDOM.render(menuSettingSuccRender(), document.getElementById("setting"));
        } else {
            ReactDOM.render(menuSettingFailRender(), document.getElementById("setting"));
        }
        
        //유지운영 가져오기
        headerStore.getAuthMenuRole('3-2');
        vocStore.work_site = headerStore.workSite; //사업장 셋팅
        //건물권한이 존재할경우 건물 내려보냄
        if(Number(headerStore.workSite) === 2) {
            if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0 && headerStore.paramAuthBuildingRole !== undefined) {
                vocStore.authBuilding = headerStore.paramAuthBuildingRole; 
            }
        }
        if(headerStore.mobileRoleFlag === true){
            vocStore.auth_Menu = 'Y';
        }else{
            vocStore.auth_Menu = 'N';
        }
        vocStore.employee_number = userInfo.employeeNumber;
        vocStore.roomtype = headerStore.paramAuthRoomTypeRole;
        vocStore.limit = 5;
        setTimeout(async function() {
            await vocStore.getItems();
        }, 250);

        const menuMaintenanceSuccRender = function() {
            headerStore.isLoading = false;
            return <button  className="btn btn_xs btn_outline" onClick={() => addTabHandler('/lodged/maintenance')}>전체보기</button>
        }
        const menuMaintenanceFailRender = function() {
            headerStore.isLoading = false;
            return <></>
        }

        //권한에 따른 버튼 처리
        if(headerStore.menuRoleFlag) {
            ReactDOM.render(menuMaintenanceSuccRender(), document.getElementById("maintenance"));
        } else {
            ReactDOM.render(menuMaintenanceFailRender(), document.getElementById("maintenance"));
        }
        
        //입숙자 현황 가져오기
        lodgedHistoryStore.worksite = headerStore.workSite;
        lodgedHistoryStore.roomType = headerStore.paramAuthRoomTypeRole;
        lodgedHistoryStore.authBuilding = headerStore.paramAuthBuildingRole;
        lodgedHistoryStore.checkin_after = stratDate;
        lodgedHistoryStore.checkin_before = lastDate;
        if(Number(headerStore.workSite) === 2) {
            if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0 && headerStore.paramAuthBuildingRole !== undefined) {
                lodgedHistoryStore.authBuilding = headerStore.paramAuthBuildingRole;
            }
        }
        setTimeout(async function() {
            await lodgedHistoryStore.guestStatTotal(); //입숙자 현황(통계)
            await lodgedHistoryStore.getItems();
        }, 250);
       

        const menuHistorySuccRender = function() {
            headerStore.isLoading = false;
            return <button className="btn btn_xs btn_outline" onClick={() => addTabHandler('/guest/history')}>전체보기</button>
        }
        const menuHistoryFailRender = function() {
            headerStore.isLoading = false;
            return <></>
        }
        //권한에 따른 버튼 처리
        headerStore.getAuthMenuRole('2-1');
        if(headerStore.menuRoleFlag) {
            ReactDOM.render(menuHistorySuccRender(), document.getElementById("history"));
        } else {
            ReactDOM.render(menuHistoryFailRender(), document.getElementById("history"));
        }
        
        //입숙 신청 현황 가져오기
        applicantJudgeStore.paramAuthRoomTypeRole = headerStore.paramAuthRoomTypeRole;
        applicantJudgeStore.paramAuthWorkSiteRole = headerStore.paramAuthWorkSiteRole;
        applicantJudgeStore.workSite = headerStore.workSite;
        if(Number(headerStore.workSite) === 2) {
            if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0 && headerStore.paramAuthBuildingRole !== undefined) {
                applicantJudgeStore.paramAuthBuildingRole = headerStore.paramAuthBuildingRole;
            }
        }
        setTimeout(async function() {
            await applicantJudgeStore.getMainItems();
        }, 250);
        

        const menuJudgeSuccRender = function() {
            headerStore.isLoading = false;
            return <button className="btn btn_xs btn_outline" onClick={() => addTabHandler('/applicant/judge')}>전체보기</button>
        }
        const menuJudgeFailRender = function() {
            headerStore.isLoading = false;
            return <></>
        }
        //권한에 따른 버튼 처리
        headerStore.getAuthMenuRole('1-2');
        if(headerStore.menuRoleFlag) {
            ReactDOM.render(menuJudgeSuccRender(),document.getElementById("judge"));
        } else {
            ReactDOM.render(menuJudgeFailRender(), document.getElementById("judge"));
        }
        headerStore.isLoading = false;

        //시계열 통계 가져오기
        setTimeout(async function() {
            await mainStore.getItemsTime();
        }, 250);
        /*if(userInfo.classification === 'HEC') {
            
        }*/
    }

    //차트바꾸기
    const onChangeChart = async () => {
        if(barChartFalg) {
            setBarChartFalg(false);
        } else {
            setBarChartFalg(true);
        }
    }

  return (
    <DefaultTemplate
      id="wrap"
      className="wrap_main"
      isMainClass=""
      isLoading={headerStore.isLoading}
      {...props}
    >
      <div className="row">
      <div className="col col1">
        <dl className="box_outer">
          <dt>
              <h4 className="m0">입숙, 이숙, 퇴숙 현황 (전체)</h4>
              <div className="right_area" id="setting"></div>
          </dt>
          <dd>
              <div className="group_counts">
                  <div className="title_sm">
                      <strong>월별현황</strong>
                      <span className="date">{getYear()}년 {getMonth()}월</span>
                  </div>
                  <ul className="lst_counts">
                      <li>
                          <strong className="color_green">{mainStore.checkinStatData.checkin_use}</strong>
                          <span>입숙</span>
                      </li>
                      <li>
                          <strong className="color_blue">{mainStore.checkinStatData.move_use}</strong>
                          <span>이숙</span>
                      </li>
                      <li>
                          <strong>{mainStore.checkinStatData.checkout_use}</strong>
                          <span>퇴숙</span>
                      </li>
                  </ul>
              </div>
              <div className="group_counts">
                  <div className="title_sm">
                      <strong>신청 현황</strong>
                  </div>
                  <ul className="lst_counts">
                      <li>
                          <strong className="color_green">{mainStore.checkinStatData.booking_apply}</strong>
                          <span>입숙신청</span>
                      </li>
                      <li>
                          <strong className="color_blue">{mainStore.checkinStatData.move_apply}</strong>
                          <span>이숙신청</span>
                      </li>
                      <li>
                          <strong>{mainStore.checkinStatData.checkout_apply}</strong>
                          <span>퇴숙신청</span>
                      </li>
                  </ul>
              </div>
          </dd>
      </dl>   {/*box_outer*/}

      <dl className="box_outer mt15">
          <dt>
              <h4 className="m0">유지운영</h4>
              <div className="right_area" id="maintenance"></div>
          </dt>
          <dd>
            {!vocStore.items.length ? (
                    <ul className="lst_notice blank_area">
                        <li className="txt_blank">데이터가 없습니다.</li>
                    </ul>) 
                : 
                (vocStore.items.map((item: any, index: number) => {
                        return(  
                            <ul className="lst_manage" key={index}>
                                <li>
                                    <strong>{WORK_SITE[item.work_site]}</strong>
                                    <span>{item.is_maintenance ? '유지보수' : 'Voc'}</span>
                                </li>
                                <li>
                                    <strong>[{item.category1}]</strong>
                                    <span className="txt_ellipsis">{item.title}</span>
                                </li>
                                <li>
                                    <strong className="color_red">{item.is_complete ? '완료' : '처리중'}</strong>
                                    <span>{formatByString(item.created_at, 'yyyy.MM.dd')}</span>
                                </li>
                            </ul>
                        )
                }))
            }
        
          </dd>
        </dl>   {/*box_outer*/}
      </div>  {/*col*/}

      <div className="col col2">
        <dl className="box_outer">
            <dt>
                <h4 className="m0">입숙자현황<span className="date">{getYear()}년 {getMonth()}월</span></h4>
                <div className="right_area" id="history"></div>
            </dt>
            <dd>
                <div className="table_normal_list">
                    <table>
                        <caption className="sr_only">입숙자 통계</caption>
                        <colgroup>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                            <col width="*"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th colSpan={5}>숙소현황</th>
                            <th colSpan={7}>이용자현황</th>
                        </tr>
                        <tr>
                            <th><p><span>숙소유형</span></p></th>
                            <th><p><span>총 호실</span></p></th>
                            <th><p><span>이용중</span></p></th>
                            <th><p><span>이용가능</span></p></th>
                            <th><p><span>이용불가</span></p></th>
                            <th><p><span>수용인원</span></p></th>
                            <th><p><span>이용인원</span></p></th>
                            <th><p><span>사내임직원<br/>A조</span></p></th>
                            <th><p><span>사내임직원<br/>B조</span></p></th>
                            <th><p><span>사내임직원<br/>상시주간</span></p></th>
                            <th><p><span>계열사<br/>임직원</span></p></th>
                            <th><p><span>외부인</span></p></th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(lodgedHistoryStore.list).length === 0? (
                            <tr>
                                <td colSpan={12} className="blank_area type_white">
                                    <p className="txt_blank">데이터가 없습니다.</p>
                                    </td>
                                </tr>
                        ) : (
                        lodgedHistoryStore.list.map((item : any, idx : number) => (
                            item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar?
                            (<tr key={idx}>
                                <td>{ROOM_TYPE[item.room_type]}</td>
                                <td>{item.checkin_room_total}</td>
                                <td><span className="point green">{item.checkin_using}</span></td>
                                <td><span className="point blue">{item.checkin_room_available}</span></td>
                                <td><span className="point red">{item.checkin_room_unavailable}</span></td>
                                <td>{item.checkin_room_capacity}</td>
                                <td>{item.checkin_room_guest}</td>
                                <td>{item.checkin_corporation_a}</td>
                                <td>{item.checkin_corporation_b}</td>
                                <td>{item.checkin_corporation_c}</td>
                                <td>{item.checkin_subsidiary}</td>
                                <td>{item.checkin_public}</td>
                            </tr>) : ''
                            ))
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                        <th>비율</th>
                            <td><span>100%</span></td>
                            <td><span className="point green">{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal1']).toFixed(1) : '0'}%</span></td>
                            <td><span className="point blue">{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal2']).toFixed(1): '0'}%</span></td>
                            <td><span className="point red">{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['calX']).toFixed(1) : '0'}%</span></td>
                            <td><span>100%</span></td>
                            <td><span>{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal4']).toFixed(1) : '0'}%</span></td>
                            <td><span>{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal5']).toFixed(1) : '0'}%</span></td>
                            <td><span>{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal6']).toFixed(1) : '0'}%</span></td>
                            <td><span>{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal7']).toFixed(1) : '0'}%</span></td>
                            <td><span>{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal8']).toFixed(1) : '0'}%</span></td>
                            <td><span>{lodgedHistoryStore.list.length > 0? parseFloat(lodgedHistoryStore.calcular['cal9']).toFixed(1) : '0'}%</span></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>  {/*table*/}
            </dd>
            <dt className="mt30">
                <h4 className="m0">통계</h4>
                <div className="right_area">
                    {userInfo.classification === 'HEC' ? 
                        <button className="btn btn_xs btn_outline" onClick={() => onChangeChart()}>차트변경</button>             
                        :
                        <></>
                    }
                    {/*<button className="btn btn_xs btn_outline" onClick={() => onChangeChart()}>차트변경</button>*/}
                </div>
            </dt>
            <dd className="group_barchart">
                <div className="chart_area">
                    {barChartFalg ?
                        <ApexChart  //bar차트(기본)
                            list = {lodgedHistoryStore.list}
                        />
                        :
                        <TimeApexChart  //line차트 (시계열)
                            list = {mainStore.checkinOutStatData}
                        />
                    }
                </div>
            </dd>
        </dl>   {/*box_outer*/}
    </div>  {/*col*/}

    <div className="col col3">
      <dl className="box_outer">
          <dt>
              <h4 className="m0">입숙신청현황</h4>
              <div className="right_area" id="judge"></div>
          </dt>
          <dd></dd>
          <dd>
              <div className="table_normal_list">
                  <table>
                      <caption className="sr_only">숙소이용안내 등록현황</caption>
                      <colgroup>
                          <col width="5%"/>
                          <col width="15%"/>
                          <col width="12%"/>
                          <col width="28%"/>
                          <col width="*"/>
                          <col width="10%"/>
                          <col width="10%"/>
                          <col width="*"/>
                          <col width="*"/>
                      </colgroup>
                      <thead>
                      <tr>
                          <th>No</th>
                          <th>사업장</th>
                          <th>숙소유형</th>
                          <th>신청구분</th>
                          <th>사번</th>
                          <th>이름</th>
                          <th>직급</th>
                          <th>신청일</th>
                          <th>작성일</th>
                      </tr>
                      </thead>
                      <tbody>
                      {/*데이타 없을 시*/}
                      {applicantJudgeStore.mainbookings === 0 ? (
                            <tr>
                                <td colSpan={12} className="blank_area type_white">
                                    <p className="txt_blank">데이터가 없습니다.</p>
                                </td>
                            </tr>
                        ) : (
                            applicantJudgeStore.mainbookings.map((item : any, idx : number) => (
                            item.room_type !== ROOM_TYPE_CODE.nothing && item.room_type !== ROOM_TYPE_CODE.gitar?
                            (<tr key={idx}>
                                <td><p><span>{idx+1}</span></p></td>
                                <td><p><span>{WORK_SITE[item.room_work_site]}</span></p></td>
                                <td><p><span>{ROOM_TYPE[item.room_type]}</span></p></td>
                                <td>{cellStatusRenderer(item)}</td>
                                <td><p><span>{item.guests ? item.guests[0].guest_employee_number : ''}</span></p></td>
                                <td><p><span>{item.guests ? item.guests[0].guest_name : ''}</span></p></td>
                                <td><p><span>{item.guests ? item.guests[0].guest_position : ''}</span></p></td>
                                <td><p><span>{formatByString(item.created_at, 'yy.MM.dd')}</span></p></td>
                                <td><p><span>{formatByString(item.modified_at, 'yy.MM.dd')}</span></p></td>
                            </tr>) : ''
                            ))
                            )}   
                      </tbody>
                  </table>
              </div>  {/*table*/}

              <div className="board_paging">
                  <ul>
                    <Pagination
                            offset={applicantJudgeStore.offset}
                            limit={applicantJudgeStore.limit}
                            setOffset={(offset: number) => {applicantJudgeStore.offset=offset; applicantJudgeStore.getMainItems();}}
                            totalPage={applicantJudgeStore.totalPage}
                    />
                  </ul>
              </div>   {/*board_paging*/}
          </dd>
      </dl>   {/*box_outer*/}
  </div>  {/*col*/}
  </div>
    </DefaultTemplate>
  )
});

export default React.memo(HomePage);
