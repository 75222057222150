import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../components/templetes/default';
import MemberUseYn from "../../components/organismas/popup/memberUseYn";
import Pagination from '../../components/organismas/pagination';
import LimitSelect from '../../components/atoms/select/limit';
import Select from '../../components/atoms/select';
import { formatByString_YYYYMMDD } from '../../utils/date';
import { maskingName, maskingPhone } from '../../utils/masking';
import { getViewPageIdx } from '../../utils/common';
import { WORK_SITE } from '../../constants';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import './index.scss';
import ReactDOM from 'react-dom';
import CustomCalendar from '../../components/organismas/customCalendar';

const AccountListPage = observer((props: any) => {
  const {memberStore: store} = useStores();
  const {headerStore: headerStore} = useStores();
  const [isShowUseModal, setIsShowUseModal] = useState<boolean>(false);
  const [userOne, setUserOne] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: string }>({ key: "", direction: "" });
  const [reload, setReload]= useState<boolean>(false);

  useEffect(() => {
    (async () => {
      defaultSelWorkSite();
      let options: any[] = [];
      for ( let i = 0; i < headerStore.paramAuthWorkSiteRole?.split(",").length; i++) {
        options.push(<option value={headerStore.paramAuthWorkSiteRole.split(",")[i]}>{WORK_SITE[headerStore.paramAuthWorkSiteRole.split(",")[i]]}</option>)
      }
      ReactDOM.render(options, document.getElementById("selectWorkSite"));

    })();
    return () => {
        if (store.findTabs('/manage/member') < 0) {
          store.destory();
        }
    }
  }, [reload, headerStore.workSite]);

  const defaultSelWorkSite = async() => {
    headerStore.isLoading = true;
    store.selectWorkSite = ''+headerStore.workSite;
    await store.getWorkSite();
    store.selectOption = "employee_number";
    store.selectText="";
    store.offset=0;
    store.limit = 10;
    await store.getItems();
    setReload(true);
    headerStore.isLoading = false;
  } 

  const onSearchHandler = async () => {
    headerStore.isLoading = true;
    store.searchFlag = true;
    store.selectWorkSite = ''+headerStore.workSite;
    await store.getItems();
    headerStore.isLoading = false;
  }

  const divPcMobile = (navigator: any) => {
    // 접속한 디바이스 환경
    if (navigator) {
      if (navigator.indexOf("iPhone") > 0 || navigator.indexOf("iPad") > 0
        || navigator.indexOf("iPot") > 0 || navigator.indexOf("Android") > 0) {
        return "모바일";
      } else {
        return "PC";
      }
    }
  }

  // 소팅
  const requestSort = (key: string) => {
    headerStore.isLoading = true;
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
    }

    setSortConfig({ key, direction });
    if (direction === 'asc') {
      store.ordering = '-' + key;
    } else {
        store.ordering = key;
    }

    store.getItems();
    headerStore.isLoading = false;
  }

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  return (
    <>
      <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
      >
          <h4>회원현황 검색</h4>
          <div className="table_modify_list">
            <dl>
                <dt>이용자 상태</dt>
                <dd>
                  <select className="form_control">
                    <option>전체</option>
                  </select>
                </dd>
            </dl>
            <dl>
              <dt>근무지명</dt>
              <dd>
                <select name="" value={headerStore.workSite} id="selectWorkSite" className="form_control" onChange={(e: any) => {store.selectWorkSite = e.target.value; headerStore.workSite = e.target.value}}>
                </select>
              </dd>
            </dl>
            <dl className="dateonly">
              <dt>계정등록일</dt>
              <dd>
                <CustomCalendar
                  max={store.dateBefore}
                  value={store.dateAfter}
                  onChange={(date:string) => store.dateAfter = date}
                ></CustomCalendar> 
                <CustomCalendar
                  min={store.dateAfter}
                  value={store.dateBefore}
                  onChange={(date:string) => store.dateBefore = date}
                ></CustomCalendar> 
              </dd>
            </dl>
            <dl className="search">
              <dt>검색어</dt>
              <dd>
                <Select
                  className="form_control"
                  options={[
                    { value: "employee_number", label: "사번" },
                    { value: "name", label: "이름" },
                    { value: "department", label: "부서" }
                  ]}
                  onChange={(e: any) => store.selectOption = e.target.value}
                />
                <input type="text" className="form_control" name="selectText"
                  onChange={(e: any) => store.selectText = e.target.value}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      onSearchHandler();
                    }
                  }} />
                <button className="btn btn_sm btn_darkblue" onClick={onSearchHandler}>검색</button>
              </dd>
            </dl>
          </div>

          <h4>회원현황 결과
            <div className="right_area">
              <div className="right_area">
                <LimitSelect 
                  className="form_control pc" 
                  limit={store.limit}  
                  onChange={(limit: number) => { store.setLimit(limit); store.setOffset(0); }} 
                />
              </div>
            </div>
          </h4>

          <div className="table_normal_list event_on box_scroll_x" style={{ overflowX: "auto" }}>
            <table>
              <caption className="sr_only">회원 현황 목록</caption>
              <colgroup>
                <col width="60px" />
                <col width="120px" />
                <col width="100px" />
                <col width="80px" />
                <col width="70px" />
                <col width="120px" />
                <col width="100px" />
                <col width="100px" />
                <col width="100px" />
                <col width="120px" />
                <col width="120px" />
                <col width="200px" />
                <col width="400px" />
                <col width="90px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>사번(아이디)</th>
                  <th>성명</th>
                  <th>생년월일</th>
                  <th><button type="button" onClick={() => requestSort('personnel__gender')} className={`table_sortBtn ${getClassNamesFor('personnel__gender')}`}>성별</button></th>
                  <th>회사명</th>
                  <th><button type="button" onClick={() => requestSort('personnel__department')} className={`table_sortBtn ${getClassNamesFor('personnel__department')}`}>소속부서</button></th>
                  <th><button type="button" onClick={() => requestSort('personnel__rank')} className={`table_sortBtn ${getClassNamesFor('personnel__rank')}`}>직위</button></th>
                  <th><button type="button" onClick={() => requestSort('personnel__occupation')} className={`table_sortBtn ${getClassNamesFor('personnel__occupation')}`}>직종</button></th>
                  <th><button type="button" onClick={() => requestSort('work_site')} className={`table_sortBtn ${getClassNamesFor('work_site')}`}>근무지명</button></th>
                  <th>휴대폰번호</th>
                  <th>이메일</th>
                  <th>주소</th>
                  <th><button type="button" onClick={() => requestSort('personnel__is_married')} className={`table_sortBtn ${getClassNamesFor('personnel__is_married')}`}>결혼유무</button></th>
                  <th><button type="button" onClick={() => requestSort('is_active')} className={`table_sortBtn ${getClassNamesFor('is_active')}`}>이용자상태</button></th>
                  <th><button type="button" onClick={() => requestSort('date_joined')} className={`table_sortBtn ${getClassNamesFor('date_joined')}`}>계정등록일</button></th>
                  <th>최근접속일</th>
                  <th>최근접속매체</th>
                  <th>접속IP</th>
                </tr>
              </thead>

              <tbody>
                {store.users.length <= 0 ? (
                  <tr>
                      <td colSpan={19} className="blank_area type_white">
                          <p className="txt_blank">데이터가 없습니다.</p>
                      </td>
                  </tr>
                ) : (  
                  store.users.map((user: any, userIdx: number) => (
                    <tr key={userIdx}>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{getViewPageIdx(store.offset, store.limit, userIdx)}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.employee_number}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}><span className="user_name gray">{maskingName(user.name)}</span></td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.personnel?.birthdate}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.personnel?.gender === 1 ? "남" : "여"}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{/*회사명*/}현대자동차</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.personnel?.department}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{!user.personnel? '' : user.personnel.rank}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{!user.personnel? '' : user.personnel.occupation}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{WORK_SITE[user.work_site]}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{maskingPhone(user.cellphone)}</td>
                      <td className="al" onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.email}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer', textAlign: 'left' }}>
                        {user.personnel ? (user.personnel.zipcode1 && user.personnel.zipcode1 !== undefined ? `(${user.personnel.zipcode1})` : '' ) : ('')}
                        {user.personnel ? (user.personnel.address1 && user.personnel.address1 !== undefined ? `${user.personnel.address1}` : '' ) : ('')}
                        {/*`${user.personnel?.address1} ${user?.personnel?.address2}`*/}
                      </td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.personnel?.is_married ? "유" : "무"}</td>
                      {/* <td>{user.is_active ? <><span className="case_inuse">이용중</span><div><button className="btn btn_sm btn_white" onClick={() => {setIsShowUseModal(true);setUserOne(user);}}>이용정지</button></div></> : <><span className="case_stopuse">이용정지</span><div><button className="btn btn_sm btn_dark" onClick={() => {setIsShowUseModal(true);setUserOne(user);}}>이용정지 해제</button></div></>}</td> */}
                      <td>{user.is_active ? <><span className="case_inuse">이용중</span></> : <><span className="case_stopuse">이용정지</span></>}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{formatByString_YYYYMMDD(user.date_joined)}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.last_login_history?.created_at && formatByString_YYYYMMDD(user.last_login_history?.created_at)}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{divPcMobile(user.last_login_history?.user_agent)}</td>
                      <td onClick={(e: any) => { store.setUser(user); props.history.replace(`/manage/member/detail`) }} style={{ cursor: 'pointer' }}>{user.last_login_history?.ip_address}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>  {/*table*/}

          <div className="board_paging">
            <ul>
              <Pagination
                offset={store.offset}
                limit={store.limit}
                setOffset={(offset: number) => {store.offset = offset; store.getItems();}}
                totalPage={store.totalPage}
              />
            </ul>
          </div>  {/*board_paging*/}
      </DefaultTemplate>

      <MemberUseYn
        isShowUseModal={isShowUseModal}
        onCloseModalHandler={() => setIsShowUseModal(false)}
        user={userOne}
      />
    </>
  );
});

export default AccountListPage;
