import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;


export interface AssingnPayload {
  room: number;
}

class FamilyRoom {
  static URL = `${IP}`;

  /**
   * 가족숙소 배방완료
   */
  @apiHandler()
  static async assign(bookingId: number, payload: AssingnPayload) {
    const PATH = `/admin/lodge/family-room/${bookingId}/assign/`;

    return await axios.post(`${FamilyRoom.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default FamilyRoom;
