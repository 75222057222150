import {action, makeObservable, observable} from 'mobx';
import xss from 'xss';
import * as apis from '../../apis';
import { SEARCH_TYPE, SENDER, SEND_SELECT, SEND_STATUS } from '../../constants';
import { getIntervalMonthDate, getTodayDate, getTwoLatersMonthDate } from '../../utils/date';
import { BoardStore } from './boardStore';
import { debounce } from 'typescript-debounce-decorator';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { HeaderStore } from './headerStore';

export class SmsStore{

    //검색 할때 필요한 변수
    limit: number = 10;
    offset: number = 0;
    totalPage: number = 0;
    text: string = ''; 
    searchTypes: any[] = [];
    searchTypes2: any[] = [];
    searchTypes3: any[] = [];
    created_after: string = getIntervalMonthDate(2)[1];
    created_before: string = getTwoLatersMonthDate(2)[1];
    searchType = -1;
    searchType2 = -1;
    searchType3 = -1;
    search = '';
    work_site : string = '';
    items: any[] = [];
    option : string = '';
    users : any = [];
    guest : any = [];
    images : any = [];

    //등록
    contents : any;
    getHour : string = '01';
    getMinute : string = '00';

    //문자발송 상세보기용 데이터
    smsSendDetail : any = [];
    cellphone : number = 0;
    message : any;
    send_date : string = getTodayDate()[0];

    //loading
    headerStore : HeaderStore;

    constructor(root: any) {
      this.headerStore = new HeaderStore(this);
        makeObservable(this, {
        cellphone:observable,
        message:observable,
        send_date:observable,
        smsSendDetail:observable,
        getHour:observable,
        getMinute:observable,
        contents:observable,
        guest: observable,
        users : observable,
        limit : observable,
        offset : observable,
        searchTypes: observable,
        searchTypes2: observable,
        searchTypes3: observable,
        created_after: observable,
        created_before: observable,
        searchType: observable,
        searchType2: observable,
        searchType3: observable,
        search: observable,
        work_site : observable,
        items: observable,
        option: observable,
        

        smsList:action,
        });
        this.initDatas();
      }

      private initDatas () {

        const _searchTypes = [{ value: '-1', label: '선택', optionIdx: 0 }];
        Object.keys(SENDER).forEach((key: string, index: number) => {
          _searchTypes.push({
            value: key,
            label: SENDER[Number(key)],
            optionIdx: index + 1,
          });
        });
        this.searchTypes = _searchTypes;

        const _searchTypes2 = [{ value: '-1', label: '선택', optionIdx: 0 }];
        Object.keys(SEND_STATUS).forEach((key: string, index: number) => {
          _searchTypes2.push({
            value: key,
            label: SEND_STATUS[Number(key)],
            optionIdx: index + 1,
          });
        });
        this.searchTypes2 = _searchTypes2;

        const _searchTypes3 = [{ value: '1', label: '등록자ID', optionIdx: 0 }];
        // Object.keys(SEND_SELECT).forEach((key: string, index: number) => {
        //   _searchTypes3.push({
        //     value: key,
        //     label: SEND_SELECT[Number(key)],
        //     optionIdx: index + 1,
        //   });
        // });
        this.searchTypes3 = _searchTypes3;
      }

      init () {
        this.searchType = -1;
        this.searchType2 = -1;
        this.search = '';
        this.created_after = getIntervalMonthDate(2)[1];
        this.created_before = getTwoLatersMonthDate(2)[1];
        this.limit = 10;
        this.offset = 0;
        this.totalPage = 0;
      }

    @debounce(500)
    async getItems() {
      // console.log('getItems: ', this.limit, this.offset, this.work_site);
      const filter: any = {
          limit: this.limit,
          offset: this.offset,
          work_site: this.work_site,
      };

      switch (this.searchType2) {
          case 1: filter['send_status'] = '발송완료'; break;
          case 2: filter['send_status'] = '발송예정'; break;
          case 3: filter['send_status'] = '발송취소'; break;
      }

      // switch (this.searchType3) {
      //     case 1: filter['employee_number'] = this.search; break;
      // }

      if(this.search) {
        filter['employee_number'] = this.search; 
      }

      if (this.created_after) {
          filter['created_after'] = this.created_after;
      }

      if (this.created_before) {
          filter['created_before'] = this.created_before;
      }

      if (this.send_date) {
        filter['send_date'] = this.send_date;
      }

      try{
        this.headerStore.isLoading = true;
        
        const { data } = await apis.sms.smsList(filter);
        this.items = data.results;
        this.totalPage = Math.ceil(data.count / this.limit);

        this.headerStore.isLoading = false;
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
      }
    }

    async setLimit(limit: number) {
        this.limit = limit;
        await this.getItems();
    }
    
    async setOffset(offset: number) {
        this.offset = offset;
        await this.getItems();
    }

    //문자발송 리스트
    async smsList(){
        const payload : any = {
          limit: this.limit,
          offset: this.offset,
          work_site : this.work_site
        }

        try {
          this.headerStore.isLoading = true;
          const { data } = await apis.sms.smsList(payload);
          this.totalPage = Math.ceil(data.count / this.limit);
          // console.log('문자발송리스트=====>',data);
          this.items = data.results;
          this.headerStore.isLoading = false;

          return {
            'save' : true,
            'status' : 200,
            'data' : ''
          };
        }catch ({ status, data }) {
          this.headerStore.isLoading = false;
          if (status === 401) {
            if (!window.localStorage.getItem("isRetryLogin")) {
              window.localStorage.setItem("isRetryLogin", "retry");
            }
            window.location.href = '/';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return {
              'save' : false,
              'status' : status,
              'data' : data
            };
          }

          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }
    }

    async getUsers(){
      const payload : any = {
        work_site : this.work_site,
      }

      try {
        this.headerStore.isLoading = true;
        const { data } = await apis.user.getUsers(payload);
        this.users = data;
        this.headerStore.isLoading = false;
        // console.log('users DATA================>',this.users);

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }

        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
}