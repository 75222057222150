import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import intl from 'react-intl-universal';
import DatePicker from '../../components/organismas/input/datepicker';
import TempleteDefault from '../../components/templetes/default';
import {
  BOOKING_STATUS_CODE,
  FILE_CODE,
  LODGE_STATUS_CODE,
  ROOM_TYPE,
  ROOM_TYPE_CODE,
  WORK_SITE,
} from '../../constants';
import { getWorkSitePhoneNum, showAlert, showConfirm } from '../../utils/common';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import './index.scss';
import RoomType from '../../components/atoms/select/roomType';
import BackroomDormitoryPopup from '../../components/organismas/popup/dormitoryBackroom';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import CheckPopup from '../../components/organismas/popup/check';
import Reflect from '../../components/organismas/popup/reflect';
import VipBackRoom from '../../components/organismas/popup/vipBackroom';
import CompanyHouseBackroom from '../../components/organismas/popup/companyHouseBackroom';
import BusinessGroupBackroom from '../../components/organismas/popup/businessGroupBackroom';
import FamilyBackroom from '../../components/organismas/popup/familyBackroom';
import BusinessSingleBackroom from '../../components/organismas/popup/businessSingleBackroom';
import { isNull } from 'lodash';
import Core from '../../apis/core';
import FileSaver from 'file-saver';
import { core, lodge } from '../../apis';
import CustomCalendar from '../../components/organismas/customCalendar';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../store/modules/modal';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../routes';

const ApplicantJudge = observer((props: any) => {
  const dispatch = useDispatch();
  const { applicantJudgeStore: store } = useStores();
  const { headerStore: headerStore } = useStores();
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [isFilePop, setIsFilePop] = useState<boolean>(false);
  const [isCheckPopupShow, setIsCheckpopupShow] = useState<boolean>(false);
  const [isShowReflect, setIsShowReflect] = useState<boolean>(false);
  const [isShowStayAfterPop, setIsShowStayAfterPop] = useState<boolean>(false);
  const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
  const [isGroup, setIsGroup] = useState<boolean>(false);
  const [downloadText, setDownloadText] = useState<string>('');
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    (async () => {
      headerStore.isLoading = true;
      store.paramAuthRoomTypeRole = headerStore.paramAuthRoomTypeRole;
      store.paramAuthWorkSiteRole = headerStore.paramAuthWorkSiteRole;
      headerStore.getAuthBuilding();
      headerStore.getAuthMenuRole('1-2');
      store.workSite = headerStore.workSite;
      if (Number(headerStore.workSite) === 2) {
        if (
          !isNull(headerStore.paramAuthBuildingRole) &&
          headerStore.paramAuthBuildingRole !== 'null' &&
          headerStore.paramAuthBuildingRole.length > 0
        ) {
          store.paramAuthBuildingRole = headerStore.paramAuthBuildingRole;
        }
      }
      //let paramSite

      await store.getItems();
      if (gridApi) gridApi.deselectAll(); // 체크 박스 -> false
      headerStore.isLoading = false;
    })();
    return () => {
      if (store.findTabs('/applicant/judge') < 0) {
        store.destory();
      }
    };
  }, [headerStore.workSite]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // 검색 버튼 클릭
  const onSearchClick = (e: any) => {
    store.getItems();
  };

  // 체크박스 로직
  const onCheckedIds = (e: any) => {
    let target = e.api.getSelectedNodes();

    store.checkeds = {};
    if (target.length > 0) {
      let tempTargets: any[] = [];

      // console.log(target);
      target.forEach((row: any) => {
        const tempId = { id: row.data.id };
        const tempStatus = { status: row.data.status };
        const tempGuestIds = { guestIds: row.data.guestIds };
        //const tempGuestIds = {''};

        tempTargets.push({ ...tempId, ...tempStatus, ...tempGuestIds });
      });

      store.checkeds = tempTargets;
    }
  };

  // 다운로드 버튼 클릭
  /*const onClickDownload = () => {
        if (Object.keys(store.checkeds).length <= 0) {
            showAlert('선택하신 체크 항목이 없습니다. \n 다운받으실 행을 선택해주세요', dispatch);
            return;
        }
        showConfirm('다운로드 하시겠습니까?', dispatch, downloadFn);
    }

    const downloadFn = async () => {
        setIsLoading(true);
        let msg = '';

        try {
            await store.downloadBookingList();
            msg = '다운로드 완료되었습니다.';
        } catch ({status, data}) {
            console.log(data);
            msg = '다운로드 중 문제발생.';
        }

        setTimeout(() => {
            setIsLoading(false);
            dispatch(
                onShowMsg({ msg })
            );
          }, 1000);
    }*/

  // 다운로드
  const xlsDownload = async () => {
    try {
      headerStore.isLoading = true;
      let payload: any = {
        employee_number: userInfo.employeeNumber,
        reason: downloadText,
        guest_status: [LODGE_STATUS_CODE.opened, LODGE_STATUS_CODE.waiting, LODGE_STATUS_CODE.closed].join(','),
        created_after: store.dateAfter,
        created_before: store.dateBefore,
        worksite: store.workSite,
      };

      if (store.roomType > -1) {
        payload.roomtype = store.roomType;
      } else {
        //전체
        payload.roomtype = store.paramAuthRoomTypeRole;
      }
      if (Number(store.workSite) > -1) {
        payload.worksite = store.workSite;
      } else {
        payload.worksite = store.workSite;
      }

      if (store.option && store.text) {
        payload[store.option] = store.text;
      }
      if (Number(store.workSite) === 2) {
        if (
          !isNull(store.paramAuthBuildingRole) &&
          store.paramAuthBuildingRole !== undefined &&
          store.paramAuthBuildingRole.length > 0
        ) {
          payload.authBuilding = store.paramAuthBuildingRole;
        }
      }

      let { data } = await Core.xlsFileDownload(payload);
      if (data) {
        //window.open(data);
        FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}` + data);
      }
      // <a href={`${process.env.REACT_APP_MEDIA}/${file.file.path}`} download></a>
      setIsShowDownloadPop(false);
      setDownloadText('');
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      // console.log(status, data);
    }
  };

  // 입숙처리 핸들러
  const onCheckinHandler = async (e: any) => {
    store.getItems();
  };

  // 반려 핸들러
  const onReflectSubmitHandler = async (reflectType: number, date: string, description: string) => {
    try {
      headerStore.isLoading = true;
      let data: any = await store.reflectSubmit(reflectType, date, description);
      headerStore.isLoading = false;
      if (data.save) {
        showAlert('반려처리가 완료되었습니다', dispatch);

        // 문자발송
        if (store.selectRow.room_type !== ROOM_TYPE_CODE.business_room) {
          if (reflectType === BOOKING_STATUS_CODE.rejected) {
            // 재요청인 경우
            let smsPayload: any = {
              cellphone: store.selectRow.applicant_cellphone,
              message:
                '입숙신청에 다음과 같은 사항으로 재요청이 들어왔습니다. \n< ' +
                description +
                ' 재요청  >' +
                '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
                getWorkSitePhoneNum(headerStore.workSite, store.selectRow.room_type),
            };
            try {
              if (store.selectRow.room_type !== ROOM_TYPE_CODE.vip_room) {
                const { data: sms } = await lodge.smsSendAPI(smsPayload);
              }
            } catch (error) {
              let { status, data } = error;
              if (status === 401) {
                if (!window.localStorage.getItem('isRetryLogin')) {
                  window.localStorage.setItem('isRetryLogin', 'retry');
                }
                window.location.href = '/';
                return;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return;
              } else if (status === 500) {
                window.location.href = '/500';
                return;
              } else {
                showAlert('문제가 발생했습니다.', dispatch);
                return;
              }
            }
          } else {
            // 거절인 경우
            let smsPayload: any = {
              cellphone: store.selectRow.applicant_cellphone,
              message:
                '입숙신청이 다음과 같은 사항으로 반려되었습니다. \n< ' +
                description +
                ' 반려사유  >' +
                '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
                getWorkSitePhoneNum(headerStore.workSite, store.selectRow.room_type),
            };
            try {
              if (store.selectRow.room_type !== ROOM_TYPE_CODE.vip_room) {
                const { data: sms } = await lodge.smsSendAPI(smsPayload);
              }
            } catch (error) {
              let { status, data } = error;
              if (status === 400) {
                if (data?.code) {
                  showAlert(intl.get(data.code), dispatch);
                  return;
                }
                return;
              } else if (status === 401) {
                if (!window.localStorage.getItem('isRetryLogin')) {
                  window.localStorage.setItem('isRetryLogin', 'retry');
                }
                window.location.href = '/';
                return;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return;
              } else if (status === 500) {
                window.location.href = '/500';
                return;
              }
              showAlert('문제가 발생했습니다.', dispatch);
              return;
            }
          }
        }
        setIsShowReflect(false);
        await store.getItems();
      } else {
        showAlert('문제가 발생했습니다.', dispatch);
        return;
      }
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 400) {
        if (data?.code) {
          showAlert(intl.get(data.code), dispatch);
          return;
        } else if (data?.terminated_at) {
          showAlert('기한이 잘못되었습니다.', dispatch);
          return;
        } else if (data?.description) {
          showAlert('사유는 400Bytes 이내로 반드시 작성해야 합니다.', dispatch);
          return;
        }
        return;
      } else if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      showAlert('문제가 발생했습니다.', dispatch);
      return;
    }
  };

  // 승인버튼 : 배방 핸들러
  const RoomSelectHandler = async () => {
    if (!headerStore.mobileRoleFlag) {
      showAlert('승인 권한이 없습니다.', dispatch);
      return;
    }
    if (store.selectRow.room_type === ROOM_TYPE_CODE.vip_room) {
      // 영빈관
      store.isVipRoomPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.company_room) {
      // 사택 - 남양이 아닐 경우, 배방
      if (Number(store.selectRow.room_work_site) !== 2) {
        store.isCompanyRoomPop = true;
      } else {
        // 남양은 배방 나중에
        showConfirm('승인 하시겠습니까?', dispatch, companyHouseNamyang);
      }
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.dormitory_room) {
      // 기숙사
      store.isDormitoryPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group) {
      // 출장자 단체
      store.isBusinessGroupPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.family_room) {
      // 가족숙소
      store.isFamilyPop = true;
    }
  };

  // 남양 - 사택 승인처리
  const companyHouseNamyang = async () => {
    try {
      headerStore.isLoading = true;
      let payload = {
        status: BOOKING_STATUS_CODE.roomOpen,
      };
      let { data } = await lodge.companyHouseNamyang(store.selectRow.id, payload);

      // 승인 완료 문자 발송
      let smsPayload: any = {
        cellphone: store.selectRow.applicant_cellphone,
        message:
          '서류승인이 완료되었습니다. 입숙예정일 입력 부탁드립니다. ( 메뉴 : 마이페이지>>신청관리 ) ' +
          '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 ' +
          getWorkSitePhoneNum(store.selectRow.room_work_site, store.selectRow.room_type),
      };

      const { data: sms } = await lodge.smsSendAPI(smsPayload);

      store.getItems();
      headerStore.isLoading = false;
    } catch (error) {
      headerStore.isLoading = false;
      // console.log(error);
      const { status, data } = error;

      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      showAlert('문제가 발생했습니다.', dispatch);
      return;
    }
  };

  //role 핸들러
  const roleHandler = async (data?: number) => {
    showAlert('권한이 없습니다.', dispatch);
    return;
  };

  // 입숙예정일 변경 요청
  const rescheduleHandler = async () => {
    if (store.statusDescription === '' || store.statusDescription === null) {
      showAlert('사유를 입력해주세요', dispatch);
      return false;
    }

    try {
      headerStore.isLoading = true;
      let data: any = await store.reschedule();
      headerStore.isLoading = false;

      setTimeout(function () {
        if (data.save) {
          setIsShowStayAfterPop(false);
          store.statusDescription = '';
          store.getItems();
        } else {
          showAlert('문제가 발생했습니다.', dispatch);
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      showAlert('문제가 발생했습니다.', dispatch);
      return;
    }
  };

  // 최종승인
  const scheduleApprove = async () => {
    try {
      headerStore.isLoading = true;
      let data: any = await store.scheduleApprove();
      headerStore.isLoading = false;

      setTimeout(function () {
        if (data.save) {
          store.getItems();
        } else {
          showAlert('문제가 발생했습니다.', dispatch);
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      showAlert('문제가 발생했습니다.', dispatch);
      return;
    }
  };

  // 증빙서류 재요청
  const documentReCheck = async () => {
    try {
      headerStore.isLoading = true;
      let data: any = await store.documentReCheck();
      headerStore.isLoading = false;

      setTimeout(function () {
        if (data.save) {
          setIsShowStayAfterPop(false);
          store.statusDescription = '';
          store.getItems();
        } else {
          showAlert('문제가 발생했습니다.', dispatch);
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      showAlert('문제가 발생했습니다.', dispatch);
      return;
    }
  };

  // 사택 최종승인
  const companyHouseApprove = async () => {
    try {
      headerStore.isLoading = true;
      let data: any = await store.companyHouseApprove();
      headerStore.isLoading = false;

      setTimeout(function () {
        if (data.save) {
          store.getItems();
        } else {
          showAlert('문제가 발생했습니다.', dispatch);
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      showAlert('문제가 발생했습니다.', dispatch);
      return;
    }
  };

  // 단체 배방 진행 완료 - 승인
  const groupScheduleApprove = async () => {
    try {
      headerStore.isLoading = true;
      let data: any = await store.groupScheduleApprove();
      headerStore.isLoading = false;

      setTimeout(function () {
        if (data.save) {
          store.getItems();
        } else {
          showAlert('문제가 발생했습니다.', dispatch);
          return;
        }
      }, 250);
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem('isRetryLogin')) {
          window.localStorage.setItem('isRetryLogin', 'retry');
        }
        window.location.href = '/';
        return;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      } else if (status === 500) {
        window.location.href = '/500';
        return;
      }

      showAlert('문제가 발생했습니다.', dispatch);
      return;
    }
  };

  // 그리드 진행상태
  const cellStatusRenderer = ({ data }: { data: any; idx: number }) => {
    if (data.status === BOOKING_STATUS_CODE.applied) {
      // 신청자 신청완료
      return (
        <>
          {headerStore.mobileRoleFlag ? ( // 승인배방 권한
            <>
              {data.rank &&
              (data.room_type === ROOM_TYPE_CODE.dormitory_room || data.room_type === ROOM_TYPE_CODE.company_room) ? (
                <span className="point blue">신청완료 : 대기 {data.rank}번</span>
              ) : (
                <span className="point blue">신청완료</span>
              )}
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  setIsShowReflect(true);
                }}
              >
                반려
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  RoomSelectHandler();
                }}
              >
                승인
              </button>
            </>
          ) : (
            <>
              {data.rank &&
              (data.room_type === ROOM_TYPE_CODE.dormitory_room || data.room_type === ROOM_TYPE_CODE.company_room) ? (
                <span className="point blue">신청완료 : 대기 {data.rank}번</span>
              ) : (
                <span className="point blue">신청완료</span>
              )}
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                반려
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                승인
              </button>
            </>
          )}
        </>
      );
    } else if (data.status === BOOKING_STATUS_CODE.rejected) {
      // 반려 재요청
      return (
        <>
          {headerStore.mobileRoleFlag ? (
            <>
              <span className="point red">반려(재요청)</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  setIsShowReflect(true);
                }}
              >
                반려사유
              </button>
            </>
          ) : (
            <>
              <span className="point red">반려(재요청)</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                반려사유
              </button>
            </>
          )}
        </>
      );
    } else if (data.status === BOOKING_STATUS_CODE.terminated) {
      // 반려 거절
      return (
        <>
          {headerStore.mobileRoleFlag ? (
            <>
              <span className="point red">승인거절</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  setIsShowReflect(true);
                }}
              >
                거절사유
              </button>
            </>
          ) : (
            <>
              <span className="point red">승인거절</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                거절사유
              </button>
            </>
          )}
        </>
      );
    } else if (data.status === BOOKING_STATUS_CODE.canceled) {
      // 신청자 신청 취소
      return <span className="point red">예약취소</span>;
    } else if (data.status === BOOKING_STATUS_CODE.approved && data.guest_status === LODGE_STATUS_CODE.waiting) {
      // 모든 승인 처리 end 입숙대기 상태
      return (
        <>
          {headerStore.mobileRoleFlag ? (
            <>
              <span className="point green">예약완료</span>
              <button
                type="button"
                className="btn btn_xs btn_green"
                onClick={() => {
                  store.selectRow = data;
                  setIsCheckpopupShow(true);
                }}
              >
                입숙처리
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray"
                onClick={() => {
                  store.selectRow = data;
                  onCancel();
                }}
              >
                입숙취소
              </button>
            </>
          ) : (
            <>
              <span className="point green">예약완료</span>
              <button
                type="button"
                className="btn btn_xs btn_green"
                onClick={() => {
                  roleHandler();
                }}
              >
                입숙처리
              </button>
            </>
          )}
        </>
      );
    } else if (data.status === BOOKING_STATUS_CODE.approved && data.room_type === ROOM_TYPE_CODE.business_room_group) {
      // 관리자
      //return <span className="point blue">서류승인완료</span>;
      return <>
              <span className="point blue">서류승인완료</span>
              <div style={{float:"left"}}>
                <button
                  type="button"
                  className="btn btn_xs btn_green"
                  onClick={() => {
                    store.selectRow = data;
                    onCancelHandler();
                  }}
                >
                  배방 취소
                </button>
              </div>
              <div style={{float:"left", margin:"0px 0px 0px 5px"}}>
                <button
                  type="button"
                  className="btn btn_xs btn_gray"
                  onClick={() => {
                    store.selectRow = data;
                    onCancel();
                  }}
                >
                  입숙 취소
                </button>
              </div>
            </>
    } else if (data.status === BOOKING_STATUS_CODE.stayHold) {
      // 입숙보류 상태
      return (
        <>
          {headerStore.mobileRoleFlag ? (
            <>
              {data.rank &&
              (data.room_type === ROOM_TYPE_CODE.dormitory_room || data.room_type === ROOM_TYPE_CODE.company_room) ? (
                <span className="point red">입숙보류 : 대기 {data.rank}번</span>
              ) : (
                <span className="point red">입숙보류</span>
              )}
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  setIsShowReflect(true);
                }}
              >
                반려
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  RoomSelectHandler();
                }}
              >
                승인
              </button>
            </>
          ) : (
            <>
              {data.rank &&
              (data.room_type === ROOM_TYPE_CODE.dormitory_room || data.room_type === ROOM_TYPE_CODE.company_room) ? (
                <span className="point red">입숙보류 : 대기 {data.rank}번</span>
              ) : (
                <span className="point red">입숙보류</span>
              )}
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                반려
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                승인
              </button>
            </>
          )}
        </>
      );
    } else if (data.status === BOOKING_STATUS_CODE.approved && data.guest_status === LODGE_STATUS_CODE.checkin) {
      // 입숙
      return <span className="point green">입숙</span>;
    } else if (data.status === BOOKING_STATUS_CODE.roomOpen && data.room_type === ROOM_TYPE_CODE.business_room_group) {
      //단체, 사용자가 배방 진행 완료
      return (
        <>
          {headerStore.mobileRoleFlag ? (
            <>
              <span className="point blue">배방완료</span>
              {/* <button type="button" className="btn btn_xs btn_gray btn_outline" onClick={() => {store.selectRow = data;}}>변경요청</button> */}
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  showConfirm('승인 하시겠습니까?', dispatch, groupScheduleApprove);
                }}
              >
                승인
              </button>
            </>
          ) : (
            <>
              <span className="point blue">배방완료</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                승인
              </button>
            </>
          )}
        </>
      );
    } else if (data.room_type === ROOM_TYPE_CODE.company_room && data.status === BOOKING_STATUS_CODE.documentApproved) {
      // 사택 입숙예정일 승인, 증빙서류 요청
      return <span className="point blue">승인완료</span>;
    } else if (data.status === BOOKING_STATUS_CODE.roomOpen || data.status === BOOKING_STATUS_CODE.documentApproved) {
      // 배방 오픈 or 서류 승인완료
      //return <span className="point blue">서류승인완료</span>;
      return <>
              <span className="point blue">서류승인완료</span>
              <div style={{float:"left"}}>
                <button
                  type="button"
                  className="btn btn_xs btn_green"
                  onClick={() => {
                    store.selectRow = data;
                    onCancelHandler();
                  }}
                >
                  배방 취소
                </button>
              </div>
              <div style={{float:"left", margin:"0px 0px 0px 5px"}}>
                <button
                  type="button"
                  className="btn btn_xs btn_gray"
                  onClick={() => {
                    store.selectRow = data;
                    onCancel();
                  }}
                >
                  입숙 취소
                </button>
              </div>
            </>
    } else if (data.status === BOOKING_STATUS_CODE.stayAfter && data.room_type === ROOM_TYPE_CODE.dormitory_room) {
      // 신청자가 입숙예정일 입력 (기숙사)
      return (
        <>
          {headerStore.mobileRoleFlag ? (
            <>
              <span className="point blue">입숙예정일 : {data.stay_after}</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  setIsShowStayAfterPop(true);
                }}
              >
                변경요청
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  showConfirm('최종 승인 하시겠습니까?', dispatch, scheduleApprove);
                }}
              >
                최종승인
              </button>
            </>
          ) : (
            <>
              <span className="point blue">입숙예정일 : {data.stay_after}</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                변경요청
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                최종승인
              </button>
            </>
          )}
        </>
      );
    } else if (data.status === BOOKING_STATUS_CODE.stayAfter && data.room_type === ROOM_TYPE_CODE.company_room) {
      // 신청자가 입숙예정일 입력 (사택)
      if (!headerStore.mobileRoleFlag) {
        // 사택 승인 권한 X
        return (
          <>
            <span className="point blue">입숙예정일 : {data.stay_after}</span>
            <button
              type="button"
              className="btn btn_xs btn_gray btn_outline"
              onClick={() => {
                roleHandler();
              }}
            >
              변경요청
            </button>
            <button
              type="button"
              className="btn btn_xs btn_gray btn_outline"
              onClick={() => {
                roleHandler();
              }}
            >
              승인
            </button>
          </>
        );
      } else if (Number(data.room_work_site) !== 2) {
        // 사택 남양이 아닌 경우, 예정일 승인
        return (
          <>
            <span className="point blue">입숙예정일 : {data.stay_after}</span>
            <button
              type="button"
              className="btn btn_xs btn_gray btn_outline"
              onClick={() => {
                store.selectRow = data;
                setIsShowStayAfterPop(true);
              }}
            >
              변경요청
            </button>
            <button
              type="button"
              className="btn btn_xs btn_gray btn_outline"
              onClick={() => {
                store.selectRow = data;
                showConfirm('승인 하시겠습니까?', dispatch, scheduleApprove);
              }}
            >
              승인
            </button>
          </>
        );
      } else {
        // 남양의 경우 입숙일 승인시 배방
        return (
          <>
            <span className="point blue">입숙예정일 : {data.stay_after}</span>
            <button
              type="button"
              className="btn btn_xs btn_gray btn_outline"
              onClick={() => {
                store.selectRow = data;
                setIsShowStayAfterPop(true);
              }}
            >
              변경요청
            </button>
            <button
              type="button"
              className="btn btn_xs btn_gray btn_outline"
              onClick={() => {
                store.selectRow = data;
                store.isCompanyRoomPop = true;
              }}
            >
              승인
            </button>
          </>
        );
      }
    } else if (data.status === BOOKING_STATUS_CODE.stayAfterTerminated) {
      // 관리자 - 입숙예정일 변경 요청
      return <span className="point red">입숙예정일 변경 요청</span>;
    } else if (data.status === BOOKING_STATUS_CODE.documentReject) {
      // 관리자 - 입숙예정일 변경 요청
      return <span className="point red">증빙서류재요청</span>;
    } else if (data.status === BOOKING_STATUS_CODE.documentSubmit) {
      // 관리자 - 증빙서류 제출상태
      return (
        <>
          {headerStore.mobileRoleFlag ? (
            <>
              <span className="point blue">증빙서류등록완료</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  setIsShowStayAfterPop(true);
                }}
              >
                변경요청
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  store.selectRow = data;
                  showConfirm('최종 승인 하시겠습니까?', dispatch, companyHouseApprove);
                }}
              >
                최종승인
              </button>
            </>
          ) : (
            <>
              <span className="point blue">증빙서류등록완료</span>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                변경요청
              </button>
              <button
                type="button"
                className="btn btn_xs btn_gray btn_outline"
                onClick={() => {
                  roleHandler();
                }}
              >
                최종승인
              </button>
            </>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  // 그리드 방정보
  const cellRoomNameRenderer = ({ data }: { data: any }) => {
    if (data.room_building_name && data.room_name) {
      if (data.room_type === ROOM_TYPE_CODE.business_room_group && headerStore.mobileRoleFlag == true) {
        return (
          <button
            type="button"
            className="btn btn_xs btn_gray btn_outline"
            onClick={() => {
              store.selectRow = data;
              openRoomInfo();
            }}
          >
            배방확인
          </button>
        );
      }
      if (data.room_type === ROOM_TYPE_CODE.business_room_group && headerStore.mobileRoleFlag == false) {
        return (
          <button
            type="button"
            className="btn btn_xs btn_gray btn_outline"
            onClick={() => {
              store.selectRow = data;
              roleHandler();
            }}
          >
            배방확인
          </button>
        );
      }
      return (
        <>
          <span className="point">
            {data.room_building_name} {data.room_building_dong} {data.room_name} 호
          </span>
          <button
            type="button"
            className="btn btn_xs btn_gray btn_outline"
            onClick={() => {
              store.selectRow = data;
              openRoomInfo();
            }}
          >
            배방확인
          </button>
        </>
      );
    }

    return '';
  };

  // 배방 확인 팝업 오픈
  const openRoomInfo = () => {
    if (store.selectRow.room_type === ROOM_TYPE_CODE.vip_room) {
      // 영빈관
      store.openRoomInfoPop = true;
      store.isVipRoomPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.company_room) {
      // 사택
      store.openRoomInfoPop = true;
      store.isCompanyRoomPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.dormitory_room) {
      // 기숙사
      store.openRoomInfoPop = true;
      store.isDormitoryPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.business_room_group) {
      // 출장자 단체
      store.openRoomInfoPop = true;
      store.isBusinessGroupPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.family_room) {
      // 가족숙소
      store.openRoomInfoPop = true;
      store.isFamilyPop = true;
    } else if (store.selectRow.room_type === ROOM_TYPE_CODE.business_room) {
      // 출장자개인
      store.openRoomInfoPop = true;
      store.isBusinessSinglePop = true;
    }
  };

  // 그리드 숙소 유형
  const cellRoomStatus = ({ data }: { data: any }) => {
    return <span>{ROOM_TYPE[data.room_type]}</span>;
  };

  // 그리드 첨부파일 팝업
  const cellFilePop = ({ data }: { data: any }) => {
    if (data.files.length > 0) {
      if (data.room_type === ROOM_TYPE_CODE.business_room_group && headerStore.downRoleFlag == true) {
        setIsGroup(true);
      } else {
        setIsGroup(false);
      }
      return (
        <a
          href="javascript:void(0);"
          onClick={() => {
            store.viewFiles = data.files;
            setIsFilePop(true);
          }}
          className="btn_file2"
        ></a>
      );
    } else {
      return <></>;
    }
  };

  //가족숙소 신청자의 사번으로 표시
  const cellRoomNameEmployeeNumber = ({ data }: { data: any }) => {
    if (data.room_type === 1 && data.guest_employee_number.length <= 0) {
      return data.applicant_employee_number;
    } else {
      return data.guest_employee_number;
    }
  };

  //휴대폰 번호 하이픈 처리
  const cellPhoneHyphen = ({ data }: { data: any }) => {
    if (data.guest_cellphone !== undefined && data.guest_cellphone > 0) {
      let phone = data.guest_cellphone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');

      return phone;
    }
    return '';
  };

  const frameworkComponents = {
    cellStatusRenderer: cellStatusRenderer,
    cellRoomStatus: cellRoomStatus,
    cellFilePop: cellFilePop,
    cellRoomNameRenderer: cellRoomNameRenderer,
    cellRoomNameEmployeeNumber: cellRoomNameEmployeeNumber,
    cellPhoneHyphen: cellPhoneHyphen,
  };

  const getItems = async (roomType: any) => {
    store.roomType = roomType;
    store.bookings = [];
    store.paramAuthWorkSiteRole = headerStore.workSite;
    store.workSite = headerStore.workSite;
    await store.getItems();
    // gridApi.redrawRows();
  };

  //배방 취소 버튼
  const onCancelHandler = async () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '배방 취소 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data: any = await store.lodgeRoomCancel();
            headerStore.isLoading = false;
            setTimeout(function () {
              if (data.save) {
                info('배방 취소를 완료했습니다.');
                store.getItems();
                return;
              } else {
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            errorHandler({ status, data }, '취소중 오류발생');
          }
        },
      },
    });
  };

  //입숙 취소 버튼
  const onCancel = async () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '입숙 취소 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data: any = await store.lodgeJudgeCancle();
            headerStore.isLoading = false;
            setTimeout(function () {
              if (data.save) {
                info('입숙취소를 완료했습니다.');
                store.getItems();
                return;
              } else {
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            errorHandler({ status, data }, '취소중 오류발생');
          }
        },
      },
    });
  };

  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    console.log(filename);
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };
  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }
    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
  
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');
    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };
  
    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);
  
    if(isImageOrPDFFile) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    else {
      //fileDownload
      try {
        let payload: any = {
          filename: filename,
          url : desiredParts,
          employeeNumber : userInfo.employeeNumber,
          classification : userInfo.classification,
        };
        let { data } = await core.fileDownload(payload);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        //info("협조전 등록이 완료되었습니다.");
      } catch ({ data, status }) {
        console.log(data, status);
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        info("파일 다운로드 중 문제가 발생했습니다");
        return;
      }
  }
    
  };
  return (
    <TempleteDefault {...props} isLoading={headerStore.isLoading} id="wrap" className="">
      <div className="group_tab mt30">
        <RoomType className="tab_lg tabs clearfix" onChange={getItems} value={store.roomType} />
      </div>

      <div className="tab_contents on">
        <h4>심사 및 입숙처리 정보 입력</h4>
        <div className="table_modify_list">
          <dl className="info">
            <dt>정보</dt>
            <dd>
              <select
                className="form_control"
                value={store.option}
                onChange={(e: any) => (store.option = e.target.value)}
              >
                <option value="guest_name">성명</option>
                <option value="guest_employee_number">사번</option>
                <option value="guest_company">회사명</option>
                {/* <option value="guest_type">소속회사 유형</option> */}
              </select>
              <input
                type="text"
                className="form_control"
                value={store.text}
                onChange={(e: any) => (store.text = e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13) onSearchClick(e);
                }}
                maxLength={30}
              />
            </dd>
          </dl>
          <dl className="info">
            <dt>진행상태</dt>
            <dd><div>
                <select className="form_control"
                 value={store.option_status}
                 onChange={(e:any) => (store.option_status = e.target.value)}>
                  <option value="0">전체</option>
                  <option value="1">신청완료</option>
                  <option value="2">반려</option>
                  <option value="3">승인거절</option>
                  <option value="4">예약취소</option>
                  <option value="5">예약완료</option>
                  <option value="6">서류승인완료</option>
                  <option value="7">입숙보류</option>
                  <option value="8">입숙</option>
                  <option value="9">배방완료</option>
                  <option value="10">승인완료</option>
                  <option value="11">입숙예정일</option>
                  <option value="12">입숙예정일 변경 요청</option>
                  <option value="13">증빙서류재요청 요청</option>
                  <option value="14">증빙서류등록완료 요청</option>
                </select>
              </div>
            </dd>
          </dl>
          <dl className="date">
            <dt>조회기간</dt>
            <dd>
              {/* <DatePicker
                                value={store.dateAfter}
                                width={250}
                                onChange={(date: string) => store.dateAfter = date}
                                isPrevMonthView={true}
                                max={store.dateBefore}
                            /> */}
              <CustomCalendar
                max={store.dateBefore}
                value={store.dateAfter}
                onChange={(date: string) => (store.dateAfter = date)}
              ></CustomCalendar>
              {/* <DatePicker
                                value={store.dateBefore}
                                width={250}
                                onChange={(date: string) => store.dateBefore = date}
                                isPrevMonthView={true}
                                min={store.dateAfter}
                            /> */}
              <CustomCalendar
                min={store.dateAfter}
                value={store.dateBefore}
                onChange={(date: string) => (store.dateBefore = date)}
              ></CustomCalendar>
              <button type="button" className="btn btn_sm btn_gray" onClick={onSearchClick}>
                검색
              </button>
            </dd>
          </dl>
        </div>
        <h4>
          심사 및 입숙처리 리스트
          <div className="right_area">
            <div>
              {headerStore.downRoleFlag ? (
                <>
                  <button
                    type="button"
                    className="btn btn_md btn_darkblue"
                    onClick={() => {
                      setIsShowDownloadPop(true);
                    }}
                  >
                    다운로드
                  </button>
                </>
              ) : (
                <>
                  <></>
                </>
              )}
            </div>
          </div>
        </h4>
        <div className="table_normal_list ag-theme-alpine" style={{ height: '600px' }}>
          <AgGridReact
            //components={{datePicker: () => {}}}
            modules={AllCommunityModules}
            rowHeight={90}
            defaultColDef={{
              width: 80,
              //autoHeight: true,
              wrapText: true,
              resizable: true,
              sortable: true,
              // filter: true,
              // floatingFilter: true,
              // cellStyle: { textAlign: 'center' },
              // headerClass: 'text-center',
            }}
            suppressRowTransform={true}
            suppressRowClickSelection={true}
            frameworkComponents={frameworkComponents}
            onGridReady={onGridReady}
            onSelectionChanged={e => onCheckedIds(e)}
            // onSortChanged={sortChanged}
            multiSortKey={'ctrl'}
            rowSelection={'multiple'}
            unSortIcon={true}
            rowData={store.bookings}
          >
            <AgGridColumn headerName="신청기본정보" pinned="left">
              {/*<AgGridColumn
                                //field="id"
                                headerName=""
                                field=""
                                editable={false}
                                headerCheckboxSelection={false}
                                checkboxSelection={true}
                                sortable={false}
                                width={60}
                                //resizable={false}
                                pinned="left"
                            ></AgGridColumn>*/}
              <AgGridColumn
                headerName="신청일자"
                field="created_at"
                sortable={true}
                //resizable={false}
                width={120}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn
                headerName="숙소유형"
                field="room_type"
                cellRenderer="cellRoomStatus"
                width={128}
                //resizable={false}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn
                headerName="신청지역"
                field="room_type"
                cellRenderer={({ data }: { data: any }) => WORK_SITE[data.room_work_site]}
                width={120}
                //resizable={false}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn
                headerName="진행상태"
                field="status"
                width={206}
                cellRenderer="cellStatusRenderer"
                pinned="left"
              />
            </AgGridColumn>
            {/* <AgGridColumn headerName="신청자" pinned="left">
                            <AgGridColumn
                                headerName="성명"
                                field="applicant_name"
                                width={80}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                                pinned="left"
                            ></AgGridColumn>
                            <AgGridColumn
                                headerName="부서명"
                                field="applicant_department"
                                width={80}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                                pinned="left"
                            ></AgGridColumn>
                            <AgGridColumn
                                headerName="휴대폰"
                                field="applicant_cellphone"
                                width={112}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                                pinned="left"
                            ></AgGridColumn>
                        </AgGridColumn> */}
            <AgGridColumn headerName="이용자">
              <AgGridColumn
                headerName="숙소정보(동호수)"
                cellStyle={{
                  textAlign: 'center',
                }}
                field="room_building_name"
                width={170}
                cellRenderer="cellRoomNameRenderer"
              ></AgGridColumn>
              <AgGridColumn
                headerName="입숙희망일"
                field="checkin_hope"
                width={125}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="입숙예정일"
                field="stay_after"
                width={120}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="퇴숙예정일"
                field="stay_before"
                width={120}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="사번"
                field="guest_employee_number"
                cellRenderer="cellRoomNameEmployeeNumber"
                width={90}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="성명"
                field="guest_name_total"
                sortable={true}
                width={90}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="성별"
                field="guest_gender"
                cellRenderer={({ data }: { data: any }) => (data.guest_gender === 1 ? '남자' : '여자')}
                sortable={true}
                width={90}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="결혼유무"
                field="is_married"
                cellRenderer={({ data }: { data: any }) => (data.is_married ? '유' : '무')}
                sortable={true}
                width={120}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="휴대폰"
                field="guest_cellphone"
                cellRenderer="cellPhoneHyphen"
                width={120}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              {/* <AgGridColumn
                                headerName="소속회사 유형"
                                cellRenderer={({data}:{data:any}) => GUEST_TYPE[data.guest_type]}
                                width={140}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                            ></AgGridColumn> */}
              <AgGridColumn
                headerName="회사명/단체명"
                field="guest_company"
                width={140}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="소속부서"
                field="guest_department"
                width={110}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="직급"
                field="guest_position"
                width={97}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="직종"
                field="guest_occupation"
                width={90}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
              {/* <AgGridColumn
                                headerName="근무조"
                                field="guest_party"
                                width={90}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                            ></AgGridColumn> */}
              {/* <AgGridColumn
                            headerName="차량번호"
                            // cellRenderer={props => `${props.guest_car_number1} ${props.guest_car_number2} ${props.guest_car_number3}`}
                            ></AgGridColumn> */}
            </AgGridColumn>
            {/* <AgGridColumn headerName="신청자">
                            <AgGridColumn
                                headerName="회사명"
                                cellRenderer={(props: any) => `현대자동차`}
                                width={140}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                            ></AgGridColumn>
                            <AgGridColumn
                                headerName="직급"
                                field="applicant_position"
                                sortable={true}
                                width={85}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                            ></AgGridColumn>
                            <AgGridColumn
                                headerName="사번"
                                field="applicant_employee_number"
                                sortable={true}
                                width={90}
                                onCellClicked = {(e) => {
                                    props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                                }}
                            ></AgGridColumn>
                        </AgGridColumn> */}
            <AgGridColumn headerName="">
              <AgGridColumn headerName="첨부파일" field="files" width={120} cellRenderer="cellFilePop"></AgGridColumn>
              <AgGridColumn
                headerName="수정일"
                field="modified_at"
                width={97}
                onCellClicked={e => {
                  props.history.replace(`/applicant/judge/${e.data.id}/detail`);
                }}
              ></AgGridColumn>
            </AgGridColumn>
          </AgGridReact>
        </div>{' '}
        {/*table*/}
        <div className="board_paging">
          {/* <ul>
                        <Pagination
                            offset={store.offset}
                            limit={store.limit}
                            setOffset={store.setOffset}
                            totalPage={store.totalPage}
                        />
                    </ul> */}

          {/*<div className="submit ac">
                        {headerStore.downRoleFlag ?
                            <>
                                <button type="button" className="btn btn_lg btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button>
                            </>
                            :
                            <>
                                <></>
                            </>
                        }
                    </div>*/}
        </div>{' '}
        {/*board_paging*/}
      </div>

      {/* 입숙처리 팝업 */}
      <CheckPopup
        isShowModal={isCheckPopupShow}
        onCloseModalHandler={() => setIsCheckpopupShow(false)}
        onCompleteHander={onCheckinHandler}
        //bookingData={bookingData}
      />

      {/* 반려 팝업 */}
      <Reflect
        isShowReflect={isShowReflect}
        onClose={() => {
          store.selectRow = [];
          setIsShowReflect(false);
        }}
        onSubmit={onReflectSubmitHandler}
        booking={props.booking}
      />

      {/* 기숙사 배방 */}
      <BackroomDormitoryPopup />

      {/* 영빈관 배방 */}
      <VipBackRoom />

      {/* 사택 배방 */}
      <CompanyHouseBackroom />

      {/* 출장자 단체 */}
      <BusinessGroupBackroom />

      {/* 가족숙소 배방 */}
      <FamilyBackroom 
        applicant_cellphone = {store.selectRow.applicant_cellphone}/>

      {/* 출장자 개인 */}
      <BusinessSingleBackroom />

      {/* 입숙예정일 변경 요청 팝업 */}
      <div
        id="pop_change_request"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isShowStayAfterPop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            {store.selectRow.status === BOOKING_STATUS_CODE.documentSubmit ? (
              <h3>증빙서류 변경요청</h3>
            ) : (
              <h3>입숙예정일 변경</h3>
            )}
            <button
              className="pop_btn_close pop_close"
              onClick={() => {
                setIsShowStayAfterPop(false);
                store.statusDescription = '';
              }}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
                <dt>사유</dt>
                <dd>
                  <textarea
                    className="form_control"
                    value={store.statusDescription}
                    onChange={(e: any) => (store.statusDescription = e.target.value)}
                  ></textarea>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
            {store.selectRow.status === BOOKING_STATUS_CODE.documentSubmit ? (
              <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={documentReCheck}>
                적용
              </button>
            ) : (
              <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={rescheduleHandler}>
                적용
              </button>
            )}
          </div>
        </div>
      </div>

      <div id="pop_files" className="pop_dim_wrap pop_modal pop_lg" style={{ display: isFilePop ? 'block' : 'none' }}>
        <div className="pop_wrap">
          <button className="pop_btn_close" onClick={() => setIsFilePop(false)}>
            닫기
          </button>
          <div className="title_wrap">파일첨부 확인</div>

          <div className="contents_wrap">
            {/* <FileForm value={props.value} /> */}
            <div className="table_normal_list">
              <table>
                <caption className="sr_only">입숙 상세</caption>
                <colgroup>
                  <col width="280" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  {!isGroup
                    ? store.viewFiles.map(
                        (file: any, fileIdx: number) =>
                          file?.document && (
                            <tr key={fileIdx}>
                              <th>{file?.document?.name}</th>
                              <td>
                                {headerStore.downRoleFlag ? (
                                  <button 
                                  className="btn item file"
                                  onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${file.file.path}`, '')}
                                  >
                                  {file.file.name}
                                  </button>
                                  /*<a
                                    href={`${process.env.REACT_APP_MEDIA}/${file.file.path}`}
                                    download
                                    target={
                                      `${file.file.path}`.split('.').pop() &&
                                      ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(
                                        `${file.file.path}`.split('.').pop() || '',
                                      ) &&
                                      !isMobile
                                        ? '_blank'
                                        : ''
                                    }
                                  >
                                    {file.file.name}
                                  </a>*/
                                ) : (
                                  <>{file.file.name}</>
                                )}
                              </td>
                            </tr>
                          ),
                      )
                    : store.viewFiles.map(
                        (file: any, fileIdx: number) =>
                          file.document.visible_type === FILE_CODE.RFC && (
                            <tr key={fileIdx}>
                              <th>협조전</th>
                              <td>
                                {headerStore.downRoleFlag ? (
                                  <button 
                                  className="btn item file"
                                  onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${file.file.path}`, '')}
                                  >
                                  {file.file.name}
                                  </button>
                                  /*
                                  <a
                                    href={`${process.env.REACT_APP_MEDIA}/${file.file.path}`}
                                    download
                                    target={
                                      `${file.file.path}`.split('.').pop() &&
                                      ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(
                                        `${file.file.path}`.split('.').pop() || '',
                                      ) &&
                                      !isMobile
                                        ? '_blank'
                                        : ''
                                    }
                                  >
                                    {file.file.name}
                                  </a>
                                  */
                                ) : (
                                  <>{file.file.name}</>
                                )}
                              </td>
                            </tr>
                          ),
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* 다운로드 사유 팝업 */}
      <div
        id="pop_download"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isShowDownloadPop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => {
                setDownloadText('');
                setIsShowDownloadPop(false);
              }}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="process_list">
              <dl>
                <dt>사유</dt>
                <dd>
                  <textarea
                    className="form_control"
                    value={downloadText}
                    onChange={(e: any) => setDownloadText(e.target.value)}
                  ></textarea>
                </dd>
              </dl>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_lg btn_darkblue pop_close"
              onClick={() => {
                xlsDownload();
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </TempleteDefault>
  );
});

export default ApplicantJudge;
