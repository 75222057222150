
import {action, makeObservable, observable} from 'mobx';
import * as apis from '../../apis';
import { parserEscape } from '../../utils/common';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { BoardStore } from './boardStore';
import { HeaderStore } from './headerStore';

export class FaqStore extends BoardStore {
  worksite : any;
  work_site : any ;

  ordering: string = '';

  //loading
  headerStore : HeaderStore;

  constructor(root: any) {
    super(root);
    this.headerStore = new HeaderStore(this);
    makeObservable(this, {
      getItems: action,
      cancelItem: action,
      newItem: action,
      selectedItem: action,
      isChange: action,
      saveItem: action,
      deleteItem: action,
      deleteItems: action,
      
      worksite : observable,
      work_site : observable,
      ordering : observable,
      
    });
  }

  init () {
    this.category = -1;
    this.limit = 10;
    this.offset = 0;
    this.totalPage = 0;
    this.items = [];
    this.checkeds = {};
    this.item = {};
  }

  async getItem(id: string) {
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.faq.getFaq({ id });
      if (data) {
        this.item = data;
      }
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async getItems() {
    // console.log('getItems: ', this.category, this.limit, this.offset);
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      work_site : this.work_site,
      ordering : this.ordering
    };

    if (this.category > 0) {
      filter['category'] = this.category;
    }

    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.faq.getFaqs(filter);
      this.items = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;

      // return {
      //   'save' : true,
      //   'status' : 200,
      //   'data' : ''
      // };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        // return {
        //   'save' : false,
        //   'status' : status,
        //   'data' : data
        // };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        // return {
        //   'save' : false,
        //   'status' : status,
        //   'data' : data
        // };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        // return {
        //   'save' : false,
        //   'status' : status,
        //   'data' : data
        // };
      }

      // return {
      //   'save' : false,
      //   'status' : status,
      //   'data' : data
      // };
    }
  }

  cancelItem() {
    this.item = { ...this.orgItem };
    this.orgItem = {};
  }

  newItem() {
    this.item = {
      id: -1,
      category: 1,
      author_name: this.user.username,
      author_employee_number: this.user.employeeNumber,
      title: '',
      contents: '',
      images: [],
      files: [],
      created_at: new Date(),
    };
    this.orgItem = { ...this.item };
  }

  selectedItem(item: any) {
    this.item = item;
    this.orgItem = { ...item };
  }

  isChange() {
    return JSON.stringify(this.orgItem) !== JSON.stringify(this.item)
  }

  async saveItem() {
    // console.log('FaqStore.saveItem:', this.item);
    const data: any = {
      category: this.item.category,
      author_name: this.item.author_name,
      title: this.item.title,
      contents: parserEscape(this.item.contents),
      work_site : this.work_site,
     };
    data.images = this.item.images.map((image: any) => image.id);
    data.files = this.item.files.map((file: any) => file.id);

    try{
      this.headerStore.isLoading = true;
      if (this.item.id < 0) {
        await apis.faq.createFaq(data);
      } else {
        await apis.faq.updateFaq(this.item.id, data);
      }
      this.selectedItem({});
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  async deleteItem() {
    // console.log('FaqStore.deleteItem:', this.item);
    if (this.item) {
      try{
        this.headerStore.isLoading = true;
        await apis.faq.deleteFaq(this.item.id);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }
  
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }

  async deleteItems() {
    const keys = Object.keys(this.checkeds);
    if (keys.length > 0) {
      try{
        this.headerStore.isLoading = true;
        const actions = Object.keys(this.checkeds).map((checkKey: string) => apis.faq.deleteFaq(checkKey));
        await Promise.all(actions);
        this.headerStore.isLoading = false;

        return {
          'save' : true,
          'status' : 200,
          'data' : ''
        };
      }catch ({ status, data }) {
        this.headerStore.isLoading = false;
        if (status === 401) {
          if (!window.localStorage.getItem("isRetryLogin")) {
            window.localStorage.setItem("isRetryLogin", "retry");
          }
          window.location.href = '/';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_ALERT_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return {
            'save' : false,
            'status' : status,
            'data' : data
          };
        }
  
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }
    }
  }
}
