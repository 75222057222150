const initialState: any = {
  id: 0,
  username: '',
  gender: 1, // 남자(1)/여자(2)
  phone: '',
  work_site: 0, 
  employeeNumber: "",
  is_naver_connected: false,
  is_kakao_connected: false,
  is_google_connected: false,
};

export const USERINFO_REQUEST = 'USER/USERINFO_REQUEST'; // 유저정보 요청
export const USERINFO_COMPLETE = 'USER/USERINFO_COMPLETE'; // 유저정보 요청성공
export const USERINFO_FAIL = 'USER/USERINFO_FAIL'; // 유저정보 요청실패

export const USERINFO_CLEAR = 'USER/USERINFO_CLEAR';

type UserAction = any;

function userReducer(state: any = initialState, action: UserAction) {
  switch (action.type) {
    case USERINFO_COMPLETE:
      const { payload } = action;
      const user = {
        ...state,
        phone: payload.cellphone,
        email: payload.email,
        username: payload.name,
        employeeNumber: payload.employee_number,
        work_site: payload.work_site,
        duty: payload.duty,
        company: payload.company,
        birthdate: payload.birthdate,
        authorized: payload.authorized,
        classification: payload.classification,
        assigned_task: payload.assigned_task,
        loginHistory: payload.last_before_login_history
      };
      window.sessionStorage.setItem('user', JSON.stringify(user));
      return user;

    case USERINFO_CLEAR:
      window.sessionStorage.removeItem('user');
      return {
        ...state,
        id: '',
        username: '',
        is_naver_connected: false,
        is_kakao_connected: false,
        is_google_connected: false,
      };
    default:
      return state;
  }
}

export default userReducer;
