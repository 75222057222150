import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useStores } from '../../../store/mobx';
import { observer } from 'mobx-react';

import LodgedTotal from '..';

import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../routes';
import { LODGE_STATUS_CODE, ROOM_TYPE } from '../../../constants';
import Pagination from '../../../components/organismas/pagination';
import { showAlert, errorAlert, showConfirm } from '../../../utils/common';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../store/modules/modal';
import { getYear10 } from '../../../utils/date';
import { isNull } from 'lodash';
import Core from '../../../apis/core';
import FileSaver from 'file-saver';


const LodgedCheckIn = observer((props: any) => {
    const dispatch = useDispatch();
    const { loadgedTotalStore : store } = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const {headerStore: headerStore} = useStores();

    const [isShowDownloadPop, setIsShowDownloadPop] = useState<boolean>(false);
    const [downloadText, setDownloadText] = useState<string>("");
    const [room_types, setRoom_types] = useState<any[]>([]);
    //rendering시 리스트 뽑아오기
    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                headerStore.getAuthMenuRole('2-3');
                headerStore.getAuthBuilding();
                headerStore.getAuthRoomRole();
                setRoom_types(headerStore.paramAuthRoomTypeRole.split(","));
                store.work_site = headerStore.workSite;
                store.roomtype = headerStore.paramAuthRoomTypeRole;
                if(Number(headerStore.workSite) === 2) {
                    if(!isNull(headerStore.paramAuthBuildingRole) && headerStore.paramAuthBuildingRole !== 'null' && headerStore.paramAuthBuildingRole.length > 0) {
                        store.building = headerStore.paramAuthBuildingRole;
                    }
                }
                store.option = '';
                store.text = '';
                await store.getCheckInList();
                headerStore.isLoading = false;
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                info("문제가 발생했습니다");
                return;
            }
        })();
        return () => {
            if (store.findTabs('/lodged/type') < 0) {
                store.init();
            }
        }
    },[headerStore.workSite, store.selectRoomType]);

    const onCheckedByAll = (e: any) => store.checkAll(e.target.checked);

    const onCheckedHandler = (e: any, item: any) => store.checkItem(e.target.checked, item);

    /*const onDownloanHandler = async () => {
        console.log(store.checkeds);
        if(Object.keys(store.checkeds).length <= 0){
            showAlert('선택하신 체크 항목이 없습니다. \n 다운받으실 행을 선택해주세요', dispatch);
            return;
        }

        try {

            dispatch({
                type: SHOW_CONFIRM_MODAL,
                payload: {
                    isModalShow: true,
                    title: '확인',
                    msg: '다운로드 하시겠습니까?',
                    action: async () => {
                        try {
                            await store.downloadCheckIn();
                        } catch ({ status, data }) {
                            errorHandler({ status, data }, '다운로드 중 오류발생');
                            errorAlert("다운로드 중 문제가 발생했습니다.", dispatch);
                        }
                    },
                }
            });
        } catch (e) {
            console.log(e);
            errorAlert("다운로드 중 문제가 발생했습니다.", dispatch);
        }
    }*/

    // 다운로드 
    const xlsDownload = async()=>{
        try {
            headerStore.isLoading = true;
            store.getCurrentDateRange();
            store.status = [LODGE_STATUS_CODE.waiting, LODGE_STATUS_CODE.checkin].join(',');
        
            const payload: any = {
                employee_number : userInfo.employeeNumber,
                reason : downloadText,
                status : store.status,
                checkin_after : store.dateAfter,
                checkin_before : store.dateBefore,
                worksite : store.work_site,
                roomtype : store.roomtype,
            }
            if(Number(store.work_site) === 2) {
                if(!isNull(store.building) && store.building !== undefined && store.building.length > 0) {
                    payload.authBuilding = store.building;
                }
            }

            let { data } = await Core.xlsgeustlist2(payload);
            if(data) {
                FileSaver.saveAs(`${process.env.REACT_APP_MEDIA}`+data);
            }
            setIsShowDownloadPop(false);
            setDownloadText('');
            headerStore.isLoading = false;
        }catch ({status, data}){
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                  window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_ALERT_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }
            info("문제가 발생했습니다");
                return;
            // console.log(status, data);
        }
    }

    const onSearchHeandler = (e: any) => {
        store.getCheckInList();
    }


    return (
        <LodgedTotal
            id=""
            className=""
            isLoading={headerStore.isLoading}
            {...props}
        >
        <h4>입숙 설정</h4>
        <div className="table_modify_list">
            <dl className="info">
                <dt>정보</dt>
                <dd>
                    <select name="" id="select_sex" className="form_control"
                        value={store.selectRoomType}
                        onChange={(e:any) => store.selectRoomType = e.target.value}
                    >
                        <option value="0">숙소유형</option>
                        {room_types.map((item: any, index: number) => {
                            if(Number(item) !== 0 && Number(item) !== 7) {
                                return (<option key={index} value={item}>{ ROOM_TYPE[item] }</option>);
                            }    
                        })}
                    </select>
                    <select className="form_control ml10" value={store.option} onChange={(e: any) => store.option = e.target.value} >
                        <option value="guest_name">성명</option>
                        <option value="guest_employee_number">사번</option>
                    </select>
                    <input type="text"
                        className="form_control"
                        value={store.text}
                        onChange={(e: any) => store.text = e.target.value}
                        onKeyDown={(e: any) => {
                            if (e.keyCode === 13) onSearchHeandler(e);
                        }} 
                    />
                </dd>
            </dl>
            <dl className="info">
                <dt>조회기간</dt>
                <dd>
                    <select className="form_control year" value={store.currentYear} 
                        onChange={(e:any) => {store.currentYear = parseInt(e.target.value); store.getCurrentDateRange(); store.getCheckInList()}}
                    >
                        {getYear10().map((year:any, index: number) => (
                            <option key={index} value={year}>{year}</option>
                        ))}
                    </select>
                    <select className="form_control month ml10 mr10" value={store.currentMonth} 
                        onChange={(e:any) => {store.currentMonth = parseInt(e.target.value); store.getCurrentDateRange(); store.getCheckInList()}}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                    </select>
                    <button type="button" className="btn btn_sm btn_gray" onClick={onSearchHeandler}>검색</button>
                </dd>
            </dl>
        </div>

        <h4>입숙 리스트
            <div className="right_area">
                {headerStore.downRoleFlag ? <button className="btn btn_sm btn_darkblue" onClick={()=>{setIsShowDownloadPop(true);}}>다운로드</button> : <></>}
            </div>
        </h4>

        <div className="table_normal_list">
            <table>
                <caption className="sr_only">입숙 리스트</caption>
                <colgroup>
                    {/*<col width="60px"/>*/}
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                </colgroup>
                <thead>
                <tr>
                    {/*<th>
                        <label className="input_check pop_notext">
                            <input type="checkbox" id="" name="" 
                                onChange={onCheckedByAll}
                                checked={store.allCheckFlg}
                            />
                            <span className="label_text"></span>
                        </label>
                    </th>*/}
                    <th>숙소유형</th>
                    <th>사번</th>
                    <th>성명</th>
                    <th>휴대폰번호</th>
                    <th>건물명</th>
                    <th>숙소정보(동호수)</th>
                    <th>입숙일</th>
                    <th>만료일</th>
                    <th>비고</th>
                </tr>
                </thead>
                <tbody>
                {store.list.length <= 0 ? (
                    <tr key="0">
                        <td colSpan={9} className="blank_area type_white">
                            <p className="txt_blank">데이터가 없습니다.</p>
                        </td>
                    </tr>
                ) : (
                store.list.map((item, idx) => (
                    <tr key={idx}>
                        {/*<td>
                            <label className="input_check pop_notext">
                                <input type="checkbox"
                                    checked={store.checkeds[item.id] ? true : false}
                                    onChange={(e: any) => onCheckedHandler(e, item)}
                                />
                                <span className="label_text"></span>
                            </label>
                        </td>*/}
                        <td>{ROOM_TYPE[item.room_type]}</td>
                        <td>{item.guest_employee_number}</td>
                        <td>{item.guest_name}</td>
                        <td>{item.guest_cellphone}</td>
                        <td>{item.room_building_name}</td>
                        <td>{item.room_building_dong} {item.room_name}</td>
                        <td>
                            {item.checkin_at ? item.checkin_at : (<>{item.stay_after}<br/>(입숙예정일)</>)}
                        </td>
                        {/* <td>{item.checkout_at ? item.checkout_at : (<>{item.stay_before}<br/>(퇴숙예정일)</>)}</td> */}
                        {Number(headerStore.workSite) === 1 && ROOM_TYPE[item.room_type] === '기숙사' ? (<td></td>) : <td>{item.expired_at}</td>}
                        <td><span className="txt_ellipsis">{item.memo}</span></td>
                    </tr>
                ))
                )}
                </tbody>
            </table>
        </div>  {/*table*/}

        <div className="board_paging">
            <ul>
            <Pagination
                offset={store.offset}
                limit={store.limit}
                totalPage={store.totalPage}
                setOffset={(offset: number) => store.setOffset(offset)} />
            </ul>
        </div>   {/*board_paging*/}

        {/*<div className="submit ac">
            {headerStore.downRoleFlag ? <button className="btn btn_lg btn_darkblue" onClick={onDownloanHandler}>다운로드</button> : <></>}
                    </div>*/}

        {/* 다운로드 사유 팝업 */}
        <div id="pop_download" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowDownloadPop? "block": "none" }}>
        <div className="pop_wrap">
            <div className="title_wrap">
            <h3>다운로드 사유</h3>
            <button className="pop_btn_close pop_close" onClick={() => {setDownloadText(''); setIsShowDownloadPop(false);}}>닫기</button>
            </div>
            <div className="contents_wrap">
            <div className="process_list">
                <dl>
                <dt>사유</dt>
                <dd>
                    <textarea className="form_control"
                    value={downloadText}
                    onChange={(e: any) => setDownloadText(e.target.value)}
                    >
                    </textarea>
                </dd>
                </dl>
            </div>
            </div>
            <div className="button_wrap btn_length_1">
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={()=>{xlsDownload();}}>확인</button>
            </div>
        </div>
        </div>
        </LodgedTotal>
        
        
    )
});

export default LodgedCheckIn;