import React, {useState} from 'react';

import DefaultTemplate from '../../components/templetes/default';
import './index.scss';

const InfoPage = (props: any) => {
  
  const [isPass, setIsPass] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const onSetPass = (isPass: boolean) => {
    setIsPass(isPass);
  };

  const AuthCheck = (props: any) => {
    return (
      <div className="inner">
        <h4 className="mt0">본인확인</h4>
        <div className="table_join_list pb20">
          <dl className="col fl w100">
            <dt>비밀번호</dt>
            <dd>
              <div className="form_wrap">
                <input type="text" className="form_control form_lg" value="비밀번호를 입력해주세요"/>
                <button className="btn btn_md btn_darkgray" onClick={() => onSetPass(true)}>완료</button>
              </div>
              <p className="txt_help">
                  본인 확인을 위해 비밀번호를 한 번 더 입력해 주세요.
              </p>
            </dd>
          </dl>
        </div>
      </div>
    );
  };

  const BasicInfo = (props: any) => {
    return (
      <div className="inner">
      <div className="user_area">
        <span className="user_name">기본정보</span>
        <em>홍길동</em>
        <p className="txt_essential">성명과 사번(아이디)는 수정이 불가능 하나, 나머지 항목은 수정 가능합니다.</p>
      </div>
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">정보확인</caption>
          <colgroup>
            <col width="130"/>
            <col width="*"/>
            <col width="130"/>
            <col width="*"/>
            <col width="130"/>
            <col width="*"/>
          </colgroup>
          <tbody>
            <tr>
              <th>아이디</th>
              <td className="disabled">56102555</td>
              <th>생년월일</th>
              <td><input type="text" className="form_control" value="890125"/></td>
              <th>성별</th>
              <td>
                <label className="input_radio" htmlFor="sex_user_choice1">
                  <input type="radio" id="sex_user_choice1" name="sex"/>
                  <span className="label_text">남자</span>
                </label>
                <label className="input_radio" htmlFor="sex_user_choice2">
                  <input type="radio" id="sex_user_choice2" name="sex" />
                  <span className="label_text">여자</span>
                </label>
              </td>
            </tr>
            <tr>
              <th>소속회사</th>
              <td>
                <select className="form_control">
                  <option value="">무슨관리회사</option>
                  <option value=""></option>
                  <option value=""></option>
                </select>
              </td>
              <th>근무지</th>
              <td><input type="text" className="form_control" value="남양"/></td>
              <th>담당업무</th>
              <td><input type="text" className="form_control"/></td>
            </tr>
          </tbody>
        </table>
      </div>
      { !isEdit ? (<AddInfo />) : (<AddInfoEdit />)}
      <div className="submit ac">
        { isEdit ? (
        <>
          <button type="button" className="btn_md btn_darkblue btn_outline" onClick={() => setIsEdit(false)}>취소</button>
          <button type="button" className="btn_md btn_darkblue">확인</button>
        </>
        ) : (
        <>
          <button type="button" className="btn_md btn_darkblue btn_outline" onClick={() => setIsEdit(true)}>수정</button>
          <button type="button" className="btn_md btn_darkblue">확인</button>
        </>
        )}
      </div>
    </div>
    );
  };

  const AddInfo = () => {
    return (
      <>
        <h4>추가정보</h4>
        <div className="table_join_list">
          <dl className="col fl w100">
            <dt>비밀번호</dt>
            <dd>
              <input type="text" className="form_control form_lg" value="********asd!132*" disabled/>
              <p className="txt_help">
                  마우스 호버 시 비밀번호 전체를 확인하실 수 있습니다
              </p>
            </dd>
          </dl>
          <dl className="col fl">
            <dt>휴대폰 번호</dt>
            <dd>
              <input type="text" className="form_control form_lg" value="01012345678" disabled/>
            </dd>
          </dl>
          <dl className="col fr">
            <dt>이메일</dt>
            <dd>
              <input type="text" className="form_control form_lg" value="fdsafdf@fasdfsd.com" disabled/>
            </dd>
          </dl>
        </div>
      </>
    );
  };

  const AddInfoEdit = () => {
    return (
      <>
        <h4>추가정보 변경</h4>
        <div className="table_join_list">
          <dl className="col fl">
            <dt>비밀번호</dt>
            <dd>
              <input type="text" className="form_control form_lg" placeholder="비밀번호 변경이 필요할 시 입력해주세요."/>
              <p className="txt_help">
                  ※ 비밀번호는 8~20자로 영문 , 특수문자, 숫자 중 세 가지를 조합하여 입력해주세요. 영문 대문자와 소문자를 구별해주세요
              </p>
            </dd>
            <dt>휴대폰 번호</dt>
            <dd>
              <div className="form_wrap">
                <input type="text" className="form_control form_lg" value="01012345678"/>
                <button className="btn btn_md btn_darkgray">인증하기</button>
              </div>
              <p className="txt_help">
                  ※ 본인의 휴대폰 번호가 아닌 타인의 휴대폰 번호를 입력할 경우 인사상 불이익을 당할 수 있습니다
              </p>
            </dd>
          </dl>
            <dl className="col fr">
              <dt>비밀번호 확인</dt>
              <dd><input type="text" className="form_control form_lg" placeholder="비밀번호를 다시 한번 입력해주세요"/></dd>
              <dt>인증번호</dt>
              <dd>
                <div className="form_wrap">
                  <input type="text" className="form_control form_lg" placeholder="인증번호를 입력해주세요"/>
                  <button className="btn btn_md btn_darkgray">인증하기</button>
                </div>
              </dd>
            </dl>
            <dl className="fl">
              <dt>이메일</dt>
              <dd>
                <div className="form_wrap">
                  <ul>
                    <li><input type="text" className="form_control form_lg" value="hong1234"/></li>
                    <li className="dash">@</li>
                    <li><input type="text" className="form_control form_lg" value="naver.com"/></li>
                    <li>
                      <input type="text" className="form_control form_lg" placeholder="직접입력"/>
                      {/* <select className="form_control form_lg">
                          <option value=""></option>
                      </select> */}
                    </li>
                  </ul>
                </div>
              </dd>
            </dl>
        </div>
      </>
    );
  };

  const CurrContent = () => {
    if (!isPass) {
      return <AuthCheck />;
    } else {
      return <BasicInfo />;
    }
  };

  return (
    <DefaultTemplate
      {...props}
      id="wrap"
      className=""
    >
      <CurrContent />
    </DefaultTemplate>
  );
};

export default InfoPage;
