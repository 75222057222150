import React, { useContext, useEffect, useRef, useState } from 'react';
import { auth } from '../../../apis';
import { MessageContext } from '../../../routes';
import { getEscapeString, INPUT_TYPE } from '../../../utils/escapestring';
import './index.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';

const MobileSignIn = observer((props: any) => {
    const {headerStore: headerStore} = useStores();
    const { info } = useContext(MessageContext);
    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [number, setNumber] = useState<string>("");

    const [verifyToken, setVerifyToken] = useState<string>("");
    const [employeeNumber, setEmployeeNumber] = useState<string>("");

    const ref = useRef<any>(null);

    const init = () => {
        setName('');
        setPhone('');
        setNumber('');
        setVerifyToken('');
        setEmployeeNumber('');
    }

    // 인증하기 버튼 클릭
    const onClickVerifySendHandler = async () => {
        if (!name || name === '') {
            info("성명을 확인해주세요.");
            return;
        }

        if (phone.length !== 11) {
            info("휴대폰 번호를 확인해주세요.");
            return;
        }
        try {
            headerStore.isLoading = true;
            let { data } = await auth.findIdAuth({
                name,
                cellphone: phone
            })

            info("인증번호를 발송했습니다.");
            setVerifyToken(data.token);
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            // console.log(status, data);
            info("성명 또는 휴대폰 번호를 확인해주세요")
            return;
        }
    }

    // 확인버튼 클릭
    const onClickVerifyHandler = async () => {
        if (!verifyToken) {
            info("인증번호를 발송하지 않았습니다.");
            return
        }
        if (number.length !== 6) {
            info("인증번호가 잘못되었습니다.");
            return
        }
        try {
            headerStore.isLoading = true;
            let { data } = await auth.findIdVerify({
                name,
                number,
                token: verifyToken,
                cellphone: phone,
            })
            //ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            info("아이디 찾기를 완료했습니다.");
            setEmployeeNumber(data.employee_number);
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            // console.log(status, data);
            if (status === 404) {
                info("가입하지 않는 사용자입니다.")
                return;
            } else if (status === 400) {
                if (data?.code === "invalid_number") {
                    info("인증번호가 잘못되었습니다.");
                    return;
                }
            }
        }
    }

    if (!props.isShowSearchId) return <></>;

    return (
        <>
            {/* 아이디 찾기 팝업 */}
            <div id="pop_srch_id" className="pop_modal" style={{ display: props.isShowSearchId ? 'block' : 'none' }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>아이디 찾기</h3>
                        <button className="pop_btn_close pop_close" onClick={() => { init(); props.onCloseSearchIdPopupHandler(); }}>닫기</button>
                    </div>
                    <div className="contents_wrap">

                        <div className="form_write">
                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_username" className="title_form">성명</label>
                                    <input type="text" id="input_username"
                                        className="form_control form_sm"
                                        placeholder="성명을 입력해주세요"
                                        value={name}
                                        onChange={(e: any) => setName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_phone_forid" className="title_form">휴대폰 인증</label>
                                    <div className="form_phone">
                                        <input type="text"
                                            id="input_phone_forid"
                                            className="form_control form_sm"
                                            placeholder="숫자만 입력해주세요"
                                            maxLength={11}
                                            value={phone}
                                            onChange={(e: any) => {
                                                e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum);
                                                setPhone(e.target.value);
                                            }}
                                        />
                                        <button className="btn btn_sm btn_gray" onClick={onClickVerifySendHandler}>인증하기</button>
                                        {/*<button className="btn btn_lg btn_gray">재전송</button>*/}
                                    </div>
                                </div>
                            </div>

                            <ul className="lst_warning">
                                <li>회원가입 시 입력한 휴대폰 번호를 입력해주세요</li>
                            </ul>

                            <div className="row">
                                <div className="col w100">
                                    <label htmlFor="input_cnum_forid" className="title_form">인증번호</label>
                                    <p className="form_phone">
                                        <input type="text"
                                            id="input_cnum_forid"
                                            className="form_control form_sm"
                                            placeholder="인증번호를 입력해주세요"
                                            maxLength={6}
                                            value={number}
                                            onChange={(e) => {
                                                e.target.value = getEscapeString(e.target.value, INPUT_TYPE.phoneNum);
                                                setNumber(e.target.value);
                                            }}
                                        />
                                        <button className="btn btn_sm btn_gray" onClick={onClickVerifyHandler}>확인</button>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr />

                        {/*아이디 찾기 결과*/}
                        <dl className="lst_basicinfo">
                            <dt>아이디 찾기 결과</dt>
                            <dd>
                                <ul>
                                    <li>
                                        <strong>{employeeNumber? '아이디' :''}</strong>
                                        <span>{employeeNumber}</span>
                                    </li>
                                </ul>
                            </dd>
                        </dl>

                    </div>
                    <div className="button_wrap">
                        <button type="button" className="btn btn_darkblue pop_close"
                            onClick={(e: any) => { init(); props.onCloseSearchIdPopupHandler(e) }}
                        >완료</button>
                    </div>
                </div>
            </div>
        </>
    );
});

export default MobileSignIn;
