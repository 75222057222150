import React, { useState, useEffect } from 'react';

import {CalendarComponent} from '@syncfusion/ej2-react-calendars';
import {L10n} from '@syncfusion/ej2-base';
import { formatByString_YYYYMMDD, getTodayDate } from '../../../utils/date';
import { format } from 'date-fns';
import { es, ko } from 'date-fns/locale';
//import './index.scss';

interface Props {
  onChange: Function
  value?: string
  min?: string
  max?: string
  width?: number
  disableDates?: any[]
  isPrevMonthView?: boolean
  isNextMonthNotView? : boolean
}
L10n.load({
  'en' : {
    'calendar':{today : '오늘'}
  }

});
const show = []

const CustomCalendar = (props: Props) => {
const [isDatePickerShow, setIsDatePickerShow] = useState<boolean>(false);

//달력 닫게하지 않기위해
const consoles = (e : any)=> {
    if(e.target.element.className === "e-control e-calendar e-lib e-keyboard"){
        setIsDatePickerShow(false);
    }   
}

//
window.onclick = (e : any) => {
    
    if(e.target.className === "e-day") {
      var title = e.target.title;
      var arrTitle =title.split(",");

      if(arrTitle.length >= 3) {
        var yyyy = arrTitle[2].replace(" ", "");
        var arrMMDD = arrTitle[1];
        arrMMDD = arrMMDD.replace(" ", "");
        arrMMDD = arrMMDD.replace(" ", "");
        arrMMDD = arrMMDD.replace("월", "-");
        var mmdd = arrMMDD.split("-");
        var mm = mmdd[0];
        var dd = mmdd[1];
        if(mm.length ===1) mm = "0"+mm;
        if(dd && dd.length === 1) {
          dd = "0"+dd;
        }
        
        let setDate = document.getElementById("setDate");
        
        if(setDate?.getAttribute("value") === yyyy + "-" + mm + "-" +dd) {
          setIsDatePickerShow(false);
        }
      }
    }

    if(e.target.id !== 'setDate' && e.target.className !== 'e-day e-title' 
        && e.target.className !== 'e-header e-decade' && e.target.className !== 'e-day' && e.target.className !== 'e-date-icon-prev e-icons' && e.target.className !== 'e-date-icon-next  e-icons') {
      setIsDatePickerShow(false);
    }
}

  return (
    <div style={{ position: 'relative' }} >
      <input
        id="setDate"
        value={props.value}
        className="form_control datepicker"
        autoComplete="off"
        onClick={() => setIsDatePickerShow(!isDatePickerShow)}
        readOnly={true}
      />
      <div id="calendarSet_wrap"
        style={{
          width: `${props.width}px`,
          display: isDatePickerShow ? "block" : "none",
        }}
      >
        <button className="calendarSet_btn_close" onClick={() => setIsDatePickerShow(false)}>닫기</button>
        <CalendarComponent
            id="calendarSet"
            locale='en'
            dayHeaderFormat="Short"
            max={props.max? new Date(props.max) : new Date(2099, 11, 31)}
            min={props.min? new Date(props.min) : new Date(1900, 1, 1)}
            value={new Date(props.value? props.value:'')}
            onChange={(date:any) => {
                consoles(date);
                props.onChange(''+format(date.value, 'yyyy-MM-dd'));
            }}
        ></CalendarComponent>
      </div>
    </div>
  );
};

export default CustomCalendar;