import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import DefaultTemplate from '../../components/templetes/default';
import { useStores } from '../../store/mobx';
import { ROOM_TYPE, ROOM_TYPE_CODE } from '../../constants';
import DormitoryMultiType from './type/dormitoryMultiType';
import BusinessRoomGroupType from './type/businessRoomGroupType';
import BusinessRoomType from './type/businessRoomType';

import FamilyType from './type/familyType';
import DormitoryType from './type/dormitoryType';
import CompanyType from './type/companyType';
import VipType from './type/vipType';

const LodgedInsert = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  if (props.match.path !== '/guest/history/:id/insert') return <></>;

  useEffect(() => {
    if(!headerStore.paramAuthRoomTypeRole) {
      props.history.replace(`/guest/history`);
      return;
    }

    return () => {
      if (store.findTabs('/guest/history/:id/insert') < 0) {
        store.destory();
        store.insertStore.items = [];
        store.insertStore.item = [];
      }
    }
  }, []);

  const {lodgedHistoryStore: store} = useStores();

  const baseUrl = '/guest/history';
  const goBack = () => props.history.replace(baseUrl);

  store.insertRoomType = Number(props.match.params.id);
  store.insertStore.newItem(store.insertRoomType);

  if (store.insertRoomType < 0) {
    goBack();
  }

  if(!headerStore.paramAuthRoomTypeRole) return <></>;

  switch(store.insertRoomType) {
    case ROOM_TYPE_CODE.dormitory_room:
      if (store.isMultiType) {
        return <DormitoryMultiType {...props} />;
      } else {
        return <DormitoryType {...props} />;
      }
    case ROOM_TYPE_CODE.business_room_group:
      return <BusinessRoomGroupType {...props} />;
    case ROOM_TYPE_CODE.business_room:
      return <BusinessRoomType {...props} />;
    case ROOM_TYPE_CODE.family_room:
      return <FamilyType {...props} />;
    case ROOM_TYPE_CODE.company_room:
      return <CompanyType {...props} />;
    case ROOM_TYPE_CODE.vip_room:
      return <VipType {...props} />;
    default:
      return <></>;
  }
});

export default LodgedInsert;
