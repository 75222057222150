import React, { useCallback, useState } from 'react';
import DefaultTemplate from '../../components/templetes/default';

const EstablishmentBasicInfoPage = (props: any) => {
  const [useAddInfo, setUseAddInfo] = useState(false);
  const addInfo = useCallback(() => {
    setUseAddInfo(!useAddInfo);
  }, []);

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      {...props}
    >
      <div className="inner">
        <h4 className="mt0">사업장 기본정보 입력</h4>

        <div className="table_normal_list table_write">
          <table>
            <caption className="sr_only"></caption>
            <colgroup>
              <col width="130" />
              <col width="*" />
              <col width="130" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>사업장</th>
                <td>
                  <select name="" id="" className="form_control">
                    <option value=""></option>
                  </select>
                </td>
                <th>건물명</th>
                <td><input type="text" className="form_control" /></td>
              </tr>
              <tr>
                <th>동</th>
                <td><input type="text" className="form_control" /></td>
                <th>총 층 수</th>
                <td><input type="text" className="form_control" /></td>
              </tr>
              <tr>
                <th>연면적</th>
                <td><input type="text" className="form_control" /></td>
                <th>대지면적</th>
                <td><input type="text" className="form_control" /></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="submit ar">
          {/*<button type="button" className="btn_md btn_darkblue btn_outline">취소</button>*/}
          <button type="button" className="btn_md btn_gray" onClick={addInfo}>추가입력</button>
        </div>

        <div className="table_normal_list table_write mt30"
          style={{
            display: useAddInfo ? '' : 'none'
          }}
        >
          <table>
            <caption className="sr_only"></caption>
            <colgroup>
              <col width="130" />
              <col width="*" />
              <col width="130" />
              <col width="*" />
              <col width="130" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>층</th>
                <td>
                  <select name="" id="" className="form_control">
                    <option value="">전체</option>
                  </select>
                </td>
                <th>세대수</th>
                <td><input type="text" className="form_control" /></td>
                <th>기본유형</th>
                <td>
                  <select name="" id="" className="form_control">
                    <option value="">기숙사</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="submit ar">
          {/*<button type="button" className="btn_md btn_darkblue btn_outline">취소</button>*/}
          <button type="button" className="btn_md btn_gray">완료</button>
        </div>

        <h4 className="">
          사업장 기본정보 리스트
                            <div className="right_area">
            <select name="" id="" className="form_control">
              <option value="">사업장선택</option>
              <option value="">남양</option>
              <option value="">울산</option>
              <option value="">아산</option>
              <option value="">전주</option>
              <option value="">마북</option>
            </select>
            <select name="" id="" className="form_control">
              <option value="">10</option>
            </select>
          </div>
        </h4>

        <div className="table_normal_list event_on box_scroll_x mt30">
          <table>
            <caption className="sr_only">퇴숙 목록</caption>
            <colgroup>
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="5%" />
              <col width="5%" />
              <col width="5%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <label className="input_check pop_notext">
                    <input type="checkbox" id="" name="" />
                    <span className="label_text"></span>
                  </label>
                </th>
                <th>코드</th>
                <th>기본유형</th>
                <th>건물명</th>
                <th>동</th>
                <th>층</th>
                <th>세대수</th>
                <th>작성자</th>
                <th>작성일</th>
                <th>수정일</th>
              </tr>
            </thead>
            <tbody>
              {/*데이타 없을 시*/}
              {/*<tr>
                                        <td colspan="10" class="blank_area type_white">
                                            <p class="txt_blank">데이터가 없습니다.</p>
                                        </td>
                                    </tr>*/}

              <tr>
                <td>
                  <label className="input_check pop_notext">
                    <input type="checkbox" id="" name="" />
                    <span className="label_text"></span>
                  </label>
                </td>
                <td>N001002</td>
                <td>기숙사</td>
                <td>뉴타운</td>
                <td>101</td>
                <td>2</td>
                <td>21</td>
                <td>김현대</td>
                <td>2019.06.07</td>
                <td>2019.06.07</td>
              </tr>
              <tr>
                <td>
                  <label className="input_check pop_notext">
                    <input type="checkbox" id="" name="" />
                    <span className="label_text"></span>
                  </label>
                </td>
                <td>N001002</td>
                <td>기숙사</td>
                <td>뉴타운</td>
                <td>101</td>
                <td>2</td>
                <td>21</td>
                <td>김현대</td>
                <td>2019.06.07</td>
                <td>2019.06.07</td>
              </tr>
              <tr>
                <td>
                  <label className="input_check pop_notext">
                    <input type="checkbox" id="" name="" />
                    <span className="label_text"></span>
                  </label>
                </td>
                <td>N001002</td>
                <td>기숙사</td>
                <td>뉴타운</td>
                <td>101</td>
                <td>2</td>
                <td>21</td>
                <td>김현대</td>
                <td>2019.06.07</td>
                <td>2019.06.07</td>
              </tr>
              <tr>
                <td>
                  <label className="input_check pop_notext">
                    <input type="checkbox" id="" name="" />
                    <span className="label_text"></span>
                  </label>
                </td>
                <td>N001002</td>
                <td>기숙사</td>
                <td>뉴타운</td>
                <td>101</td>
                <td>2</td>
                <td>21</td>
                <td>김현대</td>
                <td>2019.06.07</td>
                <td>2019.06.07</td>
              </tr>
              <tr>
                <td>
                  <label className="input_check pop_notext">
                    <input type="checkbox" id="" name="" />
                    <span className="label_text"></span>
                  </label>
                </td>
                <td>N001002</td>
                <td>기숙사</td>
                <td>뉴타운</td>
                <td>101</td>
                <td>2</td>
                <td>21</td>
                <td>김현대</td>
                <td>2019.06.07</td>
                <td>2019.06.07</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*table*/}

        <div className="board_paging">
          <ul>
            {/*<Pagination offset={offset} limit={limit} setOffset={setOffset} totalPage={totalPage} />*/}
            {/*<li><a href="javascript:void(0);" className="paging_first">paging_first</a></li>*/}
            <li><a href="javascript:void(0);" className="paging_prev">paging_prev</a></li>
            <li><a href="javascript:void(0);" className="active">1</a></li>
            <li><a href="javascript:void(0);">2</a></li>
            <li><a href="javascript:void(0);">3</a></li>
            <li><a href="javascript:void(0);">4</a></li>
            <li><a href="javascript:void(0);">5</a></li>
            <li><a href="javascript:void(0);">6</a></li>
            <li><a href="javascript:void(0);">7</a></li>
            <li><a href="javascript:void(0);">8</a></li>
            <li><a href="javascript:void(0);">9</a></li>
            <li><a href="javascript:void(0);">10</a></li>
            <li><a href="javascript:void(0);" className="paging_next">paging_next</a></li>
            {/*<li><a href="javascript:void(0);" className="paging_last">paging_last</a></li>*/}
          </ul>
        </div>
        {/*board_paging*/}
        <div className="submit ar">
          <button type="button" className="btn_md btn_darkblue btn_outline">삭제</button>
          <button type="button" className="btn_md btn_darkblue">수정</button>
        </div>
      </div>  {/*inner*/}

    </DefaultTemplate>
  )
}

export default EstablishmentBasicInfoPage;
