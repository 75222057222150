import {action, makeObservable, observable} from 'mobx';
import * as apis from '../../apis';
import { BUILDINGG, MENU_AUTH_CODE, MENU_CODE, ROOM_TYPE, WORK_SITE } from '../../constants';
import { GetManagerPayload } from '../../apis/manager';
import { BoardStore } from './boardStore';
import { SHOW_ALERT_MODAL } from '../modules/modal';
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';
import { HeaderStore } from './headerStore';
import { isNull } from 'lodash';

export class AccountPermissionStore {
  list: any = [];
  limit: number = 10;
  offset: number = 0;
  totalPage: number = 0;
  isActive: number = 0;
  workSite: number = 0;
  gender: number = 0;
  progress: number = 0;
  clickData: any = [];    // 리스트에서 클릭한 데이터
  item: any = [];         // 상세 데이터
  
  // 사업장 권한
  paramAuthWorkSiteRole: string = '';

  // 사업장 관련
  workSiteList: any = [];
  workSiteCheckds: any = {};
  workSiteAllCheckFlg: boolean = false;

  // 숙소 관련
  roomList: any = [];   
  roomCheckeds: any = {};
  roomAllCheckFlg: boolean = false;

  // 메뉴 관련
  menuList: any = [];
  menuCheckeds: any = {};
  menuAllCheckFlg: boolean = false;

  // 건물 관련
  buildingList: any = [];
  buildingCheckeds: any = {};
  buildingAllCheckFlg: boolean = false;
  
  authData: any = [];

  // 관리자 정보 관련
  employeeNum: string = '';
  name: string = '';
  birthday: string = '';
  genderType: number = 1;
  cellphone: string = '';
  company: string = '';
  work_site: number = 1;
  department: string = '';
  activeYN : string = '2';
  numFlag: boolean = false;   // 중복체크용
  adminCode: string = '';
  mEmployeeNum: string = '';

  //login user classification
  loginClassification : string = '';

  //loading
  headerStore : HeaderStore;

  //권한관련
  building : any;
  
  constructor(root: any) {
    
    this.headerStore = new HeaderStore(this);

    makeObservable(this, {
      mEmployeeNum: observable,
      list: observable,
      limit: observable,
      offset: observable,
      totalPage: observable,
      isActive: observable,
      workSite: observable,
      gender: observable,
      progress: observable,
      paramAuthWorkSiteRole: observable,
      workSiteList: observable,
      workSiteCheckds: observable,
      workSiteAllCheckFlg: observable,
      roomList: observable,
      roomCheckeds : observable,
      roomAllCheckFlg : observable,
      authData: observable,
      menuList: observable,
      menuCheckeds: observable,
      menuAllCheckFlg: observable,
      buildingList: observable,
      buildingCheckeds: observable,
      buildingAllCheckFlg: observable,
      employeeNum: observable,
      name: observable,
      birthday: observable,
      genderType: observable,
      cellphone: observable,
      company: observable,
      work_site: observable,
      department: observable,
      activeYN: observable,
      numFlag: observable,
      adminCode: observable,
      loginClassification : observable,
      building : observable,
      

      destory: action,
      findTabs: action,
      getItems: action,
      init: action,
      setAuthList: action,
      getItem: action,
      putManageAuth: action,
      workCheckAll: action,
      workCheckItem: action,
      roomCheckAll: action,
      roomCheckItem: action,
      menuCheckAll: action,
      menuCheckItem: action,
      menuSubCheckItem: action,
      buildCheckAll: action,
      buildCheckItem: action,
      getAdmin: action,
      checkEmployeeNum: action,
      adminSave: action,
      adminUpdate: action,
      adminDelete: action
    });

    this.init();
  }

  findTabs(name: string) {
    let result = -1;
    if ((window as any).store) {
      const opened = (window as any).store.getState().base.tab.opened;
      if (opened.length > 0) {
        result = opened.find((item: string) => item && item.indexOf(name) > -1);
      }
    }
    return result;
  }
  
  destory () {
    this.init();
  }

  // 리스트 조회
  async getItems() {
    let payload: any = {
      limit: this.limit,
      offset: this.offset,
      classification: this.loginClassification
    }

    if(this.workSite > 0) {
      payload.work_site = this.workSite+'';
    }else { // 전체일 경우, 접근 가능함 사업장만
      payload.work_site = this.paramAuthWorkSiteRole;
    }
    if(this.gender !== 0) {
      payload.gender = this.gender; 
    }
    if(this.isActive !== 0){
      payload.is_active = (this.isActive === 1) ? true : false;
    }
    if(this.progress !== 0) {
      payload.progress = this.progress; 
    }
    if(Number(this.workSite) === 2) {
      if(!isNull(this.building) && this.building !== undefined) {
        payload.authBuilding = this.building;
      }
    }

    try {
      this.headerStore.isLoading = true;
      let {data}  = await apis.manager.getManagers(payload);

      this.list = data.results;
      this.totalPage = Math.ceil(data.count / this.limit);
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 초기화
  init() {
    this.isActive = 0;
    this.workSite = 0;
    this.gender = 0;
    this.progress = 0;
    this.limit  = 10;
    this.offset = 0;
    this.totalPage = 0;

    this.clickData = [];
    this.item = [];
    this.workSiteList = [];
    this.workSiteCheckds = [];
    this.workSiteAllCheckFlg = false;
    this.roomList = [];
    this.roomCheckeds = [];
    this.roomAllCheckFlg = false;
    this.menuList = [];
    this.menuCheckeds = [];
    this.menuAllCheckFlg = false;
    this.buildingList = [];
    this.buildingCheckeds = [];
    this.buildingAllCheckFlg = false;

    this.employeeNum = '';
    this.name = '';
    this.birthday = '';
    this.genderType = 1;
    this.cellphone = '';
    this.company = '';
    this.work_site = 1;
    this.department = '';
    this.activeYN = '2';
    this.numFlag = false;
    this.adminCode = '';
  }

  // 권한 체크 박스 그리기 용 전체 데이터
  setAuthList () {
    // 사업장 권한 선택 그리기
    this.workSiteList = [];
    let tempWorkList: any = {};
    Object.keys(WORK_SITE).map((key: string, index: number) => {
      let tmp: any = {}
      tmp['key'] = key;
      tmp['name'] = WORK_SITE[Number(key)];
      tempWorkList[key] = tmp;
    })
    this.workSiteList = tempWorkList;

    // 숙소 권한 선택 그리기
    this.roomList = [];
    let tempRoomList: any = {};
    Object.keys(ROOM_TYPE).map((key: string, index: number) => {
        if(Number(key) !== 0 && Number(key) !== 7) {
            let tmp: any = {}
            tmp['key'] = key;
            tmp['name'] = ROOM_TYPE[Number(key)];
            tempRoomList[key] = tmp;
        }
    })
    this.roomList = tempRoomList;

    // 메뉴 권한 선택 그리기
    this.menuList = [];
    let tempMenuList: any = {};
    Object.keys(MENU_CODE).map((key: string, index: number) => {
        let tmp: any = {};
        tmp['key'] = key;
        tmp['name'] = MENU_CODE[key];
        
        // 메뉴별 활동 권한
        let subMenuList: any = {};
        Object.keys(MENU_AUTH_CODE).map((sub: string, index: number) => {
          //let tmp2: any = {};
          //tmp2['key'] = sub;
          //tmp2['name'] = MENU_AUTH_CODE[sub];

          // console.log(MENU_AUTH_CODE[sub]);

          if(key ==="1-1-R" && (sub === "W" || sub === "D" || sub === "M")) { //기준설정 : +읽기/편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];
          }else if(key ==="1-2-R") { //심사및 입숙처리 : all
            let tempMenuName = MENU_AUTH_CODE[sub]
            if(sub === "F") {
              tempMenuName = "입숙처리권한"; 
            }
            subMenuList[sub] = tempMenuName;
            tmp[sub] = tempMenuName;  
          }else if(key ==="2-1-R") { // 입숙자현황 및 이력 : all
            let tempMenuName = MENU_AUTH_CODE[sub]
            if(sub === "F") {
              tempMenuName = "이/퇴숙";
            }
            subMenuList[sub] = tempMenuName;
            tmp[sub] = tempMenuName; 
          }else if(key ==="2-2-R" && (sub !== 'F')) { //입숙자 현황표 : -최종처리권한
            let tempMenuName = MENU_AUTH_CODE[sub]
            if(sub === "P") {
              tempMenuName = "방상태변경";
            }
            subMenuList[sub] = tempMenuName;
            tmp[sub] = tempMenuName; 
          }else if(key ==="2-3-R") { //입숙,이숙,퇴숙 현황 : all
            let tempMenuName = MENU_AUTH_CODE[sub]
            if(sub === "P") {
              tempMenuName = "승인/반려";
            }else if(sub === "F"){
              tempMenuName = "이/퇴숙";
            }
            subMenuList[sub] = tempMenuName;
            tmp[sub] = tempMenuName; 
          }else if(key ==="2-4-R" && (sub === "W" || sub === "D" || sub === "M")) { //공제내역 : +편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];  
          }else if(key ==="2-5-R" && (sub !== "F")) { //정기서류심사 : +편집/삭제/다운로드
            let tempMenuName = MENU_AUTH_CODE[sub]
            
            if(sub === "P") {
              tempMenuName = "업무처리";
            }

            subMenuList[sub] = tempMenuName;
            tmp[sub] = tempMenuName; 
          }else if(key ==="3-1-R" && (sub === "W" || sub === "D" || sub === "M")) { //기본정보설정 : +편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];  
          }else if(key ==="3-2-R" && (sub !== "F")) { //유지운영 : -최종처리권한
            let tempMenuName = MENU_AUTH_CODE[sub]
            if(sub === "P") {
              tempMenuName = "업무이관";
            }
            subMenuList[sub] = tempMenuName;
            tmp[sub] = tempMenuName; 
          }else if(key ==="4-1-R" && (sub !== "F")) { //회원현황 : -최종처리권한
            let tempMenuName = MENU_AUTH_CODE[sub]
            if(sub === "P") {
              tempMenuName = "이용정지/해제/관리자지정";
            }

            subMenuList[sub] = tempMenuName;
            tmp[sub] = tempMenuName; 
          }else if(key ==="4-2-R" && (sub === "W" || sub === "D" || sub === "M")) { //공지관리 : +편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];  
          }else if(key ==="4-3-R" && (sub === "W" || sub === "D" || sub === "M")) { //문자발송 : +편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];  
          }else if(key ==="4-4-R" && (sub === "W" || sub === "D" || sub === "M")) { //FAQ : +편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];  
          }else if(key ==="5-1-R" && (sub === "W" || sub === "D" || sub === "M")) { //개인정보 : +편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];  
          }else if(key ==="6-1-R" && (sub === "W" || sub === "D" || sub === "M")) { //계정 및 권한관리 : +편집/삭제/다운로드
            subMenuList[sub] = MENU_AUTH_CODE[sub];
            tmp[sub] = MENU_AUTH_CODE[sub];  
          }
          
          // 최종 처리권한은 심사및입숙처리와 입퇴이 현황만 
          // if((key !== '1-2-R' && key !== '2-3-R' && key !== '2-1-R') && sub !== "F") {
          //   subMenuList[sub] = MENU_AUTH_CODE[sub];
          //   tmp[sub] = MENU_AUTH_CODE[sub];
          // }else if(key === '1-2-R' || key === '2-3-R' || key === '2-1-R') {
          //   subMenuList[sub] = MENU_AUTH_CODE[sub];
          //   tmp[sub] = MENU_AUTH_CODE[sub];
          // }
        });

        tmp['sub'] = subMenuList;
        tempMenuList[key] = tmp;
    });
    this.menuList = tempMenuList;

    // 건물 권한 선택 그리기
    this.buildingList = [];
    let tempBuildingList: any = {};
    Object.keys(BUILDINGG).map((key: string, index: number) => {
        let tmp: any = {}
        tmp['key'] = key;
        tmp['name'] = BUILDINGG[Number(key)];
        tempBuildingList[key] = tmp;
    })
    this.buildingList = tempBuildingList;
  }

  // 권한 상세 조회
  async getItem(employeeNumber: string) {
    
    // 권한 가져오기
    try{
      this.headerStore.isLoading = true;

      const { data } = await apis.auth.getManagerAuth(employeeNumber);

      
      this.authData = data;
      
      let authWork: any = data.auth_worksite.replace(/\"/gi,"").split(",");
      let authRoom: any = data.auth_roomtype.replace(/\"/gi,"").split(",");
      let authMenu: any = data.auth_menu.replace(/\"/gi,"").split(",");
      let authBuild: any = data.auth_building.replace(/\"/gi,"").split(",");
      
      //console.log(authWork, authRoom, authMenu, authBuild);
      
      // 소속 사업장 체크하기
      const temp: any = { ...this.workSiteCheckds };
      authWork.map((data: any) => {
        if(data){
          temp[data] = data;
          this.workSiteCheckds = temp;
        }
      });
      if (Object.keys(temp).length === Object.keys(this.workSiteList).length) {
        this.workSiteAllCheckFlg = true;
      } else {
        this.workSiteAllCheckFlg = false;
      }
      
      // 숙소유형 체크하기
      const roomTemp: any = { ...this.roomCheckeds };
      authRoom.map((data: any) => {
        if(data){
          roomTemp[data] = data;
          this.roomCheckeds = roomTemp;
        }
      });
      if (Object.keys(roomTemp).length === Object.keys(this.roomList).length) {
        this.roomAllCheckFlg = true;
      } else {
        this.roomAllCheckFlg = false;
      }
      
      // 메뉴 권한 체크하기
      const menuTemp: any = { ...this.menuCheckeds };
      authMenu.map((data: any) => {
        if(data){
          let tempMenu: any = {};
          let key = data.slice(0,5);  // 메뉴 코드 ex> 1-1-R
          let subAuth: any = data.slice(5).split("");   // 메뉴코드 이후 글자 한글자씩 뽑아서 배열로 담기
          
          tempMenu['key'] = key;
          tempMenu['auth'] = {};
          if(subAuth.length > 0){
            let subMenuTmp: any = {};
            subAuth.map((subData: any) => {
              subMenuTmp[subData] = subData;
            })
            tempMenu['auth'] = subMenuTmp;
          }
          
          menuTemp[key] = tempMenu;
        }
      })
      this.menuCheckeds = menuTemp;
      
      if (Object.keys(menuTemp).length === Object.keys(this.menuList).length) {
        this.menuAllCheckFlg = true;
      } else {
        this.menuAllCheckFlg = false;
      }
      
      // 건물 체크하기
      const builTemp: any = { ...this.buildingCheckeds };
      authBuild.map((data: any) => {
        if(data === BUILDINGG[1]){
          builTemp[1] = data;
        }else if(data === BUILDINGG[2]) {
          builTemp[2] = data;
        }
        this.buildingCheckeds = builTemp;
      });
      if (Object.keys(builTemp).length === Object.keys(this.buildingList).length) {
        this.buildingAllCheckFlg = true;
      } else {
        this.buildingAllCheckFlg = false;
      }
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 사업장 전체 체크
  workCheckAll(checked: boolean) {
    const temp: any = { ...this.workSiteCheckds };
    Object.keys(this.workSiteList).map((item: any, index: number) => {
      if (checked) {
        this.workSiteAllCheckFlg = true;
        temp[item] = item;
      } else {
        this.workSiteAllCheckFlg = false;
        delete temp[item];
      }
    });
    this.workSiteCheckds = temp;
  }

  // 사업장 단건 체크
  workCheckItem(checked: boolean, item: any) {
    const temp: any = { ...this.workSiteCheckds };
    if (checked) {
      if (!temp[item]) {
        temp[item] = item;
      }
    } else {
      if(item === "2"){ // 남양이 체크가 풀릴 경우, 건물 체크 빈값으로
        this.buildingCheckeds = {};
        this.buildingAllCheckFlg = false;
      }
      delete temp[item];
    }
    this.workSiteCheckds = temp;

    if (Object.keys(temp).length === Object.keys(this.workSiteList).length) {
        this.workSiteAllCheckFlg = true;
    } else {
      this.workSiteAllCheckFlg = false;
    }
  }

  // 숙소 전체 체크
  roomCheckAll(checked: boolean) {
    const temp: any = { ...this.roomCheckeds };
    Object.keys(this.roomList).map((item: any, index: number) => {
      if (checked) {
        this.roomAllCheckFlg = true;
        temp[item] = item;
      } else {
        this.roomAllCheckFlg = false;
        delete temp[item];
      }
    });
    this.roomCheckeds = temp;
  }

  // 숙소 단건 체크
  roomCheckItem(checked: boolean, item: any) {
    const temp: any = { ...this.roomCheckeds };
    if (checked) {
      if (!temp[item]) {
        temp[item] = item;
      }
    } else {
      delete temp[item];
    }
    this.roomCheckeds = temp;

    if (Object.keys(temp).length === Object.keys(this.roomList).length) {
        this.roomAllCheckFlg = true;
    } else {
      this.roomAllCheckFlg = false;
    }
  }

  // 메뉴 전체 체크
  menuCheckAll(checked: boolean) {
    const temp: any = { ...this.menuCheckeds };
    Object.keys(this.menuList).map((item: any, index: number) => {
      if (checked) {
        this.menuAllCheckFlg = true;
        temp[item] = this.menuList[item];
        
        // 서브 active 전체 체크
        let subMenuList : any = {};
        Object.keys(this.menuList[item]['sub']).map((suMbenu: any) => {
          subMenuList[suMbenu] = suMbenu;
        })
        temp[item]['auth'] = subMenuList;
      } else {
        this.menuAllCheckFlg = false;
        delete temp[item];
      }
    });
    this.menuCheckeds = temp;
  }

  // 메뉴 단건 체크
  menuCheckItem(checked: boolean, item: any) {
    const temp: any = { ...this.menuCheckeds };
    if (checked) {
      if (!temp[item['key']]) {
        temp[item['key']] = item;
        //if(!temp[item['key']]['auth']) {
          temp[item['key']]['auth'] = [];
        //}
      }
    } else {
      delete temp[item['key']];
    }
    this.menuCheckeds = temp;

    //console.log(Object.keys(temp).length, Object.keys(this.menuList).length)
    if (Object.keys(temp).length === Object.keys(this.menuList).length) {
        this.menuAllCheckFlg = true;
    } else {
      this.menuAllCheckFlg = false;
    }
  }

  // 메뉴 active 체크
  // item - 메뉴 key값, subItem - sub key값
  menuSubCheckItem(checked: boolean, item: any, subItem: any) {
    const temp: any = { ...this.menuCheckeds };
    if (checked) {
      if (temp[item]) {
        temp[item]['auth'][subItem] = subItem;
      }
    } else {
      if (temp[item]) {
        delete temp[item]['auth'][subItem];
      }
    }
    this.menuCheckeds = temp;
  }

  // 건물 전체 체크
  buildCheckAll(checked: boolean) {
    const temp: any = { ...this.buildingCheckeds };
    Object.keys(this.buildingList).map((item: any, index: number) => {
      if (checked) {
        this.buildingAllCheckFlg = true;
        temp[item] = this.buildingList[item]['name'];
      } else {
        this.buildingAllCheckFlg = false;
        delete temp[item];
      }
    });
    this.buildingCheckeds = temp;
  }

  // 건물 단건 체크
  buildCheckItem(checked: boolean, item: any) {
    const temp: any = { ...this.buildingCheckeds };
    if (checked) {
      if (!temp[item['key']]) {
        temp[item['key']] = item['name'];
      }
    } else {
      delete temp[item['key']];
    }
    this.buildingCheckeds = temp;

    if (Object.keys(temp).length === Object.keys(this.buildingList).length) {
        this.buildingAllCheckFlg = true;
    } else {
      this.buildingAllCheckFlg = false;
    }
  }

  // 권한 수정
  async putManageAuth(employeeNumber: string) {
    let tmpMenu = Object.keys(this.menuCheckeds).map((key: any) => {
        let str : string = this.menuCheckeds[key]['key']
        if(this.menuCheckeds[key] && this.menuCheckeds[key]['auth']){
            Object.keys(this.menuCheckeds[key]['auth']).map((auth: any) => {
                str += auth;
            })
        }
        return str;
    }).join(','); 

    const payload: any = {
      "auth_worksite": Object.keys(this.workSiteCheckds).map((id: any) => this.workSiteCheckds[id]).join(','),
      "auth_roomtype": Object.keys(this.roomCheckeds).map((id: any) => this.roomCheckeds[id]).join(','),
      "auth_menu" : tmpMenu,
      "auth_building" : Object.keys(this.buildingCheckeds).map((id: any) => this.buildingCheckeds[id]).join(',')
    }
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.auth.putManagerAuth(employeeNumber, payload);

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };return;
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });

      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 계정 조회 
  async getAdmin(employeeNumber: string){
    try{
      this.headerStore.isLoading = true;
      const { data } = await apis.auth.getAdminManager(employeeNumber);

      this.item = data;
      
      // 값 세팅
      this.employeeNum = data.employee_number;
      this.name = data.name;
      this.birthday = data.birthdate;
      this.department = data.assigned_task;
      this.cellphone = data.cellphone;
      this.company = data.company;
      this.gender = data.gender;
      this.activeYN = data.is_active? '1' : '2';
      this.work_site = data.work_site_id;
      this.adminCode = data.classification
      this.numFlag = true;
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 사번 중복체크
  async checkEmployeeNum(employeeNumber: string) {
    try{
      this.headerStore.isLoading = true;
      this.numFlag = false;
  
      const { data } = await apis.auth.getAdminManager(employeeNumber);

      
      if(!data || data === "Not Find") {
        this.numFlag = true;
      }
      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 계정 등록
  async adminSave() {
    // 메뉴 권한 string으로 만들기
    let tmpMenu = Object.keys(this.menuCheckeds).map((key: any) => {
      let str : string = this.menuCheckeds[key]['key']
      if(this.menuCheckeds[key] && this.menuCheckeds[key]['auth']){
          Object.keys(this.menuCheckeds[key]['auth']).map((auth: any) => {
              str += auth;
          })
      }
      return str;
    }); 

    // 메뉴 권한 sorting
    tmpMenu.sort((a:any, b:any) => a>b? 1 : a<b? -1 : 0);

    // 사업장 권한 선택이 X -> 소속 사업장 하나만 전송
    let tmpWork = Object.keys(this.workSiteCheckds).map((id: any) => this.workSiteCheckds[id]).join(',');

    if(!tmpWork) {
      tmpWork = this.work_site+'';
    }

    const payload: any = {
      'employee_number': this.employeeNum,
      "password":"qw12!@QW",
      "name": this.name,
      "birthdate": this.birthday,
      "gender": this.genderType,
      "cellphone": this.cellphone,
      "company": this.company,
      "work_site": this.work_site,
      "assigned_task": this.department,
      "classification": this.adminCode,
      "auth_worksite": tmpWork,
      "auth_roomtype": Object.keys(this.roomCheckeds).map((id: any) => this.roomCheckeds[id]).join(','),
      "auth_menu": tmpMenu.join(','),
      "auth_building": Object.keys(this.buildingCheckeds).map((id: any) => this.buildingCheckeds[id]).join(','),
      "manager_employee_number": this.mEmployeeNum,
      //activeYN : this.activeYN
    }
    try{
      this.headerStore.isLoading = true;

      const { data } = await apis.auth.postAdminManager(payload);

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 계정 수정
  async adminUpdate(id: number) {
    // 메뉴 권한 string으로 만들기
    let tmpMenu = Object.keys(this.menuCheckeds).map((key: any) => {
      let str : string = this.menuCheckeds[key]['key']
      if(this.menuCheckeds[key] && this.menuCheckeds[key]['auth']){
          Object.keys(this.menuCheckeds[key]['auth']).map((auth: any) => {
              str += auth;
          })
      }
      return str;
    })

    // 메뉴 권한 sorting
    tmpMenu.sort((a:any, b:any) => a>b? 1 : a<b? -1 : 0);

    const payload: any = {
      //"birthdate": this.birthday,
      "gender": this.genderType,
      "cellphone": this.cellphone,
      //"company": this.company,
      //"work_site": this.work_site,
      "assigned_task": this.department,
      "classification": this.adminCode,
      "auth_worksite": Object.keys(this.workSiteCheckds).map((id: any) => {if(id) return this.workSiteCheckds[id]}).join(','),
      "auth_roomtype": Object.keys(this.roomCheckeds).map((id: any) => {if(id) return this.roomCheckeds[id]}).join(','),
      "auth_menu": tmpMenu.join(','),
      "auth_building": Object.keys(this.buildingCheckeds).map((id: any) => this.buildingCheckeds[id]).join(','),
      "is_active" : this.activeYN === '1' ? true : false,
      "is_staff" : true,
      "manager_employee_number": this.mEmployeeNum,
    }
    try{
      this.headerStore.isLoading = true;

      const { data } = await apis.auth.putAdminManager(id, payload);

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;

      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }

  // 계정 삭제
  async adminDelete(id: number) {
    try{
      this.headerStore.isLoading = true;

      const { data } = await apis.auth.deleteAdminManager(id);

      this.headerStore.isLoading = false;

      return {
        'save' : true,
        'status' : 200,
        'data' : ''
      };
    }catch ({ status, data }) {
      this.headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          'save' : false,
          'status' : status,
          'data' : data
        };
      }

      (window as any).store.dispatch({
        type: SHOW_ALERT_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return {
        'save' : false,
        'status' : status,
        'data' : data
      };
    }
  }
}


