import { observer } from 'mobx-react';
import React from 'react';

import DefaultTemplate from '../../components/templetes/default';
import NoticeList from '../../components/templetes/notice';
import { useStores } from '../../store/mobx';

const NoticeListPage = observer((props: any) => {
  const {noticeStore: store} = useStores();
  const {headerStore: headerStore} = useStores();

  return (
    <DefaultTemplate
      id="wrap"
      className=""
      isLoading={headerStore.isLoading}

      {...props}
    >
      <NoticeList {...props} />
    </DefaultTemplate>
  );
});

export default NoticeListPage;
