import React from 'react';

import styled from 'styled-components';

const _button = styled.button`
  background: white;
  // border: 1px solid #8e8e8e;
  // border-radius: 5px;
  border: 0;
  cursor: pointer;
  width: 25px;
  height: 25px;
  font-size: 14px;
  margin-left: ${(props: any) => (props.marginLeft ? props.marginLeft : 0)}px;

  &:hover {
    // background-color: #efefef;
  }

  // 테블릿
  @media (min-width: 768px) {
    & {
      width: 30px;
      height: 30px;
      font-size: 18px;
    }
  }

  // PC
  @media (min-width: 1024px) {
    & {
      width: 36px;
      height: 36px;
      font-size: 20px;
    }
  }
`;

//캘린더 버튼
const calendarNavBtn = (props: any) => {
  return <_button {...props} name="CALENDAR_NAV"> {props.children} </_button>;
};

export default calendarNavBtn;
