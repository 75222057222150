const initialState: any = {
  worksites: [],
  worksiteMap: {},
  tab: {
    opened: [],
    select: -1,
  },
};

interface Workplace {
  id: number
  name: string
  is_active: boolean
  is_visible: boolean
}

interface WorkplaceMap {
  id: {
    name: string
    is_active: boolean
  }
}
export const INIT_REQUEST = "BASE/INIT_REQUEST";

export const WORKSITE_INIT_REQUEST = "BASE/WORKSITE_INIT_REQUEST";
export const WORKSITE_INIT_COMPLETE = "BASE/WORKSITE_INIT_COMPLETE";

export const ADD_TAB = 'BASE/ADD_TAB';
export const CLOSE_TAB = 'BASE/CLOSE_TAB';
export const CLICK_TAB = 'BASE/CLICK_TAB';
export const CLEAN_TAB = 'BASE/CLEAN_TAB';
export const CHANGE_TAB = 'BASE/CHANGE_TAB';

// export const addTab = (payload: {path: string}): any => (dispatch: any) => {
//   dispatch({
//     type: ADD_TAB,
//     payload,
//   });
// };

function baseReducer(state: any = initialState, action: any) {
  const { opened, select } = state.tab;
  let tempOpend: any[] = [...opened];

  switch (action.type) {
    case WORKSITE_INIT_COMPLETE:
      let { payload: worksites } = action;
      
      let worksiteMap = worksites.reduce((acc: any, curVal: Workplace, idx: number) => {
        let { name, is_active, is_visible } = curVal
        acc[curVal.id] = { name, is_active, is_visible }
        return acc
      }, {})
      
      return {
        ...state,
        worksites,
        worksiteMap
      }
    case ADD_TAB:
      tempOpend.push(action.path);

      return {
        ...state,
        tab: {
          opened: tempOpend,
          select: opened.length,
        },
      };
    case CLICK_TAB:
      return {
        ...state,
        tab: {
          opened,
          select: action.select,
        },
      };
    case CLOSE_TAB:
      // let removedSelect = action.select;
      // tempOpend = tempOpend.slice(0, removedSelect).concat(tempOpend.slice(removedSelect + 1, tempOpend.length));
      // console.log('tempOpend: ', tempOpend);
      tempOpend.splice(action.select, 1);

      const removedSelect = tempOpend.length - 1;
      // console.log('removedSelect: ', removedSelect);

      return {
        ...state,
        tab: {
          opened: tempOpend,
          select: removedSelect,
        },
      };
    case CLEAN_TAB:
      tempOpend.splice(0, tempOpend.length);
      return {
        ...state,
        tab: {
          opened: tempOpend,
          select: -1,
        },
      };
    case CHANGE_TAB:
      tempOpend[select] = action.path;
      return {
        ...state,
        tab: {
          opened: tempOpend,
          select,
        },
      };
    default:
      return state;
  }
}

export default baseReducer;
