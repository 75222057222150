import React, { useEffect } from 'react';
import './index.scss';

const SsoFail = (props: any) => {
    return (
        <>
            <div id="wrap" className="loading">
                <div className="loading_message">
                    <p className="ico_fail">로그인에 실패했습니다. <br />로그인을 하시려면 아래 버튼으로 이동하시면 됩니다.</p>
                    <button type="button" className="btn_lg btn_darkblue" onClick={() => {props.history.push('/');}}>로그인 페이지로 이동</button>
                </div>
            </div>
        </>
    );
}

export default SsoFail;
