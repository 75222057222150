import React, { useState } from 'react';

import DefaultTemplate from '../../components/templetes/default';
import Pagination from '../../components/organismas/pagination';
import Button from '../../components/atoms/button';

import useAdminManager, { AdminInterface } from '../../hooks/useAdminManager';
import useRegistriedAdminInfo, { registriedAdminInfoInterface } from '../../hooks/useRegistriedAdminInfo';
import { GENDER } from '../../constants';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';

// 관리자관리 - 관리자계정관리
const AdminRegisterApplyManage = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  const {
    admins = [],
    totalPage, offset, limit, setOffset,

    isActive, isRegisterd, workSite, gender,
    setIsActive, setIsRegistered, setWorkSite, setGender,
  } = useAdminManager();

  const {
    company: inputCompany, name: inputName, workSite: inputWorkSite, cellphone: inputCellphone, employeeNumber: inputEmployeeNumber,
    setCompany: setInputCompany, setName: setInputName, setWorkSite: setInputWorkSite, setCellphone: setInputCellphone, setEmployeeNumber: setInputEmployeeNumber,
    submit, init: inputInit
  } = useRegistriedAdminInfo();

  const [ isShowResisterPopup, setUsShowResisterPopup ] = useState<boolean>(false);

  const submitHandler = async () => {
    headerStore.isLoading = true;
    let isFail = await submit();
    if (isFail) {
      alert('등록중 문제발생');
      headerStore.isLoading = false;
      return
    }

    alert('등록완료');
    setUsShowResisterPopup(false);
    headerStore.isLoading = false;
  }

  return (
    <>

      <DefaultTemplate
        id="wrap"
        className=""
        isLoading={headerStore.isLoading}
        {...props}
      >
        <div className="tab_contents on">
          <h4>계정관리 리스트
            <div className="right_area">
              <select name="" id="" className="form_control" value={gender} onChange={(e) => setGender(parseInt(e.target.value))}>
                <option value={-1}>성별</option>
                <option value={0}>선택안함</option>
                <option value={1}>남자</option>
                <option value={2}>여자</option>
              </select>
              <select name="" id="" className="form_control" value={isActive} onChange={(e) => setIsActive(parseInt(e.target.value))}>
                <option value={-1}>활성여부</option>
                <option value={1}>활성</option>
                <option value={0}>비활성</option>
              </select>
              <select name="" id="" className="form_control" value={isRegisterd} onChange={(e) => setIsRegistered(parseInt(e.target.value))}>
                <option value={-1}>상태</option>
                <option value={1}>활성</option>
                <option value={0}>비활성</option>
              </select>
            </div>
          </h4>
          <div className="table_normal_list">
            <table>
              <caption className="sr_only">계정 및 권한관리 리스트</caption>
              <colgroup>
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>관리번호</th>
                  <th>성명</th>
                  <th>근무지</th>
                  <th>소속</th>
                  <th>생년월일</th>
                  <th>성별</th>
                  <th>휴대폰번호</th>
                  <th>활성여부</th>
                  <th>담당업무</th>
                  <th>상태</th>
                </tr>
              </thead>
              <tbody>
                {/*데이타 없을 시*/}
                {!admins.length && (
                  <tr>
                    <td className="blank_area type_white" colSpan={9}>
                      <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                  </tr>
                )}

                {admins.map((admin: AdminInterface, adminIdx: number) => (
                  <tr>
                    <td>{admin.employee_number}</td>
                    <td>{admin.name}</td>
                    <td>{admin.worksite}</td>
                    <td>{admin.company}</td>
                    <td className="al">{admin.birthdate}</td>
                    <td className="al">{GENDER[admin.gender]}</td>
                    <td>{admin.cellphone}</td>
                    <td>{admin.is_active ? '활성' : '비활성'}</td>
                    <td> {admin.duty}</td>
                    <td>{admin.is_registered ? '활성' : '비활성'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>  {/*table*/}


          <div className="board_paging">
            <ul>
              <Pagination offset={offset} limit={limit} totalPage={totalPage} setOffset={setOffset} />
            </ul>
          </div>   {/*board_paging*/}

          <div className="tab_contents on">
            <div className="fl">
              <Button outline onClick={() => setUsShowResisterPopup(true)}>관리자등록</Button>
            </div>
            <div className="fr">
              {/* <Button outline onClick={() => { }}>삭제</Button> */}
            </div>
          </div>
        </div> {/* tab_content */}
      </DefaultTemplate>
      
      <div id="pop_regist_admin" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowResisterPopup? '': 'none'}}>
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>관리자 등록</h3>
            <button className="pop_btn_close pop_close" onClick={() => { inputInit(); setUsShowResisterPopup(false)}}>닫기</button>
          </div>
          <div className="contents_wrap">
            <div className="table_normal_list table_write">
              <table>
                <caption className="sr_only">관리자 등록</caption>
                <colgroup>
                  <col width="125" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>관리번호</th>
                    <td><input type="text" className="form_control" placeholder="관리번호를 입력해주세요" value={inputEmployeeNumber} onChange={ e => setInputEmployeeNumber(e.target.value)}/></td>
                  </tr>
                  <tr>
                    <th>성명</th>
                    <td><input type="text" className="form_control" placeholder="성명을 입력해주세요" value={inputName} onChange={ e => setInputName(e.target.value)}/></td>
                  </tr>
                  <tr>
                    <th>소속</th>
                    <td><input type="text" className="form_control" placeholder="소속을 입력해주세요" value={inputCompany} onChange={ e => setInputCompany(e.target.value)}/></td>
                  </tr>
                  <tr>
                    <th>근무지</th>
                    <td>
                      <select name="" id="" className="form_control" value={inputWorkSite} onChange={ e => setInputWorkSite(parseInt(e.target.value))}>
                        <option value={1}>울산사업장</option>
                        <option value={2}>남양사업장</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>휴대폰번호</th>
                    <td><input type="text" className="form_control" placeholder="휴대폰번호를 입력해주세요" value={inputCellphone} onChange={ e => setInputCellphone(e.target.value)}/></td>
                  </tr>
                </tbody>
              </table>
            </div>  {/*table*/}
          </div>
          <div className="button_wrap btn_length_1">
            <button type="button" className="btn btn_lg btn_outline pop_close" onClick={() => {inputInit(); setUsShowResisterPopup(false)}}>취소</button>
            <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={submitHandler}>확인</button>
          </div>
        </div>
      </div>

    </>
  );
});

export default AdminRegisterApplyManage;
