import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Notice {
  static URL = `${IP}`;

  /**
   * 공지사항 내역 조회
   * @param payload
   */
  @apiHandler()
  static async getNotices(payload: any) {
    const PATH = `/admin/board/notice/`;

    return await axios.get(`${Notice.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 상세 조회
  @apiHandler()
  static async getNotice({ id }: { id: string }) {
    const PATH = `/admin/board/notice/`;

    return await axios.get(`${Notice.URL}${PATH}${id}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 상세 조회
  @apiHandler()
  static async getNoticeExhibit(payload: any) {
    const PATH = `/admin/board/notice/exhibit/`;

    return await axios.get(`${Notice.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 생성
  @apiHandler()
  static async createNotice(payload: any) {
    const PATH = `/admin/board/notice/`;

    return await axios.post(`${Notice.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 생성
  @apiHandler()
  static async updateNotice(id: string, payload: any) {
    const PATH = `/admin/board/notice/`;

    return await axios.patch(`${Notice.URL}${PATH}${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 삭제
  @apiHandler()
  static async deleteNotice(id: string) {
    const PATH = `/admin/board/notice/`;
    //const PATH = `/admin/board/audit/`;
    
    return await axios.delete(`${Notice.URL}${PATH}${id}/`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

    // 공지사항 삭제
    @apiHandler()
    static async deleteAudit(id: string) {
      //const PATH = `/admin/board/notice/`;
      const PATH = `/admin/board/audit/`;
      
      return await axios.delete(`${Notice.URL}${PATH}${id}/`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      });
    }
}

export default Notice;
