import React, { useState, useEffect } from 'react';

interface Props {
  allocationTitle: string;
  freeTitle: string;

  allocations: number[];    // 부여된 부분
  free: number[];           // 부여되지 않은 부분(전체항목 중 allocations에 없는 아이템만 화면에 표시)

  onChange: Function;

  mapper: { [key: number]: string };
}

const CheckPannel = (props: Props) => {
  const [checkedByAllocation, setCheckedByAllocation] = useState<{ [key: string]: boolean }>({});
  const [checkedByFree, setCheckedByFree] = useState<{ [key: string]: boolean }>({});

  const [isAllCheck, setIsAllCheck] = useState<boolean>(false);

  useEffect(() => {
    if (isAllCheck) {
      let free = props.free.reduce((acc: { [key: string]: boolean }, cur: number) => {
        acc[cur] = true
        return acc;
      }, {})
      setCheckedByFree(free);
    }
  }, [isAllCheck])

  useEffect(() => {
    let all = props.free.length;
    let checkedCnt = Object.keys(checkedByFree).filter((item: string) => checkedByFree[item] && !props.allocations.includes(parseInt(item))).length;
    
    if (!(all - props.allocations.length - checkedCnt)) {
      setIsAllCheck(true);
    }

  }, [checkedByFree])

  const allocation = () => { // free => allocation
    let allocation = props.allocations.concat(Object.keys(checkedByFree).filter((item: string) => checkedByFree[item]).map(item => parseInt(item)))
    props.onChange(allocation);
    setCheckedByFree({});
  }

  const free = () => {      // allocation => frree
    let free = props.allocations.filter((allocation:number) => !checkedByAllocation[allocation])
    props.onChange(free);
    setCheckedByAllocation({});
  }

  return (
    <div className="box_outer">
      
      <div className="box_top">
        <button className="btn btn_sm btn_outline" onClick={ allocation }><span className="ico_prev"></span>이동</button>
        <button className="btn btn_sm btn_outline" onClick={ free }>이동<span className="ico_next"></span></button>
      </div>  {/*box_top*/}

      <div className="box_inner">
        <dl className="lst_items">
          <dt>{props.allocationTitle}</dt>
          <dd>
            <ul>
              {props.allocations.map((allocation: number) => (
                <li className="active">
                  <label className="input_check">
                    <input type="checkbox" checked={ checkedByAllocation[allocation]} onChange={(e: any) => setCheckedByAllocation({...checkedByAllocation, [allocation]: e.target.checked}) }/>
                    <span className="label_text">{props.mapper[allocation]}</span>
                  </label>
                </li>
              ))}
            </ul>
          </dd>
        </dl>
        <dl className="lst_items">
          <dt>{props.freeTitle}</dt>
          <dd>
            <ul>
              <li>
                <label className="input_check">
                  <input type="checkbox" checked={isAllCheck} onClick={(e: any) => {
                    if (!e.target.checked) {
                      setCheckedByFree({})
                    }
                    setIsAllCheck(e.target.checked)
                  }} />
                  <span className="label_text">전체</span>
                </label>
              </li>
              {props.free.map((item: number) => !props.allocations.includes(item) && (
                <li>
                  <label className="input_check">
                    <input type="checkbox" checked={checkedByFree[item]} onChange={(e: any) => {
                      if (!e.target.checked) {
                        setIsAllCheck(false);
                      }
                      setCheckedByFree({ ...checkedByFree, [item]: e.target.checked })
                    }} />
                    <span className="label_text">{props.mapper[item]}</span>
                  </label>
                </li>
              ))}
             
            </ul>
          </dd>
        </dl>
      </div>  {/*box_inner*/}
    </div> 
  );
}

export default CheckPannel;