import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
  
import DefaultTemplete from '../../components/templetes/default';

import RoomTypeSelect from '../../components/atoms/select/roomTypeSelectBox';
import GuestType from '../../components/atoms/select/guestType';
import Visable from '../../components/atoms/select/visable';
import Button from '../../components/atoms/button';

import { worksite } from '../../apis';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import { SHOW_ALERT_MODAL } from '../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../routes';
import CustomCalendar from '../../components/organismas/customCalendar';
import DaySelect from '../deduct/daySelect';

// 이용자 범위설정
const LocusGuestType = observer((props: any) => {
  const { userInfo = {}, worksites = [], worksiteMap = {} } = useSelector((state: any) => ({
    worksites: state.base.worksites,
    worksiteMap: state.base.worksiteMap,
    userInfo: state.user
  }))

  const [roomType, setRoomType] = useState<number>(1);
  const [guestType, setGuestType] = useState<number>(1);
  const [isVisable, setIsVisable] = useState<number>(1);
  const {headerStore: headerStore} = useStores();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  var date = new Date();
  var m = date.getMonth();
  var y = date.getFullYear();
  var startMonth = new Date(y, m+1, 1);
    
  const s_year = startMonth.getFullYear();
  const s_month = ((startMonth.getMonth() + 1) >= 10) ? startMonth.getMonth() + 1 : `0${startMonth.getMonth() + 1}`;
  const s_day = '22';

  const [remainDay, setRemainDay] = useState<any>(22);

  console.log(userInfo)
  const onSaveHandler = async () => {
    const payload: any = {
      work_site: headerStore.workSite,
      room_type: roomType,
      guest_type: guestType,
      is_visible: isVisable,
      remain_day: (roomType == 1 && headerStore.workSite == 2) ? remainDay : 0,
    }

    try {
      headerStore.isLoading = true;
      let { data } = await worksite.addGuest(payload)
      info("등록이 완료 되었습니다.");
      props.history.replace('/locus/guest/type/');
      headerStore.isLoading = false;
    } catch ({ status, data }) {
      headerStore.isLoading = false;
      if (status === 401) {
        if (!window.localStorage.getItem("isRetryLogin")) {
          window.localStorage.setItem("isRetryLogin", "retry");
        }
        window.location.href = '/';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_ALERT_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (status === 400) {
        info('이미 등록되어 있습니다.');
      }
    }
  }

  return (
    <DefaultTemplete
      className=""
      id="wrap"
      isLoading={headerStore.isLoading}
      {...props}
    >
      <h4>이용자범위 설정 등록</h4>

      {/* table start */}
      <div className="tab_contents on">
        <div className="table_normal_list" style={{ overflowX: 'auto' }}>
          <table>
            <caption className="sr_only">이용자범위 설정 등록</caption>
            <colgroup>
              <col width="15%"></col>
              <col width="35%"></col>
              <col width="15%"></col>
              <col width="35%"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>사업장</th>
                <td>{worksiteMap[headerStore.workSite]?.name}</td>
                <th>숙소유형</th>
                <td>
                  <RoomTypeSelect value={roomType} onChange={(roomType: number) => setRoomType(roomType)}/>
                </td>
              </tr>
              <tr>
                <th>이용자 타입</th>
                <td>
                  <GuestType onlyData value={guestType} onChange={(guestType: number) => setGuestType(guestType)}/>
                </td>
                <th>표시여부</th>
                <td>
                 <Visable onlyData value={isVisable} onChange={(isVisable: number) => setIsVisable(isVisable)}/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
            {(roomType == 1 && userInfo.work_site == 2) ? 
            <div style={{marginTop:'20px', width:'15%'}}>
              <span>잔여분 신청일</span>
              <div>
              <DaySelect
                day={remainDay}
                onChange={(e: any) => (setRemainDay(e))}
                ></DaySelect>
              </div>
            </div> : <></>}
        </div>
      </div>

      {/* 하단버튼 */}
      <div className="submit">
        <div className="fl">
          <Button large backgroundGray onClick={() => props.history.push('/locus/guest/type')}>목록</Button>
        </div>
        <div className="fr">
          <Button large background onClick={onSaveHandler}>등록</Button>
        </div>
      </div>
    </DefaultTemplete>
  );
});

export default LocusGuestType;
