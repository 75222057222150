import React, { useState, useEffect, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { FAQ_CATEGORY } from '../../../../constants';
import { formatByString, formatByString_YYYYMMDD } from '../../../../utils/date';
import ConfirmBtn from '../../../atoms/button/confirm';
import { core, faq } from '../../../../apis';
import { useStores } from '../../../../store/mobx';
import { ApiErrorHandleContext, ApiErrorHandleImpl, MessageContext, MessageImpl } from '../../../../routes';
import { SHOW_ALERT_MODAL, SHOW_CONFIRM_MODAL } from '../../../../store/modules/modal';
import { useDispatch } from 'react-redux';
import { observer } from 'mobx-react';
import { parserEscape, xss } from '../../../../utils/common';
import FileSaver from 'file-saver';

const ViewContent = observer((props: any) => {
  const dispatch = useDispatch();
  const {faqStore} = useStores();
  const {headerStore: headerStore} = useStores();
  const baseUrl = '/manage/faq';

  // const [item, setItem] = useState<any>(faqStore.item);
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');
  const goBack = () => props.history.replace(baseUrl);

  const onEditHandler = () => {
    props.history.replace(`${baseUrl}/${faqStore.item.id}/update`);
  };

  const onDeletehandler = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: '확인',
        msg: '현재 항목을 삭제 하시겠습니까?',
        action: async () => {
          try {
            headerStore.isLoading = true;
            let data : any = await faqStore.deleteItem();
            headerStore.isLoading = false;
            setTimeout(function () {
              if(data.save) {
                info('삭제 완료했습니다.');
                goBack()
                return;
              }else {
                info("문제가 발생했습니다");
                return;
              }
            }, 250);
          } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }else {
              info("문제가 발생했습니다");
              return;
            }
      
            //errorHandler({ status, data }, '삭제중 오류발생');
          }
        },
      }
    });
  };

  return (
    <>
      <h4>FAQ  상세</h4>
      <div className="table_normal_list table_write">
        <table>
          <caption className="sr_only">FAQ 상세</caption>
          <colgroup>
            <col width="130" />
            <col width="*" />
            <col width="130" />
            <col width="*" />
            <col width="130" />
            <col width="*"/>
          </colgroup>
          <tbody>
            <tr>
              <th>유형</th>
              <td>{FAQ_CATEGORY[Number(faqStore.item.category)]}</td>
              <th>작성자</th>
              <td>{faqStore.item.author_name}</td>
              <th>등록일시</th>
              <td>{formatByString(faqStore.item.created_at, 'yyyy.MM.dd HH:mm')}</td>
            </tr>
            <tr>
              <th>질문</th>
              <td className="fwb" colSpan={5}>{faqStore.item.title}</td>
            </tr>
            <tr>
              <th>답변</th>
              <td colSpan={5}>
                <div className="contents_area" id="editor" dangerouslySetInnerHTML={{ __html: parserEscape(faqStore.item.contents) }}></div>
              </td>
            </tr>
            <tr>
              <th>이미지</th>
              <td colSpan={5}>
                <div className="images_area">
                  {faqStore.item.images.map((image: any, index: number) => (
                    <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                      <a href="javascript:void(0);">
                        <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt="미리보기"/>
                      </a>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <th>첨부파일</th>
              <td colSpan={5}>
                {faqStore.item.files.map((file: any, index: number) => (
                  <a key={index} href={`${process.env.REACT_APP_MEDIA}/${file.path}`} className="item file"
                  target={`${file.path}`.split('.').pop() 
                          && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                          && !isMobile? '_blank' : ''}
                  >
                    {file.name}
                  </a>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*table*/}

      <div className="submit ar">
        <div className="fl">
          <button type="button" className="btn_lg btn_darkgray" onClick={goBack}>
            목록
          </button>
        </div>
        <div className="fr">
          {headerStore.writeRoleFlag? <button type="button" className="btn_lg btn_darkblue btn_outline" onClick={onEditHandler}>수정</button> : <></>}
          {headerStore.deleteRoleFlag? <button type="button" className="btn_lg btn_darkblue" onClick={onDeletehandler}>삭제</button> : <></>}
        </div>
      </div>

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
          <div className="pop_wrap">
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
});

export default ViewContent;
