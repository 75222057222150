import { useSelector } from 'react-redux';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import React, { useState, useEffect, useContext } from 'react';
import DefaultTemplate from '../../../components/templetes/default';
import ChoiceSmsGuest from '../../../components/organismas/popup/choiceSmsGuest';
import { format_YYYYMMDDhhmm, format_YYYYMMDDhhmm2, getTodayDate } from '../../../utils/date';
import DatePicker from '../../../components/organismas/input/datepicker';
import * as apis from '../../../apis';
import { MessageContext, MessageImpl } from '../../../routes';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import CustomCalendar from '../../../components/organismas/customCalendar';

const SmsPushPage = observer((props: any) => {
    const [isChoiceGuestsOpen, setIsChoiceGuestsOpen] = useState<boolean>(false);
    const {headerStore: headerStore} = useStores();
    const { smsStore : store } = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));
    const [contents, setContents] = useState<string>('');
    const [checking, setChecking] = useState<boolean>(false);
    const { info, warning, error }: MessageImpl = useContext(MessageContext);

    useEffect(() => {
        (async () => {
            try{
                headerStore.isLoading = true;
                store.guest = [];
                store.work_site = headerStore.workSite;
                await store.getUsers();
                // console.log('회원현황 리스트=======>',store.users);
                headerStore.isLoading = false;
            }catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }

                info("문제가 발생했습니다");
                return;
            }
        })();
    },[headerStore.workSite]);
    
    //저장버튼
    const save = async() => {
        if(!store.guest || store.guest.length <= 0) {
            info('발송 대상자를 선택해주세요');
            return;
        }else if(!contents || contents === '') {
            info('메세지 내용을 입력해주세요');
            return;
        }else if(!checking && format_YYYYMMDDhhmm2() < store.send_date.replace(/-/g,"")+store.getHour+store.getMinute === false){
            info('예약 발송은 현재시간보다 빠르게 설정을 해야합니다.');
            return;
        }

        let cellMap =store.guest.map((item:any, index:number) => {
            return item.cellphone
        }).join(";");
        
        const payload : any = {
            work_site: headerStore.workSite,
            employee_number: userInfo.employeeNumber,
            cellphones : ''+cellMap,
            message : contents,
        }
        if(checking === true){
            payload['send_date'] = format_YYYYMMDDhhmm();
            payload['send_status'] = "즉시발송";
        }else{
            payload['send_date'] = store.send_date+" "+store.getHour+":"+store.getMinute;
            payload['send_status'] = "발송예정";
        }

        try{
            headerStore.isLoading = true;
            let data : any = await apis.sms.smsSend(payload);
            headerStore.isLoading = false;
            goBack();
            // setTimeout(function () {
            //     if(data.save) {
            //     }else {
            //       return;
            //     }
            // }, 250);
        }catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
                if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                }
                window.location.href = '/';
                return ;
            } else if (status === 403) {
                (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                        title: '권한오류',
                        msg: '접근권한이 없습니다.',
                        redirectPath: false,
                        statusCode: status,
                        errorCode: '',
                        type: 'error',
                        isModalShow: true,
                    },
                });
                return ;
            }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
            }

            info("문제가 발생했습니다");
            return;
        }
    }

    const goBack = () => {
        props.history.replace(`/manage/sms`);
    }
    const onShowChoiceGuestsPopupHandler = (isOpen: boolean) => {
        store.guest = [];
        setIsChoiceGuestsOpen(isOpen);
    };

    const onUpdateGuests = (selectedGuests: any) => {
        store.guest = selectedGuests;
    };

    return(
        <DefaultTemplate
            id="wrap"
            className=""
            isLoading={headerStore.isLoading}
            {...props}
        >
        
        <h4>문자발송 등록</h4>
        <div className="table_normal_list table_write">
            <table>
                <caption className="sr_only">문자발송 등록</caption>
                <colgroup>
                    <col width="150"/>
                    <col width="*"/>
                    <col width="150"/>
                    <col width="*"/>
                </colgroup>
                <tbody>
                <tr>
                    <th><span className="emp_red">발송 대상자</span></th>
                    <td colSpan={4}>
                        <button type="button" className="btn_sm btn_darkblue btn_outline btn_srch" onClick={()=> onShowChoiceGuestsPopupHandler(true)}>검색</button>
                        {store.guest.length > 0 ? (
                        <div className="items_area">
                            <a className="item">{store.guest.length === 0 ? '' : `${store.guest.length} 명`}</a>
                        </div>
                        ) : (<></>)}
                    </td>
                </tr>

                <tr>
                    <th><span className="emp_red">메시지 내용</span></th>
                    <td colSpan={4}>
                        <textarea className="form_control" value={contents} onChange={(e: any) => setContents(e.target.value)}></textarea>
                    </td>
                </tr>

                {/* <tr>
                    <th>이미지</th>
                    <td colSpan={4}>
                    <ImageUpload
                        type="image"
                        fileId="imageFile"
                        items={store.images}
                        onSetData={(data: any[]) => store.images = data}
                    />
                    </td>
                </tr>  */}

                <tr>
                    <th><span className="emp_red">발송 날짜 선택 및<br/>예약 발송 설정</span></th>
                    <td colSpan={4} className="form_inline">
                        <label className="input_check">
                            <input type="checkbox" id="" name="check_notice" onChange={(e: any) => setChecking(e.target.checked)}/>
                            <span className="label_text">즉시발송</span>
                        </label>
                        <div className="ml20">
                            
                        {/* <DatePicker
                            value={store.send_date}
                            width={250}
                            onChange={(date: string) => (store.send_date = date)}
                            isPrevMonthView={false}
                            min={getTodayDate()[0]}
                        /> */}
                        <CustomCalendar
                            min={getTodayDate()[0]}
                            value={store.send_date}
                            onChange={(date:string) => (store.send_date = date)}
                        ></CustomCalendar>
                        </div>

                        <select className="form_control month" value={store.getHour} 
                            onChange={(e:any) => {store.getHour = e.target.value}}>
                            <option value="01">1</option>
                            <option value="02">2</option>
                            <option value="03">3</option>
                            <option value="04">4</option>
                            <option value="05">5</option>
                            <option value="06">6</option>
                            <option value="07">7</option>
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                        </select>
                        <select className="form_control month" value={store.getMinute} 
                            onChange={(e:any) => {store.getMinute = e.target.value}}>
                            <option value="00">0</option>
                            <option value="01">1</option>
                            <option value="02">2</option>
                            <option value="03">3</option>
                            <option value="04">4</option>
                            <option value="05">5</option>
                            <option value="06">6</option>
                            <option value="07">7</option>
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                            <option value={32}>32</option>
                            <option value={33}>33</option>
                            <option value={34}>34</option>
                            <option value={35}>35</option>
                            <option value={36}>36</option>
                            <option value={37}>37</option>
                            <option value={38}>38</option>
                            <option value={39}>39</option>
                            <option value={40}>40</option>
                            <option value={41}>41</option>
                            <option value={42}>42</option>
                            <option value={43}>43</option>
                            <option value={44}>44</option>
                            <option value={45}>45</option>
                            <option value={46}>47</option>
                            <option value={48}>49</option>
                            <option value={50}>50</option>
                            <option value={51}>51</option>
                            <option value={52}>52</option>
                            <option value={53}>53</option>
                            <option value={54}>54</option>
                            <option value={55}>55</option>
                            <option value={56}>56</option>
                            <option value={57}>57</option>
                            <option value={58}>58</option>
                            <option value={59}>59</option>
                        </select>
                    </td>
                </tr>

                <tr>
                    <th>등록자ID</th>
                    <td>사번 : {userInfo.employeeNumber} 성명 : {userInfo.username}</td>
                    <th>등록일시</th>
                    <td colSpan={2}>{getTodayDate()[0]}</td>
                </tr>
                </tbody>
            </table>
        </div>  {/*table*/}

        <div className="submit">
            <div className="fl">
                <button className="btn btn_lg btn_gray" onClick={() => props.history.replace(`/manage/sms`)}>목록</button>
            </div>
            <div className="fr">
                <button className="btn btn_lg btn_darkblue" onClick={() => {save()}}>등록</button>
                {/* <button className="btn btn_lg btn_outline">삭제</button> */}
            </div>
        </div>

        {isChoiceGuestsOpen ? (
        <ChoiceSmsGuest
            isOpen={isChoiceGuestsOpen}
            guests={store.guest}
            setIsOpen={setIsChoiceGuestsOpen}
            onApply={onUpdateGuests}
            setIsLoading={(data:boolean) => {headerStore.isLoading = data;}}
        />
      ) : (<></>)}
        </DefaultTemplate>
    );
});

export default SmsPushPage;