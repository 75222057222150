import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Checkout {
  static URL = `${IP}`;

  // 조회
  @apiHandler()
  static async findAll(payload: {limit: number, offset: number, status: string}) {
    const PATH = `/admin/lodge/checkout/`;

    return await axios.get(`${Checkout.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  // 단일조회
  @apiHandler()
  static async findOne(guestId: number, payload: {}) {
    const PATH = `/admin/lodge/checkout/${guestId}/`;

    return await axios.get(`${Checkout.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 퇴숙승인
  @apiHandler()
  static async accept(guestId: number, payload: {}) {
    const PATH = `/admin/lodge/checkout/${guestId}/accept/`;

    return await axios.post(`${Checkout.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 퇴숙재요청
  @apiHandler()
  static async reject(guestId: number, payload: {checkout_status_terminated_at: string, checkout_status_description: string}) {
    const PATH = `/admin/lodge/checkout/${guestId}/reject/`;

    return await axios.post(`${Checkout.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  // 퇴숙거절
  @apiHandler()
  static async terminate(guestId: number, payload: {checkout_status_description: string}) {
    const PATH = `/admin/lodge/checkout/${guestId}/terminate/`;

    return await axios.post(`${Checkout.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 퇴숙완료
  @apiHandler()
  static async complete(ids: any, payload: {checkout_at: string, checkout_keyin_at: string}) {
    const PATH = `/admin/lodge/checkout/bulk/complete/?ids=${ids}`;

    return await axios.post(`${Checkout.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Checkout;
