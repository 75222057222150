import React from 'react';

import { BOOKING_STATUS, ROOM_TYPE, STATUS } from '../../../constants';

import { formatByString_YYYYMMDD } from '../../../utils/date';

const ApplicantForm = (props: any) => {
  return (
    <>
    <h4>기본정보</h4>
    <ul className="lst_status">
        <li>
            <strong>신청일자</strong>
            <span>{props.createdAt}</span>
        </li>
        <li>
            <strong>숙소유형</strong>
            <span>{ROOM_TYPE[props.roomType]}</span>
        </li>
        <li>
            <strong>신청지역</strong>
            <span>{props.workSite}</span>
        </li>
        <li>
            <strong>진행상태정보</strong>
            {/* <span>{BOOKING_STATUS[props.status]}</span> */}
            <span>{props.cellStatusRenderer(props.detailData)}</span>
        </li>
    </ul>

    <h4>신청자정보</h4>
    <div className="table_normal_list">
        <table>
        <caption className="sr_only">신청자정보</caption>
        <colgroup>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
        </colgroup>
        <thead>
            <tr>
                <th>사번(아이디)</th>
                <th>성명</th>
                <th>성별</th>
                <th>소속부서</th>
                <th>휴대폰번호</th>
            </tr>
        </thead>
        <tbody>
        {/*데이타 없을 시*/}
        {/*<tr>
            <td colspan="" className="blank_area type_white">
                <p className="txt_blank">데이터가 없습니다.</p>
            </td>
        </tr>*/}
        <tr>
            <td>{props.applicant.employeeNumber}</td>
            <td>{props.applicant.name}</td>
            <td>{props.applicant.gender === 1? '남' : '여'}</td>
            <td>{props.applicant.department}</td>
            <td>{props.applicant.cellphone}</td>
        </tr>
        </tbody>
        </table>
    </div>
    </>
  );
};

export default ApplicantForm;
