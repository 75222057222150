import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Voc {
  static URL = `${IP}`;

  /**
   * VOC 내역조회
   * @param payload
   */
  @apiHandler()
  static async getVocs(payload: any) {
    const PATH = `/admin/board/voc/`;

    return await axios.get(`${Voc.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // VOC 조회
  @apiHandler()
  static async getVoc(id: string) {
    const PATH = `/admin/board/voc/`;

    return await axios.get(`${Voc.URL}${PATH}${id}/`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // VOC 등록
  @apiHandler()
  static async createVoc(payload: any) {
    const PATH = `/admin/board/voc/`;

    return await axios.post(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // VOC 수정
  @apiHandler()
  static async updateVoc(id: string, payload: any) {
    const PATH = `/admin/board/voc/`;

    return await axios.patch(`${Voc.URL}${PATH}${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }


  // 공지사항 삭제
  @apiHandler()
  static async deleteVoc(id: string) {
    const PATH = `/admin/board/voc/`;

    return await axios.delete(`${Voc.URL}${PATH}${id}/`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  // comment 생성
  @apiHandler()
  static async addComment(payload: any) {
    const PATH = `/admin/board/voc/comment/`;

    return await axios.post(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //[유지보수] 답변만 저장
  @apiHandler()
  static async addOnlyComment(payload: any) {
    const PATH = `/admin/board/voccomment/`;

    return await axios.post(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //[유지보수] 상태값만 저장
  @apiHandler()
  static async statusChagne(payload: any) {
    const PATH = `/admin/board/voccomment/`;

    return await axios.put(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  /**
   * 매니저 정보를 가져온다.
   * @param payload
   */
  @apiHandler()
  static async getManages(payload: any) {
    const PATH = `/admin/auth/user/?is_staff=true`;

    return await axios.get(`${Voc.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // VOC 카테고리 조회
  @apiHandler()
  static async getCategories(payload: any) {
    const PATH = `/admin/board/voc/category/`;

    return await axios.get(`${Voc.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // VOC 카테고리 등록
  @apiHandler()
  static async createCategory(payload: any) {
    const PATH = `/admin/board/voc/category/`;

    return await axios.post(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // VOC 카테고리 등록
  @apiHandler()
  static async updateCategory(id: number, payload: any) {
    const PATH = `/admin/board/voc/category/${id}/`;

    return await axios.patch(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //4차분류 삭제
  @apiHandler()
  static async deleteCategory(id: string) {
    const PATH = `/admin/board/voc/category/${id}/`;

    return await axios.delete(`${Voc.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Voc;
