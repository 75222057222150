import { observer } from 'mobx-react';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LodgedTotal from '..';
import { CHECKOUT_STATUS, CHECKOUT_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE } from '../../../constants';
import { MessageContext, MessageImpl } from '../../../routes';
import { useStores } from '../../../store/mobx';
import { errorAlert, showAlert, showConfirm } from '../../../utils/common';
import MngFileUpload from '../../../components/organismas/form/managementFile';
import { formatByString_YYYYMMDD, format_YYYYMMDD, getDate, getDays, getMonth, getMonths, getYear, getYear5 } from '../../../utils/date';
import ConfirmBtn from '../../../components/atoms/button/confirm';
import { SHOW_ALERT_MODAL } from '../../../store/modules/modal';
import { core } from '../../../apis';
import FileSaver from 'file-saver';

const LodgedCheckOutDetail = observer((props: any) => {
    const dispatch = useDispatch();
    const { loadgedTotalStore: store } = useStores();
    const { headerStore: headerStore } = useStores();
    const { info, warning, error }: MessageImpl = useContext(MessageContext);
    const [isCheckOutPop, setIsCheckOutPop] = useState<boolean>(false);
    const [isImgPop, setIsImgPop] = useState<boolean>(false);
    const [imgPath, setImgPath] = useState<string>('');
    const [isShowReflect, setIsShowReflect] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user
    }));

    //rendering시 리스트 뽑아오기
    useEffect(() => {
        if(!store.checkOutDetailItem || store.checkOutDetailItem.length === 0) {
            props.history.replace(`/guest/status/checkout`);
            return;
        }
        (async () => {
            headerStore.getAuthMenuRole('2-3');
            if(store.checkOutDetailItem) {
                fetchData();
            }
        })();
    }, [reload]);

    const saveCheckOut = async () => {
        showConfirm('퇴숙처리 하시겠습니까?', dispatch, async () => {
            if (store.manageYear === '' || store.manageMonth === '' || store.manageDay === '') {
                info('필수 입력사항을 체크하여주세요');
                return false;
            } else if (!store.isKeyReturn && store.keySelect === '기타' && (store.keyDescription === '' || store.keyDescription === null)) {
                info('열쇠 미 반납 이유를 적어주세요');
                return false;
            } else if (store.isEquipment && (store.equipmentDescription === '' || store.equipmentDescription === null)) {
                info('비품 이상 여부를 적어주세요');
                return false;
            }else if(store.manageYear !== '' && store.manageMonth !== '' && store.manageDay !== '' ){
                if(formatByString_YYYYMMDD(store.checkOutDetailItem.checkin_at) > format_YYYYMMDD(store.manageYear, store.manageMonth, store.manageDay)){
                    info('퇴숙일이 입숙일보다 빠를 수 없습니다.');
                    return false;
                }
            }

            try {
                headerStore.isLoading = true;
                store.checkoutData = store.checkOutDetailItem;
                let data : any = await store.checkOut();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsCheckOutPop(false);
                        info('퇴숙처리가 완료되었습니다');
                        setReload(!reload);
                        popInit();
                    }else {
                      return;
                    }
                }, 250);
            } catch ({ status, data }) {
                headerStore.isLoading = false;
                if (status === 401) {
                  if (!window.localStorage.getItem("isRetryLogin")) {
                    window.localStorage.setItem("isRetryLogin", "retry");
                  }
                  window.location.href = '/';
                  return ;
                } else if (status === 403) {
                  (window as any).store.dispatch({
                    type: SHOW_ALERT_MODAL,
                    payload: {
                      title: '권한오류',
                      msg: '접근권한이 없습니다.',
                      redirectPath: false,
                      statusCode: status,
                      errorCode: '',
                      type: 'error',
                      isModalShow: true,
                    },
                  });
                  return ;
                }else if ( status === 500 ) {
                  window.location.href = '/500';
                  return;
                }
                return;
            }
        });
    }

    // 상세 데이터
    const fetchData = async() => {
        try {
            headerStore.isLoading = true;
            store.getGuestCheckoutDetail();
            headerStore.isLoading = false;
        } catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
    }

    const popInit = () => {
        store.manageYear = getYear();
        store.manageMonth = getMonth();
        store.manageDay = getDate();
        store.isKeyReturn = true;
        store.keyDescription = ''
        store.keySelect = '열쇠 분실';
        store.isEquipment  = false;
        store.equipmentDescription = '';
        store.images = [];
        store.mealTicket = 0;
        store.status_description = '';
        store.checkoutStatus = 4;
    }

    // 승인 처리
    const applySubmit = async() => {
        try {
            headerStore.isLoading = true;
            let data : any = await store.applySubmit();
            setTimeout(function () {
                if(data.save) {
                    info('승인처리가 완료되었습니다');
                    setReload(!reload);
                }else {
                  info('문제가 발생했습니다');
                  return;
                }
            }, 250);
            headerStore.isLoading = false;
        }  catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
    }

    // 반려, 재요청 처리
    const rejectSubmit = async() => {
        try {
            headerStore.isLoading = true;
            if(store.checkoutStatus === CHECKOUT_STATUS_CODE.terminate) {
                let data : any = await store.terminateSubmit();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsShowReflect(false);
                        info(CHECKOUT_STATUS[store.checkoutStatus]+'처리가 완료되었습니다');
                        setReload(!reload);
                        popInit();
                    }else {
                        info('문제가 발생했습니다');
                      return;
                    }
                }, 250);
            }else {
                let data : any = await store.rejectSubmit();
                headerStore.isLoading = false;
                setTimeout(function () {
                    if(data.save) {
                        setIsShowReflect(false);
                        info(CHECKOUT_STATUS[store.checkoutStatus]+'처리가 완료되었습니다');
                        setReload(!reload);
                        popInit();
                    }else {
                      info('문제가 발생했습니다');
                      return;
                    }
                }, 250);
            }
        }  catch ({ status, data }) {
            headerStore.isLoading = false;
            if (status === 401) {
              if (!window.localStorage.getItem("isRetryLogin")) {
                window.localStorage.setItem("isRetryLogin", "retry");
              }
              window.location.href = '/';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_ALERT_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
            return;
        }
    }

    // 퇴숙 진행상태
    const getChekcoutStatus = (item: any) => {
        let status = parseInt(item.checkoutrequest.status);
        if(status === CHECKOUT_STATUS_CODE.apply) {
            return (
                <>
                    {headerStore.mobileRoleFlag ? 
                        <>
                            <button className="btn btn_lg btn_darkblue fr" onClick={() => {store.setCheckoutData(item); setIsShowReflect(true);}}>반려</button>
                            <button className="btn btn_lg btn_outline fr" onClick={()=> {store.setCheckoutData(item); showConfirm('승인 하시겠습니까?', dispatch, applySubmit);}}>승인</button>
                        </>
                    :   <>
                        </>
                    }
                </>
            )
        }else if(status === CHECKOUT_STATUS_CODE.reject) {
            return( 
                <>
                    {headerStore.mobileRoleFlag ?
                    <>
                        <button className="btn btn_lg btn_outline fr" onClick={()=>{store.setCheckoutData(item); setIsShowReflect(true)}}>거절사유</button>
                    </>
                    : <></>}
                </>
            )
        }else if(status === CHECKOUT_STATUS_CODE.terminate) {
            return( 
                <>
                    {headerStore.mobileRoleFlag ?
                    <>
                        <button className="btn btn_lg btn_outline fr" onClick={()=>{store.setCheckoutData(item); setIsShowReflect(true)}}>사유</button>
                    </>
                    : <></>}
                </>
            )
        }

        return <></>;
    }
    // 파일의 확장자를 가져오는 함수
    const getFileExtension = (filename: string) => {
      const parts = filename.split('.');
      console.log(filename);
      if (parts.length > 1) {
        return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
      } else {
        return ''; // 마침표가 없는 경우 빈 문자열 반환
      }
    };

    const downloadFile = async (url: string, filename: string) => {
      const token = window.localStorage.getItem('accessToken');

      if (!url) {
        console.error('url 경로가 없습니다.');
        return;
      }

      if(!filename || filename.length == 0) {
        const name = url.split("/");
        filename = name[name.length - 1];
      }
    
      const unifiedUrl = url.replace(/\\/g, '/');
      const parts = unifiedUrl.split('/');
      const desiredParts = parts.slice(2).join('\\');

      // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
      const isImageOrPDF = (extension: string) => {
        const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
        return supportedExtensions.includes(extension);
      };
    
      const fileExtension = getFileExtension(filename);
      const isImageOrPDFFile = isImageOrPDF(fileExtension);
    
      if(isImageOrPDFFile) {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
      else {
        //fileDownload
        try {
          let payload: any = {
            filename: filename,
            url : desiredParts,
            employeeNumber : userInfo.employeeNumber,
            classification : userInfo.classification,
          };

          let { data } = await core.fileDownload(payload);
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          //info("협조전 등록이 완료되었습니다.");
        } catch ({ data, status }) {
          console.log(data, status);
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_ALERT_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
          info("파일 다운로드 중 문제가 발생했습니다");
          return;
        }
    }
      
    };

    if(!store.checkOutDetailItem || store.checkOutDetailItem.length === 0) return <></>;

    return (
        <LodgedTotal
            id=""
            className=""
            isLoading={headerStore.isLoading}
            {...props}
        >
            <h4>기본정보</h4>
            <ul className="lst_status type2">
                <li>
                    <strong>숙소유형</strong>
                    <span>{ROOM_TYPE[store.checkOutDetailItem.room_type]}</span>
                </li>
                <li>
                    <strong>이용사업장</strong>
                    <span>{store.checkOutDetailItem.room_work_site}</span>
                </li>
                <li>
                    <strong>입숙일</strong>
                    <span>{store.checkOutDetailItem.checkin_at}</span>
                </li>
                <li>
                    <strong>입숙만료일</strong>
                    <span>{store.checkOutDetailItem.expired_at}</span>
                </li>
                <li>
                    <strong>배방</strong>
                    <span>{store.checkOutDetailItem.room_building_name} {store.checkOutDetailItem.room_building_dong} {store.checkOutDetailItem.room_name}호</span>
                </li>
            </ul>

            <h4>퇴숙신청 정보 입력</h4>
            <div className="table_normal_list table_write">
                <table>
                    <caption className="sr_only">퇴숙신청 정보 입력</caption>
                    <colgroup>
                        <col width="160" />
                        <col width="*" />
                    </colgroup>
                    <tbody>
                        {/*데이타 없을 시*/}
                        {/*<tr>
                        <td colspan="" className="blank_area type_white">
                            <p className="txt_blank">데이터가 없습니다.</p>
                        </td>
                    </tr>*/}
                        <tr>
                            <th>퇴숙요청일</th>
                            <td>
                                {store.checkOutDetailItem.stay_before}
                            </td>
                        </tr>
                        <tr>
                            <th>퇴숙사유</th>
                            {store.checkOutDetailItem.checkoutrequest ? (
                                <>
                                    {store.checkOutDetailItem.checkoutrequest.reason_select === "기타" ? (
                                        <>
                                            <td>
                                                <span className="fwb">{store.checkOutDetailItem.checkoutrequest.reason_select} I {store.checkOutDetailItem.checkoutrequest.reason}</span>
                                            </td>

                                        </>
                                    ) : (
                                        <>
                                            <td><span className="fwb">{store.checkOutDetailItem.checkoutrequest.reason_select}</span></td>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <td></td>
                                </>
                            )}
                        </tr>
                        <tr>
                            <th>요청사항</th>
                            <td>
                                {store.checkOutDetailItem.checkoutrequest ? (
                                    <>
                                        {store.checkOutDetailItem.checkoutrequest.claim}
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>비품 이상유무 체크</th>
                            <td>
                                {store.checkOutDetailItem.checkoutrequest ? (
                                    <>
                                        <label className="input_radio" htmlFor="radio_warning1">
                                            <input type="radio" id="sample_case2" name="sample" checked={!store.checkOutDetailItem.checkoutrequest.is_equipment_ok} />
                                            <span className="label_text">숙소 상태 및 비품 등에 이상이 없습니다.</span>
                                        </label>
                                        <label className="input_radio" htmlFor="radio_warning2">
                                            <input type="radio" id="sample_case1" name="sample" checked={store.checkOutDetailItem.checkoutrequest.is_equipment_ok} />
                                            <span className="label_text">숙소 상태 및 비품 등에 이상이 있습니다.</span>
                                        </label>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </td>
                        </tr>
                        {store.checkOutDetailItem.checkoutrequest.is_equipment_ok? (
                            <tr>
                                <th>이상 품목</th>
                                <td>
                                    {store.checkOutDetailItem.checkoutrequest && store.checkOutDetailItem.checkoutrequest.equipment_description? (
                                        <>
                                            {store.checkOutDetailItem.checkoutrequest.equipment_description}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ) :''}

                        {store.checkOutDetailItem.checkoutrequest && store.checkOutDetailItem.checkoutrequest.files ? store.checkOutDetailItem.checkoutrequest.files.map((item: any, idx: number) => (
                            <>
                                <tr key={idx}>
                                    <th>{item?.document?.name}</th>
                                    <td>{<button 
                                    className="btn_sm btn_darkblue btn_download"
                                    onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${item.file.path}`, '')}
                                    >
                                    {item.file.name}
                                    </button>
                                    /*<a className="item file" href={`${process.env.REACT_APP_MEDIA}/${item.file.path}`} download >{item.file.name}</a>*/}</td>
                                </tr>
                            </>
                        )) : ''}

                    </tbody>
                </table>
            </div>  {/*table*/}
            
            {/* 퇴숙 완료인 경우 */}
            {store.checkOutDetailItem.checkout_at ? (
                <>
                <h4>퇴숙정보</h4>
                 <div className="table_normal_list table_write">
                    <table>
                        <caption className="sr_only">퇴숙정보</caption>
                        <colgroup>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                            <col width="200"/>
                            <col width="*"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>최종 퇴숙일</th>
                            <td>{store.checkOutDetailItem.checkout_at}</td>
                            <th>열쇠 반납 여부</th>
                            <td>
                                {store.checkOutDetailItem.is_key_return? '반납 완료' : 
                                store.checkOutDetailItem.key_select !== "기타" ? '미 반납 (' + store.checkOutDetailItem.key_select +')' :
                                '미 반납 (' + store.checkOutDetailItem.key_select + ' : ' + store.checkOutDetailItem.key_description + ' )'}
                            </td>
                            <th>비품 이상 여부</th>
                            <td>{!store.checkOutDetailItem.is_equipment_ok? '이상 없음' : '이상 있음 (' + store.checkOutDetailItem.equipment_description + ')'}</td>
                        </tr>
                        {store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room || store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room_group? (
                            <tr>
                                <th>식권수량</th>
                                <td>{store.checkOutDetailItem.meal_ticket}</td>
                                <th>반납수량</th>
                                <td>{store.checkOutDetailItem.return_meal_ticket}</td>
                                <th>사용수량</th>
                                <td>{(store.checkOutDetailItem.meal_ticket - store.checkOutDetailItem.return_meal_ticket)}</td>
                            </tr>
                        ): ''}
                        <tr>
                            <th>숙소 이미지</th>
                            <td colSpan={5}>
                                <div className="images_area">
                                    {store.checkOutDetailItem.checkout_files && store.checkOutDetailItem.checkout_files.length > 0 ? (
                                        store.checkOutDetailItem.checkout_files.map((image: any, index: number) => (
                                            <div className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                                <a key={index} >
                                                <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                                                </a>
                                            </div>
                                        ))
                                    ) :''}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> 
                </>
            ) :''}

            <div className="submit">
                <button className="btn btn_lg btn_gray fl" onClick={() => props.history.replace(`/guest/status/checkout/`)}>목록</button>
                {store.checkOutDetailItem.checkout_at ? (
                    <></>
                ) : ((store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.dormitory_room || store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.company_room) && store.checkOutDetailItem.checkoutrequest.status !== CHECKOUT_STATUS_CODE.approve ) ? (
                    getChekcoutStatus(store.checkOutDetailItem)
                ) : (
                    <>
                        {headerStore.finallyRoleFlag ? <button className="btn btn_lg btn_darkblue fr" onClick={() => { setIsCheckOutPop(true) }}>퇴숙처리</button> : <></>}
                    </>
                )}
            </div>

            {/* 이미지 팝업 */}
            <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
                <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
                <div className="pop_wrap">
                    <div className="contents_wrap">
                        <div className="slide">
                            <p className="img_area">
                                <img src={imgPath} alt=""/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="pop_change_request" className="pop_dim_wrap pop_modal pop_sm" style={{ display: isCheckOutPop ? 'block' : 'none' }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>퇴숙완료처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() => { popInit(); setIsCheckOutPop(false) }}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <ul className="request_date">
                            <li>
                                <strong>퇴숙요청일</strong>
                                <span>{store.checkOutDetailItem.stay_before}</span>
                            </li>
                            <li>
                                <strong>신청일</strong>
                                <span>{store.checkOutDetailItem.checkoutrequest && store.checkOutDetailItem.checkoutrequest.created_at ? formatByString_YYYYMMDD(store.checkOutDetailItem.checkoutrequest.created_at) : ''}</span>
                            </li>
                        </ul>
                        <div className="process_list">
                            <dl>
                                <dt>열쇠 반납 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="return_check">
                                            <input type="radio" id="return_check" name="return" checked={store.isKeyReturn} onClick={() => store.isKeyReturn = true} />
                                            <span className="label_text">반납 완료</span>
                                        </label>
                                        <label className="input_radio" htmlFor="return_check2">
                                            <input type="radio" id="return_check2" name="return" checked={!store.isKeyReturn} onClick={() => store.isKeyReturn = false} />
                                            <span className="label_text">미 반납</span>
                                        </label>
                                    </div>

                                    {!store.isKeyReturn ? (
                                        <div className="input_box">
                                            <select className="form_control" value={store.keySelect} onChange={(e: any) => store.keySelect = e.target.value}>
                                                <option value="열쇠 분실">열쇠 분실</option>
                                                <option value="기타">기타</option>
                                            </select>
                                            <input type="text" className="form_control" 
                                                placeholder="미 반납 이유를 적어주세요."
                                                value={store.keyDescription} 
                                                onChange={(e: any) => store.keyDescription = e.target.value} 
                                                disabled={store.keySelect !== "기타"}
                                            />
                                        </div>
                                    ) : ''}
                                </dd>

                                <dt>비품 이상 여부</dt>
                                <dd>
                                    <div className="radio_box">
                                        <label className="input_radio" htmlFor="fixtures_check">
                                            <input type="radio" id="fixtures_check" name="fixtures" checked={!store.isEquipment} onClick={() => store.isEquipment = false} />
                                            <span className="label_text">이상 없음</span>
                                        </label>
                                        <label className="input_radio" htmlFor="fixtures_check2">
                                            <input type="radio" id="fixtures_check2" name="fixtures" checked={store.isEquipment} onClick={() => store.isEquipment = true} />
                                            <span className="label_text">이상 있음</span>
                                        </label>
                                    </div>

                                    {store.isEquipment ? (
                                        <input type="text" className="form_control" placeholder="비품 이상 여부를 적어주세요."
                                            value={store.equipmentDescription} onChange={(e: any) => store.equipmentDescription = e.target.value} />
                                    ) : ''}
                                </dd>

                                {store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room || store.checkOutDetailItem.room_type === ROOM_TYPE_CODE.business_room_group ? (
                                    <>
                                        {/* <dt>식권 반납 수량</dt>
                                        <dd>
                                            <div className="input_box">
                                                <input type="text" className="form_control" placeholder="식권 반납 수량을 입력해주세요"
                                                    value={store.mealTicket} onChange={(e:any) => store.mealTicket = parseInt(e.target.value) || 0}/>
                                            </div>
                                        </dd> */}

                                        <ul className="request_date">
                                            <li>
                                                <strong>식권수량</strong>
                                                <span>{store.checkOutDetailItem.meal_ticket}</span>
                                            </li>
                                            <li>
                                                <strong>반납수량</strong>
                                                <span>
                                                    <input type="text" className="form_control" placeholder="식권 반납 수량을 입력해주세요" style={{ 'width': '50px' }}
                                                        value={store.mealTicket} onChange={(e: any) => store.mealTicket = parseInt(e.target.value) || 0}
                                                        maxLength={4}
                                                    />
                                                </span>
                                            </li>
                                            <li>
                                                <strong>사용수량</strong>
                                                <span>{(store.checkOutDetailItem.meal_ticket - store.mealTicket)}</span>
                                            </li>
                                        </ul>
                                    </>
                                ) : ''}
                            </dl>
                        </div>

                        <div className="table_normal_list mt10">
                            <table>
                                <caption className="sr_only">숙소 이미지 첨부</caption>
                                <colgroup>
                                    <col width="*" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className="ac">숙소 이미지 첨부</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="al">
                                            <MngFileUpload type="image" fileId="attacheFile" items={store.images} onSetData={(data: any[]) => store.images = [...data]} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>  {/*table*/}

                        <div className="process_list mt20">
                            <dl>
                                <dt>최종 퇴숙일</dt>
                                <dd>
                                    <select className="form_control year" value={store.manageYear} onChange={(e: any) => store.manageYear = e.target.value}>
                                        {getYear5(true).map((year: any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                    <select className="form_control month" value={store.manageMonth} onChange={(e: any) => store.manageMonth = e.target.value}>
                                        {getMonths(store.manageYear, true).map((year: any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                    <select className="form_control day" value={store.manageDay} onChange={(e: any) => store.manageDay = e.target.value}>
                                        {getDays(store.manageYear, store.manageMonth, false).map((year: any, index: number) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </dd>
                                {/* <dt>비고입력</dt>
                                <dd>
                                    <textarea className="form_control"></textarea>
                                </dd> */}
                            </dl>
                        </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        {/* <button type="button" className="btn btn_lg btn_outline pop_close">취소</button> */}
                        <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={saveCheckOut}>완료</button>
                    </div>
                </div>
            </div>

            {/* 거절 popup */}
            {store.checkOutDetailItem && store.checkOutDetailItem.checkoutrequest && store.checkOutDetailItem.checkoutrequest.status?(
                <div id="pop_companion" className="pop_dim_wrap pop_modal pop_sm" style={{display: isShowReflect? "block": "none" }}>
                    <div className="pop_wrap">
                        <div className="title_wrap">
                            <h3>반려처리</h3>
                        <button className="pop_btn_close pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>닫기</button>
                        </div>
                        <div className="contents_wrap">
                        <div className="process_list">
                            <dl>
                                <dt>반려항목</dt>
                                <dd>
                                    {store.checkOutDetailItem.checkoutrequest.status === CHECKOUT_STATUS_CODE.apply? (
                                        <select className="form_control" value={store.checkoutStatus} onChange={(e: any) => store.checkoutStatus = (parseInt(e.target.value))}>
                                            <option value={CHECKOUT_STATUS_CODE.terminate}>재요청</option>
                                            {/* <option value={CHECKOUT_STATUS_CODE.reject}>거절</option> */}
                                        </select>
                                    ):(
                                        <select className="form_control" value={store.checkOutDetailItem.checkoutrequest.status} disabled>
                                            <option value={CHECKOUT_STATUS_CODE.terminate}>재요청</option>
                                            {/* <option value={CHECKOUT_STATUS_CODE.reject}>거절</option> */}
                                        </select>
                                    )}
                                </dd>
                                <dt>사유</dt>
                                <dd>
                                    {store.checkOutDetailItem.checkoutrequest.status === CHECKOUT_STATUS_CODE.apply? (
                                        <textarea className="form_control"
                                            value={store.status_description}
                                            onChange={(e: any) => store.status_description = e.target.value}
                                        >
                                        </textarea>
                                    ) : (
                                        <textarea className="form_control"
                                            value={store.checkOutDetailItem.checkoutrequest.status_description}
                                            disabled
                                        >
                                        </textarea>
                                    )}
                                </dd>
                            </dl>
                        </div>
                        </div>
                        <div className="button_wrap btn_length_1">
                            {store.checkOutDetailItem.checkoutrequest.status === CHECKOUT_STATUS_CODE.apply? (
                                <ConfirmBtn
                                    className="btn btn_lg btn_darkblue pop_close"
                                    action={rejectSubmit}
                                    title={""}
                                    label="확인"
                                    msg={CHECKOUT_STATUS[store.checkoutStatus]+"하시겠습니까?"}
                                />
                            ) : (
                                <button type="button" className="btn btn_lg btn_darkblue pop_close" onClick={() => {setIsShowReflect(false); popInit();}}>확인</button>
                            )}
                        </div>
                    </div>
                </div>
            ):''}
        </LodgedTotal>
    )
});

export default LodgedCheckOutDetail;